import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { AppState } from '../../../../../reducers/rootReducer';
import { StartDeleteSstImage, startSetModalImageStt } from '../actions/images';
import { ModalImageSst } from './ModalImageSst';
import { Gallery } from '../../../../../shared';
import { deleteImagen } from '../../../../../helpers';


export const RegistroFotografico = () => {
	const disptach = useDispatch();
	const { images } = useSelector((state: AppState) => state.sst.modules.imagesSst);

	const handeAddImagen = () => {
		disptach(startSetModalImageStt(true))
	}

	const handleDelete = async (id: number) => {
		const res = await deleteImagen(id);				
		if (res.status) {			
			disptach(StartDeleteSstImage(id));
		}
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<IconButton color="primary" aria-label="Ubicación" size="large" onClick={handeAddImagen}>
					<AddAPhotoIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}>
				{images.length > 0 && <Gallery images={images} handleDelete={handleDelete} />}
			</Box>
			<ModalImageSst />
		</>
	)
}
