import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { MaterialTable } from './MaterialTable';
import { StyleProps } from '../../../modules/reportes/interfaces/reportes';

const Table = memo(() => {
    return (
        <MaterialTable />
    );
});

export const Material = ({mt = 4, mb = 1 }: StyleProps) => {
    return (
        <>
            <Grid container sx={{ mt, mb }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Listado de materiales autilizados
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}
