import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { startSetActiveLista, startSetIsNewLista, startSetModalLista } from '../actions/lista';

export const ListaToolBar = () => {
	const dispatch = useDispatch();
    const handleClick = () => {
		dispatch(startSetActiveLista(''));
        dispatch(startSetIsNewLista(true));
        dispatch(startSetModalLista(true));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Nueva Lista
            </Button>
        </GridToolbarContainer>
    );
}