import { ListaAction, ListaState } from "../interfaces/interfaces"

const initState: ListaState ={
listas: [],
activeLista: null,
modalLista: false,
alertDeleteLista: false,
isNew: false
}

export const listaReducer = (state = initState , action: ListaAction): ListaState  => {
switch (action.type) {
    case 'load-listas':
        return{
            ...state,
            listas: action.payload
        };

    case 'create-lista':
        return {
            ...state,
            listas: [ ...state.listas, action.payload ]
        }

    case 'update-lista':
        return {
            ...state,
            listas: state.listas.map(l => (l.id === action.payload.id) ? action.payload : l)
        }

    case 'delete-lista':
        return {
            ...state,
            listas: state.listas.filter(l => l.id !== action.payload)
        }

    case 'set-active-lista':
        const lista = state.listas.find(l => l.id === action.payload);
        return {
            ...state,
            activeLista: lista ? lista : null
        }
    
    case 'set-modal-lista':
        return {
            ...state,
            modalLista: action.payload
        }

    case 'set-alert-delete-lista':
        return {
            ...state,
            alertDeleteLista: action.payload
        }

    case 'set-isnew-lista':
        return {
            ...state,
            isNew: action.payload
        }

    default:
        return{
            ...state
        };
}
}