import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { HeaderTableVehiculo } from './HeaderTableVehiculo';
import { TableVehiculoRow } from './TableVehiculoRow';
import { TitleTables } from '../../../../../components/pdf/TitleTables';
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
    },
});

const title = 'Describa los Vehículos utilizados para el transporte del Personal y/o Herramientas:';
interface Props {
    active: Reporte;
  }

  export const VehiculoTable = ({active}:Props) => (
    <View>
    <TitleTables
        title='Vehículos utilizados'
        sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
      />
    <TitleTables title={title} sx={{fw: 'normal', fz:10}} />
    <View style={styles.tableContainer}>
        <HeaderTableVehiculo />
        <TableVehiculoRow items={active.vehiculos!} />
        {/*<TableBlankSpace rowsCount={ tableRowsCount - active.herramientas!.length} />
        <TableFooter items={active.herramientas} />*/}
    </View>
    </View>
  );
