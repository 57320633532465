import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent } from "@mui/material";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import { AppState } from "../../../../reducers/rootReducer";
import { useScreenSize } from "../../../../hooks";
import { ModalActions, ModalBar } from "../../../../shared";
import { startSetModalInformePdf } from "../../actions/informes";
import { Loading, ButtonDownload, Transition } from "../../../../components";
import { InformeDiarioPdf } from "./diario/InformeDiarioPdf";

export const ModalInformePdf = () => {
  const dispatch = useDispatch();
  const { activeInforme, modalInformePdf } = useSelector(
    (state: AppState) => state.informes.informes);

  const{ width }= useScreenSize();

  const fileName = `Reporte Mantenimiento No ${activeInforme?.obra?.numero}.pdf`;
  const handleClose = () => {
    dispatch(startSetModalInformePdf(false));
  };
  return (
    <>
      <Dialog
        open={modalInformePdf}
        onClose={handleClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <ModalBar
          handleClose={handleClose}
          title="Informe desarrollo item de obra"
          pdf={true}
        />
        <DialogContent>
          {width > 1024 ? (
            <PDFViewer style={{ width: "100%", height: "82vh" }}>
              {<InformeDiarioPdf active={activeInforme!}/>}
            </PDFViewer>
          ) : (
            <PDFDownloadLink document={<InformeDiarioPdf active={activeInforme!} />} fileName={fileName}>
              {({ blob, url, loading, error }) =>
                loading ? <Loading /> : <ButtonDownload />
              }
            </PDFDownloadLink>
          )}
        </DialogContent>
		<ModalActions handleClose={handleClose} pdf={true} />
      </Dialog>
    </>
  );
};
