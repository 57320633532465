import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';

import { AppState } from '../../../../../reducers/rootReducer';
import imagen from '../../../../../assets/img/logo_beling.png';
import { ModalActividad } from './ModalActividad';
import { CellExpand } from '../../../../../components/ui/CellExpand';
import { deleteImagen } from '../../../../../helpers';
import { useScreenSize } from '../../../../../hooks';
import { ModalImagesActividadNoPlaneada } from './ModalImagesActividadNoPlaneada';
import { 
    startDelImgActividadNoPlaneada, 
    startDeleteActividadNoPlaneada, 
    startSetActiveActividadNoPlaneada, 
    startSetModImgActividadNoPlaneada, 
    startSetModadlActividadNoPlaneada, 
    startUpdateActividadNoPlaneada 
} from '../action/actividad';
import { ActividadToolbar } from './ActividadToolbar';
import { doMessage } from '../../../../ui/actions/ui';
import { Actividad } from '../interfaces/actividad';

export const ActividadTable = () => {
    const { width } = useScreenSize();
    const dispatch = useDispatch();
    const { actividadesNo, modalActividadNo, activeActividadNo } = useSelector((state: AppState) => state.reportes.modules.actividadNoContemplada);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        const actividad = actividadesNo.find(a => a.id === id);

        if (actividad?.images && actividad?.images?.length > 0) {
            actividad.images.forEach(async (i) => {
                const dele = await deleteImagen(i.id);
                if (dele.status) {
                    dispatch(startDelImgActividadNoPlaneada(i.id!, id))
                } else {
                    dispatch(doMessage({ message: 'Error guardando la imagen', type: 'error' }))
                }
            })
        }
        dispatch(startDeleteActividadNoPlaneada(id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const hanleAddImageClick = (id: GridRowId) => () => {
        dispatch(startSetActiveActividadNoPlaneada(id))
        dispatch(startSetModadlActividadNoPlaneada(true));
    };

    const handleViewImages = (id: GridRowId) => () => {
        dispatch(startSetActiveActividadNoPlaneada(id));
        dispatch(startSetModImgActividadNoPlaneada(true));
    }

    const processRowUpdate = (newRow: GridRowModel<Actividad>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateActividadNoPlaneada(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'actividad',
            headerAlign: 'center',
            headerName: 'Actividad No Contemplada',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'observaciones',
            headerAlign: 'center',
            headerName: 'Observaciones',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'cantidad',
            headerAlign: 'center',
            headerName: 'Cantidad',
            type: 'number',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true,
        },
        {
            field: 'imagen',
            headerAlign: 'center',
            headerName: 'Imagén',
            editable: false,
            renderCell: (row) => {
                const images = actividadesNo.find(r => r.id === row.id)?.images;
                const lastIndex = images?.length! - 1;
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'contain',
                            backgroundImage: images?.length! > 0 ? `url(${row.row.images[lastIndex].uri})` : `url(${imagen})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                );
            },
            flex: width > 900 ? 1 : 0,
            width: 150,
        },
        {
            field: 'actions',
            headerAlign: 'center',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<AddAPhotoRoundedIcon />}
                            label="Agregar imagen"
                            className="textPrimary"
                            onClick={hanleAddImageClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<ImageSearchRoundedIcon />}
                        label="Ver imagenes"
                        className="textPrimary"
                        onClick={handleViewImages(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={actividadesNo.slice(0, actividadesNo.length)}
                columns={columns}
                editMode="row"
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } }
                }}
                slots={{
                    toolbar: ActividadToolbar,
                }}
                slotProps={{
                    toolbar: { setRowModesModel },
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay registros' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            {modalActividadNo && <ModalActividad />}
            {activeActividadNo && <ModalImagesActividadNoPlaneada />}
        </Box>
    );
}
