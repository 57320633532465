import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';

import logo from '../../../../../assets/img/logo_beling.png'
import { Informe } from '../../../interfaces/informes';
import { RightColumn } from './RightColumn';



const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
	},
	column: {
		flexDirection: 'column',
		border: 1,
		alignItems: 'center',
		padding: 5,

	},
	lefColumn: {
		width: '25%'
	},
	centerColumn: {
		justifyContent: 'space-around',
		height: '100%',
		width: '50%',
		marginLeft: -1
	},
	rowContainer: {
		flexDirection: 'row',
	},
	centralTopContainer: {
		borderBottom: 1,
		paddingBottom: 8,
		marginTop: 6,
		width: '103%',
		justifyContent: 'center',
	},
	centralTitle: {
		fontSize: 11,
		fontWeight: 'bold'
	},
	logo: {
		width: 80,
		height: 72,
		margin: 2
	}
})

interface Props {
	active: Informe;
}

export const LogoHeader = ({ active }: Props) => {
	return (
		<>
			<View style={styles.row} fixed>
				<View style={[styles.column, styles.lefColumn]}>
					<Image src={logo} style={styles.logo} />
				</View>
				<View style={[styles.column, styles.centerColumn]}>
					<View style={[styles.centralTopContainer, styles.rowContainer]}>
						<Text style={styles.centralTitle}>INFORME DE OBRA</Text>
					</View>
					<View style={styles.rowContainer }>
						<Text style={styles.centralTitle}>{active.obra.cliente.nombre}</Text>
					</View>
				</View>
				<RightColumn />				
			</View>
		</>
	)
}
