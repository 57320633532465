import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { Gallery } from '../../../../shared/gallery/Gallery'
import { ModalImagePrev } from '../../modules/images_antes/components/ModalImagePrev';
import { startDeleteImagePrev, startSetModalImagePrev } from '../../modules/images_antes/actions/images';
import { AppState } from '../../../../reducers/rootReducer';
import { GridRowId } from '@mui/x-data-grid';
import { doMessage, startLoading } from '../../../ui/actions/ui';
import { deleteImagen } from '../../../../helpers';

export const StepEight = () => {
	const disptach = useDispatch();
	const { images, modalImagePrev } = useSelector((state: AppState) => state.reportes.modules.imagesPrev);

	const handeAddImagen = () => {
		disptach(startSetModalImagePrev(true))
	}

	const handleDelete = async (id: GridRowId) => {
		disptach(startLoading())
		const resp = await deleteImagen(id);
		if(resp.status === 1){
			disptach(startDeleteImagePrev(id))
		}else{			
			disptach(doMessage({message: 'Error eliminando la imagen', type: 'error'}))
		}
		disptach(startLoading())
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<IconButton color="primary" aria-label="Ubicación" size="large" onClick={handeAddImagen}>
					<AddAPhotoIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}>
				{images.length > 0 && <Gallery images={images} handleDelete={handleDelete}/> }
			</Box>
			{ modalImagePrev && <ModalImagePrev /> }
		</>
	)
}
