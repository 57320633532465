import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';
//import {v4 as uuid} from 'uuid'

import { EditToolbarProps } from '../../../../../interfaces/shared';
import { startSetModalRutinaBancoBaterias } from '../actions/rutina';


export const RutinaBancoBateriaToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
   // const { setRowModesModel } = props;
   

    const handleClick = () => {
        dispatch(startSetModalRutinaBancoBaterias(true))
        /* const id = uuid();
        dispatch(startSetRutinasRectificador({ id, numero: '', nombre: '', descripcion:'', observaciones: '', isNew: true }));

        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'actividad' },
        })); */
    }; 

    return (
        <GridToolbarContainer>           
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar Rutina
            </Button>
        </GridToolbarContainer>
    );
}