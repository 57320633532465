import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Vehiculo } from '../../../../../interfaces/informes';
import { Maquinaria } from '../../../../../../../interfaces/shared';
import { getDurationHhMm } from '../../../../../../../helpers';


const styles = StyleSheet.create({
	column: {
		flexDirection: 'column',
		borderBottom: 1,
		height: '100%',
	},
	rowContainer: {
		flexDirection: 'row',

	},
	borderRow: {
		borderBottom: 1,
		borderRight: 1,
		minHeight: 16
	},
	borderRowEnd: {
		borderBottom: 1,
		minHeight: 16
	},
	borderRowRight: {
		borderBottom: 1,
		minHeight: 16
	},
	borderRowBottom: {
		borderRight: 1,
		fontWeight: 'bold',
		minHeight: 15
	},
	borderRowBottomEnd: {
		fontWeight: 'bold',
		minHeight: 15
	},
	borderRowRightBottom: {
		fontWeight: 'bold',
		minHeight: 15
	},
	leftColumn: {
		flexDirection: 'column',
		width: '50%',
	},
	centerColumn: {
		flexDirection: 'column',
		width: '30%'
	},
	rightColumn: {
		flexDirection: 'column',
		width: '20%',

	},
	firstCell: {
		borderLeft: 1
	},
	cellRight: {
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: -1,
		
	},
	cellCenter: {
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: -1,
	},
	cellLeft: {
		alignItems: 'center',
	},
	headColumn: {
		justifyContent: 'center',
	},
	headText: {
		fontSize: 7,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 3
	},
	text: {
		fontSize: 7,
		fontWeight: 'normal',
		marginLeft: 3,
		marginTop: 3,
		textTransform: 'capitalize'
	},
	bottomText: {
		fontSize: 7,
		fontWeight: 'bold',
		marginLeft: 3,
		textTransform: 'capitalize'
	},
	rightText: {
		fontSize: 7,
		marginTop: 3,
		fontWeight: 'normal',
		textAlign: 'center'
	},
	rightBottomText: {
		fontSize: 7,
		fontWeight: 'normal',
		textAlign: 'center'
	},
})

interface Props {
	title: string;
	totalTitle: string;
	first?: boolean;
	equipos: Maquinaria[];
	vehiculos: Vehiculo[];
	sx?: {
		ml?: number,
		columnWidth?: string | number,
	}
}

export const Equipos = ({ equipos, title, totalTitle, first, sx, vehiculos }: Props) => {
	const { columnWidth } = sx ?? {
		ml: 0,
		columnWidth: 10
	}
	const border = first ? styles.firstCell : {};
	const blankRows = Array(9 - equipos.length).fill(0);
	const blankRows1 = Array(8 - vehiculos.length).fill(0);
	return (
		<View style={[styles.column, { width: columnWidth }]}>
			<View style={[styles.rowContainer, styles.borderRowRight, border, styles.headColumn]}>
				<Text style={[styles.headText]}>{title}</Text>
			</View>
			<View style={[styles.rowContainer]}>
				<View style={[styles.leftColumn, border]}>
					<LeftCell text='EQUIPOS' />
					{equipos.map((item) => {
						return (
							<LeftCell text={item.equipo} key={item.id} />
						)
					})}
					{blankRows.map((x, i) => <LeftCell text='' key={`ROW${i}`} />)}
					<LeftCell text={totalTitle} bottom />
				</View>
				<View style={[styles.centerColumn]}>
					<CenterCell text='HORARIO' />
					{equipos.map((item)=>{
						return(
							<CenterCell text={getDurationHhMm(item.horaInicio.toString(), item.horaFinal.toString())} key={item.id} />
						)
					})}
					{blankRows.map( (x, i) => <CenterCell text='' key={`ROW${i}`}/>)}
					<CenterCell text='' bottom/>
				</View>
				<View style={[styles.rightColumn]}>
					<RightCell text='CANT' />
					{equipos.map((item)=>{
						return(
							<RightCell text={item.cantidad!.toString()} key={item.id} />
						)
					})}
					{blankRows.map( (x, i) => <RightCell text='' key={`ROW${i}`}/>)}
					<RightCell text={sumaEquipos(equipos).toString()} bottom/>
				</View>
			</View>
			<View style={[styles.rowContainer, styles.borderRowRight, border, styles.headColumn, {borderTop: 1, height: 17}]}>
				<Text style={[styles.headText]}>VEHICULOS (CARGUE Y RETIRO RCD)</Text>
			</View>
			<View style={[styles.rowContainer]}>
				<View style={[styles.leftColumn, border]}>
					<LeftCell text='EQUIPOS' />
					{vehiculos.map((item) => {
						return (
							<LeftCell text={item.vehiculo} key={item.id} />
						)
					})}
					{blankRows1.map((x, i) => <LeftCell text='' key={`ROW${i}`} />)}
					<LeftCell text={totalTitle} bottom />
				</View>
				<View style={[styles.centerColumn]}>
					<CenterCell text='HORARIO' />
					{vehiculos.map((item)=>{
						return(
							<CenterCell text='' key={item.id} />
						)
					})}
					{blankRows1.map( (x, i) => <CenterCell text='' key={`ROW${i}`}/>)}
					<CenterCell text='' bottom/>
				</View>
				<View style={[styles.rightColumn]}>
					<RightCell text='CANT' />
					{vehiculos.map((item)=>{
						return(
							<RightCell text="1" key={item.id} />
						)
					})}
					{blankRows1.map( (x, i) => <RightCell text='' key={`ROW${i}`}/>)}
					<RightCell text={vehiculos.length.toString()} bottom/>
				</View>
			</View>
		</View>
	)
}

interface CellProps {
	text: string;
	bottom?: boolean;
	end?: boolean
}

const LeftCell = ({ text, bottom = false, end = false }: CellProps) => {
	const borderBottom = bottom && end ? {...styles.borderRowBottom, ...styles.borderRowRightBottom}: ''
	const border = bottom ? styles.borderRowBottom : styles.borderRow;
	const textStyle = bottom ? styles.bottomText : styles.text;
	return (
		<View style={[styles.rowContainer, border, {...borderBottom}, styles.cellLeft]}>
			<Text style={[textStyle]}>{text}</Text>
		</View>
	)
}

const CenterCell = ({ text, bottom = false }: CellProps) => {
	const border = bottom ? styles.borderRowBottom : styles.borderRow;
	const textStyle = bottom ? styles.bottomText : styles.text;
	return (
		<View style={[styles.rowContainer, border, styles.cellCenter]}>
			<Text style={[textStyle]}>{text}</Text>
		</View>
	)
}

const RightCell = ({ text, bottom = false }: CellProps) => {
	const border = bottom ? styles.borderRowBottomEnd : styles.borderRowEnd;
	const textStyle = bottom ? styles.bottomText : styles.text;
	return (
		<View style={[styles.rowContainer, styles.cellRight, border,{height: 15}]}>
			<Text style={[textStyle]}>{text}</Text>
		</View>
	)
}

function sumaEquipos(equipos: Maquinaria[]) {
	const total = equipos.reduce(
		(accumulator, currentValue) => accumulator + currentValue.cantidad!,
		0,
	)
	return total;
}