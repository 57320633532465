import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { Reporte } from '../../../interfaces/reportes';

import { FooterContainer } from "./FooterContainer";

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "column",
		marginTop: 10,
	},
	container: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		maxWidth: '100%',
		marginTop: 10,
	},
	colContainer: {
		flexDirection: 'column',
		width: '50%'
	},
	title: {
		flexDirection: 'row',
		width: '100%'
	},
	headerName: {
		flexDirection: 'row',
		width: '100%',
		color: '#aaaaaa',
	},
	name: {
		flexDirection: 'row',
		width: '100%'
	},
	identificacion: {
		flexDirection: 'row',
		width: '100%'
	},
	numero: {
		marginLeft: 4
	},
	firma: {
		flexDirection: 'row',
		width: '100%',
		padding: 5,
		borderRadius: 5
	},
	image: {
		maxHeight: 100,
		height: 100,
		objectFit: 'scale-down',
		borderRadius: 10,
		padding: 2
	}
});

interface Props {
	active: Reporte;
}

export const Footer = ({ active }: Props) => {

	return (
		<View>
			<View style={styles.headerContainer}>
				<FooterContainer responsable={active.responsableBeling![0]} />
				<FooterContainer responsable={active.responsableCliente![0]} />
			</View>
		</View>
	)
}