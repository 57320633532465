import { Box, IconButton, Typography } from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { startSetUbicacionFinal } from '../../../actions/reportes';
import { useDispatch } from 'react-redux';
import { usePosition } from 'use-position';

export const NofiticacionReporte = () => {
	const dispatch = useDispatch();
	const watch = false;
	const { latitude, longitude, timestamp } = usePosition(watch);
  
	const handleFinalizar = () => {
	  const ubicacion = {
		latitude,
		longitude,
		timestamp
	  }
	  dispatch(startSetUbicacionFinal(ubicacion));
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<IconButton 
				color="primary" 
				aria-label="Ubicación" 
				size="large"
				onClick={handleFinalizar}
				>
					<NotificationsActiveOutlinedIcon sx={{ fontSize: 70 }} />
				</IconButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Typography>
					Pulse para finalizar el informe
				</Typography>
			</Box>
		</>
	)
}
