import { View, StyleSheet } from '@react-pdf/renderer';
import { Cell } from '../../../../../interfaces/shared';
import { HeadItems } from '../../../../../components/pdf/HeadItems';
import { RowItems } from '../../../../../components/pdf/RowItems';
import { TitleTables } from '../../../../../components/pdf/TitleTables';
import { Reporte } from '../../../interfaces/reportes';

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap"
	},
});


interface Props {
	active: Reporte;
}

const title='Describa el Tipo de Servicio y Alcance de los trabajos a realizar:'
const subtitle='Descripcion General de Actividades a Realizar:'

export const Descripcion = ({ active}: Props) => {	
	const filaUno: Cell[] = [
		{name: active.alcanceActividad!},
	];
	
	return (
		<>
			<View>
			<TitleTables
				title={title}
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
			/>
			</View>


			<View style={{marginTop: 5, ...styles.headerContainer}}>				
				<HeadItems names={[subtitle]} />
				<RowItems columnas={filaUno}/>
			</View>			
		</>
	);
};
