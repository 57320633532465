import { Informe } from '../../../interfaces/informes';
import { Document, Page } from '@react-pdf/renderer';
import { styles } from '../../../../../styles/pdfStyles';
import { Detalle, LogoHeader } from './header';
import { Actividades, Condiciones, InformeFotografico, PageTwoHead, Periodo, PersonalEquipo } from './body';
import { Firmas } from './footer/Firmas';

interface Props {
	active: Informe;
}
export const InformeDiarioPdf = ({ active }: Props) => {
	return (
		<Document title={`Informe de obra No: ${active.obra.numero}`}>
			<Page size="A4" style={[styles.page, { fontFamily: 'Arial' }]} wrap dpi={100} >
				<LogoHeader active={active} />
				<Detalle active={active} />
				<Periodo active={active} />
				<Condiciones active={active} />
				<PersonalEquipo active={active} />
				<Actividades title='CONTRATISTA' active={active} />
				<Actividades title='SCALA' active={active} />
				<Firmas active={active} />
			</Page>
			<Page size="A4" style={[styles.page, { fontFamily: 'Arial' }]} wrap dpi={100} >
				<LogoHeader active={active} firts={false} />
				<PageTwoHead active={active} />
				<InformeFotografico active={active} />
				<Firmas active={active} />
			</Page>
		</Document>
	)
}
