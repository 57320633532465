import { SitioAction, SitioState } from "../interfaces/sitio"

const initState: SitioState = {
    sitioInfo: []
}

export const sitioReducer = (state = initState, action: SitioAction): SitioState => {
    switch (action.type) {
        case 'set-sitio-info':
            return {
                ...state,
                sitioInfo: [...state.sitioInfo, action.payload]
            }

        case 'update-sitio-info':
            return {
                ...state,
                sitioInfo: state.sitioInfo.map((b) => {
                    if (b.id === action.payload.id) {
                        return action.payload;
                    }
                    return b;
                }),
            }

        case 'delete-sitio-info':
            return {
                ...state,
                sitioInfo: state.sitioInfo.filter((b) => b.id !== action.payload),
            }
            
        case 'edit-sitio-info':
            return {
                ...state,
                sitioInfo: state.sitioInfo.map((b) => {
                    if (b.id === action.payload) {
                        b.isNew = false;
                    }
                    return b;
                }),
            };

        case 'set-sitio-empty':
            return {
                ...state,
                sitioInfo: [],
            };

        default:
            return state

    }
}