import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { RiesgoSitioTable } from './RiesgoSitioTable';

const Table = memo(() => {
    return(
        <RiesgoSitioTable />
    )
});

export const RiesgoSitio = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                    Riesgos potenciales en sitio
                    </Typography>
                </Grid>
            </Grid>
            <Table />
        </>
    )
}