import { View, StyleSheet, Text } from "@react-pdf/renderer";


const styles = StyleSheet.create({
	column: {
		flexDirection: "column",
		border: 1,
		alignItems: "center",
		padding: 5,
	},
	rightColumn: {
		height: "100%",
		width: "30%",
		marginLeft: -1,
		justifyContent: "space-around",
	},
	rowContainer: {
		flexDirection: "row",
	},
	columnContainer: {
		flexDirection: "column",
	},
	endRowContainer: {
		borderBottom: 1,
		fontSize: 9,
		width: "106%",
		paddingBottom: 2,
		marginTop: 2,
	},
	endRowContainerB: {
		fontSize: 9,
		width: "106%",
		paddingBottom: 2,
		marginTop: 2,
	},
});

interface Props {
	titleA: string,
	titleB: string,
	rowBottom?: boolean
}

export const RightRow = ({ titleA, titleB, rowBottom = true }: Props) => {
	return (
		<>
			<View style={rowBottom ? { ...styles.endRowContainer, ...styles.rowContainer } : { ...styles.endRowContainerB, ...styles.rowContainer }}>
				<View
					style={{
						fontWeight: "demibold",
						width: "40%",
						...styles.columnContainer,
					}}
				>
					<View style={{ flexDirection: "row" }}>
						<Text style={{ marginLeft: 4 }}>{titleA}</Text>
					</View>
				</View>
				<View style={{ ...styles.columnContainer }}>
					<View style={{ flexDirection: "row" }}>
						{rowBottom ? <Text style={{ marginLeft: 2 }}>{titleB}</Text> : <Text style={{ marginLeft: 2 }} render={({ pageNumber, totalPages }) => (
							`${pageNumber} de  ${totalPages}`
						)} />}
					</View>
				</View>
			</View>
		</>
	)
}
