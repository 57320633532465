import { InversorAction, InversorState } from "../interfaces/inversores";


const initState: InversorState = {
    inversoresInfo: [],
}

export const inversoresReducer = (state = initState, action: InversorAction): InversorState => {
    switch (action.type) {
        case 'set-inversores-info':
            return {
                ...state,
                inversoresInfo: [...state.inversoresInfo, action.payload],
            };
        case 'update-inversores-info':
            return {
                ...state,
                inversoresInfo: state.inversoresInfo.map((b) => {
                    if (b.id === action.payload.id) {
                        return action.payload;
                    }
                    return b;
                }),
            };
        case 'delete-inversores-info':
            return {
                ...state,
                inversoresInfo: state.inversoresInfo.filter((b) => b.id !== action.payload),
            };
        case 'edit-inversores-info':
            return {
                ...state,
                inversoresInfo: state.inversoresInfo.map((b) => {
                    if (b.id === action.payload) {
                        b.isNew = false;
                    }
                    return b;
                }),
            };
        case 'set-inversores-empty':
            return {
                ...state,
                inversoresInfo: [],
            };
        default:
            return state;
    }

}