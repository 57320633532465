import { ChangeEvent, useState } from "react"

export const useForm = <T>(initState: T) => {

    const [ formData, setFormData ] = useState(initState);
    
    const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData( (prev: T) => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    return {
        ...formData,
        registerData: formData,
        changeData: setFormData,
        onChange
    }
}