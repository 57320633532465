import { VehiculoAction, VehiculoState } from "../interfaces/vehiculo";

const initState: VehiculoState = {
    vehiculos: [],
};

export const vehiculoReducer = (state = initState , action: VehiculoAction): VehiculoState => {
    switch (action.type) {
        case 'set-vehiculos':
            return {
                ...state,
                vehiculos: [ ...state.vehiculos, action.payload,]
            };
        case 'update-vehiculo':
            return {
                ...state,
                vehiculos: state.vehiculos.map((v) => {
                    if (v.id === action.payload.id) {
                        return action.payload;
                    }
                    return v;
                }
                ),
            };
        case 'delete-vehiculo':
            return {
                ...state,
                vehiculos: state.vehiculos.filter((v) => v.id !== action.payload),
            };
        case 'edit-vehiculo':
            return {
                ...state,
                vehiculos: state.vehiculos.map((v) => {
                    if (v.id === action.payload) {
                        v.isNew = false;
                    }
                    return v;
                }
                ),
            };
        case 'set-vehiculos-empty':
            return {
                ...state,
                vehiculos: [],
            };
        default:
            return state;
    }
}
