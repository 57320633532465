import { VoltajeAction, VoltajeRectificaroState } from "../interfaces/voltaje";

const initState: VoltajeRectificaroState = {
    voltajeRectificador: [
        {
            id: 1,
            l1_l2: "",
            l1_l3: "",
            l2_l3: "",
            isNew: false
        }
    ],
}

export const voltajeRectificadorReducer = (state = initState, action: VoltajeAction): VoltajeRectificaroState => {
    switch (action.type) {
        case 'update-voltatje-rectificador':
            return {
                ...state,
                voltajeRectificador: state.voltajeRectificador.map(vr => vr.id === action.payload.id ? action.payload : vr)
            }       
        case 'set-voltaje-rectificador-empty':
            return {
                ...state,
                voltajeRectificador: [
                    {
                        id: 1,
                        l1_l2: "",
                        l1_l3: "",
                        l2_l3: "",
                        isNew: false
                    }
                ]
            }
        default:
            return state;
    }
}