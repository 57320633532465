import { RutinaRectificadorState, RutinaRectificadorAction } from '../interfaces/rutina';

const initState: RutinaRectificadorState = {
    rutinasRectificador: [],
    activeRutinaRectificador: null,
    rutinaRectificarorId: '',
    modalRectificador: false,
    modalSelectRutina: false,
    modalImagenesRutinaRectificador: false
};

export const rutinaRectificadorReducer = (state = initState, action: RutinaRectificadorAction): RutinaRectificadorState => {
    switch (action.type) {
        case 'set-rutina-rectificador':
            return {
                ...state,
                rutinasRectificador: [...state.rutinasRectificador, action.payload],
            };
        case "set-active-rutina-rectificador":
            const rutina = state.rutinasRectificador.find((rr) => rr.id === action.payload);
            return {
                ...state,
                activeRutinaRectificador: rutina ? rutina : null,
            };
        case 'update-rutina-rectificador':
            return {
                ...state,
                rutinasRectificador: state.rutinasRectificador.map((rr) => {
                    if (rr.id === action.payload.id) {
                        return action.payload;
                    }
                    return rr;
                }),
            };
        case 'delete-rutina-rectificador':
            return {
                ...state,
                rutinasRectificador: state.rutinasRectificador.filter((rr) => rr.id !== action.payload),
            };
        case 'edit-rutina-rectificador':
            return {
                ...state,
                rutinasRectificador: state.rutinasRectificador.map((rr) => {
                    if (rr.id === action.payload) {
                        rr.isNew = false;
                    }
                    return rr;
                }),
            };
        case 'set-rutina-update-image-rectificador':
            return {
                ...state,
                rutinasRectificador: state.rutinasRectificador.map((rr) => {
                    if (rr.id === action.payload.id) {
                        if (!rr.images) {
                            rr.images = [];
                        }
                        rr.images?.push(action.payload.image);
                    }
                    return rr;
                }),
            };
        case 'set-delete-image-rutina-rectificador':
            return {
                ...state,
                rutinasRectificador: state.rutinasRectificador.map((rr) => {
                    if (rr.id === action.payload.id) {
                        rr.images = rr.images!.filter(i => i.id !== action.payload.imgId);
                    }
                    return rr;
                }),
            };
        case 'set-clear-images-rutina':
            return {
                ...state,
                rutinasRectificador: state.rutinasRectificador.map(rr => {
                    if (rr.id === action.payload) {
                        rr.images?.shift();
                    }
                    return rr
                })
            }
        case 'set-rutina-rectificador-id':
            return {
                ...state,
                rutinaRectificarorId: action.payload,
            };
        case 'set-rutina-modal':
            return {
                ...state,
                modalRectificador: action.payload,
            };
        case 'set-modal-select-rutina':
            return {
                ...state,
                modalSelectRutina: action.payload
            }
        case 'set-modal-imagenes-rutina-rectificador':
            return {
                ...state,
                modalImagenesRutinaRectificador: action.payload
            }
        case 'set-rutina-rectificador-empty':
            return {
                ...state,
                rutinasRectificador: [],
                rutinaRectificarorId: '',
            };
        default:
            return state;
    }
}