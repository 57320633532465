import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { v4 as UUID } from 'uuid';

import { AppState } from '../../../../../reducers/rootReducer'
import initImage from '../../../../../assets/img/bg.png';
import imagen from '../../../../../assets/img/logo_beling.png';
import { startSetRutinaRectificadorId, startSetRutinaUpdateImageRectificador, startSetModalRectificador } from '../actions/rutina';
import { saveImagen } from '../../../../../helpers';
import { doMessage, startLoading } from '../../../../ui/actions/ui';
import { CustomCardImage } from '../../../../../components';
import { useScreenSize } from '../../../../../hooks';

export const ModalRutinaR = () => {
    const dispatch = useDispatch();
    const { width } = useScreenSize();
    const [imgWidth, setImgWidth] = useState<number>(220)
    const [foto, setFoto] = useState(initImage)
    const { activeServicio } = useSelector((state: AppState) => state.servicios);
    const { modalRectificador, rutinaRectificarorId, rutinasRectificador } = useSelector((state: AppState) => state.reportes.modules.rutinaRectificador);
    const images = rutinasRectificador.find(r => r.id === rutinaRectificarorId)?.images ?? [];
    const lastId = images.length > 0 ? images?.length! - 1 : 0;
    const imgUrl = images.length > 0 ? rutinasRectificador.find(r => r.id === rutinaRectificarorId)?.images![lastId].uri : imagen;

    const stat = imgUrl?.slice(0, 8);

    useEffect(() => {
        if (stat !== '/static/') {
            setFoto(imgUrl!)
        } else {
            setFoto(initImage)
        }
    }, [imgUrl, stat])

    const handleClose = () => {
        dispatch(startSetModalRectificador(false));
    };

    const handelLoadImage = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setFoto(reader.result as string);
        }
    }

    const handleSave = async () => {
        dispatch(startLoading());
        if (rutinaRectificarorId) {
            const data = {
                imagen: foto,
                servicio: activeServicio!.id,
                name: `${activeServicio!.tipo.alias}-${UUID()}`,
                descripcion: activeServicio!.descripcion ? activeServicio!.descripcion : 'ribu claro',
            }

            const { imagen, msg, status } = await saveImagen(data);
            if (status) {
                dispatch(startSetRutinaUpdateImageRectificador(imagen, rutinaRectificarorId));
                dispatch(startSetModalRectificador(false));
                dispatch(doMessage({ message: msg, type: 'success' }));
                dispatch(startSetRutinaRectificadorId(''));
            } else {
                dispatch(doMessage({ message: 'Error guardando la imagen', type: 'error' }));
            }
        }
        dispatch(startLoading());
        handleClose();
    }

    useEffect(() => {
        if (width > 843) {
            setImgWidth(220)
        } else {
            setImgWidth(120)
        }
    }, [width])

    return (
        <>
            <Dialog open={modalRectificador} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogTitle>Agregar Imagén</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CustomCardImage
                        foto={foto}
                        imgWidth={imgWidth}
                        descripcion={activeServicio!.descripcion ? activeServicio!.descripcion : 'ribu claro'}
                        onChange={handelLoadImage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
