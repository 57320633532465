import { Route, Routes } from 'react-router-dom';
import { ReporteScreen } from './ReporteScreen';

export const ReporteRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/reportes" element={<ReporteScreen />} />
        <Route path="/" element={<ReporteScreen />} />
      </Routes>
    </>
  );
};
