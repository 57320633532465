import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as UUID } from 'uuid';


import img from '../../../../../assets/img/logo_beling.png';
import { AppState } from '../../../../../reducers/rootReducer';
import { 
  startSetActiveActividadBdB, 
  startSetActividadUpdateImage, 
  startSetModadlActividad 
} from '../action/actividad';
import { doMessage, startLoading } from '../../../../ui/actions/ui';
import { getImagen, saveImagen } from '../../../../../helpers/imagen';
import {useScreenSize} from '../../../../../hooks';


export const useModalActividad = () => {
  const dispatch = useDispatch();
  const { width } = useScreenSize();
  const [imgWidth, setImgWidth] = useState<number>(220)
  const [foto, setFoto] = useState(img);
  const { activeServicio } = useSelector((state: AppState) => state.servicios);
  const { modalActividad, actividades, actividadId } = useSelector((state: AppState) => state.reportes.modules.actividad);

  const images = actividades.find(r => r.id === actividadId)?.images ?? [];
  const lastId = images.length > 0 ? images?.length! - 1 : 0;
  const imgUrl = images.length > 0 ? actividades.find(r => r.id === actividadId)?.images![lastId].uri : img;

  const stat = imgUrl?.slice(0, 8);

  const handleClose = () => {
    dispatch(startSetModadlActividad(false));
  };

  const handelLoadImage = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFoto(reader.result as string);
    }
  }

  const handleSave = async () => {
    dispatch(startLoading());
    if (actividadId) {
      const data = {
        imagen: foto,
        servicio: activeServicio!.id,
        name: `${activeServicio!.tipo.alias}-${UUID()}`,
        descripcion: activeServicio!.descripcion,
      };
      const { imagen, status } = await saveImagen(data)
      if (status) {
        const imageSave = await getImagen(imagen.name);
        dispatch(startSetActividadUpdateImage(imagen, actividadId));
        dispatch(startSetModadlActividad(false));
        setFoto(imageSave)
        dispatch(startSetActiveActividadBdB(''));
        dispatch(doMessage({ type: 'success', message: 'Imagen guardada' }));
      }
    } else {
      dispatch(doMessage({ type: 'error', message: 'Error al guardar la imagen' }));
      dispatch(startSetModadlActividad(false));
      setFoto(img)
    }
    dispatch(startLoading());
  }

  useEffect(() => {
    if (width > 843) {
      setImgWidth(220)
    } else {
      setImgWidth(120)
    }
  }, [width])

  useEffect(() => {
    if (stat !== '/static/') {
      setFoto(imgUrl!)
    } else {
      setFoto(img)
    }
  }, [imgUrl, stat])

  return {
    descripcion: activeServicio?.descripcion,
    foto,
    imgWidth,
    modalActividad,
    handleClose,
    handelLoadImage,
    handleSave,
  }
}
