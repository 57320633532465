/**
 * Dialog personalizado para renderizar los formularios
 */

import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { Transition } from './Transition';
import { ModalBar, ModalActions } from '../../shared';
import {useScreenSize} from '../../hooks';

interface Props {
	children: React.ReactNode;
	err?: boolean;
	fullscreen?: boolean;
	openModal: boolean;
	title: string
	onClose: () => void;
	onSave: () => void;
}

/**
 * El componente necesita las propiedades para abrir el Dialog, las 
 * acciones del Dialog, y el hijo del dialogo a renderizar.
 * 
 * <CustomDialog
		onClose={onClose}
		onSave={onSave}
		openModal={openModal}
		err={err}
		title="title"
	  >
		{children}

	  </CustomDialog>
 */

export const CustomDialog = ({
	children,
	err,
	fullscreen = false,
	openModal,
	title,
	onClose,
	onSave
}: Props) => {
	const {width} = useScreenSize();
	const [modalBar, setModalBar] = useState(true);

	useEffect(()=>{
		if(fullscreen && width < 933){
			setModalBar(false)
		}else{
			setModalBar(true)
		}
	},[fullscreen, width])

	return (
		<Dialog
			open={openModal}
			onClose={onClose}
			fullScreen={fullscreen}
			maxWidth="lg"
			TransitionComponent={Transition}
		>
			{modalBar &&
				<ModalBar
					handleClose={onClose}
					handleSave={onSave}
					title={title}
					err={err}
				/>}
			<DialogContent>
				{children}
			</DialogContent>
			<ModalActions handleClose={onClose} handleSave={onSave} err={err} />
		</Dialog>
	)
}
