import { ActividadObraAction, ActividadObraState } from "../interfaces/actividad";

const initState: ActividadObraState = {
    actividadesObra: [],
    modalActividadObra: false,
    activeActividadObra: {
        id: 0,
        actividad: '',        
        observaciones: '',
        isNew: false
    },
}

export const actividadObraReduder = (state = initState, action: ActividadObraAction): ActividadObraState => {
    switch (action.type) {
        case 'set-actividad-obra-bdb':
            return {
                ...state,
                actividadesObra: [...state.actividadesObra, action.payload]
            };
        case 'update-actividad-obra-bdb':
            return {
                ...state,
                actividadesObra: state.actividadesObra.map(actividad => {
                    if (actividad.id === action.payload.id) {
                        return action.payload;
                    }
                    return actividad;
                })
            }
        case 'delete-actividad-obra-bdb':
            return {
                ...state,
                actividadesObra: state.actividadesObra.filter(a => a.id === action.payload)
            }
        case 'edit-actividad-obra-bdb':
            return {
                ...state,
                actividadesObra: state.actividadesObra.map( a =>{
                    if(a.id === action.payload){
                        a.isNew = true;
                        return a;
                    }
                    return a;
                })
            }      
            case 'set-actividad-obra-Active-bdb':
                const actividad = state.actividadesObra.find(a => a.id === action.payload);
                return {
                    ...state,
                    activeActividadObra: actividad ? actividad: {
                        id: 0,
                        actividad: '',
                        observaciones: '',
                        isNew:false
                    }
                };        
        default:
            return state;
    }
}