import { useEffect } from 'react'
import { Container, Grid, Paper, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadServicios } from '../actions/servicio';
import { Servicios } from './Servicios';
import { startLoadReportes } from '../../reportes/actions/reportes';
//import { startLoadAts } from '../../ats/actions/ats';
import { AppState } from '../../../reducers/rootReducer';
import { MapHeader } from '../../../shared/maps/components/serviciosmaps/MapHeader';
import { startLoadAts } from '../../ats/actions/ats';

export const ServiciosScreen = () => {
  const dispatch = useDispatch();
  const { servicios } = useSelector((state: AppState) => state.servicios)
  const { reportes } = useSelector((state: AppState) => state.reportes.reportes)
  const { ats } = useSelector((state: AppState) => state.ats.ats)

  useEffect(() => {
    if (servicios && servicios.length >= 0) return;
    dispatch(startLoadServicios());
  }, [dispatch, servicios]);

  useEffect(() => {
    if(servicios.length >= 1 && (ats && ats.length >= 0))return;
    dispatch(startLoadAts())
  }, [dispatch, ats, servicios ])
 

  useEffect(() => {
    if (servicios.length >= 1 && (reportes && reportes.length >= 0)) return;
    dispatch(startLoadReportes());
  }, [dispatch, reportes, servicios])

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Clientes */}
        <Grid item xs={12}>
          <Stack sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Servicios />
          </Stack>
        </Grid>
        {servicios.length > 0 &&
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <MapHeader />
            </Paper>
          </Grid>}
      </Grid>
    </Container>
  )
}
