import { Dialog, DialogContent } from '@mui/material';

import { ModalActions } from '../../../../shared/modal/ModalActions';
import { FormReporteScreenObra } from './FormReporteScreenObra';
import { useModalReporteObra } from '../../hooks/useModalReporteObra';
import { ModalBar } from '../../../../shared/modal/ModalBar';
import { Transition } from '../../../../components/ui/Transition';

export const ModalReporteObra = () => {
  const {
    formError,
    modalReporteObra,
    registerData,
    registerDataDate,
   props,
    handleIniciar,
    handleFinalizar,
    onChange,
    onDateChange,
    handleClose,
    handleSave
  } = useModalReporteObra();  
  return (
    <>
      <Dialog open={modalReporteObra} onClose={handleClose} fullScreen TransitionComponent={Transition}>
        <ModalBar handleClose={handleClose} handleSave={handleSave} title="Desarrollo Actividad de Obra" />

        <DialogContent>
          <FormReporteScreenObra
          formError={formError}
            onChange={onChange}
            onDateChange={onDateChange}
            registerData={registerData}
            registerDataDate={registerDataDate}
            handleFinalizar={handleFinalizar}
            handleIniciar={handleIniciar} 
            name={props.name}
            reporte={props.reporte}
            />
        </DialogContent>

        <ModalActions handleClose={handleClose} handleSave={handleSave} />
      </Dialog>
    </>
  )
}