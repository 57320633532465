import { HeadLine, BodyLines } from '../shared'
import { Sst } from '../../../interfaces/sst'


interface Props {
	active: Sst
}

export const PlanTrabajo = ({ active }: Props) => {
	return (
		<>
			<HeadLine title='plan de trabajo' />
			{active.planesTrabajo.map((plan, index)=>(
				<BodyLines index={index} title={plan} key={index} />
			))}
		</>
	)
}
