import { GridRowId } from "@mui/x-data-grid";
import { Equipo, EquipoAction } from "../interfaces/equipo";


export const startSetEquipos = (equipo: Equipo):EquipoAction => ({
    type: 'set-equipo-seguridad',
    payload: equipo,
});

export const startUpdateEquipo = (equipo: Equipo):EquipoAction => ({
    type: 'update-equipo-seguridad',
    payload: equipo,
});

export const startDeleteEquipo = (id: GridRowId):EquipoAction => ({
    type: 'delete-equipo-seguridad',
    payload: id,
});

export const startEditEquipo = (id: GridRowId):EquipoAction => ({
    type: 'edit-equipo-seguridad',
    payload: id,
});

export const startSetEquiposEmpty = ():EquipoAction => ({
    type: 'set-equipo-seguridad-empty',
});