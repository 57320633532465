import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { starSetTipoActividad } from '../actions/actions';

const actividades: string[] = [
  'Visita',
  'Servicio y/o Atención de Emergencia',
  'Mantenimiento preventivo y/o Correctivo',
];

export const useTipoActividad = () => {

	const dispatch = useDispatch();
	const { tipoActividad } = useSelector((state: AppState) => state.shared.tipoActividad)
	const [checked, setChecked] = useState(actividades);  
  
	const handleToggle = (value: string) => () => {
	  const currentIndex = checked.indexOf(value);
	  const newChecked = [...checked];
  
	  if (currentIndex === -1) {
		newChecked.push(value);
	  } else {
		newChecked.splice(currentIndex, 1);
	  }
  
	  setChecked(newChecked);
	  dispatch(starSetTipoActividad(newChecked))
	  
	}; 
  
	useEffect(() => {
	  setChecked(tipoActividad);
	},[tipoActividad]);

	return {
		actividades,
		checked,
		handleToggle
	}
}