import { GridRowId } from "@mui/x-data-grid";
import { RutinaBancoBaterias, RutinaBancoBateriaAction } from "../interfaces/rutina";
import { Imagen } from "../../../../../interfaces/image";

export const startSetRutinasBancoBateria = (rutina: RutinaBancoBaterias):RutinaBancoBateriaAction => ({
    type: 'set-rutina-banco-bateria',
    payload: rutina,
});

export const startUpdateRutinasBancoBateria = (rutina: RutinaBancoBaterias):RutinaBancoBateriaAction => ({
    type: 'update-rutina-banco-bateria',
    payload: rutina,
});

export const startDeleteRutinasBancoBateria = (id: GridRowId):RutinaBancoBateriaAction => ({
    type: 'delete-rutina-banco-bateria',
    payload: id,
});

export const startSetRutinaBancoId = (id: GridRowId):RutinaBancoBateriaAction => ({
    type: 'set-rutina-banco-id',
    payload: id,
});

export const startSetRutinaBancoUpdateImage = (image: Imagen, id: GridRowId):RutinaBancoBateriaAction => ({
    type: 'set-rutina-update-image-banco',
    payload: {
        image,
        id,
    },
});

export const startSetModalBancos = (modal: boolean):RutinaBancoBateriaAction => ({
    type: 'set-rutina-modal-bancos',
    payload: modal,
});

export const startSetRutinaBancoBateriaEmpty = ():RutinaBancoBateriaAction => ({
    type: 'set-rutina-banco-bateria-empty',
});

export const startSetModalRutinaBancoBaterias = (payload: boolean): RutinaBancoBateriaAction =>({
    type: 'set-rutina-modal-select-bancos',
    payload
})

export const startSetActiveRutinaBancoBaterias = (payload: GridRowId): RutinaBancoBateriaAction =>({
    type: 'set-active-rutina-banco-bateria',
    payload
})

export const startDelImgRutinaBancoBaterias = (imgId: GridRowId, id: GridRowId): RutinaBancoBateriaAction => ({
    type: 'set-delete-image-rutina-banco-baterias',
    payload:{
        id,
        imgId
    }
})

export const startClearImagesRutinaBancoBaterias = (payload: GridRowId): RutinaBancoBateriaAction =>({
    type: 'set-clear-images-rutina-bancos',
    payload
})

export const startSetModImgBancoBaterias = (payload: boolean): RutinaBancoBateriaAction =>({
    type: 'set-modal-imagenes-rutina-banco-baterias',
    payload
})