import { Route, Routes } from 'react-router-dom'
import { ServicioDetail } from './ServicioDetail'
import { ServiciosScreen } from './ServiciosScreen'

export const ServiciosRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/servicios" element={<ServiciosScreen />} />
        <Route path="/detalle" element={<ServicioDetail />} />
        <Route path="/" element={<ServiciosScreen />} />
      </Routes>
    </>
  )
}
