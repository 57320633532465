import { SitioAction, SitioState } from "../interfaces/sitio";




const initState: SitioState = {
    condicionesSitio: [
        { id: 1, name: 'Operable', status: 'Si', isNew: false },
        { id: 2, name: 'Parcialmente Operable', status: '', isNew: false },
        { id: 3, name: 'Inoperable', status: 'No', isNew: false },
        { id: 4, name: 'Horas Hombre Trabajada', status: 8, isNew: false },
    ],
    codicionSitio: null
}

export const condicionesSitoReducer = (state = initState, action: SitioAction): SitioState => {
    switch (action.type) {
        case 'set-condiciones-sitio':
            return {
                ...state,
                condicionesSitio: action.payload
        }
        case 'update-condiciones-sitio':
            return {
                ...state,
                condicionesSitio: state.condicionesSitio.map(condicion => {
                    if (condicion.id === action.payload.id) {
                        return action.payload;
                    }
                    return condicion;
                })
            }
        case 'set-condiciones-sitio-init-state':
            return{
                ...state,
                condicionesSitio: initState.condicionesSitio
            }
        default:
            return state
    }
}