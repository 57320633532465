import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ContactosScreen } from './ContactosScreen'

export const ContactosRoutes = () => {
  return (
    <>    
    <Routes>
            <Route path="/contactos" element={<ContactosScreen />} />
            <Route path="/" element={<ContactosScreen />} />
    </Routes>    
    </>
  )
}