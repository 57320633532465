import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { starSetActividadTipo } from '../actions/reportes';

const actividades: string[] = [
  'Visita',
  'Servicio y/o Atención de Emergencia',
  'Mantenimiento preventivo y/o Correctivo',
];

export const useStepSix = () => {

	const dispatch = useDispatch();
	const { actividadTipo } = useSelector((state: AppState) => state.reportes.reportes)
	const [checked, setChecked] = useState(actividades);  
  
	const handleToggle = (value: string) => () => {
	  const currentIndex = checked.indexOf(value);
	  const newChecked = [...checked];
  
	  if (currentIndex === -1) {
		newChecked.push(value);
	  } else {
		newChecked.splice(currentIndex, 1);
	  }
  
	  setChecked(newChecked);
	  dispatch(starSetActividadTipo(newChecked))
	  
	}; 
  
	useEffect(() => {
	  setChecked(actividadTipo);
	},[actividadTipo]);

	return {
		actividades,
		checked,
		handleToggle
	}
}
