import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { AcompanaCliente } from '../../../interfaces/reportes';
import { HeaderTable, ItemsTable } from '../../../../informes/components/pdf/normal';
import { Cell } from "../../../../../interfaces/shared";
import { TitleTables } from "../../../../../components/pdf/TitleTables";



const styles = StyleSheet.create({
	container: {
		marginTop: 2
	},
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

interface Props {
	field: AcompanaCliente;
	actividad: string;
	marginTop?: number;
	sx?: {
		fz?: number,
		fw: 'bold' | 'extrabold' | 'light' | 'normal',
		mh?: number
	}
}

const tableTitles: Cell[] = [
	{ name: 'Nombre de quien acompaña la actividad:', width: '40%' },
	{ name: 'Identificación:', width: '30%' },
	{ name: 'Cargo:', width: '30%' }
]

export const AcompanaClientes = ({ actividad, field, marginTop, sx }: Props) => {
	const { nombre, cargo, identificacion } = field;
	const fila: Cell[] = [
		{ name: nombre, width: '40%' },
		{ name: identificacion, width: '30%', align: "center" },
		{ name: cargo, width: '30%', align: "center" },
	];

	const actividadFila: Cell[]=[
		{name: actividad}
	]

	return (
		<>
			<TitleTables
				title='Describa el tipo de servicio y quien acompaña la actividad por parte del CLIENTE:'
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 2 }}
			/>
			<View style={styles.container} >
				<View style={styles.descriptionContainer}>
				<HeaderTable names={[{name: 'Tipo de Actividad:', align:'left'}]} sx={{ txa: 'left', bc: "#dddddd", c: '#717171' }} />
					<ItemsTable key={1} columnas={actividadFila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom />
					<HeaderTable names={tableTitles} sx={{ txa: 'center', bc: "#dddddd", c: '#717171' }} defaultWidth={false} bottom />
					<ItemsTable key={1} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom defaultWidth={false} />
					
				</View>
			</View>
		</>
	);
};