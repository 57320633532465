import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { useDate, useForm, useScreenSize } from '../../../hooks';
import { AppState } from '../../../reducers/rootReducer';
import { closeReportes } from '../actions/reportes';


const initForm = {
    actividades: '',
    observaciones: '',
    ats: false,
}

const initDate = {
    fecha: new Date()
}

export const useModalReporteEmergencia = () => {
    const dispatch = useDispatch();
    const { width } = useScreenSize();
    const [err, setErr] = useState<boolean>(true);
    const [fullscreen, setFullscreen] = useState<boolean>(true);
    //const [guardar, setGuardar] = useState(false);

    const { modalReporteEmergencia } = useSelector((state: AppState) => state.servicios);
    //const { reportes, modules } = useSelector((state: AppState) => state.reportes);

    const { onChange, registerData, changeData } = useForm(initForm);
    const { onDateChange, changeDate, registerDataDate } = useDate(initDate);

    const handleClose = () => {
        dispatch(closeReportes())
    }

    const handleSave = () => { }

    useEffect(() => {
        if (width < 1024) {
            setFullscreen(true)
        } else {
            setFullscreen(false)
        }
    }, [width])

    useEffect(() => {
        let counter: number = 0;
        if (registerData.actividades.length === 0) {
            counter++;
        }
        if (counter === 0) {
            setErr(false)
        } else {
            setErr(true)
        }
    }, [registerData.actividades.length])

    return {
        err,
        fullscreen,
        modalReporteEmergencia,
        handleClose,
        handleSave,
        onChange,
        onDateChange,
        changeData,
        changeDate,
        registerData,
        registerDataDate,
    }
}
