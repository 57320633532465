import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Condicion } from '../../../../../../../interfaces/shared';


const styles = StyleSheet.create({
	column: {
		flexDirection: 'column',
		borderBottom: 1,
		height: '100%',
	},
	rowContainer: {
		flexDirection: 'row',
	},
	borderRow: {
		borderBottom: 1,
		borderRight: 1
	},
	borderRowBottom: {
		borderRight: 1
	},
	leftColumn: {
		flexDirection: 'column',
		width: '81%',
	},
	rightColumn: {
		flexDirection: 'column',
		width: '19%'
	},
	firstCell:{
	},
	cellRight: {
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: -1,
		height: 20,
	},
	cellLeft: {
		height: 20,
		alignItems: 'center'
	},	
	headColumn: {
		backgroundColor: '#aaaaaa',
		borderTop: 1,
		justifyContent: 'center',
	},
	headText: {
		fontSize: 7,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 2
	},
	text: {
		fontSize: 9,
		fontWeight: 'normal',
		marginLeft: 3,
	},
	rightText: {
		fontSize: 10,
		fontWeight: 'bold',
		textAlign: 'center'
	},
})

interface Props {
	condiciones: Condicion[]
	title: string;
	first?: boolean;
	sx?:{
		ml?: number,
		columnWidth?: string | number,
	}
}
export const ColCondicion = ({ condiciones, title, sx, first= false}: Props) => {
	const { columnWidth } = sx ??{
		ml: 0,
		columnWidth: 10
	}
	const border = first? styles.firstCell : {};
	
	return (
		<View style={[styles.column, {width: columnWidth}]}>
			<View style={[styles.rowContainer, styles.borderRow, border, styles.headColumn]}>
				<Text style={[styles.headText]}>{title}</Text>
			</View>
			<View style={[styles.rowContainer]}>
				<View style={[styles.leftColumn, border]}>
					{
						condiciones.map((item, index)=> {
							return <LeftCell text={item.name} key={item.id} bottom={index===3}/>
						})
					}					
				</View>
				<View style={[styles.rightColumn]}>
				{
						condiciones.map((item, index)=> {
							return <RightCell text={item.status.toString()} key={item.id} bottom={index===3}/>
						})
					}
				</View>
			</View>
		</View>
	)
}

interface CellProps {
	text: string,
	bottom?: boolean
}

const LeftCell = ({ text, bottom = false }: CellProps) => {
	const border = bottom ? styles.borderRowBottom : styles.borderRow;
	return (
		<View style={[styles.rowContainer, border, styles.cellLeft]}>
			<Text style={[styles.text]}>{text}</Text>
		</View>
	)
}

const RightCell = ({ text, bottom = false }: CellProps) => {
	const border = bottom ? styles.borderRowBottom : styles.borderRow;
	return (
		<View style={[styles.rowContainer, styles.cellRight, border]}>
			<Text style={[styles.rightText]}>{text}</Text>
		</View>
	)
} 