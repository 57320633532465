import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { useForm } from '../../../hooks/useForm';
import { AppState } from '../../../reducers/rootReducer';
import { startUpdateAcompanaCliente } from '../actions/actions';

const formInit = {
	nombreForm: '',
	identificacionForm: '',
	cargoForm: ''
}

export const useAcompanaCliente = () => {
	const dispatch = useDispatch();

	const { formError } = useSelector((state: AppState)=> state.ui);
	const { acompanaCliente } = useSelector((state: AppState) => state.shared.acompana);
	const { cargo, nombre, identificacion, isNew } = acompanaCliente;


	const { registerData, onChange, changeData } = useForm(formInit);
	const { nombreForm, identificacionForm, cargoForm } = registerData;

	const onChangeState = () => {
		const data = {
			cargo: cargoForm,
			nombre: nombreForm,
			identificacion: identificacionForm,
			isNew: false,
		}
		dispatch(startUpdateAcompanaCliente(data))
	}

	useEffect(() => {
		if (!isNew) {
			const data = {
				nombreForm: nombre,
				identificacionForm: identificacion,
				cargoForm: cargo,
			};
			changeData(data);
			//dispatch(startUpdateAcompanaCliente(data))
		}
	}, [cargo, identificacion, nombre, changeData, isNew])

	return {
		registerData,
		formError,
		onChange,
		onChangeState
	}
}
