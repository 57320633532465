import { Box, IconButton, Typography } from "@mui/material";

import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';

interface Props {
	handleIniciar: () => void;
}
export const IniciarForm = ({ handleIniciar }: Props) => {
	return (
		<Box sx={{
			height: "100%",
			display: "flex",
			flexDirection: "column",
			flexWrap: "wrap",
			justifyContent: "center",
			alignItems: "center"
		}}>
			<Box
			>
				<IconButton color="primary" aria-label="Ubicación" size="large" onClick={handleIniciar}>
					<AddLocationAltOutlinedIcon sx={{ fontSize: 100 }} />
				</IconButton>
			</Box>
			<Box
			>
				<Typography>
					Pulse una vez se encuentre en sitio
				</Typography>
			</Box>
		</Box>
	)
}
