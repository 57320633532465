import { View, StyleSheet } from '@react-pdf/renderer';
import { Cell } from '../../../../../interfaces/shared';
import { HeadItems } from '../../../../../components/pdf/HeadItems';
import { RowItems } from '../../../../../components/pdf/RowItems';



const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap"
	},
});

interface Props {
	field: string;
	title: string;
	subtitle?: string;
	marginTop?: number;
	sx?: {
		fz?: number,
		fw: 'bold' | 'extrabold' | 'light' | 'normal',
		mh?: number
	}
}
export const Detalle = ({ field, subtitle, title, marginTop, sx }: Props) => {	
	const filaUno: Cell[] = [
		{name: field},
	];
	
	return (
		<>
			<View style={{marginTop: marginTop, ...styles.headerContainer}}>
				<HeadItems names={[title]} />
				{subtitle  && <HeadItems names={[subtitle ?? '']} />}
				<RowItems columnas={filaUno}/>
			</View>			
		</>
	);
};
