import { View, StyleSheet } from "@react-pdf/renderer";
import { Cell } from "../../interfaces/pdf";
import { HeadItems } from "./HeadItems";
import { RowItems } from "./RowItems";

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 5
  },
});

interface Props {
  value: string;
  title: string[];
}
export const ConfirmarOpcion = ({ value, title }: Props) => {
  const filaUno: Cell[] = [{ name: value }];
  return (
    <View style={styles.headerContainer} wrap={false}>
      <HeadItems names={title} />
      <RowItems columnas={filaUno} />
    </View>
  );
};
