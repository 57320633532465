import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { TableInfoRectificadorHeader } from './TableInfoRectificadorHeader';
import { TableInfoRectificadorRow } from './TableInfoRectificadorRow';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        border: 1,
    },
});

interface Props {
    active: Reporte;
}

export const InfoRectificadorTable = ({ active }: Props) => (
    <View >
        <TitleTables title='Información del Rectificador' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />
        <View style={styles.tableContainer}>
            <TableInfoRectificadorHeader />
            <TableInfoRectificadorRow items={active.infoRectificadores!} />
        </View>
    </View>
);
