import React, { memo } from 'react'
import { EquipoTable } from '../../../../shared/equipo_seguridad/components/EquipoTable';

const Table = memo(() => {
	return (
		<EquipoTable />
	);
});

export const StepThree = () => {
	return (
		<>
			<Table />
		</>
	)
}
