
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from '../../../../../interfaces/informes';
import { getDurationDays } from '../../../../../../../helpers/fecha';


const styles = StyleSheet.create({
	column: {
		flexDirection: 'column',
		borderLeft: 1,
		width: '20%',
		height: '100%',

	},
	rowContainer: {
		flexDirection: 'row',
		width: '100%'
	},
	borderRow: {
		borderBottom: 1,
	},
	leftColumn: {
		flexDirection: 'column',
		width: '70%',
	},
	rightColumn: {
		flexDirection: 'column',
		width: '30%'
	},
	leftText: {
		fontSize: 8,
		fontWeight: 'normal',
		marginLeft: 3,
		marginTop: 2
	},
	rightText: {
		fontSize: 8,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 2
	},
})

interface Props {
	active: Informe;
}
export const ColTwo = ({ active }: Props) => {
	const transcurrido = getDurationDays(active.fecha!, active.obra.fechaInicio);
	const falta = Number(active.obra.plazo) - Number(transcurrido);		
	return (
		<View style={[styles.column]}>
			<View style={[styles.rowContainer ]}>
				<View style={[styles.leftColumn]}>
					<View style={[styles.rowContainer, styles.borderRow]}>
						<Text style={[styles.leftText]}>CONTRACTUAL</Text>
					</View>
					<View style={[styles.rowContainer, styles.borderRow]}>
						<Text style={[styles.leftText]}>TRANSCURRIDO</Text>
					</View>
					<View style={[styles.rowContainer, { height: 15 }]}>
						<Text style={[styles.leftText]}>FALTA</Text>
					</View>
				</View>
				<View style={[styles.rightColumn ]}>
					<View style={[styles.rowContainer, styles.borderRow, { height: 15, justifyContent: 'center' }]}>
						<Text style={[styles.rightText]}>{active.obra.plazo}</Text>
					</View>
					<View style={[styles.rowContainer, styles.borderRow, { height: 15, justifyContent: 'center' }]}>
						<Text style={[styles.rightText]}>{transcurrido}</Text>
					</View>
					<View style={[styles.rowContainer, { height: 15, justifyContent: 'center' }]}>
						<Text style={[styles.rightText]}>{falta}</Text>
					</View>
				</View>
			</View>
		</View>
	)
}
