import { ImagenPrevState, ImagesPrevAction } from "../interfaces/images";

const imageState: ImagenPrevState = {
    images: [],
    modalImagePrev: false
}

export const imagePrevReducer = (state = imageState, action: ImagesPrevAction): ImagenPrevState =>{
    switch(action.type){
        case 'load-prev-images':
            return{
                ...state,
                images: action.payload
            }
            case "create-prev-images":
                return {
                    ...state,
                    images: [...state.images, action.payload]
                }
            case "update-prev-images":
                return {
                    ...state,
                    images: state.images.map(image => image.id === action.payload.id ? action.payload : image)
                }
            case "delete-prev-images":
                return {
                    ...state,
                    images: state.images.filter(image => image.id !== action.payload)
                }
            case "clear-prev-image-state":
                return {
                    ...state,
                    images: []
                }
            case 'set-modal-prev-image':
                return {
                    ...state,
                    modalImagePrev: action.payload
                }
        default:
            return state
    }
}