import { useSelector } from "react-redux";
import { ResponsableBeling } from "../../../../shared/responsable_beling/ResponsableBeling";
import { AppState } from "../../../../reducers/rootReducer";
import { useEffect, useState } from "react";
import { GridRowId } from "@mui/x-data-grid";


export const FirmaBeling = () => {
    const {activeServicio} = useSelector((state: AppState) => state.servicios);   
    const [props, setProps] = useState({
        name:'',
        reporte: '' as GridRowId
    })

    useEffect(() => {
      if(activeServicio){
        setProps({
            name: activeServicio!.tipo.alias,
            reporte: activeServicio?.id!
          })
      }
    },[activeServicio])
    
    return (
        <>
            <ResponsableBeling name={props.name} reporte={props.reporte} />
        </>
    )
}