import { View, StyleSheet, Text } from '@react-pdf/renderer';


const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		border: 1,
		backgroundColor: '#aaaaaa',
		minHeight: 15,
		width: '100%',
		marginTop: -1
	},
	column: {
		flexDirection: 'column',
		border: 1,
		backgroundColor: '#aaaaaa',
		width: '99.75%',
		height: 15,
		alignItems: 'center',
		justifyContent: 'center',


	},
	text: {
		fontSize: 7,
		fontWeight: 'bold',
		marginTop: 2,
	},


})

interface Props {
	title?: string
}
export const BackTitle = ({ title = '' }: Props) => {
	return (
		<View style={styles.row} fixed>
			<Text style={[styles.text]}>{title}</Text>
		</View>
	)
}
