import { Box } from '@mui/material';
import { Actividad } from '../../../../shared/actividad/components/Actividad';

export const StepSevenA = () => {
	return (
		<>
			<Box
			>
				<Actividad />
			</Box>
		</>
	)
}
