import Box from '@mui/material/Box';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    esES,
} from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';

import { ModalCliente } from './ModalCliente';
import { AlertCliente } from './AlertCliente';
import { ClienteToolBar } from './ClienteToolBar';
import { useClientesTable } from '../hooks/useClientesTable';


export const ClientesTable = () => {
    const {
        clientesTable,
        columns,
        rowModesModel,
        handleEditClick,
        handleDeleteClick,
        processRowUpdate,
        setRowModesModel
    } = useClientesTable();  

    const columnsGrid: GridColDef[] = [
        ...columns,       
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex:1,
            cellClassName: 'actions',
            getActions: ({ id }) => {     
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Ver"><PageviewOutlinedIcon color='secondary' /></Tooltip>}
                        label="Ver"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Borrar cliente"><DeleteIcon color='error' /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={clientesTable.slice(0, clientesTable.length)}
                columns={columnsGrid}
                rowModesModel={rowModesModel}
                processRowUpdate={processRowUpdate}
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}                
                slots={{
                    toolbar: ClienteToolBar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }}
                localeText={{...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay datos para mostrar'}}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
                rowHeight={35}
            />
            <ModalCliente />
            <AlertCliente />         
        </Box>
    );
}
