import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridRowsProp,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';
import moment from "moment";


import { AppState } from '../../../reducers/rootReducer';
import { CellExpand } from '../../../components/ui/CellExpand';

import { startSetActiveInforme, startSetModalInformePdf } from '../actions/informes';
import { ModalInformePdf } from './pdf/normal';
import { useScreenSize } from '../../../hooks';


export const InformesTable = () => {
    const dispatch = useDispatch();
    const { width } = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText;

    const { informes } = useSelector((state: AppState) => state.informes.informes);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const reportes: GridRowsProp = informes.map((informe) => {
        return {
            id: informe.id,
            fecha: informe.fecha,
            cliente: informe.obra.cliente.nombre ?? '',
            elaboro: informe.responsableBeling[0].nombre ?? '',
            observaciones: informe.observaciones,
        }
    });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        //dispatch(startSetActiveReporte(id));
        //dispatch(startSetModalReporte(true));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {

    };

    const handleShowInforme = (id: GridRowId) => () => {
        dispatch(startSetActiveInforme(id));
        dispatch(startSetModalInformePdf(true));
    }


    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        console.log('processRowUpdate', updatedRow);
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'fecha',
            headerName: 'Fecha Reporte',
            flex: width > 900 ? 0.7 : 0,
            width: 100,
            editable: false
            , renderCell: (value) => {
                return (<Typography>{moment(value.row.fecha).format('DD/MM/YYYY')}</Typography>)
            }
        },
        {
            field: 'cliente',
            headerName: 'Cliente',
            flex: width > 900 ? 1 : 0,
            width: 200,
            editable: false, renderCell: CellExpand
        },
        {
            field: 'observaciones',
            headerName: 'Observaciones',
            flex: width > 900 ? 1 : 0,
            width: 200,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'elaboro',
            headerName: 'Elaboró informe',
            flex: width > 900 ? 1 : 0,
            width: 200,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Guardar"><SaveIcon /></Tooltip>}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Cancelar"><CancelIcon /></Tooltip>}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Informe"><InsertDriveFileOutlinedIcon /></Tooltip>}
                        label="Informe"
                        onClick={handleShowInforme(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar"><DeleteIcon /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <DataGrid
                autoHeight
                rows={reportes.slice(0, reportes.length)}
                columns={columns}
                editMode="row"
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } }
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            <ModalInformePdf />
            {/* <ModalReporteClaro />
            <AlertDelete />*/}
        </Box>
    );
}
