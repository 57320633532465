import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';

import { AppState } from '../../../../../reducers/rootReducer';
import { useSelect } from '../../../../../hooks/useSelect';
import { startLoadRutinasBancoBaterias } from '../../../../resources/actions/resources';

import { ModalSelect } from '../../../../../components/ui/ModalSelect';
import { RutinaBancoBaterias } from '../interfaces/rutina';
import { startSetModalRutinaBancoBaterias, startSetRutinasBancoBateria } from '../actions/rutina';

const initSelect = {
  idRutinaBB: '',
}

interface Props {
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}
export const ModalSelectRutinaRectificador = ({ setRowModesModel }: Props) => {
  const dispatch = useDispatch();
  const { rutinasBancoBaterias: rutinas } = useSelector((state: AppState) => state.resources);
  const { modalSelectBanco } = useSelector((state: AppState) => state.reportes.modules.rutinaBancoBateria);

  const { onChangeSelect, registerSelectData, changeSelect } = useSelect(initSelect);

  const { idRutinaBB } = registerSelectData;

  const handleClose = () => {
    dispatch(startSetModalRutinaBancoBaterias(false))
    changeSelect(initSelect);
  }

  const handleSelect = () => {
    const rutinaDB = rutinas.filter(rutina => rutina.id === idRutinaBB);

    const rutina: RutinaBancoBaterias = {
      id: rutinaDB[0].id,
      numero: rutinaDB[0].numero,
      nombre: rutinaDB[0].nombre,
      descripcion: rutinaDB[0].descripcion,
      observaciones: '',      
      isNew: true
    }

    dispatch(startSetRutinasBancoBateria(rutina));
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [rutinaDB[0].id]: { mode: GridRowModes.Edit, fieldToFocus: 'nombre' },
    }));
    handleClose();
  }

  useEffect(() => {
    if (rutinas.length === 0) {
      dispatch(startLoadRutinasBancoBaterias())
    }
  }, [dispatch, rutinas])
  
  return (
    <>
    <ModalSelect 
     handleClose={handleClose}
     handleSelect={handleSelect}
     modal={ modalSelectBanco }
     onChangeSelect={onChangeSelect}
     value={idRutinaBB}
     values={rutinas}
     name='idRutinaBB'
     label='Rutina'
     title='Rutina Banco de Baterías'
    />      
    </>
  );
}
