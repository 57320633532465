import { useMapServicios } from '../../hooks/useMapServicios';

export const MapComponent = () => { 

  const { mapContainer } =useMapServicios();
  return (
    <div ref={mapContainer} className="map-container" ></div>
  )

}
