import { GridRowId } from "@mui/x-data-grid";
import { Equipo } from "../../../interfaces/ats";
import { RiesgoSitioActions } from "../interfaces/riesgoSitio";


export const startSetRiesgosSitio = (riesgo: Equipo):RiesgoSitioActions => ({
    type: 'set-riesgos-sitio',
    payload: riesgo,
});

export const startUpdateRiesgoSitio = (riesgo: Equipo):RiesgoSitioActions => ({
    type: 'update-riesgos-sitio',
    payload: riesgo,
});

export const startDeleteRiesgoSitio = (id: GridRowId):RiesgoSitioActions => ({
    type: 'delete-riesgos-sitio',
    payload: id,
});

export const startEditRiesgoSitio = (id: GridRowId):RiesgoSitioActions => ({
    type: 'edit-riesgos-sitio',
    payload: id,
});

export const startSetRiesgosSitioEmpty = ():RiesgoSitioActions => ({
    type: 'set-riesgos-sitio-empty',
});