import { GridRowId } from "@mui/x-data-grid";
import { RutinaAlarma, RutinaAlarmaAction } from "../interfaces/alarma";
import { Imagen } from "../../../../../interfaces/image";

export const startSetAlarma = (rutina: RutinaAlarma):RutinaAlarmaAction => ({
    type: 'set-rutina-alarma',
    payload: rutina,
});

export const startUpdateAlarma = (rutina: RutinaAlarma):RutinaAlarmaAction => ({
    type: 'update-rutina-alarma',
    payload: rutina,
});

export const startDeleteAlarma = (id: GridRowId):RutinaAlarmaAction => ({
    type: 'delete-rutina-alarma',
    payload: id,
});

export const startSetAlarmaId = (id: GridRowId):RutinaAlarmaAction => ({
    type: 'set-rutina-alarma-id',
    payload: id,
});

export const startSetAlarmaUpdateImage = (image: Imagen, id: GridRowId):RutinaAlarmaAction => ({
    type: 'set-rutina-update-image-alarma',
    payload: {
        image,
        id,
    },
});

export const startSetModalAlarma = (modal: boolean):RutinaAlarmaAction => ({
    type: 'set-rutina-modal-alarma',
    payload: modal,
});

export const startSetAlarmaEmpty = ():RutinaAlarmaAction => ({
    type: 'set-rutina-alarma-empty',
});

export const startSetModalRutinaAlarmas = (payload: boolean): RutinaAlarmaAction =>({
    type: 'set-modal-select-rutina-alarma',
    payload
})


export const startSetActiveRutinaAlarmas = (payload: GridRowId): RutinaAlarmaAction =>({
    type: 'set-active-rutina-alarma',
    payload
})

export const startDelImgRutinaAlarmas = (imgId: GridRowId, id: GridRowId): RutinaAlarmaAction => ({
    type: 'set-delete-image-rutina-alarmas',
    payload:{
        id,
        imgId
    }
})

export const startClearImagesRutinaAlarmas = (payload: GridRowId): RutinaAlarmaAction =>({
    type: 'set-clear-images-rutina-alarmas',
    payload
})

export const startSetModImgAlarmas = (payload: boolean): RutinaAlarmaAction =>({
    type: 'set-modal-imagenes-rutina-alarmas',
    payload
})