import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from '../../../../interfaces/informes';
import { dateShortFour } from '../../../../../../helpers';
const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		minHeight: 14,
		textAlign: 'center',
		borderLeft: 1,
		borderBottom: 1,
		borderRight: 1,
		marginTop: -0.5
	},
	columnLeft: {
		flexDirection: 'column',
		width: '50%',
		borderRight: 1
	},
	colunmRight: {
		flexDirection: 'column',
		width: '50%'
	},
	rowContainer: {
		flexDirection: 'row',
		width: '100%',
	},
	text: {
		fontSize: 7,
		fontWeight: 'bold',
		marginTop: 2,
	},
	leftText:{
		fontSize: 7, 
		fontWeight: 'bold', 
		marginTop: 2, 
		marginLeft: 3 
	},
	rightText:{
		fontSize: 7, 
		fontWeight: 'normal', 
		marginTop: 2, 
		marginLeft: 3
	}
})


interface Props {
	active: Informe;
}

export const PageTwoHead = ({active}: Props) => {
	return (
		<>
			<View style={[styles.row, { justifyContent: 'center' }]}>
				<Text style={[styles.text]}>Proyecto / Trabajo</Text>
			</View>
			<View style={[styles.row, { justifyContent: 'center' }]}>
				<View style={[styles.columnLeft]}>
					<View style={[styles.rowContainer, { alignItems: 'center'}]}>
						<Text style={[styles.leftText]}>
							Nº: Contratada / Pedido de Compra:
						</Text>
						<Text style={[styles.rightText]}>PO 202</Text>
					</View>
				</View>
				<View style={[styles.colunmRight]}>
					<View style={[styles.rowContainer, { alignItems: 'center'}]}>
						<Text style={[styles.leftText]}>
							Empresa contratante :
						</Text>
						<Text style={[styles.rightText]}>Scala Data Centers</Text>
					</View>
				</View>
			</View>
			<View style={[styles.row, { justifyContent: 'center' }]}>
				<View style={[styles.columnLeft]}>
					<View style={[styles.rowContainer, { alignItems: 'center'}]}>
						<Text style={[styles.leftText]}>
							Data:
						</Text>
						<Text style={[styles.rightText]}>{dateShortFour(active.fecha!)}</Text>
					</View>
				</View>
				<View style={[styles.colunmRight]}>
					<View style={[styles.rowContainer, { alignItems: 'center'}]}>
						<Text style={[styles.rightText]}>
						SBOGZB01
						</Text>
					</View>
				</View>
			</View>
		</>
	)
}
