import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
} from '@mui/x-data-grid';

import { v4 as uuid } from 'uuid'
import { AppState } from '../../../reducers/rootReducer';
import { startDeleteMaquinaria, startSetMaquinaria, startUpdateMaquinaria } from '../actions/maquinaria';
import { EditToolbarProps, Maquinaria } from '../../../interfaces/shared';
import { StyledDataGrid } from '../../../styles/styledDataGrid';



function EditToolbar(props: EditToolbarProps) {
    const dispatch = useDispatch();
    //const { herramientas } = useSelector((state: AppState) => state.reportes.modules.herramientas);
    const { setRowModesModel } = props;

    const handleClick = () => {
       const id: GridRowId = uuid();
        /*if (herramientas.length === 0) {
            id = 1;
        } else {
            id = herramientas[herramientas.length - 1].id + 1;
        } */
        dispatch(startSetMaquinaria({ id, equipo: '', cantidad: 0, marca: '', propiedad: '', horaInicio: new Date(), horaFinal: new Date(), isNew: true }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'equipo' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar Maquinaria y/o Equipo
            </Button>
        </GridToolbarContainer>
    );
}

export const MaquinariaTable = () => {
    const locale = esES.components.MuiDataGrid.defaultProps.localeText
    const dispatch = useDispatch();
    const { equipos } = useSelector((state: AppState) => state.shared.maquinaria);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [pageSize, setPageSize] = useState(5);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteMaquinaria(id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel<Maquinaria>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateMaquinaria(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[]= [
        { field: 'equipo', headerName: 'Tipo maquinaria o equipo', flex:2, editable: true },        
        {
            editable: true,
            field: 'cantidad',
            flex:1,
            headerAlign:'center',
            headerName: 'Cantidad',
            type: 'number',
        },
        {
            field: 'marca',
            headerName: 'Marca',
            flex:1,
            editable: true,
        }, {
            field: 'propiedad',
            headerName: 'Propiedad de',
            type:'singleSelect',
            valueOptions:['Beling', 'Otros'],
            headerAlign:'center',
            flex:1,
            editable: true,
        },
        {
            field: 'horaInicio',
            headerName: 'Hora Inicial',
            type: 'dateTime',
            flex:1,
            editable: true,
        },
        {
            field: 'horaFinal',
            headerName: 'Hora Final',
            type: 'dateTime',
            flex:1,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex:1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: "auto",
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <StyledDataGrid
                autoHeight
                rows={equipos.slice(0, equipos.length)}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: { setRowModesModel },
                }}
                experimentalFeatures={{ newEditingApi: true }}
                localeText={{ ...locale, noRowsLabel: 'No hay herramientas registradas' }}
                rowHeight={35}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15]}
            />
        </Box>
    );
}