import { useEffect } from "react";
import { Container, Grid, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../reducers/rootReducer";
import { startLoadItems } from '../actions/items';
import { Items } from './Items';
import { useNavigate } from "react-router-dom";
import { startSetActiveLista } from '../../listas/actions/lista';



export const ItemsScreen = () => {
	const navigate = useNavigate();
  const dispatch = useDispatch();

  const {items} = useSelector((state: AppState) => state.items);
  const {activeLista} = useSelector((state: AppState) => state.listas);

  useEffect(()=>{
    if(items && items.length > 0) return;
    dispatch(startLoadItems())
  },[dispatch, items])

  useEffect(() => {
	if(!activeLista){
		navigate('/listas',{replace: false})
	}
  
	return () => {
	  dispatch(startSetActiveLista(''))
	}
  }, [activeLista, dispatch, navigate])
  
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Clientes */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Items />
          </Paper>
        </Grid>
      </Grid>      
    </Container>
  )
}