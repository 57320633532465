import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, TextField, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { AppState } from '../../../reducers/rootReducer';
import { useForm } from '../../../hooks/useForm';
import { startCreateUser, startSetActiveUser, startSetIsSameUser, startSetModalUser, startUpdateUser } from '../actions/user';
import { startLoadRoles } from '../../resources/actions/resources';
import { useMobileDetect } from '../../../hooks';

const initForm = {
    username: "",
    email: "",
    name: "",
    lastname: "",
    movil: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export const ModalUser = () => {
    const dispatch = useDispatch();
    const { isMobile } = useMobileDetect();
    const { activeUser, modalUser, isSameUser } = useSelector((state: AppState) => state.usuarios);
    const { roles } = useSelector((state: AppState) => state.resources);

    const { onChange, changeData, registerData } = useForm(initForm);

    const { username, email, name, lastname, movil } = registerData;
    const [role, setRole] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof role>) => {
        const {
            target: { value },
        } = event;
        setRole(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {

        if (roles.length === 0) {
            dispatch(startLoadRoles());
        }

    }, [dispatch, roles]);

    useEffect(() => {
        if (!activeUser) {
            changeData(initForm);
        } else {
            const formData = {
                username: activeUser.username,
                email: activeUser.email,
                name: activeUser.name,
                lastname: activeUser.lastname,
                movil: activeUser.movil,
            };
            changeData(formData);
            setRole(activeUser.roles);
        }
    }, [activeUser, changeData]);

    const handleClose = () => {
        dispatch(startSetActiveUser(''));
        dispatch(startSetModalUser(false));
        dispatch(startSetIsSameUser(false))
        changeData(initForm);
        setRole([]);
    };

    const handleSave = () => {
        const user = {
            username,
            email,
            name,
            lastname,
            movil,
            roles: role,
            id: "",
            status: true
        };

        if (!activeUser) {
            dispatch(startCreateUser(user));
        } else {
            dispatch(startUpdateUser(user));
        }
        dispatch(startSetActiveUser(''));
        handleClose();

    }

    return (
        <>
            <Dialog
                open={modalUser}
                onClose={handleClose}
                fullScreen={isMobile}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    <Box
                        sx={{
                            mx: 4,
                            display: "flex",
                            flexDirection: isMobile ? "row" : "column",
                            alignItems: "center",
                            justifyContent: 'left'
                        }}
                    >
                        <Avatar sx={{ bgcolor: "secondary.main" }}>
                            <PeopleAltIcon />
                        </Avatar>
                        <Typography component="h1" variant={isMobile ? "h6" : "h5"} sx={{ml: 2}}>
                            {activeUser
                                ? "Actualizar"
                                : "Agregar Usuario"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        width="100%"
                        component="div"
                        sx={{ mt: 1 }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    disabled={isSameUser}
                                    margin="dense"
                                    value={username}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="username"
                                    label="Usuario"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    inputProps={{ tabIndex: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={email}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type='email'
                                    inputMode='email'
                                    autoComplete="email"
                                    inputProps={{ tabIndex: 2 }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={name}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="name"
                                    label="Nombres"
                                    name="name"
                                    autoComplete="name"
                                    inputProps={{ tabIndex: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={lastname}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="lastname"
                                    label="Apellidos"
                                    name="lastname"
                                    autoComplete="lastname"
                                    inputProps={{ tabIndex: 4 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={movil}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="movil"
                                    label="Celular"
                                    name="movil"
                                    inputMode='tel'
                                    autoComplete="movil"
                                    inputProps={{ tabIndex: 5 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth sx={{ mt: 1 }} >
                                    <InputLabel id="role-label">Role</InputLabel>
                                    <Select
                                        disabled={isSameUser}
                                        labelId="role-label"
                                        id="role-checkbox"
                                        multiple
                                        value={role}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Role" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                        inputProps={{ tabIndex: 5 }}
                                    >
                                        {roles.map((r) => (
                                            <MenuItem key={r} value={r}>
                                                <Checkbox checked={role.indexOf(r) > -1} />
                                                <ListItemText primary={r} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button tabIndex={7} onClick={handleClose}>Cancelar</Button>
                    <Button tabIndex={8} onClick={handleSave}>{activeUser ? 'Actualizar' : 'Guardar'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
