import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridColDef,
} from '@mui/x-data-grid';

import { AppState } from '../../../reducers/rootReducer';
import { CellExpand } from '../../../components/ui/CellExpand';
import { useNavigate } from 'react-router-dom';
import { startSetActiveLista, startSetAlertDeleteLista, startSetIsNewLista, startSetModalLista } from '../actions/lista';

export const useListaTable = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isAdmin } = useSelector((state: AppState) => state.auth);
  const { listas } = useSelector((state: AppState) => state.listas);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({}); 

  const handleRowEditStart = (
      params: GridRowParams,
      event: MuiEvent<React.SyntheticEvent>,
  ) => {
      event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {        
      dispatch(startSetActiveLista(id));
      dispatch(startSetIsNewLista(false))
      dispatch(startSetModalLista(true));
  };

  const handleViewClick = (id: GridRowId) => () => {        
    dispatch(startSetActiveLista(id));
    navigate("/listas/detalle", { replace: false })
};

  const handleSaveClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
      dispatch(startSetActiveLista(id));
      dispatch(startSetAlertDeleteLista(true));
  };

  const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  };

  const processRowUpdate = (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isNew: false };
      //dispatch(startUpdateServicio(updatedRow));
      console.log('processRowUpdate', updatedRow);
      return updatedRow;
  };

  const columns: GridColDef[] = [
    { 
        field: 'lista', 
        headerName: 'lista', 
        flex:2, 
        editable: false, 
        headerAlign: 'center',
        renderCell: CellExpand 
    },
    { 
        field: 'descripcion', 
        headerName: 'Descripción', 
        flex:2, 
        editable: false, 
        headerAlign: 'center',
        renderCell: CellExpand 
    }
]; 

  return{
    columns,
    isAdmin,
    listas,
    rowModesModel,
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    handleViewClick,
    processRowUpdate,
    setRowModesModel,
  }
}
