import {
	Checkbox,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
  } from '@mui/material'; 
  

import { useTipoActividad } from '../hooks/useTipoActividad';
  
  export const TipoActividad = () => {
	const { actividades, checked, handleToggle } = useTipoActividad();
  
	return (
	  <>		
		<List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
		  {actividades.map((value, index) => {
			const labelId = value;
			return (
			  <ListItem key={index} disablePadding>
				<ListItemButton
				  role={undefined}
				  onClick={handleToggle(value)}
				  dense
				>
				  <ListItemIcon>
					<Checkbox
					  edge="start"
					  checked={checked.indexOf(value) !== -1}
					  tabIndex={-1}
					  disableRipple
					  inputProps={{ "aria-labelledby": labelId.toString() }}
					/>
				  </ListItemIcon>
				  <ListItemText id={labelId.toString()} primary={`${value}`} />
				</ListItemButton>
			  </ListItem>
			);
		  })}
		</List>
	  </>
	);
  };
  