import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { Cell } from '../../../../../interfaces/shared';
import { getImagen } from '../../../../../helpers/imagen';

const styles = StyleSheet.create({
	containerItem: {
		flexDirection: 'row',
		textAlign: 'left',
		color: '#212121',
	},
	cellContainerItem: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	cellContainerItemTop: {
		borderRight: 1,
	},
	cellContainerItemLeft: {
		borderLeft: 1
	},
	cellContainerItemBottom: {
		borderRight: 1,
		borderBottom: 1,
	},
	textContainerItem: {
		paddingLeft: 5,
		textAlign: 'left',
		flexWrap: 'wrap',
		width: '99%'
	},
	cellContainerVoidItemTop: {
		flexDirection: 'row',
		borderRight: 1,
		borderBottom: 1,
	},
	cellContainerVoidItemBottom: {
		borderBottom: 1,
	},	
	image: {
		padding: 5,
		maxHeight: 100,
		height: 'auto',
		borderRadius: 10
	}
});

interface Props {
	columnas: Cell[];
	bottom?: boolean;
	defaultWidth?: boolean
	sx?: {
		fz?: number,
		fw?: 'bold' | 'extrabold' | 'light' | 'normal' | 'demibold',
		mb?: number,
		mh?: number,
		mt?: number
	}
}


export const ItemsTable = ({ columnas, sx, bottom = false, defaultWidth = true }: Props) => {
	const largo = columnas.length;
	const { fz, fw, mb, mh, mt } = sx ?? {
		fz: 8,
		fw: 'bold',
		mb: 0,
		mh: 37,
		mt: 0
	};

	return (
		<View style={{
			fontSize: fz,
			marginBottom: mb,
			marginTop: mt,
			minHeight: mh,
			...styles.containerItem
		}}>
			{columnas.map((col: Cell, index: number) => {
				const length = col.name.toString().length;
				const cellStyles = cellStyle(length, bottom)
				const width = defineWidth(defaultWidth, col.width!, largo);
				const name: string = col.name.toString().split('.')[1];
				const align = col.align? col.align : 'left';
				const rows = name !== 'jpeg' ?
					<View style={[
						index === 0 ? { ...styles.cellContainerItemLeft } : {},
						styles.cellContainerItem,
						cellStyles,
						width,
						{ minHeight: mh }
					]} key={index}>
						<Text style={[
							styles.textContainerItem,
							{
								fontSize: fz,
								fontWeight: fw,
								textAlign: align
							}
						]}>{col.name}</Text>
					</View>
					: <View style={[cellStyles, width]} key={index}>
						<Image src={() => getImagen(col.name.toString())} style={styles.image} />
					</View>
				return (

					<Fragment key={index}>
						{rows}
					</Fragment>

				)
			})}
		</View>
	);
}

function cellStyle(length: number, position: boolean) {

	const fullCellTop = {

		...styles.cellContainerItemTop
	}

	const voidCellTop = {

		...styles.cellContainerVoidItemTop
	}
	const fullCellBottom = {

		...styles.cellContainerItemBottom
	}

	const voidCellBottom = {

		...styles.cellContainerVoidItemBottom
	}

	if (length > 0 && position) {
		return fullCellBottom
	} else if (length > 0 && !position) {
		return fullCellTop
	} else if (length < 0 && position) {
		return voidCellBottom
	} else {
		return voidCellTop
	}
}

function defineWidth(defaultW: boolean, w: string | number, l: number) {

	if (defaultW) {
		const width = 100 / l;
		return {
			minWidth: `${width}%`,
			maxWidth: `${width}%`
		}
	} else {
		return {
			minWidth: `${w}`,
			maxWidth: `${w}`
		}
	}
}


