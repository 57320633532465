import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from '@mui/material'
import { styled } from '@mui/material/styles';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useItemObraModal } from '../hooks/useItemObraModal';
import { ModalItemObraTable } from './ModalItemObraTable';

const Input = styled('input')({
    display: 'none',
});

export const ModalItemObra = () => {

    const {
        activeGrupo,
        modalItemObra,
        items,
        handleClose,
        handleSave,
        //handelLoadFile,
        //onChange,
    } = useItemObraModal();

   return (
        <>
            <Dialog
                open={modalItemObra}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    <Box
                        sx={{
                            my: 1,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <UploadFileOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Cargar Listado
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <label htmlFor="icon-button-file">
                            <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="icon-button-file" type="file" onChange={handleClose} />
                            <IconButton
                                color="primary" aria-label="upload picture" component="span">
                                <FileUploadOutlinedIcon />
                            </IconButton>
                        </label>
                    </Stack>
                    {
                        (!items) 
                        ? (<></>)
                        : (<ModalItemObraTable items={items} />)
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} tabIndex={14}>Cancelar</Button>
                    <Button onClick={handleSave} tabIndex={15}>
                        {activeGrupo ? "Actualizar" : "Guardar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
