import { useState } from "react";

export const useDate = <Date>(initState: Date) => {
    const [value, setValue] = useState<Date>(initState);
    const onChange = (e: any, name: string) => {
        setValue((prev: Date ) => ({
            ...prev,
            [name]: e
        }));
    }

    return{
        onDateChange: onChange,
        changeDate: setValue,
        registerDataDate: value,
    }
}