import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    titleContainer: {
        flexDirection: 'row',
    },    
});

interface Props {
    title: string;
    sx?:{
        mt?: number,
        fz?: number,
        fs?: 'italic' | undefined;
        fw?: 'bold' | 'extrabold' | 'light' | 'normal' |'demibold',
        ta?: 'center' | 'justify' | 'left' | 'right',
    };
}

export const TitleTables = ({title, sx }:Props) => {
    const {fw, fz, mt, ta, fs} = sx?? {
        fz: 10,
        fs: undefined,
        fw: 'bold',
        mt: 10,
        ta: 'left'
    }
    return (
    <View style={{marginTop: mt, ...styles.titleContainer}}>
        <Text style={[{fontSize: fz, fontWeight: fw, textAlign: ta, fontStyle: fs}]} break>{title}</Text>
    </View>
)};

