import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AppState } from '../reducers/rootReducer';


export const PublicRoute = ({children}: any) => {
    const { status } = useSelector((state: AppState) => state.auth)
  return status === 'authenticated'
  ? <Navigate to="/dashboard" />
  : children  
}
