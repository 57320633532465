import { View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import { Informe } from '../../../../interfaces/informes';
import { getImagen } from '../../../../../../helpers/imagen';
const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		height: '85%',
		textAlign: 'center',
		borderLeft: 1,
		borderRight: 1,
		marginTop: -0.5,
		flexWrap: 'wrap'
	},
	imageContainer: {
		flexDirection: 'row',
		border: 1,
		borderRadius: 5,
		borderColor: '#a1a1a1',
		height: 200,
		maxHeight: 300,
		alignItems: 'center',
		justifyContent: 'center',
		padding: 5
	},
	image: {
		maxWidth: 350,
		borderRadius: 5,
		border: 1,
	}
})


interface Props {
	active: Informe;
}

export const InformeFotografico = ({ active }: Props) => {
	const images = active.images ? active.images : [];
	return (
		<View style={[styles.row]}>
			{
				images.map((imagen, i) => {
					return (
						<View
							key={imagen.id}
							style={[{ flexDirection: 'column', justifyContent: 'center', height: 350, margin: 5 }]}
						>
							<View
								style={[styles.imageContainer]}
							>
								<Image src={() => getImagen(imagen.imagen!.name!.toString())} style={styles.image} />

							</View>
							<View style={[{ flexDirection: 'row', justifyContent: 'center' }]}>
								<Text style={[{ marginTop: 3, fontSize: 10, fontWeight: 'bold' }]}>{`FOTO ${i + 1}: ${imagen.description}`}</Text>
							</View>
						</View>
					)
				})
			}
		</View>
	)
}