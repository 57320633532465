import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';

import { AppState } from '../../../../../reducers/rootReducer';
import { useSelect } from '../../../../../hooks/useSelect';
import { startLoadAlarmas } from '../../../../resources/actions/resources';

import { ModalSelect } from '../../../../../components/ui/ModalSelect';
import { startSetAlarma, startSetModalRutinaAlarmas } from '../actions/alarma';
import { RutinaAlarma } from '../interfaces/alarma';


const initSelect = {
  idRutinaAL: '',
}

interface Props {
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}
export const ModalSelectRutinaAlarmas = ({ setRowModesModel }: Props) => {
  const dispatch = useDispatch();
  const { alarmas: rutinas } = useSelector((state: AppState) => state.resources);
  const { modalSelectRutinaAlarma } = useSelector((state: AppState) => state.reportes.modules.alarma);

  const { onChangeSelect, registerSelectData, changeSelect } = useSelect(initSelect);

  const { idRutinaAL } = registerSelectData;

  const handleClose = () => {
    dispatch(startSetModalRutinaAlarmas(false))
    changeSelect(initSelect);
  }

  const handleSelect = () => {
    const rutinaDB = rutinas.filter(rutina => rutina.id === idRutinaAL);

    const rutina: RutinaAlarma = {
      id: rutinaDB[0].id,
      numero: rutinaDB[0].numero,
      nombre: rutinaDB[0].nombre,
      descripcion: rutinaDB[0].descripcion,
      observaciones: '',      
      isNew: true
    }

    dispatch(startSetAlarma(rutina));
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [rutinaDB[0].id]: { mode: GridRowModes.Edit, fieldToFocus: 'nombre' },
    }));
    handleClose();
  }

  useEffect(() => {
    if (rutinas.length === 0) {
      dispatch(startLoadAlarmas())
    }
  }, [dispatch, rutinas])
  
  return (
    <>
    <ModalSelect 
     handleClose={handleClose}
     handleSelect={handleSelect}
     modal={ modalSelectRutinaAlarma }
     onChangeSelect={onChangeSelect}
     value={idRutinaAL}
     values={rutinas}
     name='idRutinaAL'
     label='Rutina'
     title='Rutina Alarmas'
    />      
    </>
  );
}
