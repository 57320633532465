import { GridRowId } from '@mui/x-data-grid';
import { fetchConToken } from '../../../apis/fetch';
import { doMessage, startLoading } from '../../ui/actions/ui';
import { NewSst, Sst, SstAction, Ubicacion } from '../interfaces/sst';

export const startLoadInformesSst = () => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('sst', {}, "GET");
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(loadInformesSst(body.informes));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const loadInformesSst = (sst: Sst[]): SstAction => ({
    type: 'load-informes-sst',
    payload: sst
});

export const startLoadInformeSst = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().sst.sst.activeSst.id;
        const resp = await fetchConToken(`sst/${id}`, {}, "GET");
        const body = await resp.json();
        const { errors, msg, ok, informe } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(loadSst(informe));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const loadSst = (sst: Sst): SstAction => ({
    type: 'load-informe-sst',
    payload: sst
})

export const startCreateInformesSst = (newSst: NewSst) => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('sst', newSst, "POST");
        const body = await resp.json();
        const { errors, msg, ok, informe } = body;       
        if (ok) {
            dispatch(startLoading());
            dispatch(createInformeSst(informe))
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const createInformeSst = (sst: Sst): SstAction => ({
    type: 'create-informes-sst',
    payload: sst
})

export const startUpdateInformeSst = (updatedSst: NewSst) => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().sst.sst.activeSst.id;
        if (!id) {
            return dispatch(doMessage({ message: 'No hay obra activa', type: 'error' }));
        }
        const resp = await fetchConToken(`sst/${id}`, updatedSst, 'PUT');
        const body = await resp.json();
        const { errors, msg, ok, informe } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(updateInformeSst(informe));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const updateInformeSst = (sst: Sst): SstAction => ({
    type: 'update-informes-sst',
    payload: sst
})

export const startDeleteInformeSst = (id: GridRowId) => {
    return async(dispatch: any) =>{
        dispatch(startLoading());
        const resp = await fetchConToken(`sst/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if(ok){
            dispatch(startLoading());
            dispatch(doMessage({ message: msg, type: 'success' }));
            dispatch(deleteInformeSst(id))
        }else{
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const deleteInformeSst = (id: GridRowId): SstAction => ({
    type: 'delete-informes-sst',
    payload: id
})

export const startSetActiveInformeSst = (id: GridRowId): SstAction => ({
    type: 'set-active-informe-sst',
    payload: id
})

export const startSetInactiveInformeSst = (): SstAction => ({
    type: 'set-inactive-informe-sst'
})

export const startSetModalInformeSst = (modal: boolean): SstAction => ({
    type: 'set-modal-informe-sst',
    payload: modal
})

export const startSetModalObraSst = (modal: boolean): SstAction => ({
    type: 'set-modal-obra-sst',
    payload: modal
})

export const startSetModalInformePdfSst = (modal: boolean): SstAction => ({
    type: 'set-modal-informe-pdf-sst',
    payload: modal
})

/*
* ubicacion
TODO: TRASLADAR A SHARED
*/

export const startSetUbicacionInicial = (ubicacion: Ubicacion): SstAction => ({
    type: 'set-ubicacion-inicial',
    payload: ubicacion
});

export const startClearUbicacionInicial = (): SstAction => ({
    type: 'set-clear-ubicacion-inicial',
});

export const startSetUbicacionFinal = (ubicacion: Ubicacion): SstAction => ({
    type: 'set-ubicacion-final',
    payload: ubicacion
});

export const startClearUbicacionFinal = (): SstAction => ({
    type: 'set-clear-ubicacion-final',
});
