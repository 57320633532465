import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        textAlign: 'center',
        flexGrow: 1,
    },
    referencia: {
        width: '70%',       
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    detalle: {
        width: '30%'
    }
  });

  interface Props{
    title: string;  
  }
export const EquiposHead = ({title}: Props) => (
    <View style={styles.container} fixed>
        <Text style={styles.referencia}>{ title }</Text>
        <Text style={styles.detalle}>Cantidad</Text>
    </View>
  );