import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from "../../../../interfaces/informes";
const styles = StyleSheet.create({
	row: {
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: 14,
		textAlign: 'center',
		borderLeft: 1,
		borderBottom: 1,
		borderRight: 1,
	},
	rowContainer: {
		flexDirection: 'row',
		width: '100%',
		minHeight: 14
	},
})

interface Props {
	active: Informe;
	title: string
}

export const Actividades = ({ active, title }: Props) => {
	const actividades = title === 'CONTRATISTA'? active.actividades! : active.actividadesCliente!;		
	const blankRows = Array(10 - actividades!.length).fill(0);
	return (
		<>
			<View style={styles.row} fixed>
				<View style={[styles.rowContainer, { justifyContent: 'center', alignItems: 'center', alignContent: 'center', borderBottom: 1 }]}>
					<Text style={[{ marginTop: 2, fontSize: 8 }]}>{title}</Text>
				</View>
				{actividades.map((item) => {
					return <View style={[styles.rowContainer, { borderBottom: 1, alignItems: 'center'}]} key={item.id}>
						<Text style={[{marginTop: 2, marginLeft: 2}]}>{item.actividad}</Text>
					</View>
				})}
				{blankRows.map((x, i) => <View style={[styles.rowContainer, { borderBottom: 1, alignItems: 'center', }]} key={`IRT${i}`}>
					<Text>{''}</Text>
				</View>)}
				<View style={[styles.rowContainer, {}]}>
					<Text>{''}</Text>
				</View>
			</View>
		</>
	)
}
