import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { v4 as UUID } from 'uuid';

import img from '../../../../../assets/img/bg.png';
import { AppState } from '../../../../../reducers/rootReducer'
import { saveImagen } from '../../../../../helpers';
import { useScreenSize } from '../../../../../hooks';
import { startAddImages, startSetModalImagePrev } from '../actions/images';
import { CustomCardImage } from '../../../../../components/ui';
import { doMessage, startLoading } from '../../../../ui/actions/ui';



export const ModalImagePrev = () => {
    const dispatch = useDispatch();
    const { width } = useScreenSize();
    const [imgWidth, setImgWidth] = useState<number>(220)
    const [foto, setFoto] = useState(img)
    const { activeServicio } = useSelector((state: AppState) => state.servicios)
    const { modalImagePrev } = useSelector((state: AppState) => state.reportes.modules.imagesPrev);

    const handleClose = () => {
        dispatch(startSetModalImagePrev(false));
        setFoto(img)
    };
    const handelLoadImage = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setFoto(reader.result as string);
        }
    }

    const handleSave = async () => {
        dispatch(startLoading())
        const data = {
            imagen: foto,
            servicio: activeServicio!.id,
            name: `${activeServicio!.tipo.alias}-${UUID()}`,
            descripcion: activeServicio!.descripcion,
        };

        const { imagen, status } = await saveImagen(data)

        if (status && status === 1) {
            dispatch(startAddImages(imagen))
        } else {
            dispatch(doMessage({ message: 'Error guardando la imagen', type: 'error' }))
        }
        dispatch(startSetModalImagePrev(false));
        setFoto(img)
        dispatch(startLoading())
    }
    useEffect(() => {
        if (width > 843) {
            setImgWidth(220)
        } else {
            setImgWidth(120)
        }
    }, [width])

    return (
        <>
            <Dialog open={modalImagePrev} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogTitle>Agregar Imagén</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CustomCardImage
                        foto={foto}
                        imgWidth={imgWidth}
                        descripcion={activeServicio!.descripcion}
                        onChange={handelLoadImage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
