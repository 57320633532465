import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { MedicionesBateria } from '../../../interfaces/reportes';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',        
        alignItems: 'center',
        minHeight: 14,
        color: '#212121'
    },
    rowTop:{
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
    },
    numeroBanco: {
        width: '20%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 4,
    },
    numeroBateria: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    medicion: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    impedancia: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    porcentajeSulfatacion: {
        width: '20%',
        textAlign: 'center',
    },
  });

interface Props {
    items: MedicionesBateria[];
    }

export const TableMedicionBateriaRow = ({items}: Props) => {
    const rows = items.map( (item, index) => {
        const cellStyles = cellStyle(index, items.length);
        return(
        <View style={[cellStyles]} key={item.id.toString()}>
            <Text style={styles.numeroBanco}>{item.numeroBanco}</Text>
            <Text style={styles.numeroBateria}>{item.numeroBateria}</Text>
            <Text style={styles.medicion}>{item.medicion}</Text>
            <Text style={styles.impedancia}>{item.impedancia}</Text>
            <Text style={styles.porcentajeSulfatacion}>{item.porcentajeSulfatacion}</Text>
        </View>)}
    )
    return (<Fragment>{rows}</Fragment> )
};

function cellStyle(index: number, length: number) {

    const cell = {
        ...styles.row,
        ...styles.rowTop
    }

    const cellEnd = {
        ...styles.row
    }

    if (length === index + 1) {
        return cellEnd
    } else {
        return cell
    }

}