import { View, StyleSheet } from "@react-pdf/renderer";
import { Reporte } from '../../../interfaces/reportes';
import { TitleTables } from "../../../../../components/pdf/TitleTables";
import { HeaderTable, ItemsTable } from "../../../../informes/components/pdf/normal";
import { Cell } from "../../../../../interfaces/shared";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

interface Props {
	active: Reporte;
}

const tableTitles: Cell[] = [
	{ name: "Item", width: "7%" },
	{ name: "Actividad", width: "35%" },
	{ name: "unidad", width: "13%" },
	{ name: "Cantidad", width: "13%" },
	{ name: "Observaciones", width: "32%" }
];

const title: string = 'Actividades a realizar';
const titleTable: string = 'Enumere las actividades realizadas durante el servicio'


export const ActividadesBasico = ({ active }: Props) => {
	
	return (
		<View>
			<TitleTables
				title={title}
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
			/>
			<TitleTables title={titleTable} sx={{ fw: 'normal', fz: 10 }} />
			<View style={styles.tableContainer} wrap={false}>

			<HeaderTable names={tableTitles} defaultWidth={false} sx={{ txa: 'center', bc: "#dddddd" }} />
			{
				active.actividades?.map((item, index) => {
					const fila: Cell[] = [
						{ name: index + 1, width: '7%', align: 'center' },
						{ name: item.actividad!, width: '35%' },
						{ name: item.unidad!, width: '13%' },
						{ name: item.cantidad!, width: '13%', align: 'center' },
						{ name: item.observaciones!, width: '32%' },
					]
					return <ItemsTable key={item.id} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom defaultWidth={false} />
					
				})
			}
			</View>
		</View>
	)
}
