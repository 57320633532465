import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../../reducers/rootReducer';
import { useForm } from '../../../hooks/useForm';

import { startCreateGrupo, startSetInactiveGrupo, startSetModalGrupo, startUpdateGrupo } from '../actions/grupos';
import { useMobileDetect } from '../../../hooks';

const initForm = {
	descripcion: "",
	item: ""
};


export const useGrupoModal = () => {
const dispatch = useDispatch();
const { isMobile } = useMobileDetect();
const {activeObra } = useSelector((state: AppState) => state.obras.obras);

const { activeGrupo, modalGrupo} = useSelector((state: AppState) => state.obras.grupos);

const { onChange, changeData, registerData, item, descripcion } = useForm(initForm);

const handleClose = () => {
    dispatch(startSetModalGrupo(false));
    dispatch(startSetInactiveGrupo())
}

const handleSave = () => {
    const grupo = {
        item,
        descripcion,
        obra: activeObra?.id!
    }
    if(!activeGrupo){
        dispatch(startCreateGrupo(grupo));
    }else{
        dispatch(startUpdateGrupo(grupo))
    }
    changeData({...initForm})
    handleClose();

}

useEffect(()=>{
    if(!activeGrupo){
        changeData({ ...initForm});
    }else{
        changeData({
            descripcion: activeGrupo.descripcion,
            item: activeGrupo.item,
        });
    }
},[activeGrupo, changeData]);

return {
    activeGrupo,
    isMobile,
    modalGrupo,
    registerData,
    handleClose,
    handleSave,
    onChange,
    changeData
}
}