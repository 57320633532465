import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from '../../../../interfaces/informes';

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
		marginTop: -1,
		borderLeft: 1,
		borderBottom: 1,
		borderRight: 1,
	},
	column: {
		flexDirection: 'column',		
		alignItems: 'center',
		justifyContent: 'center',
		padding: 4	
	},
	lefColumn: {
		width: '60%',
		height: '100%',
		borderRight: 1
	},	
	rowContainer: {
		flexDirection: 'row',
	},	
	rightColumn: {		
		width: '40%',
		height: '100%',
		marginLeft: -1
	},
	leftTitle: {
		fontSize: 12,
		fontWeight: 'bold'
	},
	topRightTitle: {
		fontSize: 8,
		fontWeight: 'bold'
	},
	bottomRightTitle: {
		fontSize: 10,
		fontWeight: 'normal'
	},
})

interface Props {
	active: Informe;
}

export const Detalle = ({ active }: Props) => {
	return (
		<>
			<View style={styles.row} fixed>
				<View style={[styles.column, styles.lefColumn]}>
					<Text style={styles.leftTitle}>INFORME DIARIO DE TRABAJO</Text>
				</View>
				<View style={[styles.column, styles.rightColumn]}>
					<View style={[styles.rowContainer]}>
						<Text style={styles.topRightTitle}>Nº: Contratada / Pedido de Compra</Text>
					</View>
					<View style={styles.rowContainer}>
						<Text style={styles.bottomRightTitle}>SBOGZB01-25268</Text>
					</View>
				</View>
			</View>
		</>
	)
}
