import { fetchConToken } from "../../../apis/fetch";
import { doMessage } from "../../ui/actions/ui";
import { ResourceAction, Resource, Informacion, ResourceRutina, ItemObra, AtsResource } from '../interfaces/resources';

export const startLoadClientes = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/clientes', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadClientesSelect(body.clientes));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadClientesSelect = (clientes: Resource[]):ResourceAction => {
    return {
        type: "load-clientes-select",
        payload: clientes
    }
}

export const starLoadContactos = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/contactos', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadContactosSelect(body.contactos));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadContactosSelect = (contactos: Resource[]):ResourceAction => {
    return {
        type: "load-contactos-select",
        payload: contactos
    }
}

export const starLoadAtsResources = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/ats', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadAtsSelect(body.ats));            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadAtsSelect = (payload: AtsResource[]):ResourceAction => {
    return {
        type: "load-ats-resources",
        payload
    }
}

export const startLoadEstados = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/estados', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadEstadosSelect(body.estados));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}



const loadEstadosSelect = (estados: Resource[]):ResourceAction => {
    return {
        type: "load-estados-select",
        payload: estados
    }
}

export const startLoadRoles = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/roles', {});
        const body = await resp.json();
        if (body.ok) {
            const roles = body.roles.map((r: any) => r.name);
            dispatch(loadRoles(roles));
        } else {
            dispatch(doMessage(body.msg));
        }
    }
}

const loadRoles = (roles: string[]):ResourceAction => ({
    type: "load-roles",
    payload: roles
})

export const startLoadTipos = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/tipos', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadTiposSelect(body.tipos));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadTiposSelect = (tipos: Resource[]):ResourceAction => {
    return {
        type: "load-tipos-select",
        payload: tipos
    }
}

export const startLoadTecnicos = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/tecnicos', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadTecnicosSelect(body.tecnicos));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadTecnicosSelect = (tecnicos: Resource[]):ResourceAction => {
    return {
        type: "load-tecnicos-select",
        payload: tecnicos
    }
}

export const startLoadCondicionesSeguridad = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/condiciones-seguridad', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadCondicionesSeguridad(body.condiciones_seguridad));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadCondicionesSeguridad = (condicionesSeguridad: Resource[]):ResourceAction => {
    return {
        type: "load-condiciones-seguridad",
        payload: condicionesSeguridad
    }
}

export const startLoadRiesgosAltura = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/riesgos-altura', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadRiesgosAltura(body.riesgos_altura));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadRiesgosAltura = (riesgosAltura: Resource[]):ResourceAction => {
    return {
        type: "load-riesgo-altura",
        payload: riesgosAltura
    }
}

export const startLoadRiesgos = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/riesgos', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadRiesgos(body.riesgos));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadRiesgos = (riesgos: Resource[]):ResourceAction => {
    return {
        type: "load-riesgos",
        payload: riesgos
    }
}

export const startLoadEquipos = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/equipos', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadEquipos(body.equipos));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadEquipos = (equipos: Resource[]):ResourceAction => {
    return {
        type: "load-equipos",
        payload: equipos
    }
}

export const startLoadHerramientas = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/herramientas', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadHerramientas(body.herramientas));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadHerramientas = (herramientas: Resource[]):ResourceAction => {
    return {
        type: "load-herramientas",
        payload: herramientas
    }
}

export const startLoadRutinasRectificador = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/rutinas-rectificador', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadRutinasRectificador(body.rutinas_rectificador));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadRutinasRectificador = (rutinasRectificador: ResourceRutina[]):ResourceAction => {
    return {
        type: "load-rutinas-rectificador",
        payload: rutinasRectificador
    }
}

export const startLoadRutinasBancoBaterias = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/rutinas-banco-baterias', {});
        const body = await resp.json();
        if (body.ok) {            
            dispatch(loadRutinasBancoBaterias(body.rutinas_banco_baterias));
            
        } else {
            dispatch(doMessage(body.msg));            
        }
    }
}

const loadRutinasBancoBaterias = (rutinasBancoBaterias: ResourceRutina[]):ResourceAction => {
    return {
        type: "load-rutinas-banco-baterias",
        payload: rutinasBancoBaterias
    }
}

export const startLoadAlarmas = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/alarmas',{});
        const body = await resp.json();
        if(body.ok){
            dispatch(loadAlarmas(body.alarmas));
        }else{
            dispatch(doMessage(body.msg))
        }
    }
}

const loadAlarmas = (alarmas: ResourceRutina[]): ResourceAction =>({
    type: "load-alarmas",
    payload: alarmas
})

export const startLoadBateriasInfo = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/baterias',{});
        const body = await resp.json();
        if(body.ok){
            dispatch(loadBateriasInfo(body.bateria_info));
        }else{
            dispatch(doMessage(body.msg))
        }
    }
}

const loadBateriasInfo = (bateriasInfo: Informacion[]): ResourceAction =>({
    type: "load-baterias",
    payload: bateriasInfo
});

export const startLoadInversoresInfo = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/inversores',{});
        const body = await resp.json();
        if(body.ok){
            dispatch(loadInversoresInfo(body.inversor_info));
        }else{
            dispatch(doMessage(body.msg))
        }
    }
}

const loadInversoresInfo = (inversoresInfo: Informacion[]): ResourceAction =>({
    type: "load-inversores",
    payload: inversoresInfo
});

export const startLoadSitioInfo = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/sitio',{});
        const body = await resp.json();
        if(body.ok){
            dispatch(loadSitioInfo(body.sitio));
        }else{
            dispatch(doMessage(body.msg))
        }
    }
}

const loadSitioInfo = (sitio: Informacion[]): ResourceAction =>({
    type: "load-sitio",
    payload: sitio
});

export const startLoadRectificadoresInfo = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('resources/rectificadores',{});
        const body = await resp.json();
        if(body.ok){
            dispatch(loadRectificadoresInfo(body.rectificador_info));
        }else{
            dispatch(doMessage(body.msg))
        }
    }
}

const loadRectificadoresInfo = (rectificadoresInfo: Informacion[]): ResourceAction =>({
    type: "load-rectificadores",
    payload: rectificadoresInfo
});

export const startLoadItemsObra = () =>{
    return async (dispatch: any, getState: any) => {
        const grupo = getState().obras.grupos.activeGrupo.id;
        if (!grupo) return;
        const resp = await fetchConToken(`items-obra/${grupo}`, {}, "GET");
        const body = await resp.json();
        
        const { ok, itemsObra } = body;
        if (ok) {            
            dispatch(loadItemsObra(itemsObra));
        } else {
            dispatch(loadItemsObra([]));
        }
    }
}

const loadItemsObra = (items: ItemObra[]): ResourceAction =>({
    type: 'load-items-obra',
    payload: items
})