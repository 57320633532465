import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { EquipoTable } from './EquipoTable';

const Table = memo(() => {
    return(
        <EquipoTable />
    )
});

export const Equipo = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Equipos de seguridad requeridos
                    </Typography>
                </Grid>
            </Grid>
            <Table />
        </>
    )
}