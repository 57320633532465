import { ActividadAction, ActividadState } from '../interfaces/actividad';

const initState: ActividadState = {
    actividadesNo: [],
    modalActividadNo: false,
    activeActividadNo: null,
    modalImagesActividadNo: false
};

export const actividadNoContempladaReducer = (state = initState, action: ActividadAction): ActividadState => {
    switch (action.type) {
        case 'set-actividad-no-planeada':
            return {
                ...state,
                actividadesNo: [...state.actividadesNo, action.payload]
            };
        case 'update-actividad-no-planeada':
            return {
                ...state,
                actividadesNo: state.actividadesNo.map(actividad => {
                    if (actividad.id === action.payload.id) {
                        return action.payload;
                    }
                    return actividad;
                })
            };
        case 'delete-actividad-no-planeada':
            return {
                ...state,
                actividadesNo: state.actividadesNo.filter(actividad => actividad.id !== action.payload)
            };
        case 'edit-actividad-no-planeada':
            return {
                ...state,
                actividadesNo: state.actividadesNo.map(actividad => {
                    if (actividad.id === action.payload) {
                        actividad.isNew = true;
                        return actividad;
                    }
                    return actividad;
                })
            };
        case 'set-modal-actividad-no-planeada':
            return {
                ...state,
                modalActividadNo: action.payload
            };
        case 'set-active-actividad-no-planeada':
            const actividad = state.actividadesNo.find(a => a.id === action.payload);
            return {
                ...state,
                activeActividadNo: actividad ? actividad : null
            };
        case 'set-actividad-update-image-no-planeada':
            return {
                ...state,
                actividadesNo: state.actividadesNo.map(actividad => {
                    if (actividad.id === action.payload.id) {
                        if(!actividad.images){
                            actividad.images = [];
                        }
                        actividad.images.push(action.payload.image);
                    }
                    return actividad;
                })
            };
            case 'set-delete-image-actividad-no-planeada':
                return {
                    ...state,
                    actividadesNo: state.actividadesNo.map((actividad) => {
                        if (actividad.id === action.payload.id) {
                            actividad.images = actividad.images!.filter(i => i.id !== action.payload.imgId);
                        }
                        return actividad;
                    }),
                };
            case 'set-clear-images-actividad-no-planeada':
                return {
                    ...state,
                    actividadesNo: state.actividadesNo.map(actividad => {
                        if (actividad.id === action.payload) {
                            actividad.images?.shift();
                        }
                        return actividad
                    })
                }
        case 'set-inactive-actividad-no-planeada':
            return {
                ...state,
                activeActividadNo: null
            }
        case 'set-actividad-empty-no-planeada':
            return {
                ...state,
                actividadesNo: []
            };
            case 'set-modal-imagenes-actividad-no-planeada':
                return {
                    ...state,
                    modalImagesActividadNo: action.payload
                }      
        default:
            return state;
    }
}