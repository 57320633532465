import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { AppState } from '../../../reducers/rootReducer';
import { useForm } from '../../../hooks/useForm';

import { startCreateLista, startSetActiveLista, startSetModalLista, startUpdateLista } from '../actions/lista';
import { Lista } from '../interfaces/interfaces';
import { useMobileDetect } from '../../../hooks';


const initForm = {
    lista: '',
    descripcion: '',
    tipo: ''
}



export const ModalLista = () => {
    const dispatch = useDispatch();
    const { isMobile } = useMobileDetect();
    const { activeLista, isNew, modalLista } = useSelector((state: AppState) => state.listas);
    const { onChange, changeData, lista, descripcion, tipo } = useForm(initForm);


    const handleClose = () => {
		dispatch(startSetActiveLista(''))
        dispatch(startSetModalLista(false));
    };

    const handleSave = async () => {
        const newLista: Lista = {
            lista,
			descripcion,
			tipo
        };

        if (isNew) {
            dispatch(startCreateLista(newLista));            
        } else {
            dispatch(startUpdateLista(newLista));            
        }
        handleClose();

    }

    useEffect(() => {
        if (isNew) {
            changeData(initForm);
        } else {
            if (!activeLista) {
                handleClose()
            } else {
                const formData = {
                    lista: activeLista.lista,
					descripcion: activeLista.descripcion,
					tipo: activeLista.tipo
                };
                changeData(formData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew, activeLista]);

    return (
        <>
            <Dialog
             open={modalLista} 
             onClose={handleClose} 
             fullWidth
             maxWidth="xs">
                <DialogTitle>
                    <Box
                        sx={{
                            my: 1,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {!isMobile && <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
						<FormatListBulletedIcon />
                        </Avatar>}
                        <Typography component="h1" variant={isMobile ? "h6": "h5"}>
                            {isNew
                                ? "Agregar Lista"
                                : "Actualizar"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        width="100%"
                        component="div"                      
                        sx={{ mt: 1 }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    value={lista}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="lista"
                                    label="Nombre de la lista"
                                    name="lista"
                                    autoComplete="lista"
                                    autoFocus
                                    size="small"
                                    inputProps={{ tabIndex: 1 }}
                                />
                            </Grid>                                                
                            <Grid item xs={12} >
                                <TextField
                                    margin="dense"
                                    value={tipo}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="tipo"
                                    label="Tipo"
                                    name="tipo"
                                    autoComplete="tipo"
                                    size="small"
                                    inputProps={{ tabIndex: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    value={descripcion}
                                    onChange={onChange}
                                    fullWidth
									multiline
									rows={3}
                                    id="descripcion"
                                    label="Descripción"
                                    name="descripcion"
                                    autoComplete="descripcion"
                                    size="small"
                                    inputProps={{ tabIndex: 2 }}
                                />
                            </Grid>                             
                        </Grid>                       
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} tabIndex={8}>Cancelar</Button>
                    <Button onClick={handleSave} tabIndex={9}>{activeLista ? 'Actualizar' : 'Guardar'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
