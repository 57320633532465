import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: 24,
		textAlign: 'center',
	},
	column: {
		flexDirection: 'column',
		borderTop: 3,
		borderBottom: 3,
		padding: 5,

	},
	columnBottom: {
		flexDirection: 'column',
		borderBottom: 3,
		padding: 5,
	},

	lefColumn: {
		height: '100%',
		width: '10%',
		borderLeft: 3,
		borderRight: 3,

	},
	rightColumn: {
		borderRight: 3,
		width: '89.8%',
		marginLeft: -1,
	},
	rowContainer: {
		flexDirection: 'row',
	},
	colContainer: {
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		flexWrap: 'wrap',
	},
	txTitle: {
		fontSize: 11,
		fontWeight: 'bold',
	},
	txContent: {
		fontSize: 11,
		fontWeight: 'normal',
	},
});

interface Props {
	index: number;
	title: string | number
}

export const BodyLines = ({ index, title }: Props) => {
	return (
		<>
			<View style={[styles.row, { marginTop: -1 }]} key={index}>
				<View style={[styles.columnBottom, styles.lefColumn]}>
					<View style={[styles.rowContainer]}>
						<View style={[styles.colContainer, {}]}>
							<Text style={[styles.txTitle, { textAlign: 'center' }]}>{`${index + 1}.`}</Text>
						</View>
					</View>
				</View>
				<View style={[styles.columnBottom, styles.rightColumn]}>
					<View style={[styles.rowContainer]}>
						<View style={[styles.colContainer]}>
							<Text style={[styles.txContent, { textAlign: 'left' }]}>{title}</Text>
						</View>
					</View>
				</View>
			</View>
		</>
	)
}
