import { Button, Card, CardMedia, Dialog, DialogActions, DialogContent, IconButton, Stack } from "@mui/material"
import { useImagen } from "../hooks/useImagen"
import { PhotoCamera } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

const Input = styled('input')({
    display: 'none',
});


export const ModalImagen = () => {

    const { foto, modalImagen, handleClose, handelLoadImage, handleSave } = useImagen();

    return (
        <>
            <Dialog open={modalImagen} onClose={handleClose}>
                <DialogContent>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={foto}
                            alt="Paella dish"
                        />
                    </Card>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <label htmlFor="icon-button-file">
                            <Input accept="image/*" id="icon-button-file" type="file" onChange={handelLoadImage} />
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                <PhotoCamera />
                            </IconButton>
                        </label>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}