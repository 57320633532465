import { Dialog, DialogContent } from '@mui/material';
import { useSstaModal } from '../hooks/useSstModal';
import { FormReporteScreenSst } from './FormReporteScreenSst';
import { ModalBar } from '../../../shared/modal/ModalBar';
import { Transition } from '../../../components/ui/Transition';
import { ModalActions } from '../../../shared/modal/ModalActions';


export const ModalSst = () => {
  const {
    ats,
    atsM,
    activeSst,
    formError,
    modalSst,
    registerData,
    registerDataDate,
    pantalla,
    planes,
    planesTrabajo,    
    handleIniciar,
    handleFinalizar,
    onChange,
    onDateChange,
    handleClose,
    handleSave,
    handleChangePlanes,
    handleChangeAts,
  } = useSstaModal();

  const title = activeSst? `Informe SST No ${activeSst.numero}`: 'Agregar Informe SST';

  return (
    <>
      <Dialog open={modalSst} onClose={handleClose} fullScreen={pantalla} TransitionComponent={Transition} maxWidth={'sm'} >
        <ModalBar handleClose={handleClose} handleSave={handleSave} title={title} />
        <DialogContent>
          <FormReporteScreenSst
          activeSst={activeSst!}
          ats={ats}
          atsM={atsM}
          formError={formError}
            onChange={onChange}
            onDateChange={onDateChange}
            registerData={registerData}
            registerDataDate={registerDataDate}
            handleFinalizar={handleFinalizar}
            handleIniciar={handleIniciar}
            handleChangePlanes={handleChangePlanes}
            handleChangeAts={handleChangeAts}
            planes={planes}
            planesTrabajo={planesTrabajo}          
            />
        </DialogContent>

        <ModalActions handleClose={handleClose} handleSave={handleSave} />
      </Dialog>
    </>
  )
}