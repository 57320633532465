import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { Info } from '../../../interfaces/reportes';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',        
        alignItems: 'center',
        minHeight: 14,
        color: '#212121'
    },
    rowTop:{
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
    },
    referencia: {
        width: '50%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 4,
    },
    detalle: {
        width: '50%',
        borderRightColor: borderColor,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
  });

interface Props {
    items: Info[];
    }

export const TableInfoBateriasRow = ({items}: Props) => {
    const rows = items.map( (item, index) =>{
        const cellStyles = cellStyle(index, items.length);
        return( 
        <View wrap={false} style={[cellStyles]} key={item.id.toString()}>
            <Text style={styles.referencia}>{item.referencia}</Text>
            <Text style={styles.detalle}>{item.detalle}</Text>
        </View>)}
    )
    return (<Fragment>{rows}</Fragment> )
};

function cellStyle(index: number, length: number) {

    const cell = {
        ...styles.row,
        ...styles.rowTop
    }

    const cellEnd = {
        ...styles.row
    }

    if (length === index + 1) {
        return cellEnd
    } else {
        return cell
    }

}