import { GrupoAction, GrupoState } from "../interfaces/grupo.interface";

const initState: GrupoState ={
    activeGrupo: null,
    grupo: null,
    grupos: [],
    modalGrupo: false
}

export const grupoReducer = (state = initState, action: GrupoAction): GrupoState =>{
  switch(action.type){
    case 'load-grupos':
        return {
            ...state,
            grupos: action.payload
        }
    case 'create-grupo':
        return {
            ...state,
            grupos: [...state.grupos, action.payload]
        }
    case 'update-grupo':
        return {
            ...state,
            grupos: state.grupos.map(
                o => (o.id === action.payload.id) ? action.payload : o
            )
        }
    case 'delete-grupo':
        return {
            ...state,
            grupos: state.grupos.filter(g => g.id !== action.payload)
        }
    case 'set-modal-grupo':
        return {
            ...state,
            modalGrupo: action.payload
        }
    case 'set-active-grupo':
        const grupo = state.grupos.find(o => o.id === action.payload);
        return {
            ...state,
            activeGrupo: grupo ? grupo : null
        }
    case 'set-inactive-grupo':
        return {
            ...state,
            activeGrupo: null
        }
    case 'load-grupo':
        return {
            ...state,
            grupo: action.payload
        }    
    default:
    return state
  }
}