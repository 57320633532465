import {useEffect, useMemo, useState } from 'react';
import throttle from 'lodash/throttle';
import { PlaceType } from '../interfaces/google';
import { getMapboxGeoCode } from '../helpers/getMapboxGeoCode';

export const useMapboxAutocomplete = (value: PlaceType | null ) => {
  
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<readonly PlaceType[]>([]);     
  
    const fetch = useMemo(
          () =>
              throttle(
                  (
                      request: { input: string },
                      callback: (results?: readonly PlaceType[]) => void,
                  ) => {
                    getMapboxGeoCode({request, callback})
                  },
                  500,
              ),
          [],
      );    
  
     useEffect(() => {
      let active = true;    
      if (inputValue === '') {
        setOptions(value ? [value] : []);
        return undefined;
      }  
  
  
    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {      
      if (active) {
        let newOptions: readonly PlaceType[] = [];
  
        if (value) {
          newOptions = [value];
        }
  
        if (results) {
          newOptions = [...newOptions, ...results];
        }
  
        setOptions(newOptions);
      }
    });
  
      return () => {
        active = false;
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, inputValue]);

  return{
    options,
    setInputValue,
    setOptions,
  }
}
