import { combineReducers } from 'redux';
import { sstReducer } from './sstReducer';
import { sstModuleReducer } from './sstModuleReducer';



export const sstRootReducer = combineReducers({
    modules: sstModuleReducer,    
    sst: sstReducer,
});

export type SstState = ReturnType<typeof sstRootReducer>;