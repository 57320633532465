import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight: 18,
        textAlign: 'center',        
        flexGrow: 1,
    },
    containerTitle:{
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        textAlign: 'center',
        
        flexGrow: 1,
    },
    title:{
        width: '100%',
        textAlign: 'center'
    },
    description: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    marca: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    cantidad: {
        width: '15%'
    },
    unidad:{
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1, 
    }
  });

export const HeaderTable  = () => (
    <>
    <View style={styles.containerTitle}>
        <Text style={styles.title}>Herramientas y Equipos</Text>
    </View>
    <View style={styles.container} fixed>
        <Text style={styles.description}>Descripción</Text>
        <Text style={styles.unidad}>Unidad</Text>
        <Text style={styles.qty}>Cantidad</Text>
        <Text style={styles.marca}>Marca</Text>
        <Text style={styles.cantidad}>Propiedad</Text>
    </View>
    </>
  );
