import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { TableInfoInversorRow } from './TableInfoInversorRow';
import { TableInfoInversorHeader } from './TableInfoInversorHeader';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
    },
});

interface Props {
    active: Reporte;
}

export const InfoInversorTable = ({ active }: Props) => (
    <View>
        <TitleTables title='Información del Sistema Inversor - Caracteristicas del Inversor' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />
        <View style={styles.tableContainer}>
            <TableInfoInversorHeader />
            <TableInfoInversorRow items={active.infoInversores!} />
        </View>
    </View>
);
