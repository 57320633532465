import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';

import beling from '../../../../../assets/img/beling.png'
import sst from '../../../../../assets/img/sst.png'
import { Sst } from '../../../interfaces/sst';
import { getImagen } from '../../../../../helpers/imagen';



const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',		
		textAlign: 'center',
		minHeight: 100		
	},
	column: {
		flexDirection: 'column',
		borderTop: 3,
		borderBottom: 3,
		alignItems: 'center',

	},
	lefColumn: {
		height: 100,
		width: '25%',
		borderLeft: 3,
	},
	rightColumn: {
		height: 100,
		borderRight: 3,
		width: '25%',
		justifyContent: 'space-around',
		marginLeft: -1
	},
	centerColumn: {
		borderRight: 3,
		borderLeft: 3,
		justifyContent: 'space-around',
		width: '50%',
		height: 100,
		marginLeft: -1
	},
	rowContainer: {
		flexDirection: 'row',		
	},
	centralTopContainer: {
		borderBottom: 3,
		justifyContent: 'center',
		alignItems: 'center',
		width: '101%',
		height: 50
	},
	centralBottomContainer:{
		height:50, 
		justifyContent: 'center', 
		alignItems: 'center'
	},
	centralTitle: {
		alignItems: 'center',
		alignContent: 'center',
		fontSize: 11,
		fontWeight: 'bold'
	},
	logo: {
		width: 100,
		height: 100,
		margin: 2,
		padding: 5,
	},
	beling: {

		padding: 5,
		width: 120,
		margin: 2,
		objectFit: 'fill'
	}
})

interface Props {
	active: Sst;
}

export const LogoHeader = ({ active }: Props) => {
	const logoCliente =  active.obra.cliente.logo ? active.obra.cliente.logo.name: '';
	return (
		<>
			<View style={[styles.row]}>
				<View style={[styles.column, styles.lefColumn]}>
					<Image src={sst} style={styles.logo} />
				</View>
				<View style={[styles.column, styles.centerColumn]}>
					<View style={[styles.centralTopContainer, styles.rowContainer]}>
						<Text style={styles.centralTitle}>SISTEMA DE GESTION DE SEGURIDAD Y SALUD EN ELTRABAJO</Text>
					</View>
					<View style={[styles.rowContainer, styles.centralBottomContainer]}>
						<Text style={styles.centralTitle}>INFORME DIARIO SST</Text>
					</View>
				</View>
				<View style={[styles.column, styles.rightColumn]}>
					<View>
						{
							logoCliente !== ''? <Image src={getImagen(logoCliente!)} style={styles.beling} />: ''
						}
					</View>
					<View>
					<Image src={beling} style={styles.beling} />
					</View>
				</View>
			</View>
		</>
	)
}
