import { useEffect, useState } from 'react'
import { useScreenSize } from './useScreenSize';

export const useMobileDetect = () => {
	const client = navigator.userAgent;
	const { width } = useScreenSize();
	const [isMobile, setIsMovil] = useState<boolean>(false)

	useEffect(()=>{
		if(client.match(/Android/i) || client.match(/iPhone/i) || client.match(/iPad/i) || width < 1024){
			setIsMovil(true)
		}else{
			setIsMovil(false)
		}
	},[client, width])
	
  return {
	isMobile
  }
}
