import { GridRowId } from "@mui/x-data-grid";
import { Equipo } from "../../../interfaces/ats";
import { RiesgoElectricoActions } from "../interfaces/riesgo_electrico";


export const startSetRiesgosElectrico = (riesgo: Equipo):RiesgoElectricoActions => ({
    type: 'set-riesgos-electrico',
    payload: riesgo,
});

export const startUpdateRiesgoElectrico = (riesgo: Equipo):RiesgoElectricoActions => ({
    type: 'update-riesgos-electrico',
    payload: riesgo,
});

export const startDeleteRiesgoElectrico = (id: GridRowId):RiesgoElectricoActions => ({
    type: 'delete-riesgos-electrico',
    payload: id,
});

export const startEditRiesgoElectrico = (id: GridRowId):RiesgoElectricoActions => ({
    type: 'edit-riesgos-electrico',
    payload: id,
});

export const startSetRiesgosElectricoEmpty = ():RiesgoElectricoActions => ({
    type: 'set-riesgos-electrico-empty',
});
