import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ModalFirma } from './ModalFirma';
import { startSetModalFirmaBeling, startUpdateResponsableBeling } from '../actions/responsable';
import { AppState } from '../../../reducers/rootReducer';
import { useScreenSize } from '../../../hooks';
import { Responsable } from '../../../interfaces/shared';

export const ResponsableBelingTable = () => {
    const { width } = useScreenSize();
    const dispatch = useDispatch();
    const {activeServicio} = useSelector((state: AppState) => state.servicios);
    const { responsableBeling } = useSelector((state: AppState) => state.shared.responsableBeling);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleFirmaClick = (id: GridRowId) => () => {
        dispatch(startSetModalFirmaBeling(true));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel<Responsable>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateResponsableBeling(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'nombre',
            headerAlign: 'center', 
            headerName: 'Nombre y Apellidos',
            flex: width > 600 ? 2 : 0,
            width: 250,
            editable: true,
            sortable: false,
            filterable: false
        },
        {
            field: 'identificacion',
            headerAlign: 'center', 
            headerName: 'Indentificación',
            type: 'text',
            flex: width > 600 ? 1 : 0,
            width: 100,
            editable: true,
            sortable: false,
            filterable: false
        },
        {
            field: 'cargo',
            headerAlign: 'center', 
            headerName: 'Cargo',
            flex: width > 600 ? 1 : 0,
            width: 120,
            editable: true,
            sortable: false,
            filterable: false
        },
        {
            field: 'firma',
            headerAlign: 'center', 
            headerName: 'Firma',
            flex: width > 600 ? 1 : 0,
            width: 150,
            editable: false,
            sortable: false,
            filterable: false,
            renderCell: (row) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'contain',
                            backgroundImage: `url(${row.row.firma.uri})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                );
            },
        },
        {
            field: 'actions',
            headerAlign: 'center', 
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 600 ? 1 : 0,
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                        icon={<TaskAltIcon />}
                        title="Firmar"
                        label="Firmar"
                        onClick={handleFirmaClick(id)}
                        color="success"
                    />,
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        title="Editar"
                        type='button'
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />                    
                ];
            },
        },
    ];
    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                columns={columns}
                editMode="row"                
                hideFooter
                localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay datos para mostrar' }}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                rowModesModel={rowModesModel}
                rows={responsableBeling.slice(0, responsableBeling.length)}
            />
            <ModalFirma name={activeServicio!.tipo.alias} reporte={activeServicio?.id!}/>
        </Box>
    );
}
