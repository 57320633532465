import { HerramientaAction, HerramientaState } from "../interfaces/herramienta";

const initState: HerramientaState = {
    herramientas: []
};

export const herramientaReducer = (state = initState, action: HerramientaAction): HerramientaState => {
    switch (action.type) {
        case "set-herramientas":
            return {
                ...state,
                herramientas: [...state.herramientas, action.payload]
            };
        case "update-herramientas":
            return {
                ...state,
                herramientas: state.herramientas.map(h => {
                    if (h.id === action.payload.id) {
                        return action.payload;
                    }
                    return h;
                })
            };
            case "edit-herramientas":
                return {
                    ...state,
                    herramientas: state.herramientas.map(h => {
                        if (h.id === action.payload) {
                            h.isNew = false;
                        }
                        return h;
                    })
                };
        case "delete-herramientas":
            return {
                ...state,
                herramientas: state.herramientas.filter(h => h.id !== action.payload)
            };
        case "set-herramientas-empty":
            return {
                ...state,
                herramientas: []
            };
        default:
            return state;
    }
}
