import { View, StyleSheet, Image } from '@react-pdf/renderer';
import { getImagen } from '../../../../../helpers/imagen';
import { HeadLine } from '../shared';
import { Sst } from '../../../interfaces/sst';


const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
	},
	column: {
		flexDirection: 'column',
		borderBottom: 3,
		padding: 5,

	},
	totalColumn: {
		width: '99.7%',
		borderLeft: 3,
		borderRight: 3,
	},
	rowContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '100%',
	},	
	image: {
		padding: 5,
		maxWidth: 900,
		borderRadius: 10,
		objectFit: 'fill'
	}
});

interface Props {
	active: Sst
}
export const Anexos = ({ active }: Props) => {
	return (
		<View style={{maxHeight: '50%'}}>
			<HeadLine  title='Anexos' />
			<View style={[styles.row, { marginTop: -1 }]}>
				<View style={[styles.column, styles.totalColumn]}>
					<View style={[styles.rowContainer]}>
						{
							active.soportes.map((imagen) => {
								return <View key={imagen.id}>
									<Image src={() => getImagen(imagen.name!.toString())} style={styles.image} />
								</View>
							})
						}
					</View>
				</View>
			</View>
		</View>
	)
}
