import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import {
    GridRowModes,
    GridActionsCellItem,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';


import { AlertDelete } from '../../servicios/components/AlertDelete';

import { CellExpand } from '../../../components/ui/CellExpand';
import { useGrupoTable } from '../hooks/useGrupoTable';
import { ModalGrupo } from './ModalGrupo';
import { GrupoTableToolbar } from './GrupoTableToolbar';


export const GrupoTable = () => {
 const {
    grupos,
    locale,
    rowModesModel,
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    handleShowDetalle,
    setRowModesModel
 } = useGrupoTable();



    const columns: GridColDef[] = [
        {
            field: 'item',
            headerName: 'Item',
            flex: 1,
            editable: false,
            renderCell: CellExpand
        },
        {
            field: 'descripcion',
            headerName: 'Descripción',
            flex: 3,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Guardar"><SaveIcon /></Tooltip>}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Cancelar"><CancelIcon /></Tooltip>}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Items"><PageviewOutlinedIcon color='info' /></Tooltip>}
                        label="Detalle"
                        onClick={handleShowDetalle(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar"><DeleteIcon color='error' /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <DataGrid
                autoHeight
                rows={grupos!.slice(0, grupos!.length)}
                columns={columns}
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                editMode="row"                
                slots={{
                    toolbar: GrupoTableToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }} 
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            <ModalGrupo />            
            {/* <ModalObraPdf /> */}
            <AlertDelete />
        </Box>
    );
}
