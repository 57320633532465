import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { useChangePassword } from '../hooks/useChangePassword';

export const ModalChangePassword = () => {
   
    const {
        isMobile,
        modalPass,
        values,
        onChange,
        handleClose,
        handleSave,
        handleClickShowPassword,
        handleClickShowConfirmPass,
        handleMouseDownPassword,
        handleMouseDownConfirmPass,
        handleClickShowNewPassword,
        handleMouseDownNewPassword
    } = useChangePassword();

    return (
        <>
            <Dialog open={modalPass} onClose={handleClose} fullWidth maxWidth="xs" fullScreen={isMobile}>
                <DialogTitle>
                    <Box
                        sx={{
                            my: !isMobile ? 1 : undefined,                          
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    component="div"
                    >
                        <Avatar sx={{ m: !isMobile ? 1 : undefined, bgcolor: "secondary.main" }}>
                            <LockResetOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant={isMobile ? "h6" : "h5"}>
                            Cambiar Contraseña
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{display: "flex",
                            flexDirection: "column",
                            justifyContent: 'center',
                            alignItems: 'flex-end',}}>
                    <Box
                        width="100%"
                        component="div"
                        sx={{ 
                            mt: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                         }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} >
                                <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        Contraseña Anterior *
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={values.showPassword ? "text" : "password"}
                                        value={values.password}
                                        name='password'
                                        autoComplete="current-password"
                                        required
                                        onChange={onChange("password")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label=" Contraseña Anterior *"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        Nueva Contraseña *
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-newPassword"
                                        type={values.showNewPassword ? "text" : "password"}
                                        value={values.newPassword}
                                        name='newPassword'
                                        autoComplete="current-newPassword"
                                        required
                                        onChange={onChange("newPassword")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownNewPassword}
                                                    edge="end"
                                                >
                                                    {values.showNewPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Nueva Contraseña *"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-confirmPassword">
                                        Confirmar contraseña *
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-confirmPassword"
                                        type={values.showConfirmPass ? "text" : "password"}
                                        value={values.confirmPassword}
                                        name='confirmPassword'
                                        autoComplete="current-confirmPassword"
                                        required
                                        onChange={onChange("confirmPassword")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPass}
                                                    onMouseDown={handleMouseDownConfirmPass}
                                                    edge="end"
                                                >
                                                    {values.showConfirmPass ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirmar contraseña *"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button tabIndex={7} onClick={handleClose}>Cancelar</Button>
                    <Button tabIndex={8} onClick={handleSave}>Cambiar contraseña</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
