import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ListaDetail } from './ListaDetail'
import { ListasScreen } from './ListasScreen'

export const ListasRoutes = () => {
  return (
    <>    
    <Routes>
            <Route path="/listas" element={<ListasScreen />} />
            <Route path="/detalle" element={<ListaDetail />} />
            <Route path="/" element={<ListasScreen />} />
    </Routes>    
    </>
  )
}
