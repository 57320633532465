import { GridRowId } from "@mui/x-data-grid";
import { Rectificador, RectificadorAction } from "../interfaces/rectificadores";

export const startSetRectificadoresInfo = (rectificador: Rectificador): RectificadorAction => ({
    type: "set-rectificadores-info",
    payload: rectificador
});

export const startUpdateRectificadoresInfo = (rectificador: Rectificador): RectificadorAction => ({
    type: "update-rectificadores-info",
    payload: rectificador
});

export const startDeleteRectificadoresInfo = (id: GridRowId): RectificadorAction => ({
    type: "delete-rectificadores-info",
    payload: id
});

export const startEditRectificadoresInfo = (id: GridRowId): RectificadorAction => ({
    type: "edit-rectificadores-info",
    payload: id
});

export const startSetRectificadoresEmpty = (): RectificadorAction => ({
    type: "set-rectificadores-empty"
});
// Compare this snippet from src/modules/reportes/modules/baterias_info/interfaces/baterias.ts: