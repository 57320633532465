import { Document, Page } from '@react-pdf/renderer';
import { styles } from '../../../../styles/pdfStyles';
/* import {
	Actividades,
	Alcance, 
	Description, 
	Equipos,
	Footer,
	Header, 
	Herramientas, 
	LogoHeader,
	Materiales,
	Personal, 
	Vehiculos,
 } from './'; */
import { Sst } from '../../interfaces/sst';
import { Header, LogoHeader } from './shared/';
import { Anexos, Asistentes, AtsRealizados, Incidentes, PlanTrabajo, RegistroFotografico } from './body';


interface Props {
	active: Sst;
	title: string
}

export const InformeSstPdf = ({ active, title }: Props) => {
	return (
		<Document title={title}>
			<Page size="LETTER" style={styles.page} wrap dpi={100} >
				<LogoHeader active={active} />
				<Header active={active} />
				<Asistentes active={active} />
				<Incidentes active={active} />
				<PlanTrabajo active={active} />
				<AtsRealizados active={active} />
				<RegistroFotografico active={active} />
				<Anexos active={active} />
			</Page>
		</Document>
	)
}
