import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { v4 as UUID } from 'uuid';

import initImage from '../../../../../assets/img/bg.png';
import { AppState } from '../../../../../reducers/rootReducer'
import { startSetModalBancos, startSetRutinaBancoId, startSetRutinaBancoUpdateImage } from '../actions/rutina';
import { saveImagen } from '../../../../../helpers';
import { doMessage, startLoading } from '../../../../ui/actions/ui';
import imagen from '../../../../../assets/img/logo_beling.png';
import { CustomCardImage } from '../../../../../components';
import { useScreenSize } from '../../../../../hooks';

export const ModalRutinaBB = () => {
    const dispatch = useDispatch();
    const { width } = useScreenSize();
  const [imgWidth, setImgWidth] = useState<number>(220)
    const [foto, setFoto] = useState(initImage)
    const { activeServicio } = useSelector((state: AppState) => state.servicios);
    const { rutinaBancoId, modalBancos, rutinasBancoBateria } = useSelector((state: AppState) => state.reportes.modules.rutinaBancoBateria);
    const images = rutinasBancoBateria.find(r => r.id === rutinaBancoId)?.images ?? [];
    const lastId = images.length > 0 ? images?.length! -1 : 0;
    const imgUrl = images.length > 0 ? rutinasBancoBateria.find(r => r.id === rutinaBancoId)?.images![lastId].uri : imagen;
    
     const stat = imgUrl?.slice(0, 8);

    useEffect(() => {
        if (stat !== '/static/') {
            setFoto(imgUrl!)
        } else {
            setFoto(initImage)
        }
    }, [imgUrl, stat])

    const handleClose = () => {
        dispatch(startSetModalBancos(false));
    };

    const handelLoadImage = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setFoto(reader.result as string);
        }
    }

    const handleSave = async () => {
        dispatch(startLoading());
        if (rutinaBancoId) {
            const data = {
                imagen: foto,
                servicio: activeServicio!.id,
                name: `${activeServicio!.tipo.alias}-${UUID()}`,
                descripcion: activeServicio!.descripcion ? activeServicio!.descripcion: 'ribu claro',
            }

            const { imagen, msg, status } = await saveImagen(data);
            if (status) {
                dispatch(startSetRutinaBancoUpdateImage(imagen, rutinaBancoId));
                dispatch(startSetModalBancos(false));
                dispatch(doMessage({ message: msg, type: 'success' }));
                dispatch(startSetRutinaBancoId(''));
            } else {
                dispatch(doMessage({ message: 'Error guardando la imagen', type: 'error' }));
            }
        }
        dispatch(startLoading());
        handleClose();
    }
    
    useEffect(() => {
        if (width > 843) {
          setImgWidth(220)
        } else {
          setImgWidth(120)
        }
      }, [width])

    return (
        <>
            <Dialog open={modalBancos} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogTitle>Agregar Imagén</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CustomCardImage
                        foto={foto}
                        imgWidth={imgWidth}
                        descripcion={activeServicio!.descripcion ? activeServicio!.descripcion: 'ribu claro'}
                        onChange={handelLoadImage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
