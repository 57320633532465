import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';


import { EditToolbarProps } from '../../../interfaces/shared';
import { startSetModalInformeSst, startSetModalObraSst } from '../actions/sst';
import { AppState } from '../../../reducers/rootReducer';


export const SstTableToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
    const{activeObra} = useSelector((state: AppState)=> state.obras.obras)
    
    const handleClick = () => {
        if(!activeObra){
            dispatch(startSetModalObraSst(true));
        }else{            
            dispatch(startSetModalInformeSst(true));
        }
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Nuevo
            </Button>
        </GridToolbarContainer>
    );
}