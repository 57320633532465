import { View, StyleSheet } from '@react-pdf/renderer';

import { HeaderTable, ItemsTable } from '../';
import { Cell } from '../../../../../../interfaces/shared';
import { Informe } from '../../../../interfaces/informes';
import { TitleTables } from '../../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
	container: {
		marginTop: 2
	},
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const title = 'Describa los Materiales Utilizados en la Actividad::';
const titleTable: Cell[] = [{name: "Listado de materiales autilizados"}];
const tableTitles: Cell[]  = [
	{name: "Descripción", width: "40%"},
	{name: "Unidad", width: "15%"},
	{name: "Cantidad", width: "15%"},
	{name: "Observaciones", width: "30%"}
];

interface Props {
	active: Informe;
}

export const Materiales = ({ active }: Props) => {
	const { materiales } = active;
	return (
		<>
		<TitleTables title={title} sx={{fw: 'normal', mt: 10}}/>
			<View style={styles.container}>
				<View style={styles.descriptionContainer}>
					<HeaderTable names={titleTable} sx={{bc:'#eeeeee', txa: 'center', fw: 'bold' }}/>
					<HeaderTable names={tableTitles} bottom defaultWidth={false} sx={{bc:'#eeeeee'}}/>
					{materiales?.filter(item => item.cantidad !== 0).map((item) => {
						const fila: Cell[] = [
							{ name: item.descripcion, width: '40%' },
							{ name: item.unidadMedida!, width: '15%' },
							{ name: item.cantidad!, width: '15%' },
							{ name: item.observacion!, width: '30%' },
						]
						return <ItemsTable key={item.id} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom defaultWidth={false} />
					})}					
				</View>
			</View>
		</>
	)
}