import { MutableRefObject, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'


import { startSetModalFirmaCliente, startSaveFirmaCliente } from '../actions/recibe';
import ReactSignatureCanvas from 'react-signature-canvas';

import '../css/sigCanvas.css'
import { AppState } from '../../../reducers/rootReducer';
import { deleteFirma, saveFirma } from '../../../helpers';
import { doMessage } from '../../../modules/ui/actions/ui';
import { GridRowId } from '@mui/x-data-grid';


interface Props {
    name: string;
    reporte: GridRowId;
}

export const ModalFirmaCliente = ({ name, reporte }: Props) => {
    const dispatch = useDispatch();
    const sigCanvas = useRef({}) as MutableRefObject<any>;
    const { modalFirmaCliente, responsableCliente } = useSelector((state: AppState) => state.shared.responsableCliente);

    const handleClose = () => {
        dispatch(startSetModalFirmaCliente(false));
        handleClear();
    };

    const handleSave = async () => {
        const img = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

        const data = {
            firma: img,
            name: name,
            reporte: reporte,
        };

        if (responsableCliente[0].firma.uri !== '') {
            await deleteFirma(responsableCliente[0].firma.id);
        }

        const resp = await saveFirma(data);

        if (resp.status) {
            responsableCliente[0].firma = resp.firma;
            dispatch(startSaveFirmaCliente(responsableCliente[0]));
            dispatch(doMessage({ type: 'success', message: 'Firma guardada' }));
            handleClose();
        } else {
            dispatch(doMessage({ type: 'error', message: 'Error al guardar firma' }));
            handleClose();
        }
    }

    const handleClear = () => {
        sigCanvas.current.clear();
    }   

    return (
        <>
            <Dialog open={modalFirmaCliente} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogTitle>Firmar</DialogTitle>
                <DialogContent>
                    <ReactSignatureCanvas
                        ref={sigCanvas}
                        penColor='black'
                        canvasProps={{ className: 'signatureCanvas', width: 390, height: 130, }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClear} >Limpiar</Button>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
