import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
} from '@mui/x-data-grid';


import { AppState } from '../../../reducers/rootReducer';

import { useNavigate } from 'react-router-dom';
import { startDeleteGrupo, startSetActiveGrupo, startSetModalGrupo } from '../actions/grupos';

export const useGrupoTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText;

    const { grupos } = useSelector((state: AppState) => state.obras.grupos);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});    
        
    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        dispatch(startSetActiveGrupo(id));
        dispatch(startSetModalGrupo(true));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteGrupo(id));
    };

    const handleShowDetalle = (id: GridRowId) => () => {
        dispatch(startSetActiveGrupo(id));
        navigate("/obras/items-obra", { replace: false });
    }


    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        //dispatch(startUpdateServicio(updatedRow));
        return updatedRow;
    };

    return {
        grupos,
        locale,
        rowModesModel,        
        handleRowEditStart,
        handleRowEditStop,
        handleEditClick,
        handleSaveClick,
        handleDeleteClick,
        handleCancelClick,
        handleShowDetalle,
        processRowUpdate,
        setRowModesModel,
    }
}