import { ResponsableAction, ResponsableBelingState } from "../interfaces/responsable";

const initState: ResponsableBelingState = {
    responsableBeling: [
        {
            id: 1,
            nombre: "",
            identificacion: "",
            cargo: "",
            firma: {
                uri: "",
                name: ""
            },
            isNew: false
        }
    ],
    modalFirmaBeling: false
}

export const responsableBelingReducer = (state = initState, action: ResponsableAction): ResponsableBelingState => {
    switch (action.type) {
        case 'update-responsable-beling':
            return {
                ...state,
                responsableBeling: state.responsableBeling.map(responsable => responsable.id === action.payload.id ? action.payload : responsable)
            }
        case 'set-modal-firma-beling':
            return {
                ...state,
                modalFirmaBeling: action.payload
            }
        case 'set-responsable-beling-empty':
            return {
                ...state,
                responsableBeling: [
                    {
                        id: 1,
                        nombre: "",
                        identificacion: "",
                        cargo: "",
                        firma: {
                            uri: "",
                            name: ""
                        },
                        isNew: false
                    }
                ]
            }
        case 'set-responsable-firma-reporte':
            return {
                ...state,
                responsableBeling:[action.payload]
            }
        default:
            return state;
    }
}