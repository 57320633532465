import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { AppState } from '../../../reducers/rootReducer';
import { useForm, useMobileDetect } from '../../../hooks';
import { startCreateTecnico, startSetActiveTecnico, startSetModalTecnico, startUpdateTecnico } from '../actions/tecnico';


const initForm = {
    username: "",
    email: "",
    roles: [{}],
    name: "",
    lastname: "",
    movil: "",
};

export const ModalTecnico = () => {
    const dispatch = useDispatch();
    const { isMobile } = useMobileDetect();
    const { activeTecnico, modalTecnico } = useSelector((state: AppState) => state.tecnicos);

    const { onChange, changeData, registerData } = useForm(initForm);

    const { username, email, name, lastname, movil, roles } = registerData;


    useEffect(() => {
        if (!activeTecnico) {
            changeData(initForm);
        } else {
            const formData = {
                username: activeTecnico.username,
                email: activeTecnico.email,
                roles: [{ _id: activeTecnico.roles[0]._id, name: activeTecnico.roles[0].name }],
                name: activeTecnico.name,
                lastname: activeTecnico.lastname,
                movil: activeTecnico.movil,
            };
            changeData(formData);
        }
    }, [activeTecnico, changeData]);

    const handleClose = () => {
        changeData(initForm);
        dispatch(startSetActiveTecnico(''));
        dispatch(startSetModalTecnico(false));
    };

    const handleSave = () => {
        const tecnico = {
            username,
            email,
            name,
            lastname,
            movil,
            roles,
            id: "",
        };

        if (!activeTecnico) {
            dispatch(startCreateTecnico(tecnico));
        } else {
            dispatch(startUpdateTecnico(tecnico));
        }
        dispatch(startSetActiveTecnico(''));
        handleClose();

    }

    return (
        <>
            <Dialog 
                open={modalTecnico} 
                onClose={handleClose}
                fullScreen={isMobile}
                fullWidth 
                maxWidth="sm"
            >
                <DialogTitle>
                    <Box
                        sx={{
                            my: 1,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        { !isMobile && <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <PeopleAltIcon />
                        </Avatar>}
                        <Typography component="h1" variant="h5">
                            {activeTecnico
                                ? "Actualizar"
                                : "Agregar Técnico"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        width="100%"
                        component="div"
                        sx={{ mt: 1 }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="normal"
                                    value={username}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="username"
                                    label="Usuario"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    inputProps={{ tabIndex: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="normal"
                                    value={email}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    type='email'
                                    inputMode='email'
                                    autoComplete="email"
                                    inputProps={{ tabIndex: 2 }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="normal"
                                    value={name}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="name"
                                    label="Nombres"
                                    name="name"
                                    autoComplete="name"
                                    inputProps={{ tabIndex: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="normal"
                                    value={lastname}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="lastname"
                                    label="Apellidos"
                                    name="lastname"
                                    autoComplete="lastname"
                                    inputProps={{ tabIndex: 4 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="normal"
                                    value={movil}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="movil"
                                    label="Celular"
                                    name="movil"
                                    inputMode='tel'
                                    autoComplete="movil"
                                    inputProps={{ tabIndex: 5 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>{activeTecnico ? 'Actualizar' : 'Guardar'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
