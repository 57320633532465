import { ItemObraState, ItemObraAction } from "../interfaces/item.interface";

const initialState: ItemObraState = {
    activeItemObra: null,
    itemsObra: [],
    itemObra: null,
    modalItemObra: false,
    itemsModal: []
}

export const itemObraReducer = (state = initialState, action: ItemObraAction): ItemObraState => {
    switch (action.type) {
        case 'load-items-obra':
            return {
                ...state,
                itemsObra: action.payload
            }
        case 'set-items-obra-modal':
            return{
                ...state,
                itemsModal: action.payload
            }
            
        case 'set-item-obra':
            return {
                ...state,
                itemsObra: [ ...state.itemsObra, action.payload,]
            }
        case 'create-item-obra':
            return {
                ...state,
                itemsObra: [...state.itemsObra, action.payload]
            }
        case 'update-item-obra':
            return {
                ...state,
                itemsObra: state.itemsObra.map(io => (io.id === action.payload.id) ? action.payload : io)
            }
        case 'delete-item-obra':
            return {
                ...state,
                itemsObra: state.itemsObra.filter(io => io.id !== action.payload)
            }
        case 'set-modal-item-obra':
            return {
                ...state,
                modalItemObra: action.payload
            }
        case 'set-active-item-obra':
            const itemObra = state.itemsObra.find(io => io.id === action.payload)
            return {
                ...state,
                activeItemObra: itemObra ? itemObra : null
            }
        case 'set-inactive-item-obra':
            return {
                ...state,
                activeItemObra: null
            }
        default:
            return state;
    }
}