import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useModalSelloFisico } from '../hooks/useModalSelloFisico';
import { CustomCardImage } from '../../../components';


export const ModalSelloFisico = () => {
    const {
        descripcion,
        imgWidth,
        foto,
        modalSelloFisico,
        handleClose,
        handelLoadImage,
        handleSave
    } = useModalSelloFisico();

    return (
        <>
            <Dialog open={modalSelloFisico} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogTitle>Agregar Imagén</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CustomCardImage
                        foto={foto}
                        imgWidth={imgWidth}
                        descripcion={descripcion}
                        onChange={handelLoadImage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
