import { GridRowId } from '@mui/x-data-grid';
import { fetchConToken } from '../../../apis/fetch';
import { startLoadAts, startSetNoServicio } from '../../ats/actions/ats';
import { startLoadReportes } from '../../reportes/actions/reportes';
import { doMessage, startLoading } from '../../ui/actions/ui';
import { Servicio, ServicioAction, ServicioCreate } from '../interfaces/servicio';

export const startLoadServicios = () => {
    return async (dispatch: any) =>{
        dispatch(startLoading());
        const resp = await fetchConToken('servicios', {});
        const body = await resp.json();
        if (body.ok) {
            dispatch(startLoading());
            dispatch(loadServicios(body.servicios));
        } else {
            dispatch(startLoading());
            dispatch(doMessage(body.msg));
        }
    }
}

const loadServicios = (servicios: Servicio[]): ServicioAction => ({
    type: 'load-servicio',
    payload: servicios
})

export const startCreateServicio = (newServicio: ServicioCreate) => {
    return async (dispatch: any, getState: any) => {
        const {servicioExist} = getState().ats.ats;        
        dispatch(startLoading());
        const resp = await fetchConToken('servicios', newServicio, 'POST');
        const body = await resp.json();           
        const {errors, msg, ok, servicio } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(createServicio(servicio));
            if(!servicioExist){
                dispatch(setActiveServicio(servicio.id));
                dispatch(startSetNoServicio(true));
            }
            dispatch(doMessage({message: msg, type: 'success'}));
        } else {
            dispatch(startLoading());
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
    }
}

const createServicio = (servicio: ServicioCreate): ServicioAction => ({
    type: 'create-servicio',
    payload: servicio
})

export const startUpdateServicio = (updatedServicio: ServicioCreate) => {
    
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().servicios.activeServicio.id;
        if(!id){
            return dispatch(doMessage({message: 'No hay servicio activo', type: 'error'}));
        }
        const resp = await fetchConToken(`servicios/${id}`, updatedServicio, 'PUT');
        const body = await resp.json();
        const {errors, msg, ok, servicio } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(updateServicio(servicio));
            dispatch(doMessage({message: msg, type: 'success'}));
        } else {
            dispatch(startLoading());
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
    }
}

export const updateServicioFromReporte= (id: GridRowId, status: string)=>{
    
    return async(dispatch: any )=>{
        const resp = await fetchConToken(`servicios/status/${id}`, {status}, 'PUT');

        const body = await resp.json();
        const {errors, msg, ok, servicio } = body;
        if (ok) {
            dispatch(updateServicio(servicio));
            dispatch(doMessage({message: msg, type: 'success'}));
        } else {
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
    }
}

const updateServicio = (servicio: ServicioCreate): ServicioAction => ({
    type: 'update-servicio',
    payload: servicio
})

export const startDeleteServicio = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().servicios.activeServicio.id;
        if(!id){
            return dispatch(doMessage({message: 'No hay servicio activo', type: 'error'}));
        }

        await dispatch(deleteAts(id));
        await dispatch(deleteReporte(id));

        const resp = await fetchConToken(`servicios/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const {errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(deleteServicio(id));
            dispatch(doMessage({message: msg, type: 'success'}));
            dispatch(setActiveServicio(''));
            dispatch(setInactiveServicio());
        } else {
            dispatch(startLoading());
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
    }
}

/**
 * 
 * @param servicio id del servicio
 * @returns void, se elimina la ats que pertenece a este servicio
 */

const deleteAts = (servicio: string) =>{
    return async(dispatch: any, getState: any) =>{
        await dispatch(startLoadAts())
        const {ats} = getState().ats.ats;        
        const at = ats.find((at: any) => at.servicio._id === servicio);
        if(!at){
            return;
        }
        const {id} = at;
        await fetchConToken(`ats/${id}`, {}, 'DELETE');
        return;
        
    }
}

const deleteReporte = (servicio: string) =>{
    return async(dispatch: any, getState: any) => {
        await dispatch(startLoadReportes());
        const {reportes } = getState().reportes.reportes;
        const reporte = reportes.find((r: any) => r.servicio._id === servicio);
        if(!reporte){
            return;
        }

        const {id} = reporte;
        await fetchConToken(`reportes/${id}`, {}, 'DELETE');
        return;
    }
}

const deleteServicio = (id: GridRowId): ServicioAction => ({
    type: 'delete-servicio',
    payload: id
})


export const startSetModalServicio = (modal: boolean): ServicioAction => ({
    type: 'set-modal-servicio',
    payload: modal
})

export const startSetModalDelete = (modal: boolean): ServicioAction => ({
    type: 'set-modal-delete',
    payload: modal
})

export const startSetModalReporte = (modal: boolean): ServicioAction => ({
    type: 'set-modal-reporte',
    payload: modal
});

export const startSetModalReporteNormal = (modal: boolean): ServicioAction => ({
    type: 'set-modal-reporte-normal',
    payload: modal
});

export const startSetModalReporteEmergencia = (modal: boolean): ServicioAction => ({
    type: 'set-modal-reporte-emergencia',
    payload: modal
});

export const startSetModalReporteBdB = (modal: boolean): ServicioAction => ({
    type: 'set-modal-bdb',
    payload: modal
})

export const startSetModalAts = (modal: boolean): ServicioAction => ({
    type: 'set-modal-ats',
    payload: modal
});

export const starSetInactiveServicio = () => {
    return async (dispatch: any) => {
        dispatch(setInactiveServicio());
    }
}

export const startSetOlsActiveServicio = (payload: GridRowId | null): ServicioAction =>({
    type: 'set-old-active-servicio',
    payload
})

export const startSetServicioIsNew = (payload: boolean): ServicioAction =>({
    type: 'set-is-new-servicio',
    payload
})

export const doSelected = (selected: string[]): ServicioAction => ({
    type:'set-selected',
    payload: selected
})

export const setActiveServicio = (id: GridRowId):ServicioAction => ({
    type: 'set-active-servicio',
    payload: id
})

export const setInactiveServicio = ():ServicioAction => ({
    type: 'set-inactive-servicio',
})
