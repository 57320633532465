import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';



import { AppState } from '../../../reducers/rootReducer';
import { startCloseAts, startCreateAts } from '../../ats/actions/ats';
import { FormAtsScreen } from '../../ats/components/FormAtsScreen';
import { deleteFirma } from '../../../helpers';
import { Transition } from '../../../components';
import { setActiveServicio, startSetModalReporte, updateServicioFromReporte } from '../actions/servicio';
import { GridRowId } from '@mui/x-data-grid';

export const ModalAts = () => {
    const dispatch = useDispatch();
    const [  guardar, setGuardar ] = useState(false);
    const { modalAts, activeServicio, oldActiveServicio } = useSelector((state: AppState) => state.servicios);
    const { ats, equipos, herramientas, riesgosAltura, riesgosElectrico,riesgosSitio } = useSelector((state: AppState) => state.ats);
    const {responsableBeling, responsableCliente } = useSelector((state: AppState) => state.shared);
    const { hasAts } = useSelector((state: AppState) => state.reportes.reportes);
    const{ firma: firmaResponsable } = responsableBeling.responsableBeling[0];
    const{ firma: firmaCliente } = responsableCliente.responsableCliente[0];
    
    const handleClose = () => {
        if(!guardar){
            if(firmaResponsable.id){
                deleteFirma(firmaResponsable.id);
            }
            if(firmaCliente.id){
                deleteFirma(firmaCliente.id);
            }
        }
        setGuardar(false);        
        dispatch(startCloseAts());
    };


    const handleSave = () => { 
        setGuardar(true); 
        const data = {
            servicio: {_id: activeServicio?.id!},
            ...ats.atsForm,
            equipos: equipos.equipos,
            herramientas: herramientas.herramientas,
            responsableBeling: responsableBeling.responsableBeling,
            responsableCliente: responsableCliente.responsableCliente,
            riesgosAltura: riesgosAltura.riesgosAltura,
            riesgosElectrico: riesgosElectrico.riesgosElectrico,
            riesgosSitio: riesgosSitio.riesgosSitio,
        }
        dispatch(updateServicioFromReporte(activeServicio?.id!, 'Finalizados'));
        dispatch(startCreateAts(data));
        if(!hasAts){
            dispatch(setActiveServicio(oldActiveServicio as GridRowId));
            dispatch(startSetModalReporte(true));
        }        
        setGuardar(false);
        dispatch(startCloseAts());      
    }    

    return (
        <>
            <Dialog open={modalAts} onClose={handleClose} fullScreen TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Tooltip title="Cancelar"><CloseIcon /></Tooltip>
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Análisis de Trabajo Seguro (ATS)
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleSave}
                            aria-label="close"
                        >
                            <Tooltip title="Guardar"><SaveIcon /></Tooltip>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <FormAtsScreen />
                </DialogContent>
                <DialogActions sx={{mr: 5}}>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
