import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { RutinaRectificadorTable } from './RutinaRectificadorTable';

const Table = memo(() => {
    return (
        <RutinaRectificadorTable />
    )
})

export const RutinaRectificador = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Descripción de Actividades Para Rectificadores - Inversores , UPS
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Rutina de Rectificadores - Inversores - UPS
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}
