import { ContactoAction, ContactoState } from "../interfaces/contactos"


const initState: ContactoState = {
    contactos: [],
    activeContacto: null,
    modalContacto: false,
    alertDeleteContacto: false
}

export const contactoReducer = (state = initState, action: ContactoAction): ContactoState => {
    switch (action.type) {
        case 'load-contactos':
            return {
                ...state,
                contactos: action.payload
            }
        case 'create-contacto':
            return {
                ...state,
                contactos: [...state.contactos, action.payload]
            }
        case 'update-contacto':
            return {
                ...state,
                contactos: state.contactos.map(
                    c => (c.id === action.payload.id) ? action.payload : c
                )
            }
        case 'delete-contacto':
            return {
                ...state,
                contactos: state.contactos.filter(c => c.id !== action.payload)
            }
        case 'set-active-contacto':
            const contacto = state.contactos.find(c => c.id === action.payload);
            return {
                ...state,
                activeContacto: contacto ? contacto : null
            }
        case 'set-modal-contacto':
            return {
                ...state,
                modalContacto: action.payload
            }
        case 'set-alert-delete-contacto':
            return {
                ...state,
                alertDeleteContacto: action.payload
            }
        
        default:
            return state
    }
}