import { PersonalExternoAction, PersonalExternoState } from '../interfaces/personal';

const initState: PersonalExternoState = {
    personal: [],
};

export const personalExternoReducer = (state = initState, action: PersonalExternoAction): PersonalExternoState => {
    switch (action.type) {
        case 'set-personal-externo':
            return {
                ...state,
                personal: [...state.personal, action.payload],
            };
        case 'update-personal-externo':
            return {
                ...state,
                personal: state.personal.map((personal) => {
                    if (personal.id === action.payload.id) {
                        return action.payload;
                    }
                    return personal;
                }),
            };
        case 'delete-personal-externo':
            return {
                ...state,
                personal: state.personal.filter((personal) => personal.id !== action.payload),
            };
        case 'edit-personal-externo':
            return {
                ...state,
                personal: state.personal.map((personal) => {
                    if (personal.id === action.payload) {
                        personal.isNew = false;
                    }
                    return personal;
                }),
            };
        case 'set-personal-externo-empty':
            return {
                ...state,
                personal: [],
            };
        default:
            return state;
    }
}