import { useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    DataGrid,
    GridColDef
} from '@mui/x-data-grid';


import { AppState } from '../../../reducers/rootReducer';
import { ModalReporteClaro } from '../../reportes/components/ModalReporteClaro';
import { startSetModalReporte } from '../../servicios/actions/servicio';
import { startSetActiveAts, startSetAlertDeleteAts, startSetModalAtsPdf } from '../actions/ats';
import { ModalAtsPdf } from './pdf/ModalAtsPdf';
import { CellExpand } from '../../../components/ui/CellExpand';
import { AlertDeleteAts } from './AlertDeleteAts';
import { useScreenSize } from '../../../hooks';
import { dateShort } from '../../../helpers';

export const AtsTable = () => {
    const dispatch = useDispatch();
    const {width} = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText;
    
    const { ats:atsResp } = useSelector((state: AppState) => state.ats);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});    
    const reportes = atsResp.ats.map((at) => {
        return {
            id: at.id,
            fecha : dateShort(at.fecha!),
            nombre: at.servicio?.nombre!,
            numero: at.numero!,
            numeroServicio: at.servicio?.numero,
            cliente : at.servicio?.cliente?.nombre,
            tecnico : `${at.servicio?.tecnico?.name} ${at.servicio!.tecnico!.lastname}`,
            actividades : at.actividades,
        }
    });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        dispatch(startSetActiveAts(id));
        dispatch(startSetModalReporte(true));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startSetActiveAts(id))
        dispatch(startSetAlertDeleteAts(true))
    };

    const handleShowReporte = (id: GridRowId) => () => {
        dispatch(startSetActiveAts(id));
        dispatch(startSetModalAtsPdf(true));
    }

    
    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        //dispatch(startUpdateServicio(updatedRow));
        //console.log('processRowUpdate', updatedRow);
        return updatedRow;
    };

   const columns: GridColDef[] = [
        { 
            field: 'fecha',
            headerAlign: 'center',
            headerName: 'Fecha ATS',
            flex: width > 900 ? 1 : 0,
            width: 100, 
            renderCell:CellExpand
        },
        {
            field: 'numero',
            headerAlign: 'center',
            headerName: 'No. ATS',
            flex: width > 900 ? 1 : 0,
            width: 100, 
            editable: false,
            renderCell: CellExpand 
        },
        {
            field: 'numeroServicio',
            headerAlign: 'center',
            headerName: 'No. Servicio',
            flex: width > 900 ? 1 : 0,
            width: 100, 
            editable: false,
            renderCell: CellExpand 
        },
        {
            field: 'nombre',
            headerAlign: 'center',
            headerName: 'Nombre',
            flex: width > 900 ? 1.5 : 0,
            width: 150, 
            editable: false,
            renderCell: CellExpand 
        },
        { 
            field: 'cliente',
            headerAlign: 'center', 
            headerName: 'Cliente', 
            flex: width > 900 ? 1.5 : 0,
            width: 150, 
            editable: false,
            renderCell: CellExpand 
        },
        { 
            field: 'tecnico',
            headerAlign: 'center', 
            headerName: 'Técnico Asignado', 
            flex: width > 900 ? 1.5 : 0,
            width: 150,  
            editable: false,
            renderCell: CellExpand 
        },
        { 
            field: 'actividades',
            headerAlign: 'center', 
            headerName: 'Actividades', 
            flex: width > 900 ? 2 : 0,
            width: 200, 
            editable: false,
            renderCell: CellExpand
        },
        {
            field: 'actions',
            type: 'actions',
            headerAlign: 'center',
            headerName: 'Acciones',
            flex: width > 900 ? 1.3 : 0,
            width: 120, 
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Guardar"><SaveIcon /></Tooltip>}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Cancelar"><CancelIcon /></Tooltip>}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Reporte"><InsertDriveFileOutlinedIcon /></Tooltip>}
                        label="Reporte"
                        onClick={handleShowReporte(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar"><DeleteIcon /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            
            <DataGrid
                autoHeight
                rows={reportes.slice(0, reportes.length)}
                columns={columns}            
                initialState={{
                    columns: {
                        ...columns,
                        columnVisibilityModel: {
                            numero: width < 900 ? false : true,
                            numeroServicio: width < 900 ? false : true,
                            nombre: width < 900 ? false : true
                        }
                    },
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}                
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}                
                pageSizeOptions={[5, 10, 15]}
            />
            <ModalReporteClaro />
            <ModalAtsPdf />
            <AlertDeleteAts />         
        </Box>
    );
}
