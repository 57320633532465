import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridColDef,
} from '@mui/x-data-grid';

import { AppState } from '../../../reducers/rootReducer';
import { CellExpand } from '../../../components/ui/CellExpand';
import { startSetActiveItem, startSetAlertDeleteItem, startSetIsNewItem, startSetModalItem } from '../actions/items';


export const useItemsTable = () => {

  const dispatch = useDispatch();
 
  const { activeLista } = useSelector((state: AppState) => state.listas);
  const {items } = useSelector((state: AppState) => state.items);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});  

  const itemsLista = items.filter(i => i.lista._id === activeLista?.id);


  const handleRowEditStart = (
      params: GridRowParams,
      event: MuiEvent<React.SyntheticEvent>,
  ) => {
      event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {        
      dispatch(startSetActiveItem(id));
      dispatch(startSetIsNewItem(false))
      dispatch(startSetModalItem(true));
  };

 
  const handleSaveClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
      dispatch(startSetActiveItem(id));
      dispatch(startSetAlertDeleteItem(true));
  };

  const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  };

  const processRowUpdate = (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isNew: false };
      //dispatch(startUpdateServicio(updatedRow));
      //console.log('processRowUpdate', updatedRow);
      return updatedRow;
  };

  const columns: GridColDef[] = [
    { 
        field: 'nombre', 
        headerName: 'Nombre', 
        flex:10, 
        editable: false, 
        headerAlign: 'center',
        renderCell: CellExpand 
    },
]; 

  return{
    columns,
    items: itemsLista,	
    rowModesModel,
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    setRowModesModel,
  }
}
