import { View, StyleSheet, Text, Image } from "@react-pdf/renderer";
import { Responsable } from '../../../interfaces/reportes';
import { getFirma } from '../../../../../helpers';

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		maxWidth: '100%',
		marginTop: 10,
	},
	colContainer: {
		flexDirection: 'column',
		width: '50%'
	},
	title: {
		flexDirection: 'row',
		width: '100%'
	},
	headerName: {
		flexDirection: 'row',
		width: '100%',
		color: '#aaaaaa',
	},
	name: {
		flexDirection: 'row',
		width: '100%'
	},
	identificacion: {
		flexDirection: 'row',
		width: '100%'
	},
	numero: {
		marginLeft: 4
	},
	firma: {
		flexDirection: 'row',
		width: '100%',
		padding: 5,
		borderRadius: 5
	},
	image: {
		maxHeight: 100,
		height: 100,
		objectFit: 'scale-down',
		borderRadius: 10,
		padding: 2
	}
});

interface Props {
	responsable: Responsable;
}

export const FooterContainer = ({ responsable }: Props) => {
	return (
		<View style={styles.container} wrap={false}>
			<View style={styles.title}><Text>Quien ejecuto las Actividades por parte de BELING SAS:</Text></View>
			<View style={[{ flexDirection: 'row' }]}>
				<View style={[styles.colContainer]}>
					<View style={styles.headerName}>
						<Text>Nombre y Apellido</Text>
						V</View>
					<View style={styles.name}>
						<Text>{responsable.nombre}</Text>
					</View>
					<View style={styles.headerName}>
						<Text>Identificacion:</Text>
					</View>
					<View style={styles.identificacion}>
						<Text>{responsable.identificacion}</Text>
					</View>
					<View style={styles.headerName}>
						<Text>cargo:</Text>
					</View>
					<View style={styles.identificacion}>
						<Text>{responsable.cargo}</Text>
					</View>
				</View>
				<View style={[styles.colContainer]}>
					<View style={[styles.headerName, { paddingLeft: 30 }]}>
						<Text>Firma:</Text>
					</View>
					<View style={styles.firma}>
						<Image style={styles.image} src={() => getFirma(responsable.firma.name)} />
					</View>
				</View>
			</View>
		</View>
	)
}
