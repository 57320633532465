import { View, StyleSheet } from '@react-pdf/renderer';
import { Reporte } from '../../../interfaces/reportes';
import { HeaderTable, ItemsTable } from '../../../../informes/components/pdf/normal';
import { Cell } from '../../../../../interfaces/shared';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
	container: {
		marginTop: 2
	},
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const tableTitles: Cell[] = [
	{ name: "L1" },
	{ name: "L2" },
	{ name: "L3" },
];

interface Props {
	active: Reporte;
}

export const CorrienteRectificadorTable = ({ active }: Props) => {
	const { corrienteRectificador } = active;
	return (
		<View>
			<TitleTables
				title='Corriente AC del Rectificador'
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
			/>
			<View style={styles.container} >
				<View style={styles.descriptionContainer}>
					<HeaderTable names={tableTitles} sx={{ txa: 'center', bc: "#dddddd" }} />
					{corrienteRectificador?.map((item, index) => {
						const fila: Cell[] = [
							{ name: item.l1, align: 'center' },
							{ name: item.l2, align: 'center' },
							{ name: item.l3, align: 'center' },
						]
						return <ItemsTable key={item.id} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom />
					})}
				</View>
			</View>
		</View>
	)
}
