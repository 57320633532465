import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePosition } from "use-position";
import { deleteFirma } from "../../../helpers";

import { useDate ,useScreenSize, useForm  } from "../../../hooks";
import { AppState } from "../../../reducers/rootReducer";
import { setFormError } from "../../ui/actions/ui";
import { closeReportes, startSetUbicacionInicial, startSetUbicacionFinal, startCreateReportes } from '../actions/reportes';
import { startSetSharedProps } from "../../../shared/shared_state/actions/shared";
import { Props } from "../../../shared/shared_state/interfaces/shared";


const initForm = {
  alcanceActividad: '',
  observaciones: '',
}

const initDate = {
  fecha: new Date(),
  fechaHoraInicio: new Date(),
  fechaHoraFinal: new Date()
}

export const useModalReporteBdB = () => {
  const dispatch = useDispatch();
  const { width } = useScreenSize();
  const watch = false;
  const { latitude, longitude, timestamp } = usePosition(watch);
  const [fullscreen, setFullscreen] = useState<boolean>(true);
  const [iniciar, setIniciar] = useState(false);
  const [guardar, setGuardar] = useState(false);
  const [err, setErr] = useState<boolean>(true);

  const { onChange, registerData, changeData } = useForm(initForm);
  const { onDateChange, changeDate, registerDataDate } = useDate(initDate);

  const { formError } = useSelector((state: AppState) => state.ui);
  const { modalReporteBdB, activeServicio } = useSelector((state: AppState) => state.servicios);
  const { modules, reportes } = useSelector((state: AppState) => state.reportes);
  const { acompana, tipoActividad, materiales, sello, responsableBeling,
    responsableCliente } = useSelector((state: AppState) => state.shared);

  const {
    actividad,
    actividadNoContemplada,
    equiposSeguridad,
    herramientas,
    personal,
    vehiculos,
  } = modules;
  //const { activeReporte } = reportes;
  const { firma: firmaResponsable } = responsableBeling.responsableBeling[0];
  const { firma: firmaCliente } = responsableCliente.responsableCliente[0];


  const handleClose = () => {
    if (!guardar) {
      if (firmaResponsable.id) {
        deleteFirma(firmaResponsable.id);
      }
      if (firmaCliente.id) {
        deleteFirma(firmaCliente.id);
      }
    }
    dispatch(closeReportes());
    changeData(initForm);
    changeDate(initDate);
    setIniciar(false);
    setGuardar(false);
  }

  const handleSave = () => {
    setGuardar(true)
    if (registerData.alcanceActividad === '') {
      dispatch(setFormError(true))
      return
    }
    const data = {
      requiereAts: false,
      acompanaCliente: acompana.acompanaCliente,
      actividades: actividad.actividades,
      actividadesNoPlaneadas: actividadNoContemplada.actividadesNo,
      equiposSeguridad: equiposSeguridad.equipos,
      ...registerData,
      ...registerDataDate,
      herramientas: herramientas.herramientas,
      materiales: materiales.materiales,
      personal: personal.personal,
      responsableBeling: responsableBeling.responsableBeling,
      responsableCliente: responsableCliente.responsableCliente,
      ubicacionInicial: reportes.ubicacionInicial,
      ubicacionFinal: reportes.ubicacionFinal,
      selloFisico: sello.selloFisico,
      servicio: activeServicio!.id,
      tipoActividad: tipoActividad.tipoActividad,
      vehiculos: vehiculos.vehiculos,

    }    
    dispatch(setFormError(false))
    dispatch(startCreateReportes(data));
    setIniciar(false);
    setGuardar(false);
  }

  const handleIniciar = () => {
    setIniciar(true);
    const ubicacion = {
      latitude,
      longitude,
      timestamp
    }    
    dispatch(startSetUbicacionInicial(ubicacion));
  }

  const handleFinalizar = () => {
    const ubicacion = {
      latitude,
      longitude,
      timestamp
    }
    dispatch(startSetUbicacionFinal(ubicacion));
  }
  useEffect(() => {
    if (activeServicio) {          
      const props: Props = {
        name: `sello_fisico_${activeServicio.id!}`,
        servicio: `sello_fisico_${activeServicio?.id}`,
        descripcion: `sello_fisico_${activeServicio.id}`,
        title: 'Sello fisico'
      }         
      dispatch(startSetSharedProps(props))
    }
  }, [activeServicio, dispatch])

  useEffect(() => {
    if (width < 1024) {
        setFullscreen(true)
    } else {
      setFullscreen(false)
    }
}, [width])

  useEffect(()=>{
    let counter: number = 0;
    if(acompana.acompanaCliente.nombre === '' || acompana.acompanaCliente.identificacion ===''){
      counter ++;
    }
    if(tipoActividad.tipoActividad.length === 0){
      counter ++;
    }
    if(personal.personal.length === 0){
      counter ++;
    }
    if(herramientas.herramientas.length ===0){
      counter ++;
    }
    if(materiales.materiales.length === 0){
      counter ++;
    }
    if(!reportes.ubicacionInicial){
      counter ++;
    }
    if(registerData.alcanceActividad.length === 0){
      counter ++;
    }
    if(actividad.actividades && actividad.actividades.length ===0 ){
      counter ++;
    }

    if(responsableBeling.responsableBeling[0].nombre === '' || responsableBeling.responsableBeling[0].identificacion === ''){
      counter ++;
    }
    if(responsableCliente.responsableCliente[0].nombre === '' || responsableCliente.responsableCliente[0].identificacion === ''){
      counter ++;
    }
    if(!sello.selloFisico){
      counter ++;
    }

    if(counter === 0){
      setErr(false)
    }else{
      setErr(true)
    }
  },[tipoActividad.tipoActividad.length, personal.personal.length, herramientas.herramientas.length, reportes.ubicacionInicial, registerData.alcanceActividad.length, acompana.acompanaCliente.nombre, acompana.acompanaCliente.identificacion, materiales.materiales.length, actividad.actividades, responsableBeling.responsableBeling, responsableCliente.responsableCliente, sello.selloFisico] )
  
  return {
    err,
    fullscreen,
    formError,
    modalReporteBdB,
    registerData,
    registerDataDate,
    iniciar,
    handleClose,
    handleFinalizar,
    handleIniciar,
    handleSave,
    onChange,
    onDateChange,
  }
}

