import { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';

import { PlaceType } from '../../interfaces/google';
import { useMapboxAutocomplete } from '../../hooks/useMapboxAutocomplete';


interface Props {
  required?: boolean,
  size?: Size;
  sx?: any;
  tabIndex: number;
  title: string;
  value: PlaceType | null;
  setValue: Dispatch<SetStateAction<PlaceType | null>>
}

type Size = 'medium' | 'small';


export const AutoComplete = ({ required = false, value, setValue, tabIndex, title, size ='medium', sx }: Props) => {
  const { options, setInputValue, setOptions } = useMapboxAutocomplete(value); 
  return (
    <Autocomplete
      id="mapbox-map-auto"
      fullWidth
      sx={{...sx }}
      size={size}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value || null }
      defaultValue={null}      
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      tabIndex={tabIndex}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={title} fullWidth required={required}  />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}