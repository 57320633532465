import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { startSetModalGrupo } from '../actions/grupos';


export const GrupoTableToolbar = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(startSetModalGrupo(true));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar
            </Button>
        </GridToolbarContainer>
    );
}