import { combineReducers } from 'redux';
import { actividadReducer } from '../modules/actividad/state/actividad';
import { actividadNoContempladaReducer } from '../modules/actividad_no_contemplada/state/actividad';
import { herramientaReducer } from '../modules/herramienta/state/herramienta';
import { materialReducer } from '../modules/material/state/materialReducer';
import { medicionBateriaReducer } from '../modules/medicion_banco_baterias/state/medicion';
import { personalReducer } from '../modules/personal/state/personal';
import { personalClaroReducer } from '../modules/personal_claro/state/personal';
import { rutinaAlarmasReducer } from '../modules/rutina_alarmas/state/alarma';
import { rutinaBancoBateriasReducer } from '../modules/rutina_banco_baterias/state/rutina';
import { rutinaRectificadorReducer } from '../modules/rutina_rectificador/state/rutina';
import { vehiculoReducer } from '../modules/vehiculo/state/vehiculoReducer';
import { bateriasReducer } from '../modules/baterias_info/state/bateriasReducer';
import { inversoresReducer } from '../modules/iversores_info/state/inversoresReducer';
import { voltajeRectificadorReducer } from '../modules/voltaje_rectificador/state/voltajeRectificadorReducer';
import { corrienteRectificadorReducer } from '../modules/corriente_rectificador/state/corrienteRectificadorReducer';
import { rectificadoresReducer } from '../modules/rectificadores_info/state/rectificadoresReducer';
import { imagePrevReducer } from '../modules/images_antes/state/imagePrevReducer';
import { imageFinReducer } from '../modules/images_final/state/imageFinReducer';
import { equipoSeguridadReducer } from '../../../shared/equipo_seguridad/state/equipoReducer';
import { sitioReducer } from '../modules/sitio_info/state/sitioReducer';
import { actividadObraReduder } from '../modules/actividad_obra/state/actividad_obra';

export const reporteModulesReducer = combineReducers({
    actividad: actividadReducer,
    actividadObra: actividadObraReduder,
	actividadNoContemplada: actividadNoContempladaReducer,
    alarma: rutinaAlarmasReducer,
    baterias: bateriasReducer,
    corriente: corrienteRectificadorReducer,
    equiposSeguridad: equipoSeguridadReducer,
    herramientas: herramientaReducer,
    imagesFin: imageFinReducer,
    imagesPrev: imagePrevReducer,
    inversores: inversoresReducer,
    materiales: materialReducer,
    medicionesBaterias: medicionBateriaReducer,
    personal: personalReducer,
    personalClaro: personalClaroReducer,
    rectificadores: rectificadoresReducer,
    rutinaBancoBateria: rutinaBancoBateriasReducer,
    rutinaRectificador:rutinaRectificadorReducer,
    vehiculos: vehiculoReducer,
    voltaje: voltajeRectificadorReducer,
    sitio: sitioReducer
})