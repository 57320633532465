
import { FechaFinal } from './twelve/FechaFinal';
import { NofiticacionReporte } from './twelve/NofiticacionReporte';

export const StepTwuelve = () => {
	
	return (
		<>
			<FechaFinal />
			<NofiticacionReporte />
		</>
	)
}
