import { ServicioState, ServicioAction } from '../interfaces/servicio';

const initState: ServicioState = {
    servicios: [],
    modalServicio: false,
    loading: false,
    activeServicio: null,
    selected: [],
    modalDelete: false,
    modalReporte: false,
    modalReporteBasico: false,
    modalReporteEmergencia: false,
    modalAts: false,
    modalReporteBdB: false,
    oldActiveServicio: null,
    isNew: false
}

export const servicioReducer = (state = initState, action: ServicioAction): ServicioState => {
    switch (action.type) {
        case 'load-servicio':
            return {
                ...state,
                servicios: action.payload
            }
        case "set-servicios":
            return {
                ...state,
                servicios: [...state.servicios, action.payload]
            };
        case "set-modal-servicio":
            return {
                ...state,
                modalServicio: action.payload
            };
        case 'create-servicio':
            return {
                ...state,
                servicios: [...state.servicios, action.payload]
            }
        case 'update-servicio':
            return {
                ...state,
                servicios: state.servicios.map(
                    c => (c.id === action.payload.id) ? action.payload : c
                )
            }
        case 'delete-servicio':
            return {
                ...state,
                servicios: state.servicios.filter(s => s.id !== action.payload)
            }
        case 'set-active-servicio':
            const servicio = state.servicios.find(s => s.id === action.payload);
            return {
                ...state,
                activeServicio: servicio ? servicio : null
            }
        case 'set-inactive-servicio':
            return {
                ...state,
                activeServicio: null
            }
        case 'set-selected':
            return {
                ...state,
                selected: action.payload
            }
        case 'set-loading':
            return {
                ...state,
                loading: action.payload
            }
        case 'set-modal-delete':
            return {
                ...state,
                modalDelete: action.payload
            }
        case 'set-modal-reporte':
            return {
                ...state,
                modalReporte: action.payload
            }
        case 'set-modal-reporte-normal':
            return {
                ...state,
                modalReporteBasico: action.payload
            }
        case 'set-modal-reporte-emergencia':
            return {
                ...state,
                modalReporteEmergencia: action.payload
            }
        case 'set-modal-bdb':
            return {
                ...state,
                modalReporteBdB: action.payload
            }
        case 'set-modal-ats':
            return {
                ...state,
                modalAts: action.payload
            }
        case 'set-old-active-servicio':
            return {
                ...state,
                oldActiveServicio: action.payload
            }
        case 'set-is-new-servicio':
            return {
                ...state,
                isNew: action.payload
            }
        default:
            return state;
    }
}