import { Box, IconButton } from '@mui/material'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';


export const ButtonDownload = () => {
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                typography: 'body1',
                '& > :not(style) + :not(style)': {
                    ml: 2,
                },
            }}

        >
            <IconButton color="primary" aria-label="Descargar" size='large'>
                <CloudDownloadOutlinedIcon sx={{fontSize: 100}} />
            </IconButton>
        </Box>
    )
}
