import { ItemAction, ItemState } from "../interfaces/item";

const initState: ItemState = {
	items: [],
	activeItem: null,
	modalItem: false,
	alertDeleteItem: false,
	isNew: false
}

export const itemReducer = (state = initState, action: ItemAction) => {
	switch (action.type) {
		case 'load-items':
			return {
				...state,
				items: action.payload
			}
		case 'create-item':
			return {
				...state,
				items: [...state.items, action.payload]
			}

		case 'update-item':
			return {
				...state,
				items: state.items.map(i => (i.id === action.payload.id) ? action.payload : i)
			}

		case 'delete-item':
			return {
				...state,
				items: state.items.filter(i => i.id !== action.payload)
			}

		case 'set-active-item':
			const item = state.items.find(l => l.id === action.payload);
			return {
				...state,
				activeItem: item ? item : null
			}

		case 'set-modal-item':
			return {
				...state,
				modalItem: action.payload
			}

		case 'set-alert-delete-item':
			return {
				...state,
				alertDeleteItem: action.payload
			}

		case 'set-isnew-item':
			return {
				...state,
				isNew: action.payload
			}

		default:
			return {
				...state
			};
	}
}