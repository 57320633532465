import { AnexoSstState, AnexoSstAction } from "../interfaces/anexos";

const imageState: AnexoSstState = {
    anexos: [],
    modalAnexoSst: false
}

export const anexoSstReducer = (state = imageState, action: AnexoSstAction): AnexoSstState =>{
    switch(action.type){
        case 'load-sst-anexos':
            return{
                ...state,
                anexos: action.payload
            }
            case "create-sst-anexos":
                return {
                    ...state,
                    anexos: [...state.anexos, action.payload]
                }
            case "update-sst-anexos":
                return {
                    ...state,
                    anexos: state.anexos.map(anexo => anexo.id === action.payload.id ? action.payload : anexo)
                }
            case "delete-sst-anexos":
                return {
                    ...state,
                    anexos: state.anexos.filter(anexo => anexo.id !== action.payload)
                }
            case "clear-sst-anexo-state":
                return {
                    ...state,
                    anexos: []
                }
            case 'set-modal-sst-anexo':
                return {
                    ...state,
                    modalAnexoSst: action.payload
                }
        default:
            return state
    }
}