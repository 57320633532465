
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Cell } from '../../../../../interfaces/shared';

const styles = StyleSheet.create({
	containerTable: {
		flexDirection: 'row',			
		marginBottom: 0,
		flexGrow: 1,
		alignItems: 'center',
	},
	cellContainer: {
		flexDirection: 'row',
		margin:0,
		alignItems: 'center',
		height: '100%',	
	},
	cellContainerTable: {
		borderTop: 1,
		borderRight: 1,
		borderBottom: 1,
		
	},
	cellContainerTableBottom: {
		borderRight: 1,
		borderBottom: 1,
		
	},
	cellContainerTableLeft: {
		border: 1,
	},
	cellContainerTableLeftBottom: {
		borderRight: 1,
		borderBottom: 1,
		borderLeft: 1,
	},
	textContainerTable: {
		paddingLeft: 5,
		paddingBottom: 2,
		paddingTop: 2,
		width: '100%',
		flexGrow: 1,		
	},
	cellContainerVoidTable: {
		flexDirection: 'row',		
	}
});

interface Props {
	bottom?: boolean;
	defaultWidth?: boolean
	names: Cell[];
	sx?: {
		bc?: string,
		c?: string,
		fz?: number,
		fw?: 'bold' | 'extrabold' | 'light' | 'normal' |'demibold',
		mt?: number,
		pd?: number,
		txa?:  'center' | 'justify' | 'left' | 'right',
	}
}
export const HeaderTable = ({ bottom = false, defaultWidth = true, names, sx }: Props) => {
	const largo = names.length;

	const { bc, c, fz, fw, mt, pd, txa } = sx ?? {
		bc: '',
		c: '#717171',
		fz: 8,
		fw: 'bold',
		mt: 0,
		pd: 0,
		txa: 'left'
	};
	
	return (
		<View style={[
			styles.containerTable, {
			backgroundColor: bc,
			fontSize: fz, 
			padding: pd,
			color:c,
			fontWeight: fw
			}]}>
			{names.map((name, index) => {
				const length = name.name.toString().length;
				const cellStyles = cellStyle(bottom, length, index);
				const width = defineWidth(defaultWidth, name.width!, largo)
				return(
				<View style={[
					styles.cellContainer,cellStyles,
					width
				]} key={index}>
					<Text style={[styles.textContainerTable,{marginTop: mt, textAlign: txa}]}>{name.name}</Text>
				</View>
			)})}
		</View>
	)
}


function cellStyle(bottom: boolean, length: number, index: number) {

	const cellLeft = {		
		...styles.cellContainerTableLeft
	}

	const cellRest = {
		...styles.cellContainerTable
	}

	const cellBottom = {
		...styles.cellContainerTableBottom
	}

	const cellLeftBottom = {
		...styles.cellContainerTableLeftBottom
	}
	
	if(index === 0 && length >0 && !bottom){
		return cellLeft
	}else if(index === 0 && length >0 && bottom){
		return cellLeftBottom
	}else if(index !== 0 && length >0 && bottom){
		return cellBottom
	}else if(index !== 0 && length >0 && !bottom){		
			return cellRest
	}else{
		return styles.cellContainerVoidTable
	}
}

function defineWidth(defaultW: boolean, w: string | number, l: number ){
	
	if(defaultW){
		const width = 100 / l;
		return{
			minWidth: `${width}%`, 
			maxWidth: `${width}%`
		}
	}else{
		return{
			minWidth: `${w}`, 
			maxWidth: `${w}`
		}
	}
}