import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { Ats } from '../../../interfaces/ats';
import { Cell } from "../../../../../interfaces/pdf";
import { HeadItems } from "../../../../../components/pdf/HeadItems";
import { RowItems } from "../../../../../components/pdf/RowItems";
import { Title } from "../../../../../components/pdf/Title";
import { ConfirmarOpcion } from '../../../../../components/pdf/ConfirmarOpcion';




const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 5
	},
});

const title = "Antes de iniciar el trabajo verifique."
const namesUno = ["¿El lider técnico o algún miembro del equipo tiene Tarjeta Profesional (CONTE o similar)?:"];
const namesDos = ["Tipo Tarjeta Profesional", "Número Tarjeta Profesional"];
interface Props {
	active: Ats;
}
export const Tarjeta = ({ active }: Props) => {		
	const filaDos: Cell[] = [
		{name: active.tipoTarjeta!},
		{name: active.numeroTarjeta!}
	]
	return (
		<>
			<Title title={title} sx={{fz: 11, fs: 'italic', fw: 'bold'}}/>			
			<ConfirmarOpcion title={namesUno} value={active.tieneTarjeta!.toUpperCase()} />
			<View style={styles.headerContainer}>
				<HeadItems names={namesDos} />
				<RowItems columnas={filaDos} />
			</View>			
		</>
	);
};
