import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { Herramienta } from '../../../interfaces/reportes';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 14,        
        color:'#212121',
        paddingTop: 2,
    },
    rowTop: {
        borderBottomColor: '#dddddd',
        borderBottomWidth: 1,
    },
    description: {
        width: '30%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
    },
    marca: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        paddingLeft: 4,
    },
    propiedad: {
        width: '15%',
        textAlign: 'left',
        paddingLeft: 4,
    },
    unidad:{
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
    }
  });
interface Props{
    items: Herramienta[]
}

export const TableRow = ({items}: Props) => {
    const rows = items.map( (item, index) => {
        const cellStyles = cellStyle(index, items.length);
        return(
        <View style={[cellStyles]} key={item.id.toString()}>
            <Text style={styles.description}>{item.tipo}</Text>
            <Text style={styles.unidad}>{item.unidad}</Text>
            <Text style={styles.qty}>{item.cantidad}</Text>
            <Text style={styles.marca}>{item.marca}</Text>
            <Text style={styles.propiedad}>{item.propiedad}</Text>
        </View>)}
    )
    return (<Fragment>{rows}</Fragment> )
};

function cellStyle(index: number, length: number) {

    const cell = {
        ...styles.row,
        ...styles.rowTop
    }

    const cellEnd = {
        ...styles.row
    }

    if (length === index + 1) {
        return cellEnd
    } else {
        return cell
    }

}