import { GridRowId } from "@mui/x-data-grid"
import { Material } from "../../../../../interfaces/shared"
import { MaterialAction } from "../interfaces/material"

export const startSetMateriales = (material: Material): MaterialAction => ({
    type: 'set-materiales',
    payload: material
})

export const startUpdateMaterial = (material: Material): MaterialAction => ({
    type: 'update-material',
    payload: material
})

export const startDeleteMaterial = (id: GridRowId): MaterialAction => ({
    type: 'delete-material',
    payload: id
})

export const startSetMaterialesEmpty = (): MaterialAction => ({
    type: 'set-materiales-empty'
})