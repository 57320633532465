import { Button, DialogActions } from '@mui/material';

interface Props {
	handleClose: () => void,
	handleSave?: () => void
	pdf?: boolean;
	err?: boolean
}

export const ModalActions = ({ handleClose, handleSave, pdf = false, err= false }: Props) => {
	return (
		<DialogActions sx={{ mr: 5 }}>
			<Button onClick={handleClose} color="primary">
				Cancelar
			</Button>
			{
				!pdf && <Button onClick={handleSave} color="primary" disabled={err}>
					Guardar
				</Button>
			}
		</DialogActions>
	)
}
