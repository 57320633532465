import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import imagen from '../../../../../assets/img/logo_beling.png';
import { AppState } from '../../../../../reducers/rootReducer';
import { useScreenSize } from '../../../../../hooks';
import { startSetAlarmaId, startUpdateAlarma, startSetModalAlarma, startDeleteAlarma, startSetActiveRutinaAlarmas, startSetModImgAlarmas } from '../actions/alarma';
import { ModalAlarma } from './ModalAlarma';
import { CellExpand } from '../../../../../components/ui/CellExpand';
import { RutinaAlarmaToolbar } from './RutinaAlarmaToolbar';
import { ModalSelectRutinaAlarmas } from './ModalSelectRutinaAlarmas';
import { ModalImagesRutinaAlarmas } from './ModalImagesRutinaAlarmas';
import { RutinaAlarma } from '../interfaces/alarma';

export const AlarmaTable = () => {
    const { width } = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText
    const dispatch = useDispatch();
    const { rutinasAlarmas, activeRutinaAlarma, modalAlarma } = useSelector((state: AppState) => state.reportes.modules.alarma);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteAlarma(id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const hanleAddImageClick = (id: GridRowId) => () => {
        dispatch(startSetAlarmaId(id))
        dispatch(startSetModalAlarma(true));
    }

    const handleViewImages = (id: GridRowId) => () => {
        dispatch(startSetActiveRutinaAlarmas(id));
        dispatch(startSetModImgAlarmas(true));
    }

    const processRowUpdate = (newRow: GridRowModel<RutinaAlarma>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateAlarma(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'numero',
            headerAlign: 'center',
            headerName: 'Item',
            flex: width > 900 ? 0.5 : 0,
            width: 100,
            editable: false
        },
        {
            field: 'nombre',
            headerAlign: 'center',
            headerName: 'Rutina',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: false,
            sortable: false,
            hideable: false,
            renderCell: CellExpand
        },
        {
            field: 'descripcion',
            headerAlign: 'center',
            headerName: 'Descripción',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: false,
            sortable: false,
            hideable: false,
            renderCell: CellExpand
        },
        {
            field: 'observaciones',
            headerAlign: 'center',
            headerName: 'Observaciones',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'imagen',
            headerAlign: 'center',
            headerName: 'Imagén',
            editable: false,
            renderCell: (row) => {
                const images = rutinasAlarmas.find(r => r.id === row.id)?.images;
                const lastIndex = images?.length! - 1;
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'contain',
                            backgroundImage: images?.length! > 0 ? `url(${row.row.images[lastIndex].uri})` : `url(${imagen})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                );
            },
            flex: width > 900 ? 1 : 0,
            width: 150,
        },
        {
            field: 'actions',
            headerAlign: 'center',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<AddAPhotoRoundedIcon />}
                            label="Agregar imagen"
                            className="textPrimary"
                            onClick={hanleAddImageClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<ImageSearchRoundedIcon />}
                        label="Ver imagenes"
                        className="textPrimary"
                        onClick={handleViewImages(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={rutinasAlarmas}
                columns={columns}
                sx={{
                    '& .MuiDataGrid-cell': {
                        py: '8px',
                    },
                }}
                editMode="row"
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } }
                }}
                slots={{
                    toolbar: RutinaAlarmaToolbar,
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            {modalAlarma && <ModalAlarma />}
            {activeRutinaAlarma && <ModalImagesRutinaAlarmas />}
            <ModalSelectRutinaAlarmas setRowModesModel={setRowModesModel} />
        </Box>
    );
}