import { useDispatch } from 'react-redux';
import { usePosition } from 'use-position';
import { Box, IconButton, Typography } from '@mui/material';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';

import { startSetUbicacionInicial } from '../../actions/reportes';
import { useMobileDetect } from '../../../../hooks';

export const StepOne = () => {
  const dispatch = useDispatch();
  const { isMobile } = useMobileDetect();
  const watch = false;
  const { latitude, longitude, timestamp } = usePosition(watch);

  const handleIniciar = () => {
    const ubicacion = {
      latitude,
      longitude,
      timestamp
    }
    dispatch(startSetUbicacionInicial(ubicacion));
  }


  return (
    <Box
    
      sx={{
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IconButton 
      color="primary" 
      aria-label="Ubicación" 
      size="large" 
      onClick={handleIniciar}
      sx={{
        marginTop: isMobile ? undefined : 5
      }}
      >
        <AddLocationAltOutlinedIcon sx={{ fontSize: 100 }} />
      </IconButton>
      <Typography>
        Pulse una vez se encuentre en sitio
      </Typography>
    </Box>
  );
};
