// import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { Cell } from '../../interfaces/pdf';

const styles = StyleSheet.create({
    container: {		
        flexDirection: 'row',        
		minHeight:24,
		fontSize:10,
        textAlign: 'left',
		color:'#212121',
		width: '100%'
    },
	cellContainer:{
		flexDirection: 'row',
		alignItems: 'center',
		minHeight:24,		
        borderBottom: 1,
		borderTop: 1,
		borderLeft:1,
		width: '100%'
	},
	cellContainerEnd:{
		borderRight: 1,
	},
    textContainer: {
		paddingLeft: 5,
    }
  });

interface Props{
	columnas: Cell[];
}

export const RowItems = ({ columnas }: Props) => {
	const length = columnas.length;
	const width = 100 / length ;
	return(
    <View wrap={ false } style={styles.container}>
		{columnas.map((col: any, index: number) => {
			const cellStyles = cellStyle(index, length);
			return (
		<View style={[cellStyles, { width: `${width}%` }]} key={index}>
			<Text style={styles.textContainer}>{col.name}</Text>
		</View>)})}
    </View>
  );}

  function cellStyle(index: number, length: number){

	const cell = {
		...styles.cellContainer
	}

	const cellEnd = {
		...styles.cellContainer,
		...styles.cellContainerEnd
	}

	if(length === index + 1){
		return cellEnd
	}else{
		return cell
	}

  }

