import { GridRowId } from "@mui/x-data-grid";
import { Equipo } from "../../../interfaces/ats";
import { RiesgoAlturaActions } from "../interfaces/riesgo_altura";


export const startSetRiesgosAltura = (riesgo: Equipo):RiesgoAlturaActions => ({
    type: 'set-riesgos-altura',
    payload: riesgo,
});

export const startUpdateRiesgoAltura = (riesgo: Equipo):RiesgoAlturaActions => ({
    type: 'update-riesgos-altura',
    payload: riesgo,
});

export const startDeleteRiesgoAltura = (id: GridRowId):RiesgoAlturaActions => ({
    type: 'delete-riesgos-altura',
    payload: id,
});

export const startEditRiesgoAltura = (id: GridRowId):RiesgoAlturaActions => ({
    type: 'edit-riesgos-altura',
    payload: id,
});

export const startSetRiesgosAlturaEmpty = ():RiesgoAlturaActions => ({
    type: 'set-riesgos-altura-empty',
});