import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

import { Ats } from '../../../interfaces/ats';
import { getDurationHhMmSs, fechaHora } from '../../../../../helpers/fecha';
import { Cell } from "../../../../../interfaces/pdf";
import { HeadItems } from "../../../../../components/pdf/HeadItems";
import { RowItems } from "../../../../../components/pdf/RowItems";

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 5
	},
});

const namesUno = ["Técnico:", "Lugar del Trabajo:", "Acompañamiento del Cliente:"];
const namesDos = ["Duración:", "Fecha y Hora de Inicio:", "Fecha y Hora de Finalización:"];


interface Props {
	active: Ats;
}
export const Header = ({ active }: Props) => {
	const filaUno: Cell[] = [
		{name:
			`${active.servicio?.tecnico!.name} ${active.servicio?.tecnico!.lastname}`.toUpperCase()},
		{name: active.lugarTrabajo!},
		{name: active.representanteCliente!.toUpperCase()},
		];
		
		const filaDos: Cell[] = [
			{name: getDurationHhMmSs(active.horaInicio!, active.horaFinal!)},
			{name:  fechaHora(active.horaInicio!)},
			{name: fechaHora(active.horaFinal!) }
		];


	return (
		<>
			<View style={styles.headerContainer}>
				<HeadItems names={namesUno} />
				<RowItems columnas={filaUno} />
			</View>
			<View style={styles.headerContainer}>
				<HeadItems names={namesDos} />
				<RowItems columnas={filaDos} />
			</View>
		</>
	);
};

