import { useSelector } from 'react-redux';
import { AppState } from '../../../../reducers/rootReducer';
import { useEffect, useState } from 'react';
import { ResponsableCliente } from '../../../../shared/responsable_cliente/ResponsableCliente';
import { GridRowId } from '@mui/x-data-grid';


export const FirmaCliente = () => {
    const { activeServicio } = useSelector((state: AppState) => state.servicios);
    const [props, setProps] = useState({
        name: '',
        reporte: '' as GridRowId
    })

    useEffect(() => {
        if (activeServicio) {
            setProps({
                name: activeServicio!.tipo.alias,
                reporte: activeServicio?.id!
            })
        }
    }, [activeServicio])

    return (
        <ResponsableCliente name={props.name} reporte={props.reporte} />
    )
}