import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../../reducers/rootReducer';
import { useForm } from '../../../hooks/useForm';
import { startCreateContacto, startSetActiveContacto, startSetModalContacto, startUpdateContacto } from '../actions/contactos';
import { Contacto } from '../interfaces/contactos';

const initForm = {
    nombres: '',
    referencia: '',
    direccion: '',
    telefono: '',
    email: '',
    nota: '',
  }

export const useModalContacto = () => {
    const dispatch = useDispatch();
    const { activeContacto, modalContacto } = useSelector((state: AppState) => state.contactos);
    const {activeCliente} = useSelector((state: AppState) => state.clientes);

    const { 
        onChange, 
        changeData, 
        nombres,
        referencia,
        direccion,
        telefono,
        email,
        nota 
    } = useForm(initForm);

    const handleClose = () => {
        changeData(initForm);
        dispatch(startSetActiveContacto(''));
        dispatch(startSetModalContacto(false));
    };
    
    const handleSave = () => {
        const contacto: Contacto = {
            nombres,
            referencia,
            direccion,
            telefono,
            email,
            nota,
            cliente: activeCliente?.id
        };

        if (!activeContacto) {
            dispatch(startCreateContacto(contacto));
        } else {
            dispatch(startUpdateContacto(contacto));
        }
        dispatch(startSetActiveContacto(''));
        handleClose();

    }

    useEffect(() => {
        if (!activeContacto) {
            changeData(initForm);
        } else {
            const formData = {
                nombres: activeContacto.nombres,
                referencia: activeContacto.referencia,
                direccion: activeContacto.direccion,
                telefono: activeContacto.telefono,
                email: activeContacto.email,
                nota: activeContacto.nota,
            };
            changeData(formData);
        }
    }, [activeContacto, changeData]);


  return {
    activeContacto,
    email,
    nombres,
    direccion,
    modalContacto,
    nota,
    referencia,
    telefono,
    handleClose,
    handleSave,
    onChange
  }
}
