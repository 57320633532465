import { GridRowId } from "@mui/x-data-grid";
import { Herramienta, HerramientaAction } from "../interfaces/herramienta";

export const startSetHerramienta = (herramienta: Herramienta): HerramientaAction => ({
    type: 'set-herramienta',
    payload: herramienta,
});

export const startUpdateHerramient = (herramienta: Herramienta): HerramientaAction => ({
    type: 'update-herramienta',
    payload: herramienta,
});

export const startDeleteHerramienta = (id: GridRowId) => ({
    type: 'delete-herramienta',
    payload: id,
});

export const startSetHarramientaEmpty = ():HerramientaAction => ({
    type: 'set-herramienta-empty',
});