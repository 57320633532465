import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ClientesScreen } from './ClientesScreen'
import { ClienteDetail } from './ClienteDetail';

export const ClientesRoutes = () => {
  return (
    <>    
    <Routes>
            <Route path="/clientes" element={<ClientesScreen />} />
            <Route path="/detalle" element={<ClienteDetail />} />
            <Route path="/" element={<ClientesScreen />} />
    </Routes>    
    </>
  )
}
