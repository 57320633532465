import { Avatar, Box, Typography } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import { UsersTable } from './UserTable';

export const Users = () => {
    return (
        <>
            <Box
                sx={{
                    my: 1,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <GroupsIcon />
                </Avatar>
                <Typography component="h1" align='center' variant="h6">
                    Usuarios
                </Typography>
            </Box>
            <UsersTable />
        </>
    )
}