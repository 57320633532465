import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { MaterialTable } from './MaterialTable';

const Table = memo(() => {
    return (
        <MaterialTable />
    );
});

export const Material = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Materiales a Utilizar
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}
