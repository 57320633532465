
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	column: {
		flexDirection: 'column',
		width: '5%',
	},	
	headColumn: {
		borderBottom: 1,
		backgroundColor: '#aaaaaa',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',

	},
	headText: {
		marginTop: 2,
		fontSize: 8,
		fontWeight: 'bold',
		textAlign: 'center',

	},	
	rowContainer: {
		flexDirection: 'row',
	},	
	diaText: {
		fontSize: 10,
		fontWeight: 'normal',
		marginTop: 2
	},
})


export const ColSix = () => {
	return (
		<View style={[styles.column ]}>
			<View style={[styles.rowContainer, styles.headColumn]}>
				<Text style={[styles.headText]}>Hoja</Text>
			</View>
			<View style={[styles.rowContainer, { height: 28, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }]}>
				<Text style={[styles.diaText]} render={({ pageNumber, totalPages }) => (
					`${pageNumber}/${totalPages}`
				)} fixed />
			</View>
		</View>
	)
}
