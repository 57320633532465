import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { startSetModalInformeSst, startSetModalObraSst } from '../actions/sst';
import { startLoadObras, startSetActiveObra } from '../../obras/actions/obras';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelect } from '../../../hooks/useSelect';

const initSelect = {
  idObra: '',
}

export const ModalSelectObra = () => {
  const dispatch = useDispatch();
  const { modalObraSst } = useSelector((state: AppState) => state.sst.sst);
  const {obras} = useSelector((state: AppState)=> state.obras.obras);

  const { onChangeSelect, registerSelectData,changeSelect } = useSelect(initSelect);

  const { idObra } = registerSelectData;

  const handleClose = () => {
    dispatch(startSetModalObraSst(false))
    changeSelect(initSelect);
  }

  const handleSelect = ()=>{
    dispatch(startSetActiveObra(idObra))
    dispatch(startSetModalInformeSst(true));
    handleClose();
  }

  useEffect(() => {
    if(obras.length === 0){
      dispatch(startLoadObras())
    }
  }, [dispatch, obras])
  
  return (
    <>
      <Dialog open={modalObraSst} onClose={handleClose}>
        <DialogTitle>Obra</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione la obra para iniciar el informe
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="idObra-select-label">Obra *</InputLabel>
            <Select
              labelId="idObra-select-label"
              id="idObra"
              value={idObra}
              label="Obra"
              name="idObra"
              required
              onChange={onChangeSelect}
            >
              <MenuItem disabled value="">                
              </MenuItem>
              {obras.map((obra) => (
                <MenuItem
                  key={obra.id}
                  value={obra.id}
                //style={getStyles(name, personName, theme)}
                >
                  {obra.descripcion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSelect}>Seleccionar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
