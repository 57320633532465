import * as React from 'react';
import { NavLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
//import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import { Engineering } from '@mui/icons-material';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { Tooltip } from '@mui/material';

const userItems = [
  {id: '1', name: 'Dashboard', icon: <DashboardIcon />, link: '/dashboard'},
  {id: '2', name: 'Servicios', icon: <ElectricalServicesIcon />, link: '/servicios'},
  {id: '3', name: 'Reportes', icon: <BarChartIcon />, link: '/reportes'},
  {id: '4', name: 'ATS', icon: <AssignmentLateIcon />, link: '/ats'}
];

const gestorItems = [
  {id: '1', name: 'Dashboard', icon: <DashboardIcon />, link: '/dashboard'},
  {id: '2', name: 'Servicios', icon: <ElectricalServicesIcon />, link: '/servicios'},
  {id: '3', name: 'Reportes', icon: <BarChartIcon />, link: '/reportes'},
  {id: '4', name: 'ATS', icon: <AssignmentLateIcon />, link: '/ats'},
  {id: '5', name: 'Tecnicos', icon: <Engineering />, link: '/tecnicos'},
];

const adminItems = [
  {id: '1', name: 'Dashboard', icon: <DashboardIcon />, link: '/dashboard'},
  {id: '2', name: 'Servicios', icon: <ElectricalServicesIcon />, link: '/servicios'},
  {id: '3', name: 'Obras', icon: <EngineeringOutlinedIcon />, link: '/obras'},
  {id: '4', name: 'ATS', icon: <AssignmentLateIcon />, link: '/ats'},
  {id: '5', name: 'SST', icon: <SafetyCheckOutlinedIcon />, link: '/sst'},
  {id: '6', name: 'Reportes', icon: <FactCheckOutlinedIcon />, link: '/reportes'},
  {id: '7', name: 'Informes', icon: <BarChartIcon />, link: '/informes'},
  {id: '8', name: 'Listas', icon: <FormatListBulletedIcon />, link: '/listas'},
  //{id: '6', name: 'Contactos', icon: <AssignmentLateIcon />, link: '/contactos'},
  {id: '9', name: 'Tecnicos', icon: <Engineering />, link: '/tecnicos'},
  {id: '10', name: 'Clientes', icon: <PersonIcon />, link: '/clientes'},
  {id: '11', name: 'Usuarios', icon: <GroupsIcon />, link: '/usuarios'}, 
];

export const userListItems = (
  <React.Fragment> 
    {userItems.map(item => (
      <NavLink key={item.id} to={item.link} style={{textDecoration: 'none', color:'grey'}}>
        <ListItemButton>
          <ListItemIcon>
          <Tooltip title={item.name}>
            {item.icon}
            </Tooltip>
            </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </NavLink>
    ))}
   
  </React.Fragment>
);

export const gestorListItems = (
  <React.Fragment> 
    {gestorItems.map(item => (
      <NavLink key={item.id} to={item.link} style={{textDecoration: 'none', color:'grey'}}>
        <ListItemButton>
          <ListItemIcon>
          <Tooltip title={item.name}>
            {item.icon}
            </Tooltip>
            </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </NavLink>
    ))}
   
  </React.Fragment>
);

export const adminListItems = (
  <React.Fragment> 
    {adminItems.map(item => (
      <NavLink key={item.id} to={item.link} style={{textDecoration: 'none', color:'grey'}}>
        <ListItemButton>
          <ListItemIcon>
          <Tooltip title={item.name}>
            {item.icon}
            </Tooltip>
            </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </NavLink>
    ))}
   
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Reportes guardados
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Mes actual" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Ultima semana" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Año actual" />
    </ListItemButton>
  </React.Fragment>
);