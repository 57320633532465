import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { AppState } from '../../reducers/rootReducer';
import { dataPie } from '../../helpers';
import { Title } from '../title/Title';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieServiciosCliente = () => {
	const { servicios: serviciosDB } = useSelector((state: AppState) => state.servicios);

	const servicios = serviciosDB.map(s =>{
		return {
			id: s.id,
			cliente: s.cliente.nombre
		}
	})

	const data = dataPie(servicios, 'cliente', 'Servicios');

	const options = {
		responsive: true,
		plugins: {
		  legend: {
			position: 'top' as const,
		  },
		  title: {
			display: true,
			text: 'Chart.js Bar Chart',
		  },
		},
	  };
	
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			alignContent: 'center',
			justifyContent: 'center',
			alignItems: 'center',
			height: 300
		}}>
		<Title marginTop={0}>Servicios por Cliente</Title>
		<Pie data={data} options={options} />
		</Box>
	)
}
