import { fetchConToken } from "../../../apis/fetch";
import { doMessage, startLoading } from "../../ui/actions/ui";
import { Informe, InformeAction, Ubicacion } from "../interfaces/informes";
import { GridRowId } from "@mui/x-data-grid";


import { startSetActividadEmptyNoPlaneada } from '../../reportes/modules/actividad_no_contemplada/action/actividad';
import { startSetHarramientaEmpty } from '../../reportes/modules/herramienta/actions/herramienta';
import { startSetMaterialesEmpty } from '../../reportes/modules/material/actions/material';
import { startSetPersonalEmpty } from '../../reportes/modules/personal/actions/personal';
import { startSetPersonalClaroEmpty } from '../../reportes/modules/personal_claro/actions/personal';
import { startSetVehiculosEmpty } from '../../reportes/modules/vehiculo/actions/vehiculo';
import { setInactiveServicio, startSetModalReporte, startSetModalReporteBdB, startSetModalReporteEmergencia, startSetModalReporteNormal } from '../../servicios/actions/servicio';
import { startSetAcompanaClienteEmpty } from "../../../shared/acompana_cliente/actions/actions";
import { startClearTipoActividad } from "../../../shared/tipo_actividad/actions/actions";
import { startSetmodalReporteObra } from "../../obras/actions/obras";
import { startClearSelloFisico } from "../../../shared/sello_fisico/actions/images";
import { startClearImagenActividad } from "../../../shared/images/actions/images";
import { startSetResponsableBelingEmpty } from "../../../shared/responsable/actions/responsable";
import { startSetResponsableClienteEmpty } from "../../../shared/recibe/actions/recibe";


export const startLoadInformes = () => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('informes', {}, "GET");
        const body = await resp.json();
        const { errors, msg, ok } = body;        
        if (ok) {
            dispatch(startLoading());
            dispatch(loadInformes(body.informes));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const loadInformes = (informes: Informe[]): InformeAction => ({
    type: 'load-informes',
    payload: informes
})

export const startCreateInformes = (newInforme: any) => {    
    return async (dispatch: any) => {        
        dispatch(startLoading());
        const resp = await fetchConToken('informes', newInforme, 'POST');
        const body = await resp.json();
        const { errors, msg, ok, informe } = body;
        if (ok) {            
            dispatch(createInformes(informe));
            dispatch(doMessage({ message: msg, type: 'success' }));
            dispatch(startLoadInformes())
            dispatch(closeInformes())            
        } else {
            
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }        
        dispatch(startLoading());
    }
}

const createInformes = (informe: Informe): InformeAction => ({
    type: 'create-informes',
    payload: informe
})

export const closeInformes = () => {
    return async (dispatch: any) => {
        dispatch(setInactiveServicio());
        dispatch(startSetActividadEmptyNoPlaneada());
        dispatch(startSetHarramientaEmpty())
        dispatch(startSetMaterialesEmpty());        
        dispatch(startSetPersonalEmpty());
        dispatch(startSetPersonalClaroEmpty());        
        dispatch(startSetVehiculosEmpty())
        dispatch(startSetModalReporte(false));
        dispatch(startSetModalReporteNormal(false));
        dispatch(startSetModalReporteEmergencia(false));
        dispatch(startSetModalReporteBdB(false));
        dispatch(startSetmodalReporteObra(false));
        dispatch(startSetResponsableBelingEmpty());
        dispatch(startSetResponsableClienteEmpty()); 
        dispatch(startSetAcompanaClienteEmpty());
        dispatch(startClearTipoActividad());
        dispatch(startClearUbicacionInicial());
        dispatch(startClearUbicacionFinal());
        dispatch(startClearSelloFisico());
        dispatch(startClearImagenActividad())
    }
};

export const startSetUbicacionInicial = (ubicacion: Ubicacion): InformeAction => ({
    type: 'set-ubicacionInicial',
    payload: ubicacion
});

export const startClearUbicacionInicial = (): InformeAction => ({
    type: 'set-clear-ubicacionInicial',
});

export const startSetUbicacionFinal = (ubicacion: Ubicacion): InformeAction => ({
    type: 'set-ubicacionFinal',
    payload: ubicacion
});

export const startClearUbicacionFinal = (): InformeAction => ({
    type: 'set-clear-ubicacionFinal',
});

export const starSetActividadTipo = (actividad: string[]): InformeAction => ({
    type: 'set-actividad-tipo',
    payload: actividad
});

export const startSetModalInformePdf = (modal : boolean): InformeAction => ({
    type: 'set-modal-informe-pdf',
    payload: modal
})

export const startSetActiveInforme = (id: GridRowId): InformeAction => ({
    type: 'set-active-informe',
    payload: id
})