import {View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { TablePersonalBelingHeader } from './TablePersonalBelingHeader';
import { TablePersonalBelingRow } from './TablePersonalBelingRow';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
    },
});

const title = 'Describa el personal que interviene en la actividad:';

interface Props {
    active: Reporte;
  }

  export const PersonalBelingTable = ({active}:Props) => (
    <View>
    <TitleTables
        title='Personal de Beling que interviene'
        sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
      />
    <TitleTables title={title} sx={{fw: 'normal', fz:10}} />
    <View style={styles.tableContainer}>
        <TablePersonalBelingHeader />
        <TablePersonalBelingRow items={active.personal!} />
    </View>
    </View>
  );
