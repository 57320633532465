
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from '../../../../../interfaces/informes';
import { getDurationHhMm, getHora } from '../../../../../../../helpers';


const styles = StyleSheet.create({
	column: {
		flexDirection: 'column',
		borderBottom: 1,
		height: '100%',
		width: '20%'
	},
	rowContainer: {
		flexDirection: 'row',
	},
	borderRow: {
		borderBottom: 1,
		borderRight: 1
	},	
	borderRowEnd: {
		borderBottom: 1,
	},
	borderRowBottomStart: {
		borderRight: 1
	},
	borderRowBottom: {
		borderRight: 1
	},
	leftColumn: {
		flexDirection: 'column',
		width: '60%',
	},
	rightColumn: {
		flexDirection: 'column',
		width: '40%'
	},
	firstCell: {
		borderLeft: 1
	},
	cellRight: {
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: -1,
		height: 20,
	},
	cellLeft: {
		height: 20,
		alignItems: 'center'
	},
	headRow: {
		backgroundColor: '#aaaaaa',
		borderTop: 1,
		height: 34.5,
		alignItems: 'center',
		justifyContent: 'center'
	},
	headText: {
		fontSize: 7,
		fontWeight: 'bold',
		marginTop: 2,
	},
	text: {
		fontSize: 9,
		fontWeight: 'normal',
		marginLeft: 3,
	},
	rightText: {
		fontSize: 9,
		fontWeight: 'bold',
		textAlign: 'center'
	},
})

interface Props {
	active: Informe
}

export const Jornada = ({ active }: Props) => {
	return (
		<View style={[styles.column]}>
			<View style={[styles.rowContainer, styles.borderRowEnd, styles.headRow]}>
				<Text style={[styles.headText]}>Jornada de Trabajo (Horas)</Text>
			</View>
			<View style={[styles.rowContainer]}>
				<View style={[styles.leftColumn]}>
					<LeftCell text='Entrada' />
					<LeftCell text='Salida' />
					<LeftCell text='Total horas trabajadas' bottom />
				</View>
				<View style={[styles.rightColumn]}>
					<RightCell text={getHora(active.fechaHoraInicio!)} />
					<RightCell text={getHora(active.fechaHoraFinal!)} />
					<RightCell text={getDurationHhMm(active.fechaHoraInicio!,active.fechaHoraFinal!)} bottom />
				</View>
			</View>
		</View>
	)
}


interface CellProps {
	text: string,
	bottom?: boolean
}

const LeftCell = ({ text, bottom = false }: CellProps) => {
	const border = bottom ? styles.borderRowBottomStart : styles.borderRow;
	return (
		<View style={[styles.rowContainer, border, styles.cellLeft]}>
			<Text style={[styles.text]}>{text}</Text>
		</View>
	)
}

const RightCell = ({ text, bottom = false }: CellProps) => {
	const border = bottom ? {} : styles.borderRowEnd;
	return (
		<View style={[styles.rowContainer, styles.cellRight, border]}>
			<Text style={[styles.rightText]}>{text}</Text>
		</View>
	)
} 
