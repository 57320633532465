import { Grid } from '@mui/material'
import { Title } from '../../../../components/title/Title'
import { MapComponent } from './MapComponent'

export const MapHeader = () => {
    return (
        <>
            <Grid container sx={{ mt: 1, mb: 1 }}>
                <Grid item xs={12}>
                    <Title>
                        Servicios
                    </Title>
                    <MapComponent />
                </Grid>
            </Grid>
        </>
    )
}
