import { useEffect } from 'react'
import { Container, Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { Sst } from './Sst';
import { startLoadInformesSst } from '../actions/sst';



export const SstScreen = () => {
  const dispatch = useDispatch();
  const { informesSst } = useSelector((state: AppState) => state.sst.sst);

  useEffect(() => {
    if(informesSst.length !== 0) return;    
    dispatch(startLoadInformesSst());
  }, [dispatch, informesSst]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Sst />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}