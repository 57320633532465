import { View, StyleSheet, Image } from '@react-pdf/renderer';
import { getImagen } from '../../../../../helpers/imagen';
import { Imagen } from '../../../../../interfaces/image';


const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
	},
	column: {
		flexDirection: 'column',
		padding: 5,

	},
	totalColumn: {
		height: '100%',
		width: '99.7%',
	},
	rowContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '100%',
		flexWrap: 'wrap'
	},
	image: {
		padding: 5,
		borderRadius: 10,
		objectFit: 'fill',
	}
});

interface Props {
	grupo: Imagen[];
	sx?: {
		bw?: number
	};
	imgMaxH?: number;
	imgMaxW?: number;
	imgMinH?: number;
	contMinH?: number
}
export const BodyImagenLines = ({
	grupo,
	imgMaxH,
	imgMaxW,
	imgMinH,
	contMinH,
	sx

}: Props) => {
	const { bw } = sx ?? {
		bw: 3
	}
	return (
		<>
			<View style={[styles.row]}>
				<View style={[styles.column, styles.totalColumn, {
					borderBottom: bw,
					borderLeft: bw,
					borderRight: bw,
				}]}>
					<View style={[styles.rowContainer, { minHeight: contMinH ?? 122 }]}>
						{
							grupo.map((imagen) => {
								return <View key={imagen.id} wrap={false}>
									<Image src={() => getImagen(imagen.name!.toString())}
										style={[
											styles.image, {
												maxHeight: imgMaxH ?? 150,
												maxWidth: imgMaxW ?? 200,
												minHeight: imgMinH ?? 120
											}
										]} />
								</View>
							})
						}
					</View>
				</View>
			</View>
		</>
	)
}
