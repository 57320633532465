import { useMap } from '../../hooks/useMap';

export const MapComponent = () => { 

  const { mapContainer } =useMap();
  return (
    <div ref={mapContainer} className="map-container" ></div>
  )

}
