import { View, StyleSheet } from "@react-pdf/renderer";

import { Reporte } from '../../../interfaces/reportes';
import { getDurationHhMmSs, fechaHora } from '../../../../../helpers/fecha';
import { Cell } from "../../../../../interfaces/shared";
import { HeadItems } from "../../../../../components/pdf/HeadItems";
import { RowItems } from "../../../../../components/pdf/RowItems";

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const namesUno = ["Cliente:", "Dirección Cliente:", "Dirección Contacto:"];
const namesDos = ["Contacto:", "Referencia:", "Técnico:"];
const namesTres = ["Colaboradores:", "Servicio:", "Duración:"];
const namesCuatro = ["Fecha de Inicio:", "Fecha de Finalización:", ""];

interface Props {
	active: Reporte;
}
export const Header = ({ active }: Props) => {
	const filaUno: Cell[] = [
		{name: active.servicio?.cliente.nombre!},
		{name:
			`${active.servicio?.cliente.direccion.description!}`},
		{name: `${active.servicio.contacto.direccion}`},
		];

	const filaDos: Cell[] = [		
		{name: active.servicio?.contacto.nombres},
		{name: active.servicio?.contacto.referencia?active.servicio?.contacto.referencia: 'Sin referencia'},
		{name:
			`${active.servicio?.tecnico.name} ${active.servicio?.tecnico.lastname}`},
		];
	
	const filaTres: Cell[] = [
		{name: active.servicio.colaboradores.length > 0 ? active.servicio.colaboradores!
			?.map((item) => {
				return item;
			})
			.join(", "): 'Sin Asignar'},
		{name: active.servicio.tipo.name},
		{name: getDurationHhMmSs(active.fechaHoraInicio!, active.fechaHoraFinal!)},		
	];

	const filaCuatro: Cell[] = [
		{name: fechaHora(active.fechaHoraInicio!)},
		{name: fechaHora(active.fechaHoraFinal!) },
		{name: ''}
	]

	return (
		<>
			<View style={styles.headerContainer}>
				<HeadItems names={namesUno} />
				<RowItems columnas={filaUno} />
			</View>
			<View style={[styles.headerContainer, {marginTop: 3}]}>
				<HeadItems names={namesDos} />
				<RowItems columnas={filaDos} />
			</View>
			<View style={[styles.headerContainer, {marginTop: 3}]}>
				<HeadItems names={namesTres} />
				<RowItems columnas={filaTres} />
			</View>
			<View style={[styles.headerContainer, {marginTop: 3}]}>
				<HeadItems names={namesCuatro} />
				<RowItems columnas={filaCuatro} />
			</View>
		</>
	);
};

