
import { FormReporteScreenEmergencia } from './FormReporteScreenEmergencia'
import { useModalReporteEmergencia } from '../../hooks/useModalReporteEmergencia';
import { CustomDialog } from '../../../../components/ui';

export const ModalReporteEmergencia = () => {

  const {
    err,
    fullscreen,
    modalReporteEmergencia,
    handleClose,
    handleSave,
    onChange,
    onDateChange,
    registerData,
    registerDataDate } = useModalReporteEmergencia();
  return (
    <CustomDialog
      onClose={handleClose}
      onSave={handleSave}
      openModal={modalReporteEmergencia}
      err={err}
      fullscreen={fullscreen}
      title="Formulario Emergencia"
    >
      <FormReporteScreenEmergencia onChange={onChange}
        onDateChange={onDateChange}
        registerData={registerData}
        registerDataDate={registerDataDate} />
    </CustomDialog>
  )
}
