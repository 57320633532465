//import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// const borderColor = '#757575'
const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		height: 16,
		textAlign: 'left',
		color: '#9e9e9e',
		width: '100%'
	},
	cellContainer: {
		flexDirection: 'row',
		borderLeft: 1,
		height: 16,
	},	
	cellContainerEnd: {
		borderRight: 1,
	},
	cell: {
		backgroundColor: '#dddddd',
		width: '100%'
	},
	textContainer: {
		paddingLeft: 5
	}
});

interface Props {
	names: string[],
	top?: boolean
}
export const HeadItems = ({ names, top }: Props) => {
	const length = names.length;
	const width = 100 / length;
	return (
		<View style={styles.container}>
			{names.map((name, index) => {
				const cellStyles = cellStyle(index, length, top);
				return (
					<View style={[cellStyles, { width: `${width}%` }]} key={index}>
						<View style={[styles.cell]}>
							<Text style={styles.textContainer}>{name}</Text>
						</View>
					</View>
				)
			})}
		</View>
	)
}

function cellStyle(index: number, length: number, top: boolean = true) {

	const cell = {
		...styles.cellContainer,
		borderTop: top? 1 : ''
	}

	const cellEnd = {
		...styles.cellContainer,
		...styles.cellContainerEnd,
		borderTop: top? 1 : ''
	}

	if (length === index + 1) {
		return cellEnd
	} else {
		return cell
	}

}