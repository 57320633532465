
/* export const startAddImages = (image: Imagen) => {
	return async(dispapach: any)=>{
		dispapach(addImagen)
	}
} */

import { Imagen } from "../../../interfaces/image"
import { SelloFisicoAction } from "../interfaces/images"

export const startAddSelloFisico =(imagen: Imagen): SelloFisicoAction =>({
	type: 'create-sello-images',
	payload: imagen
})

export const startSetModalSelloFisico = (modal: boolean):SelloFisicoAction =>({
	type: 'set-modal-sello-image',
	payload: modal
})

export const startClearSelloFisico = (): SelloFisicoAction =>({
	type:'clear-sello-image-state'
})

export const startDeleteSelloFisico = (): SelloFisicoAction =>({
	type:'clear-sello-image-state'
})