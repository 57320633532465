import { TipoActividadAction, TipoActividadState } from "../interfaces/interface"

const initState: TipoActividadState ={
	tipoActividad:[],
}

export const useReducerTipoActividad = (state = initState, actions: TipoActividadAction): TipoActividadState => {
	switch (actions.type) {
		case 'set-tipo-actividad':
			return{
				...state,
				tipoActividad: actions.payload
			}

		case 'clear-tipo-actividad':
			return{
				...state,
				tipoActividad:[]
			}			
	
		default:
			return state;
	}
  
}
