import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startLogin } from '../modules/auth/actions/auth';
import { LoginState } from '../interfaces/auth';
import { useScreenSize } from './useScreenSize';


export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height } = useScreenSize();

  const [values, setValues] = useState<LoginState>({
    email: "",
    password: "",
    showPassword: false,
  });

  const onChange =
    (prop: keyof LoginState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(startLogin(values.email, values.password));
    navigate("/", { replace: true });
  };
  return {
    height,
    values,
    onChange,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleSubmit,
  };
};
