import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { useModalActividad } from '../hooks/useModalActividad';
import { CustomCardImage } from '../../../../../components/ui';

export const ModalActividad = () => {
    const { descripcion, foto, imgWidth, modalActividad, handleClose, handelLoadImage, handleSave } = useModalActividad();

    return (
        <>
            <Dialog open={modalActividad} onClose={handleClose} maxWidth="xs" fullWidth>
                <DialogTitle>Agregar Imagén</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CustomCardImage
                        foto={foto}
                        imgWidth={imgWidth}
                        descripcion={descripcion}
                        onChange={handelLoadImage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
