import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid';


import { AppState } from '../../../reducers/rootReducer';
import { startSetActividad } from '../action/actividad';
import { EditToolbarProps } from '../../../interfaces/shared';

export const EditToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
    const { actividades } = useSelector((state: AppState) => state.shared.actividades);
    const { setRowModesModel } = props;

    const handleClick = () => {
        let id: number;
        if (actividades.length === 0) {
            id = 1;
        } else {
            id = actividades[actividades.length - 1].id + 1;
        }
        dispatch(startSetActividad({ id, actividad: '',unidad: '', cantidad: 0, observaciones: '', isNew: true }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'actividad' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar
            </Button>
        </GridToolbarContainer>
    );
}