import Box from '@mui/material/Box';
import {    
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    esES,
} from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useListaTable } from '../hooks/useListaTable';
import { ListaToolBar } from './ListaToolbar';
import { ModalLista } from './ModalLista';
import { AlertLista } from './AlertLista';

export const ListasTable = () => {
	const {
        isAdmin,
        listas,
        columns,        
        rowModesModel,
        handleEditClick,
        handleDeleteClick,
        handleViewClick,
        processRowUpdate,
        setRowModesModel,
    } = useListaTable(); 

	const columnsGrid: GridColDef[] = [
        ...columns,       
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex:1,
            cellClassName: 'actions',
            getActions: ({ id }) => {   
                const deleteItem = isAdmin 
                ? <GridActionsCellItem
                icon={<Tooltip title="Borrar lista"><DeleteIcon color='error' /></Tooltip>}
                label="Eliminar"
                onClick={handleDeleteClick(id)}
                color="inherit"
                /> 
                : <></>  
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Ver"><SearchIcon color='secondary' /></Tooltip>}
                        label="Ver"
                        className="textPrimary"
                        onClick={handleViewClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon color='secondary' /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    deleteItem
                ];
            },
        },
    ];

  return (
	<Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={listas.slice(0, listas.length)}
                columns={columnsGrid}
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                rowModesModel={rowModesModel}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: ListaToolBar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }}   
                localeText={{...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay datos para mostrar'}}
                rowHeight={35}
                pagination={true}               
                pageSizeOptions={[5, 10, 30]}
            />
             <ModalLista />
            <AlertLista />         
        </Box>
  )
}
