import { AppBar, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

interface Props {
	title: string,
	handleClose: () => void;
	handleSave?: () => void;
	pdf?: boolean,
	err?: boolean
}

export const ModalBar = ({ handleClose, handleSave, title, pdf = false, err= false}: Props) => {
	
	return (
		<AppBar sx={{ position: 'relative' }}>
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					onClick={handleClose}
					aria-label="close"
				>
					<Tooltip title="Cancelar"><CloseIcon /></Tooltip>
				</IconButton>
				<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
					{title}
				</Typography>
				{
					!pdf && (
						<IconButton
							edge="end"
							color="inherit"
							onClick={handleSave}
							aria-label="close"
							disabled={err}
						>
							<Tooltip title="Guardar"><SaveIcon /></Tooltip>
						</IconButton>
					)
				}
			</Toolbar>
		</AppBar>
	)
}
