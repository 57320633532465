import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { v4 as UUID } from 'uuid';

import { startSetpersonalExterno } from '../actions/personal';
import { EditToolbarProps } from '../../../interfaces/shared';



export const PersonalExternoTableToolBar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
    const { setRowModesModel } = props;

    const handleClick = () => {
        let id =UUID();
        dispatch(startSetpersonalExterno({ id, nombres: '', identificacion: '', cargo: '', isNew: true }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nombres' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar Personal Externo
            </Button>
        </GridToolbarContainer>
    );
}
