import { Avatar, Box, Typography } from '@mui/material';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { ObrasTable } from './ObrasTable';

export const Obras = () => {    

    return (
        <>
            <Box
                sx={{
                    my: 1,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <ArticleOutlinedIcon />
                </Avatar>
                <Typography component="h1" align='center' variant="h6">
                    Obras
                </Typography>
            </Box>
            <ObrasTable />
        </>
    )
}