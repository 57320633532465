import { GridRowId } from "@mui/x-data-grid";
import { Sitio, SitioAction } from "../interfaces/sitio";

/**
 * 
 * @param sitio 
 * @returns SitioAction
 * 
 * Action para agregar un item del sitio
 */

export const startSetSitioInfo = (sitio: Sitio): SitioAction => ({
    type: "set-sitio-info",
    payload: sitio
});

/**
 * 
 * @param sitio 
 * @returns SitioAction
 * 
 * Action para actualizar un item del sitio
 */

export const startUpdateSitioInfo = (sitio: Sitio): SitioAction => ({
    type: "update-sitio-info",
    payload: sitio
});

/**
 * 
 * @param id 
 * @returns SitioAction
 * 
 * Action para eliminar un item del sitio
 */

export const startDeleteSitioInfo = (id: GridRowId): SitioAction => ({
    type: "delete-sitio-info",
    payload: id
});

/**
 * 
 * @param id 
 * @returns SitioAction
 * 
 * Action para editar un item sitio
 */
export const startEditSitioInfo = (id: GridRowId): SitioAction => ({
    type: "edit-sitio-info",
    payload: id
});

/**
 * 
 * @returns SitioAction
 * 
 * Action para eliminar los items del sitio
 */
export const startSetSitioEmpty = (): SitioAction => ({
    type: "set-sitio-empty"
});
