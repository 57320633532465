import { Responsable } from "../../../interfaces/shared";
import { ResponsableAction } from "../interfaces/recibe";

export const startSaveFirmaCliente = (responsable: Responsable): ResponsableAction => ({
    type: 'set-responsable-firma-cliente-reporte',
    payload: responsable
})

export const startUpdateResponsableCliente = (responsable: Responsable): ResponsableAction => ({
    type: 'update-responsable-cliente-reporte',
    payload: responsable,
});

export const startSetModalFirmaCliente = (modal: boolean): ResponsableAction => ({
    type: 'set-modal-firma-cliente-reporte',
    payload: modal,
});

export const startSetResponsableClienteEmpty = (): ResponsableAction => ({
    type: 'set-responsable-cliente-empty-reporte',
});