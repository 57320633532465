import { Paper, Box, Grid } from '@mui/material';
import { LoginHeader } from './LoginHeader';
import { LoginForm } from './LoginForm';
import { LoginFooter } from './LoginFooter';
import { useLogin } from '../../hooks/useLogin';

export const LoginMain = () => {
  const {
    height,
    values,
    onChange,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleSubmit,
  } = useLogin();

  return (
    <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: height > 430 ? 8: 1,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LoginHeader />
        <LoginForm
          values={values}
          onChange={onChange}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          handleSubmit={handleSubmit}
        />
      </Box>
      {height > 390 && <LoginFooter /> }
    </Grid>
  );
};
