import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import { Contactos } from '../../contactos/components/Contactos';
import { useClienteDetail } from '../hooks/useClienteDetail';
import { ModalCliente } from './ModalCliente';


export const ClienteDetail = () => {
  const { avatarLetter, bgcolor, contactos, handleEditCliente, nombre, direccion, telefono, contactoPrincipal } = useClienteDetail();

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight: '70vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                my: 1,
                mx: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              
              <Card sx={{width: '100%'}} elevation={0}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ m: 1, bgcolor }}>
                    {avatarLetter}
                    </Avatar>
                  }
                  title={nombre}
                />
                <CardContent>
                  <List dense disablePadding>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar sx={{bgcolor}}>
                    {avatarLetter}
                    </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={nombre} secondary='Cliente'/>
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar children={<LocationOnOutlinedIcon />}/>
                      </ListItemAvatar>
                      <ListItemText primary={direccion?.description} secondary='Dirección'/>
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar children={<PhoneEnabledOutlinedIcon />}/>
                      </ListItemAvatar>
                      <ListItemText primary={telefono} secondary='Teléfono'/>
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar children={<PersonOutlineOutlinedIcon />}/>
                      </ListItemAvatar>
                      <ListItemText primary={contactoPrincipal} secondary='Contacto Principal'/>
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={handleEditCliente}>Editar</Button>
                </CardActions>
              </Card>
              <ModalCliente />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Contactos contactos={contactos} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}
