import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducers/rootReducer';
import { Dialog, DialogContent } from '@mui/material';
import { Transition } from '../../../../components/ui/Transition';
import { ModalBar } from '../../../../shared/modal/ModalBar';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { ButtonDownload } from '../../../../components/ui/ButtonDownload';
import { Loading } from '../../../../components/ui/Loading';
import { ModalActions } from '../../../../shared/modal/ModalActions';
import { InformeSstPdf } from './InformeSstPdf';
import { startSetModalInformePdfSst } from '../../actions/sst';
import { dateShortThree, toString } from '../../../../helpers';
import { startSetInactiveObra } from '../../../obras/actions/obras';


export const ModalInformeSstPdf = () => {
  const dispatch = useDispatch();
  const { activeSst, modalInformePdfSst } = useSelector(
    (state: AppState) => state.sst.sst);

  // eslint-disable-next-line no-restricted-globals
  const windowWidth = screen.width;
  const fileName = `INFORME DIARIO-BELING-${toString(activeSst?.numero!)}-${dateShortThree(activeSst?.fecha!)}`; 
  
  const handleClose = () => {
    dispatch(startSetModalInformePdfSst(false));
    dispatch(startSetInactiveObra());
  };
  return (
    <>
      <Dialog
        open={modalInformePdfSst}
        onClose={handleClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <ModalBar
          handleClose={handleClose}
          title={fileName}
          pdf={true}
        />
        <DialogContent>
          {windowWidth > 1024 ? (
            <PDFViewer style={{ width: "100%", height: "82vh" }} key={fileName}>
              {<InformeSstPdf active={activeSst!} title={fileName} />}
            </PDFViewer>
          ) : (
            <PDFDownloadLink document={<InformeSstPdf active={activeSst!} title={fileName} />} fileName={fileName}>
              {({ blob, url, loading, error }) =>
                loading ? <Loading /> : <ButtonDownload />
              }
            </PDFDownloadLink>
          )}
        </DialogContent>
        <ModalActions handleClose={handleClose} pdf={true} />
      </Dialog>
    </>
  );
};
