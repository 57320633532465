import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { AppState } from '../../../reducers/rootReducer';
import { Title } from '../../../components/title/Title';



export const ServicioDetail = () => {
  const { activeServicio } = useSelector((state: AppState) => state.servicios);
  return (
    <Box sx={{ width: '100%', display: 'flex', alignContent:'center' }}>
      <Paper sx={{ minHeight: '85vh', width: '100%', mt: 1, mx: 2}}>
        <Title variant='h4' marginTop={5}>
          Servicio
        </Title>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
            <Title>Número del servicio</Title>
              <Typography variant="body2" sx={{ mt: 1}}>
                {activeServicio!.numero}
              </Typography>
            </Box>
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
            <Title>Cliente</Title>
              <Typography variant="body2" gutterBottom sx={{ mt: 2}}>
                {activeServicio!.cliente}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
              <Title>
                Tipo de servicio
              </Title>
              <Typography variant="body2" gutterBottom sx={{ mt: 2}}>
                {activeServicio!.tipo}
              </Typography>
            </Box>
            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
              <Title>
                Técnico
              </Title>
              <Typography variant="body2" gutterBottom sx={{ mt: 2}}>
                {activeServicio!.tecnico}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Title>
                Descripción
              </Title>
              <Typography variant="body2" gutterBottom sx={{ mt: 2}}>
                {activeServicio!.descripcion}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
