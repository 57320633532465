import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModesModel,
  GridRowModes,
  GridRowParams,
  MuiEvent,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  esES,
  GridColDef,
  DataGrid,
} from "@mui/x-data-grid";

import { AppState } from "../../../../../reducers/rootReducer";
import { startLoadBateriasInfo } from "../../../../resources/actions/resources";
import {
  startSetBateriasInfo,
  startUpdateBateriasInfo,
} from "../actions/baterias";
import { Bateria } from "../interfaces/baterias";

export const BateriasTable = () => {
  const locale = esES.components.MuiDataGrid.defaultProps.localeText;
  const dispatch = useDispatch();
  const { baterias } = useSelector((state: AppState) => state.resources);
  const { bateriasInfo } = useSelector(
    (state: AppState) => state.reportes.modules.baterias
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  useEffect(() => {
    if (baterias.length === 0) {
      dispatch(startLoadBateriasInfo());
    }
    if (bateriasInfo.length > 0) {
      return;
    }
    baterias.map((b) => {
      return dispatch(
        startSetBateriasInfo({
          id: b.id,
          referencia: b.referencia,
          detalle: b.detalle,
          isNew: false,
        })
      );
    });
  }, [dispatch, baterias, bateriasInfo]);

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: GridRowModel<Bateria>) => {
    const updatedRow = { ...newRow, isNew: false };
    dispatch(startUpdateBateriasInfo(updatedRow));
    return updatedRow;
  };

  const columns: GridColDef[] = [
    {
      field: "referencia",
      flex: 2,
      headerName: "Item",
      editable: false,
      sortable: false,
      hideable: false,
    },
    {
      field: "detalle",
      headerName: "Detalle",
      flex: 2,
      editable: true,
      sortable: false,
      hideable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Guardar"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancelar"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Editar"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        autoHeight
        columns={columns}
        editMode="cell"
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } }
        }}
        localeText={{ ...locale, noRowsLabel: "No hay datos para mostrar" }}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        rowHeight={35}
        rowModesModel={rowModesModel}
        rows={bateriasInfo}
        pagination={true}
        pageSizeOptions={[5, 10, 20]}
      />
    </Box>
  );
};
