import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,   
    GridRowParams,
    MuiEvent,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';

import { AppState } from '../../../../../reducers/rootReducer';
import { startDeleteMedicionBateria, startSetMedicionBateria, startUpdateMedicionBateria } from '../actions/medicion';
import { EditToolbarProps } from '../../../../../interfaces/shared';
import { useScreenSize }from '../../../../../hooks';
import { MedicionBateria } from '../interfaces/medicion';

function EditToolbar(props: EditToolbarProps) {
    const dispatch = useDispatch();
    const { medicionesBateria } = useSelector((state: AppState) => state.reportes.modules.medicionesBaterias);
    const { setRowModesModel } = props;

    const handleClick = () => {
        let id: number;
        if (medicionesBateria.length === 0) {
            id = 1;
        } else {
            id = medicionesBateria[medicionesBateria.length - 1].id + 1;
        }
        dispatch(startSetMedicionBateria({ id, numeroBanco: '', numeroBateria: '', medicion: '', impedancia: '', porcentajeSulfatacion: '', isNew: true }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'numeroBanco' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar
            </Button>
        </GridToolbarContainer>
    );
}

export const MedicionesBBTable = () => {
    const { width } = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText
    const dispatch = useDispatch();
    const { medicionesBateria } = useSelector((state: AppState) => state.reportes.modules.medicionesBaterias);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});    

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteMedicionBateria(id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel<MedicionBateria>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateMedicionBateria(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'numeroBanco',
            headerAlign: 'center',
            headerName: 'No. Banco',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true
        },
        {
            field: 'numeroBateria',
            headerAlign: 'center',
            headerName: 'No. de Batería',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true
        },
        {
            field: 'medicion',
            headerAlign: 'center',
            headerName: 'Medición [Vdc]',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true,
        },
        {
            field: 'impedancia',
            headerAlign: 'center',
            headerName: 'Impedancia [Ohmios]',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true,
        }, {
            field: 'porcentajeSulfatacion',
            headerAlign: 'center',
            headerName: 'Porcentaje de Sulfatación [%]',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true,
        },
        {
            field: 'actions',
            headerAlign: 'center',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={medicionesBateria}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}                
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }} 
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                rowHeight={35}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
        </Box>
    );
}