import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { ActividadTable } from './ActividadTable';
import { StyleProps } from '../../../interfaces/reportes';

const Table = memo(() => {
    return (
        <ActividadTable />
    );
});

export const ActividadNoComtemplada = ({mt= 4, mb=1 }:StyleProps) => {
    return (
        <>
            <Grid container sx={{ mt, mb }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                    Actividades NO CONTEMPLADAS
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}
