import { ObraAction, ObraState } from '../interfaces/obras.interface';

const initState: ObraState = {
    activeObra: null,
    obras: [],
    obra: null,
    modalObra: false,
    modalReporteObra: false,
}

export const obraReducer = (state = initState, action: ObraAction): ObraState => {

    switch (action.type) {
        case 'load-obras':
            return {
                ...state,
                obras: action.payload
            }
        case 'create-obras':
            return {
                ...state,
                obras: [...state.obras, action.payload]
            }
        case 'update-obras':
            return {
                ...state,
                obras: state.obras.map(
                    o => (o.id === action.payload.id) ? action.payload : o
                )
            }
            case 'delete-obras':
                return {
                    ...state,
                    obras: state.obras.filter(o => o.id !== action.payload)
                }
        case 'set-modal-obra':
            return {
                ...state,
                modalObra: action.payload
            }
        case 'set-modal-grupo-reporte':
            return {
                ...state,
                modalReporteObra: action.payload
            }
        case 'set-active-obra':
            const obra = state.obras.find(o => o.id === action.payload);
            return {
                ...state,
                activeObra: obra ? obra : null
            }
        case 'set-inactive-obra':
            return {
                ...state,
                activeObra: null
            }
        case 'load-obra':
            return {
                ...state,
                obra: action.payload
            }
        default:
            return state
    }

}