import { Page, Document, Text } from '@react-pdf/renderer';

import { Reporte } from '../../interfaces/reportes';
import { HerramientasTable } from './table/HerramientasTable';
import { TitleTables } from '../../../../components/pdf/TitleTables';
import { Header } from './header/Header';

import { Detalle } from './detalle/Detalle';
import { VehiculoTable } from './vehiculo/VehiculoTable';
import { MaterialTable } from './material/MaterialTable';
import { PersonalBelingTable } from './personal_beling/PersonalBelingTable';
import { PersonalClienteTable } from './personal_claro/PersonalClienteTable';
import { RutinaRectificadorTable } from './rutina_rectificador/RutinaRectificadorTable';
import { InfoBateriasTable } from './info_baterias/InfoBateriasTable';
import { InfoRectificadorTable } from './info_rectificador/InfoRectificadorTable';
import { InfoInversorTable } from './info_inversor/InfoInversorTable';
import { CorrienteRectificadorTable } from './corriente_rectificador/CorrienteRectificadorTable';
import { VoltajeRectificadorTable } from './voltaje_rectificador/VoltajeRectificadorTable';
import { RutinaBancoBateriasTable } from './rutina_banco_bateria/RutinaBancoBateriasTable';
import { MedicionesBateriasTable } from './mediciones_baterias/MedicionesBateriasTable';
import { RutinaAlarmasTable } from './rutina_arlarma/RutinaAlarmasTable';
import { ActividadesNoPlaneadasTable } from './actividades_no_planeadas/ActividadesNoPlaneadasTable';
import { Footer } from './footer/Footer';
import { styles } from '../../../../styles/pdfStyles';
import { LogoHeader } from './shared/LogoHeader';
import { ImagesRutinaRectificador } from './rutina_rectificador/ImagesRutinaRectificador';
import { ImagesRutinaBancoBaterias } from './rutina_banco_bateria/ImagesRutinaBancoBaterias';
import { ImagesRutinaAlarma } from './rutina_arlarma/ImagesRutinaBancoBaterias';
import { ImagesActividaesNoPlaneadas } from './actividades_no_planeadas/ImagesActividaesNoPlaneadas';
import { hasImages } from '../../../../helpers';

interface Props {
  active: Reporte;
}

// Create Document Component
export const ReportePdf = ({ active }: Props) => {

  if (!active) {
    return <Document title='No data'>
      <Page size={'LETTER'}></Page>
    </Document>
  }

  return (<Document title={`Reporte Servicio No: ${active.servicio?.numero}`}>
    <Page size="LETTER" style={styles.page} wrap >
      <LogoHeader active={active} />
      <Header active={active} />
      <Detalle field={active.alcanceActividad!} title='Actividad:' marginTop={5} />
      <VehiculoTable active={active} />
      <MaterialTable active={active} />
      <HerramientasTable active={active} />
      <PersonalBelingTable active={active} />
      <PersonalClienteTable active={active} />
      <RutinaRectificadorTable active={active} />
      {hasImages(active.rutinaRectificador!) > 0 &&
        <ImagesRutinaRectificador active={active} />}
      <VoltajeRectificadorTable active={active} />
      <CorrienteRectificadorTable active={active} />
      <InfoRectificadorTable active={active} />
      <InfoBateriasTable active={active} />
      <RutinaBancoBateriasTable active={active} />
      {hasImages(active.rutinaBancoBateria!) > 0 &&
        <ImagesRutinaBancoBaterias active={active} />}
      <MedicionesBateriasTable active={active} />
      <InfoInversorTable active={active} />
      {
        active.rutinaAlarmas?.length! > 0 ? (
          <>
            <RutinaAlarmasTable active={active} />
            {hasImages(active.rutinaAlarmas!) > 0 &&
              <ImagesRutinaAlarma active={active} />}
          </>
        ) : <TitleTables title='' />
      }
      {active?.actividadesNoPlaneadas?.length! > 0
        && (<>
          <ActividadesNoPlaneadasTable active={active} />
          {hasImages(active.actividadesNoPlaneadas!) > 0 &&
            <ImagesActividaesNoPlaneadas active={active} />}
        </>
        )}
      <Detalle field={active.observaciones!} title='Concluciones y/o Recomendaciones:' marginTop={5} />
      <Footer active={active} />
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Página ${pageNumber} de  ${totalPages} páginas`
      )} fixed />
    </Page>
  </Document>
  )
};