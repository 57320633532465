import { GridRowId } from '@mui/x-data-grid';
import { Imagen } from '../../../../../interfaces/image';
import { ImagesFinAction } from '../interfaces/images';

/* export const startAddImages = (image: Imagen) => {
	return async(dispapach: any)=>{
		dispapach(addImagen)
	}
} */

export const startAddImagesFin =(imagen: Imagen): ImagesFinAction =>({
	type: 'create-fin-images',
	payload: imagen
})

export const startSetModalImageFin = (modal: boolean):ImagesFinAction =>({
	type: 'set-modal-fin-image',
	payload: modal
})

export const startDeleteImageFin = (payload: GridRowId): ImagesFinAction =>({
	type: 'delete-fin-images',
	payload
})

export const startClearImagesFin = (): ImagesFinAction =>({
	type: 'clear-fin-image-state'
})