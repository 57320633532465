import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";

interface Props {
 value: string;
 values: any[];
 label: string,
 name: string,
 index: number,
 isDisabled?: boolean,
 onChange: (e: SelectChangeEvent<string>) => void;
}



export const SelectComponent = ({isDisabled = false, label, name,index, value, values, onChange}: Props) => {
	return (
		<FormControl fullWidth sx={{ mt: 1, mx: 1 }} disabled={isDisabled}>
			<InputLabel id={`${name}-select-label`}>{label} *</InputLabel>
			<Select
				labelId={`${name}-select-label`}
				id={name}
				value={value || ''}
				defaultValue=""
				label={label}
				name={name}
				required
				margin="dense"
				onChange={onChange}
				inputProps={{ tabIndex: index }}
			>
				<MenuItem disabled value="">
					<em>Seleccionar {label}</em>
				</MenuItem>
				{values.map((v) => (
					<MenuItem
						key={v.id}
						value={v.id}
					//style={getStyles(name, personName, theme)}
					>
						{v.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
