import { Avatar, Box, Typography } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { ListasTable } from './ListasTable'

export const Listas = () => {
	return (
        <>
            <Box
                sx={{
                    my: 1,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <FormatListBulletedIcon />
                </Avatar>
                <Typography component="h1" align='center' variant="h6">
                    Listas 
                </Typography>
            </Box>
            <ListasTable />
        </>
    )
}
