import { GridRowId } from '@mui/x-data-grid';
import { ImagesAction , Image} from '../interfaces/images';
import { ImageResponse } from '../../../interfaces/image';


export const startSetImagenActividad = (actividad: Image): ImagesAction => ({
  type: 'set-image-actividad',
  payload: actividad,
});

export const startUpdateImageActividad = (actividad: Image): ImagesAction => ({
  type: 'update-images-actividad',
  payload: actividad
})

export const startSetModalImagen = (imagen: boolean): ImagesAction => ({
  type: 'set-modal-image-actividad',
  payload: imagen
})

export const startSetUpdateImage = (image: ImageResponse, id: GridRowId): ImagesAction => ({
  type: 'set-image-actividad-update-image',
  payload: { image, id },
});

export const startSetActiveImageActividad = (id: GridRowId): ImagesAction => ({
  type: 'set-images-actividad-active',
  payload: id
})

export const startDeleteImagenActividad = (id: GridRowId): ImagesAction => ({
  type:'delete-images-actividad',
  payload: id
})

export const startSetUpdateImagenFromDB = (image: ImageResponse, id: GridRowId): ImagesAction => ({
  type: 'set-image-actividad-update-image',
  payload: { image, id },
});

export const startClearImagenActividad = ():ImagesAction =>({
  type: 'set-images-actividad-emty'
})