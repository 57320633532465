import { GridRowId } from '@mui/x-data-grid';
import { fetchConToken } from '../../../apis/fetch';
import { doMessage, startLoading } from '../../ui/actions/ui'
import { Item, ItemAction, ItemResponse } from '../interfaces/item';


export const startLoadItems = ()=>{
	return async(dispatch: any)=>{
		dispatch(startLoading());
		const resp = await fetchConToken('items_lista', {});
        const body: ItemResponse = await resp.json();
		const { errors, items, msg, ok } = body;
		if(ok){
			dispatch(loadItems(items));
		}else{			             
            if (msg) {
                dispatch(doMessage({message: msg, type: 'error'}));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }     
		}
		dispatch(startLoading())
	}
}

const loadItems = (items: Item[]):ItemAction =>({
	type: 'load-items',
	payload: items
})


export const startCreateItem = (newItem: Item) => {
    
    const itemToSave = {
        ...newItem,
        lista: newItem.lista.id,
    }
    
    return async(dispatch: any) =>{
        dispatch(startLoading());
        const resp = await fetchConToken('items_lista', itemToSave, 'POST');
        const body: ItemResponse = await resp.json();
        const {errors, item, msg, ok } = body;
        console.log(body)
        if(ok){
         dispatch(createItem(item));
         dispatch(doMessage({message: msg, type: 'success'}));
        }else{
            if (msg) {
                dispatch(doMessage({message: msg, type: 'error'}));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
        dispatch(startLoading());
    }
}

const createItem = (item: Item): ItemAction => ({
    type: 'create-item',
    payload: item
})

export const startUpdateItem = (updateItem: Item) => {
    const itemToSave = {
        ...updateItem,
        lista: updateItem.lista.id,
    }
    return async(dispatch: any, getState: any) =>{
        dispatch(startLoading())
        const id = getState().items.activeItem.id;
        if(!id){
            return dispatch(doMessage({message: 'No hay item activo', type: 'error'}));            
        }

        const resp = await fetchConToken(`items_lista/${id}`, itemToSave, 'PUT');
        const body: ItemResponse = await resp.json();
        
        const {errors, item, msg, ok } = body;
        if (ok) {
            dispatch(upDateItem(item));
            dispatch(doMessage({message: msg, type: 'success'}));
            dispatch(startSetActiveItem(''))
        } else {
            if (msg) {
                dispatch(doMessage({message: msg, type: 'error'}));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            } 
        }
        dispatch(startLoading())
    }
}

const upDateItem = (item: Item): ItemAction => ({
    type: 'update-item',
    payload: item
})


export const startDeleteItem = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().items.activeItem.id;
        if(!id){
            dispatch(startLoading());
            return dispatch(doMessage({message: 'No hay item activo', type: 'error'}));
        }
        const resp = await fetchConToken(`items_lista/${id}`, {}, 'DELETE');
        const body: ItemResponse = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {            
            dispatch(deleteItem(id));
            dispatch(startSetActiveItem(''));
            dispatch(doMessage({message: msg, type: 'success'}));            
        } else {            
            if (msg) {
                dispatch(doMessage({message: msg, type: 'error'}));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            } 
            
        }  

        dispatch(startLoading());      
    }
}

const deleteItem = (id: string): ItemAction => ({
    type: 'delete-item',
    payload: id
})


export const startSetActiveItem =(id: GridRowId):ItemAction => ({
    type: 'set-active-item',
    payload: id
})

export const startSetModalItem = (modal: boolean):ItemAction => ({
    type: 'set-modal-item',
    payload: modal
})

export const startSetAlertDeleteItem = (alert: boolean):ItemAction => ({
    type: 'set-alert-delete-item',
    payload: alert
})

export const startSetIsNewItem = (isNew: boolean): ItemAction => ({
    type: 'set-isnew-item',
    payload: isNew
})