import { GridRowId } from "@mui/x-data-grid";
import { PersonalClaroAction } from "../interfaces/personal";
import { Personal } from "../../../../../interfaces/shared";

export const startSetpersonalClaro = (personal: Personal): PersonalClaroAction => ({
    type: 'set-personal-claro',
    payload: personal,
});

export const startUpdatePersonalClaro = (personal: Personal): PersonalClaroAction => ({
    type: 'update-personal-claro',
    payload: personal,
});

export const startDeletepersonalClaro = (id: GridRowId) => ({
    type: 'delete-personal-claro',
    payload: id,
});

export const startSetPersonalClaroEmpty = (): PersonalClaroAction => ({
    type: 'set-personal-claro-empty',
});