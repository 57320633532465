import { AcompanaCliente, AcompanaAction } from '../interfaces/interface';

export const startUpdateAcompanaCliente = (acompana: AcompanaCliente): AcompanaAction => ({
    type: 'update-acompana-cliente-reporte',
    payload: acompana,
});


export const startSetAcompanaClienteEmpty = (): AcompanaAction => ({
    type: 'set-acompana-cliente-empty-reporte',
});