import { GridRowId } from "@mui/x-data-grid";
import { PersonalAction } from "../interfaces/personal";
import { Personal } from "../../../../../interfaces/shared";

export const startSetpersonal = (personal: Personal):PersonalAction => ({
    type: 'set-personal',
    payload: personal,
});

export const startUpdatePersonal = (personal: Personal):PersonalAction => ({
    type: 'update-personal',
    payload: personal,
});

export const startDeletepersonal = (id: GridRowId) => ({
    type: 'delete-personal',
    payload: id,
});

export const startSetPersonalEmpty = ():PersonalAction => ({
    type: 'set-personal-empty',
});