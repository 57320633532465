import { useState } from "react";
import { SelectChangeEvent } from "@mui/material";

export const useSelect =<T>(initState: T) => {

    const [selectData, setSelectData] = useState(initState);

    const onChange = (e: SelectChangeEvent<string>) => {
        setSelectData((prev: T) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }

    return {
        registerSelectData: selectData,
        changeSelect: setSelectData,
        onChangeSelect: onChange,
    }
}