import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { HeaderTable } from './HeaderTable';
import { TableRow } from './TableRow';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
    },
});

interface Props {
    active: Reporte;
}

const title = 'Describa las Herramientas Utilizadas en la Actividad:';

export const HerramientasTable = ({ active }: Props) => (
    <View>
        <TitleTables
            title='Herramientas utilizadas'
            sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
        />
        <TitleTables title={title} sx={{ fw: 'normal', fz: 10 }} />
        <View style={styles.tableContainer} wrap={false}>
            <HeaderTable />
            <TableRow items={active.herramientas!} />
        </View>
    </View>
);
