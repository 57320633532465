import { Alert, UIAction } from '../interfaces/ui';

export const toggleDrawer = (payload: boolean): UIAction => ({
    type: 'toggleDrawer',
    payload
})

export const closeDrawer = (): UIAction =>({
    type: 'drawer-close'
})

export const openDrawer = (): UIAction =>({
    type: 'drawer-open'
})
export const doMessage = (payload: Alert): UIAction => ({
    type: 'set-message',
    payload
})

export const doRemoveMessage = (): UIAction => ({
    type: 'remove-message'
})

export const startLoading = (): UIAction => ({
    type: 'set-loading'
})

export const setFormError = (error: boolean): UIAction =>({
    type: 'set-form-error',
    payload: error
})
