import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { AppState } from '../../../../../reducers/rootReducer';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { startUpdateVoltajeRectificador } from '../actions/voltaje';
import { Voltaje } from '../interfaces/voltaje';


export const VoltajeRectificadorTable = () => {
    const dispatch = useDispatch();
    const { voltajeRectificador } = useSelector((state: AppState) => state.reportes.modules.voltaje);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };    

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel<Voltaje>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateVoltajeRectificador(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            editable: true,
            field: 'l1_l2',
            filterable: false,
            flex: 2,
            headerAlign:'center',
            headerName: 'L1-L2',
            sortable: false,
        },
        {
            editable: true,
            field: 'l1_l3',
            filterable: false,
            flex: 2,
            headerAlign:'center',
            headerName: 'L1-L3',
            sortable: false,
        },
        {
            editable: true,
            field: 'l2_l3',
            filterable: false,
            flex: 2,
            headerAlign:'center',
            headerName: 'L2-L3',
            sortable: false,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        title="Editar"
                        type='button'
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                columns={columns}
                editMode="row"
                hideFooter
                localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay datos para mostrar' }}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                rowHeight={35}
                rowModesModel={rowModesModel}
                rows={voltajeRectificador.slice(0, voltajeRectificador.length)}
            />           
        </Box>
    );
}
