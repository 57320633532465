import { GridRowId } from "@mui/x-data-grid";
import { Actividad, ActividadObraAction } from "../interfaces/actividad";

export const startSetActividadObra = (actividad: Actividad): ActividadObraAction => ({
    type: 'set-actividad-obra-bdb',
    payload: actividad,
});

export const startUpdateActividadObra = (actividad: Actividad): ActividadObraAction => ({
    type: 'update-actividad-obra-bdb',
    payload: actividad
})

export const startSetModalActividadObra = (actvidad: boolean): ActividadObraAction => ({
    type: 'set-modal-actividad-obra-bdb',
    payload: actvidad
})

export const startSetActividadActiveObra = (actividad: GridRowId): ActividadObraAction => ({
    type: 'set-actividad-obra-Active-bdb',
    payload: actividad
})

export const startDeleteActividadObra = (actividad: GridRowId): ActividadObraAction => ({
    type:'delete-actividad-obra-bdb',
    payload: actividad
})
