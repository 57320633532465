import { Container, Grid, Paper } from '@mui/material';
//import { useEffect } from 'react';
import { useSelector } from 'react-redux';
//import { startLoadReportes } from '../../modules/reportes/actions/reportes';
import { AppState } from '../../reducers/rootReducer';
import { MapHeader } from '../../shared/maps/components/dashboardmaps/MapHeader';
import { Copyright } from '../copyright/Copyright';
import { Orders } from '../orders/Orders';
import { PieServicios } from './PieServicios';
import { PieServiciosCliente } from './PieServiciosCliente';

export const DashboardScreen = () => {

  //const dispatch = useDispatch();
  const { reportes }  = useSelector((state: AppState) => state.reportes.reportes);

   /* const getReportes = async() => {
    dispatch(startLoadReportes());  
  }  */
/* 
  useEffect(() => {    
    if(reportes.length > 0) return;    
    dispatch(startLoadReportes());    
  }, [reportes, dispatch]) */
   

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        {/* Chart */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 340,
            }}
          >
            <PieServicios />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 340,
            }}
          >
            <PieServiciosCliente />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Orders />
          </Paper>
        </Grid>
        {reportes.length > 0 && 
        <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <MapHeader />
          </Paper>
        </Grid>}
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  )
}
