import { Route, Routes } from 'react-router-dom'
import { SstScreen } from './SstScreen'


export const SstRoutes = () => {
  return (
    <>    
    <Routes>
            <Route path="/obras" element={<SstScreen />} />
            <Route path="/" element={<SstScreen />} />
    </Routes>    
    </>
  )
}