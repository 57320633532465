import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight: 18,
        textAlign: 'center',        
        flexGrow: 1,
    },
    containerTitle:{
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        textAlign: 'center',        
        flexGrow: 1,
    },
    title:{
        width: '100%',
        textAlign: 'center'
    },
    vehiculo: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    placa: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    kilometraje: {
        width: '25%',  
    }
  });

export const HeaderTableVehiculo = () => (
    <>
    <View style={styles.containerTitle}>
        <Text style={styles.title}>Listado de Vehículos</Text>
    </View>
    <View style={styles.container}>
        <Text style={styles.vehiculo}>Vehículo</Text>
        <Text style={styles.placa}>Placa</Text>
        <Text style={styles.kilometraje}>Kilometraje</Text>
    </View>
    </>
  );