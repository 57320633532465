import{ useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
} from '@mui/x-data-grid';

import { AppState } from '../../../../../reducers/rootReducer';
import { startDeleteActividadObra, startUpdateActividadObra } from '../action/actividad_obra';
import { CellExpand } from '../../../../../components/ui/CellExpand';
import { ActividadToolbar } from './ActividadToolbar';
import { startLoadItemsObra } from '../../../../resources/actions/resources';
import { Actividad } from '../interfaces/actividad';
import { StyledDataGrid } from '../../../../../styles/styledDataGrid';
//import { startLoadListas } from '../../../../listas/actions/lista';


export const ActividadTableObra = () => {
    const dispatch = useDispatch();
    const { itemsObra: items } = useSelector((state: AppState) => state.resources);
    const { actividadesObra } = useSelector((state: AppState) => state.reportes.modules.actividadObra);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [values, setValues] = useState<any[]>([])

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteActividadObra(id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };
   
    const processRowUpdate = (newRow: GridRowModel<Actividad>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateActividadObra(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'actividad',
            headerName: 'Actividad',
            flex: 2,
            editable: true,
            type:'singleSelect',
            valueOptions: values,
            headerAlign:'center',
            renderCell: CellExpand
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad',
            headerAlign: 'center',
            editable: true,
            flex: 1,
            type: 'number'
        },
        {
            field: 'observaciones',
            headerName: 'Observaciones',
            headerAlign: 'center',
            flex: 1,
            editable: true,
            type: 'string',
            renderCell: CellExpand
        },        
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [                        
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    useEffect(() => {
      if(items.length > 0) return;
      dispatch(startLoadItemsObra())    ;
      
    }, [dispatch, items])
    
     useEffect(() => {
     if(items.length > 0){
        const itemsDB = items.map(i => i.descripcion);
        setValues(itemsDB);
     }
    }, [dispatch, items])

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <StyledDataGrid
                autoHeight
                rows={actividadesObra.slice(0, actividadesObra.length)}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                components={{
                    Toolbar: ActividadToolbar,
                }}
                hideFooter
                componentsProps={{
                    toolbar: { setRowModesModel },
                }}
                experimentalFeatures={{ newEditingApi: true }}
                localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay registros' }}
                //rowHeight={35}
            />
        </Box>
    );
}
