import { GridRowId } from "@mui/x-data-grid";
import { fetchConToken } from "../../../apis/fetch";
import { setInactiveServicio, startSetModalAts, startSetModalServicio } from "../../servicios/actions/servicio";
import { doMessage, startLoading } from "../../ui/actions/ui";
import { Ats, AtsAction } from "../interfaces/ats";
import { startSetEquiposEmpty } from "../modules/equipo/actions/equipo";
import { startSetHerramientasEmpty } from "../modules/herramienta/actions/herramienta";
import { startSetRiesgosAlturaEmpty } from "../modules/riesgo_altura/actions/riesgo_altura";
import { startSetRiesgosElectricoEmpty } from "../modules/riesgo_electrico/actions/riesgo_electrico";
import { startSetRiesgosSitioEmpty } from "../modules/riesgo_sitio/actions/riesgo_sitio";
import { startSetModalFirmaBeling, startSetResponsableBelingEmpty } from "../../../shared/responsable/actions/responsable";
import { startSetModalFirmaCliente, startSetResponsableClienteEmpty } from "../../../shared/recibe/actions/recibe";

export const startLoadAts = () => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken("ats", {});
        const {ats, ok, msg} = await resp.json();
        if (ok) {
            dispatch(startLoading());
            dispatch(loadAts(ats));
            
        } else {
            dispatch(doMessage(msg));
            dispatch(startLoading());
        }
    }
}

const loadAts = (ats: Ats[]): AtsAction => ({
    type: 'load-ats',
    payload: ats
});

export const startCreateAts = ( newAts: Ats) => {    
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken("ats", newAts, "POST");
        const body = await resp.json();
        const { ats, errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(doMessage({ message: msg, type: 'success' }));            
            dispatch(createAts(ats));
            dispatch(startLoadAts());
            dispatch(startCloseAts());
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const createAts = (ats: Ats): AtsAction => ({
    type: 'create-ats',
    payload: ats
});

export const startUpdateAts = (ats: Ats) => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading())
        const id = getState().ats.ActiveAts[0].id;
        if(!id){
            dispatch(startLoading());
            return dispatch(doMessage({message: 'Ats no activada', type: 'error'}));            
        }
        const resp = await fetchConToken(`ats/${id}`, ats, "PUT");
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(updateAts(body.ats));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const updateAts = (ats: Ats): AtsAction => ({
    type: 'update-ats',
    payload: ats
});

export const startDeleteAts = () => {
   return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const { id, servicio:{_id } } = getState().ats.ats.activeAts;
         
         if(!id){
            dispatch(startLoading());
            return dispatch(doMessage({message: 'Ats no activada', type: 'error'}));            
        }
               
        const resp = await fetchConToken(`ats/${id}`, {}, "DELETE");
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {
            await fetchConToken(`servicios/status/${_id}`, {status:'Cancelados'}, 'PUT'); 
            dispatch(startLoading());
            dispatch(deleteAts(id));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const deleteAts = (atsId: GridRowId): AtsAction => ({
    type: 'delete-ats',
    payload: atsId
});

export const startSetAtsForm = (ats:any) => ({
    type: 'set-ats-form',
    payload: ats
});

export const startSetActiveAts =(id: GridRowId):AtsAction => ({
    type: 'set-active-ats',
    payload: id
});

export const startSetInactiveAts =():AtsAction => ({
    type: 'set-inactive-ats'
});

export const startSetModalAtsPdf = (modal:boolean):AtsAction => ({
    type:'set-modal-ats-pdf',
    payload: modal
});

export const startSetAlertDeleteAts = (payload: boolean): AtsAction =>({
    type: 'set-modal-delete-ats',
    payload
})

export const startSetNoServicio = (payload: boolean): AtsAction =>({
    type: 'set-servicio-exist',
    payload
})

export const startClosePdfAts = () => {
    return async (dispatch: any) =>{
        dispatch(startSetModalServicio(false));
        dispatch(startSetInactiveAts());
        dispatch(setInactiveServicio());
        dispatch(startSetModalAtsPdf(false));
    }
}

export const startCloseAts = () => {
    return async (dispatch: any) => {    
    dispatch(startSetModalFirmaBeling(false));
    dispatch(startSetModalFirmaCliente(false))
    dispatch(startSetRiesgosElectricoEmpty());
    dispatch(startSetRiesgosAlturaEmpty());
    dispatch(startSetRiesgosSitioEmpty());
    dispatch(startSetEquiposEmpty());
    dispatch(startSetHerramientasEmpty());
    dispatch(startSetResponsableBelingEmpty());
    dispatch(startSetResponsableClienteEmpty());
    dispatch(startSetAtsForm({}));
    dispatch(startSetNoServicio(true))   
    dispatch(startSetModalAts(false));
    }
};