import { Tooltip, Chip, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    esES,
} from '@mui/x-data-grid';
import moment from "moment";


import { ModalServicio } from './ModalServicio';
import { AlertDelete } from './AlertDelete';
import { ModalReporteClaro } from '../../reportes/components/ModalReporteClaro';
import { ModalAts } from './ModalAts';
import { ModalReportePdf } from '../../reportes/components/pdf/ModalReportePdf';
import { ModalAtsPdf } from '../../ats/components/pdf/ModalAtsPdf';
import { ModalReporteBasico } from '../../reportes/components/basico/ModalReporteBasico';
import { ServicioToolbar } from './ServicioToolbar';
import { useServicioTable } from '../hooks/useServicioTable';
import { CellExpand } from '../../../components';
import { ModalReporteEmergencia } from '../../reportes/components/emergencia/ModalReporteEmergencia';
import { ModalReporteBdB } from '../../reportes/components/ombdb/ModalReporteBdB';
import { useScreenSize } from '../../../hooks';


export const ServiciosTable = () => {
    const { width } = useScreenSize();
    const {
        estadosServicio,
        isAdmin,
        modalReporte,
        modalReporteBdB,
        modalReportePdf,        
        rowModesModel,
        servicios,
        handleRowEditStart,
        handleRowEditStop,
        handleEditClick,
        handleDeleteClick,
        handleAddReporte,
        processRowUpdate,       
        setRowModesModel
    } = useServicioTable();

    const columns: GridColDef[] = [
        {
            field: 'numero',
            headerName: 'Número',
            editable: false,
            headerAlign: 'center',
            flex: width > 900 ? 0.5 : 0,
            width: 100
        },
        {
            field: 'fecha',
            headerAlign: 'center',
            headerName: 'Fecha',
            editable: false,
            renderCell: (value) => {
                return (<Typography>{moment(value.row.fecha).format('DD/MM/YYYY')}</Typography>)
            },
            flex: width > 900 ? 0.5 : 0,
            width: 100
        },
        {
            field: 'nombre',
            headerName: 'Nombre',
            headerAlign: 'center',
            editable: false,
            flex: width > 900 ? 1 : 0,
            width: 200,
            renderCell: CellExpand,
        },
        {
            field: 'tipo',
            headerName: 'Tipo',
            headerAlign: 'center',
            editable: false,
            flex: width > 900 ? 1 : 0,
            renderCell: CellExpand,
            width: 200,
        },
        {
            field: 'cliente',
            headerAlign: 'center',
            headerName: 'Cliente',
            editable: false,
            renderCell: CellExpand,
            width: 250,
            flex: width > 900 ? 1 : 0,
        },
        {
            field: 'tecnico',
            headerAlign: 'center',
            headerName: 'Técnico Asignado',
            editable: false,
            renderCell: CellExpand,
            width: 200,
            flex: width > 900 ? 1 : 0,
        },
        {
            field: 'estado',
            headerAlign: 'center',
            headerName: 'Estado',
            editable: true,
            type: 'singleSelect',
            align: width > 900 ? 'left' : 'center',
            valueOptions: estadosServicio,
            renderCell: (value) => {
                const chip = width > 900 ?
                    (<Box><Chip label={value.row.estado} sx={{ borderColor: `${value.row.color}` }} variant='outlined' size='small' /> </Box>)
                    : (<Box ><Chip sx={{ borderColor: `${value.row.color}`, backgroundColor: `${value.row.color}` }} variant='outlined' size='small' /></Box>)
                return (
                    chip
                )
            },
            width: width > 900 ? 140 : 100,
        },
        {
            field: 'actions',
            type: 'actions',
            headerAlign: 'center',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                const deleteItem = isAdmin ? <GridActionsCellItem
                    icon={<Tooltip title="Eliminar"><DeleteIcon /></Tooltip>}
                    label="Eliminar"
                    onClick={handleDeleteClick(id)}
                    color="error"
                /> : <></>
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon color='secondary' /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="primary"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title={!row.hasReporte ? 'Iniciar Servicio' : 'Ver reporte'}>
                            {!row.hasReporte ? <HandymanOutlinedIcon color='info' /> : <InsertDriveFileOutlinedIcon color='primary' />}</Tooltip>}
                        label="Reporte"
                        onClick={handleAddReporte(id)}
                        color="inherit"
                    />,
                    deleteItem
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={servicios.slice(0, servicios.length)}
                columns={columns}
                initialState={{
                    columns: {
                        ...columns,
                        columnVisibilityModel: {
                            numero: width < 900 ? false : true,
                            tipo: width < 900 ? false : true,
                            fecha: width < 900 ? false : true,
                            nombre: width < 900 ? false : true
                        }
                    },
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}   
                slots={{
                    toolbar: ServicioToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }}                
                localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay servicios registrados' }}
                rowHeight={35}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            <ModalServicio />
            {modalReportePdf && <ModalReportePdf /> }
            <ModalAtsPdf />
            { modalReporte && <ModalReporteClaro />}
            <ModalReporteBasico />
            { modalReporteBdB && <ModalReporteBdB /> }
            <ModalReporteEmergencia />
            <ModalAts />
            <AlertDelete />
        </Box>
    );
}
