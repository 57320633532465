import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';
//import {v4 as uuid} from 'uuid'

import { EditToolbarProps } from '../../../../../interfaces/shared';
import { startSetModalRutinaAlarmas } from '../actions/alarma';


export const RutinaAlarmaToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
   // const { setRowModesModel } = props;
   

    const handleClick = () => {
        dispatch(startSetModalRutinaAlarmas(true))
    }; 

    return (
        <GridToolbarContainer>           
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar Rutina
            </Button>
        </GridToolbarContainer>
    );
}