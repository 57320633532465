// import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 10,
    },
    textTitle:{
        fontSize: 14,
        textAlign: 'left',
    }
  });

interface TitleProps {
    title: string;
    sx?:{
      fz?: number | string;
      fw?: 'bold' | 'extrabold' | 'light' | 'normal' | 'demibold',
      fs?: 'italic' | undefined;
      ta?: 'center' | 'left' | 'right' | 'justify'
    }
}
  export const Title = ({title, sx}: TitleProps) => {
    const {fz, fw, fs, ta} = sx ??{
      fz: 14,
      fw: "normal",
      fs: undefined,
      ta: "left"
    }

   return (<View style={styles.titleContainer}>
        <Text style={[{fontSize: fz, fontWeight: fw, textAlign: ta, fontStyle: fs }]}>{title}</Text>
    </View>)
  };