import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { TablePersonalClienteHeader } from './TablePersonalClienteHeader';
import { TablePersonalClienteRow } from './TablePersonalClienteRow';
import { TitleTables } from '../../../../../components/pdf/TitleTables';
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
    },
});

interface Props {
    active: Reporte;
}

export const PersonalClienteTable = ({ active }: Props) => (
    <View>
        <TitleTables
            title='Personal Claro Acompaña'
            sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
        />
        <View style={styles.tableContainer}>
            <TablePersonalClienteHeader />
            <TablePersonalClienteRow items={active.personalClaro!} />
        </View>
    </View>
);
