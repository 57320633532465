//import { GridRowId } from '@mui/x-data-grid';
import { Imagen } from '../../../../../interfaces/image';
import { AnexoSstAction } from '../interfaces/anexos';

/* export const startAddImages = (anexo: Anexo) => {
	return async(dispapach: any)=>{
		dispapach(addAnexo)
	}
} */

export const StartDeleteSstAnexo =(id: number):AnexoSstAction =>({
	type: 'delete-sst-anexos',
	payload: id
})

export const startAddAnexos =(anexo: Imagen): AnexoSstAction =>({
	type: 'create-sst-anexos',
	payload: anexo
})

export const startSetModalAnexoStt = (modal: boolean):AnexoSstAction =>({
	type: 'set-modal-sst-anexo',
	payload: modal
})

export const startClearAnexosSst = ():AnexoSstAction =>({
	type:'clear-sst-anexo-state'
})