import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Typography
} from '@mui/material'
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';

import { useGrupoModal } from '../hooks/useGrupoModal';

export const ModalGrupo = () => {

    const {
        activeGrupo,
        isMobile,
        modalGrupo,
        registerData,
        handleClose,
        handleSave,
        onChange,
    } = useGrupoModal();

    const { descripcion, item } = registerData;

    return (
        <>
            <Dialog
                open={modalGrupo}
                onClose={handleClose}
                fullWidth
                fullScreen={isMobile}
                maxWidth="xs"
            >
                <DialogTitle>
                    <Box
                        sx={{
                            my: 1,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        { !isMobile && <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <EngineeringOutlinedIcon />
                        </Avatar>}
                        <Typography component="h1" variant={isMobile ? "h6" : "h5"}>
                            {activeGrupo
                                ? `Editar Grupo`
                                : "Agregar Grupo"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box width="100%" component="div" sx={{ mt: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} >
                                <FormControl fullWidth sx={{ mt: 1, mx: 1 }}>
                                    <TextField
                                        margin="none"
                                        value={item}
                                        onChange={onChange}
                                        fullWidth
                                        id="item"
                                        label="Item"
                                        name="item"
                                        autoComplete="item"
                                        inputProps={{ tabIndex: 1 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl fullWidth sx={{ mt: 1, mx: 1 }}>
                                    <TextField
                                        margin="none"
                                        value={descripcion}
                                        onChange={onChange}
                                        multiline
                                        rows={4}
                                        fullWidth
                                        id="descripcion"
                                        label="Descripción"
                                        name="descripcion"
                                        autoComplete="descripcion"
                                        inputProps={{ tabIndex: 2 }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} tabIndex={14}>Cancelar</Button>
                    <Button onClick={handleSave} tabIndex={15}>
                        {activeGrupo ? "Actualizar" : "Guardar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
