import { View, StyleSheet, Text, Image } from "@react-pdf/renderer";
import { Ats } from "../../../interfaces/ats";
import { getFirma } from "../../../../../helpers";

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		marginTop: 10,
		width: '100%',
	},
	container: {
		flexDirection: 'column',
		flexWrap: 'wrap',
		width: '50%'
	},
	title: {
		flexDirection: 'row',
		width: '100%'
	},
	name: {
		flexDirection: 'row',
		width: '100%'
	},
	textResponsable: {
		fontSize: 10,
		fontWeight: 'bold'
	},
	identificacion: {
		flexDirection: 'row',
		width: '100%'
	},
	numero: {
		marginLeft: 4,
		fontWeight: 'bold'
	},
	firma: {
		flexDirection: 'row',
		width: '100%'
	},
	titleStyle: {
		fontSize: 11,
		fontStyle: 'italic',
		fontWeight: 'bold',
		paddingLeft: 2
	},
	leyenda: {
		width: '100%',
		paddingLeft: 2,
		paddingRight: 2,
		marginTop: 10
	},
	leyendaText: {
		fontSize: 9,
		fontStyle: 'italic',
		marginRight: 5
	},
	image: {
		maxHeight: 100,
		maxWidth: 200,
		padding: 10,
		height: 'auto',
		objectFit: 'contain',
		borderRadius: 10
	}
});

interface Props {
	active: Ats;
}

export const Footer = ({ active }: Props) => {
	return (
		<View wrap={false} style={[{ flexDirection: 'column' }]}>
			<View style={styles.headerContainer}>
				<View style={styles.container}>
					<View style={styles.title}>
						<Text style={[styles.titleStyle]}>Responsable Beling</Text>
					</View>
					<View style={styles.firma}>
						<Image style={styles.image} src={() => getFirma(active.responsableBeling![0].firma.name!.toString())} />
					</View>
					<View style={styles.name}>
						<Text style={[styles.textResponsable]}>{active.responsableBeling![0].nombre}</Text>
					</View>
					<View style={styles.identificacion}>
						<Text style={[{ paddingLeft: 2 }]}>Identificacion:</Text>
						<Text style={styles.numero}>{active.responsableBeling![0].identificacion}</Text>
					</View>
				</View>
				<View style={styles.container}>
					<View style={styles.title}>
						<Text style={[styles.titleStyle]}>Responsable Claro</Text>
					</View>
					<View style={styles.firma}>
						<Image style={styles.image} src={() => getFirma(active.responsableCliente![0].firma.name!.toString())} />
					</View>
					<View style={styles.name}>
						<Text style={[styles.textResponsable]}>{active.responsableCliente![0].nombre}</Text>
					</View>
					<View style={styles.identificacion}>
						<Text style={[{ paddingLeft: 2 }]}>Identificacion:</Text>
						<Text style={styles.numero}>{active.responsableCliente![0].identificacion}</Text>
					</View>

				</View>
			</View>
			<View style={[styles.leyenda]}>
				<Text style={[styles.leyendaText]}>
					Con nuestras firmas declaramos que participamos en la elaboración de este ATS de manera conjunta, entendemos las condiciones de trabajo y estamos en la obligación de aplicar las precauciones identificadas.
				</Text>
			</View>
		</View>
	)
}