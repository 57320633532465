import { Page, Document, StyleSheet, Text } from '@react-pdf/renderer';
import { Ats } from '../../interfaces/ats';
import { Header } from './header/Header';
import { Tarjeta } from './tarjeta/Tarjeta';
import { RiegosElectricos } from './body/riesgos-electricos/RiesgosElectricos';
import { TrabajoEnAlturas } from './trabajo-alturas/TrabajoEnAlturas';
import { RiesgosEnSitio } from './riesgos-sitio/RiesgosEnSitio';
import { EquipoSeguridad } from './equipo-seguridad/EquipoSeguridad';
import { Herramientas } from './herramientas/Herramientas';
import { ConfirmarOpcion } from '../../../../components/pdf/ConfirmarOpcion';
import { Footer } from './footer/Footer';
import { toString } from '../../../../helpers';
import { LogoHeader } from './header';


const styles = StyleSheet.create({
  page: {
    fontFamily: 'Tahoma',
    fontSize: 10,
    paddingTop: 40,
    paddingBottom: 60,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: 'column',
    color: '#212121'
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 40,
    textAlign: 'right',
    color: 'grey',
  },
});

interface Props {
  active: Ats;
}

export const AtsPdf = ({ active }: Props) => {
 
    if(!active){
      return <Document title='No data'>
        <Page size={'LETTER'}></Page>
      </Document>
    }

  return (<Document title={`ATS No ${toString(active.numero!)}`}>
    <Page size="LETTER" style={styles.page} wrap >
      <LogoHeader active={active} />
      <Header active={active} />
      <ConfirmarOpcion title={["Describa las actividades a realizar:"]} value={active.actividades!} />
      <Tarjeta active={active} />
      <RiegosElectricos active={active} />
      <TrabajoEnAlturas active={active} />
      <RiesgosEnSitio active={active} />
      <EquipoSeguridad active={active} />
      <Herramientas active={active} />
      <ConfirmarOpcion title={['¿Es seguro proceder con la tarea con los controles establecidos?']} value={active.seguirActividad!.toUpperCase()} />
      <ConfirmarOpcion title={['¿El personal está capacitado para la realización de la actividad?']} value={active.personalCapacitado!.toUpperCase()} />
      <Footer active={active} />
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Página ${pageNumber} de  ${totalPages} ${totalPages > 1 ? 'páginas' : 'página'}`
      )} fixed />
    </Page>
  </Document>
  )
}
