import { SharedAction, Props } from "../interfaces/shared";

export const startSetSharedProps = (props: Props): SharedAction => ({
    type: 'set-actividad-props',
    payload: props,
});

export const startClearSharedProps = ():SharedAction => ({
    type: 'clear-actividad-props',
});
