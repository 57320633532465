import * as React from 'react';
import  { Typography } from '@mui/material';
import { VariantText } from '../../modules/ui/interfaces/ui';

interface TitleProps {
  children?: React.ReactNode;
  variant?: VariantText;
  marginTop?: number;
  color?:string
}

export const Title = ({ variant = 'h6', children, marginTop = 2, color="primary" }: TitleProps) => {
  return (
    <Typography component="h2" variant={variant} color={color} sx={{mt: marginTop}}>
      {children}
    </Typography>
  );
}