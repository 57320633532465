import { ImagenSstState, ImagesSstAction } from "../interfaces/images";

const imageState: ImagenSstState = {
    images: [],
    modalImageSst: false
}

export const imageSstReducer = (state = imageState, action: ImagesSstAction): ImagenSstState =>{
    switch(action.type){
        case 'load-sst-images':
            return{
                ...state,
                images: action.payload
            }
            case "create-sst-images":
                return {
                    ...state,
                    images: [...state.images, action.payload]
                }
            case "update-sst-images":
                return {
                    ...state,
                    images: state.images.map(image => image.id === action.payload.id ? action.payload : image)
                }
            case "delete-sst-images":
                return {
                    ...state,
                    images: state.images.filter(image => image.id !== action.payload)
                }
            case "clear-sst-image-state":
                return {
                    ...state,
                    images: []
                }
            case 'set-modal-sst-image':
                return {
                    ...state,
                    modalImageSst: action.payload
                }
        default:
            return state
    }
}