import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { InversoresTable } from './InversoresTable';

const Table = memo(() => {
    return (
        <InversoresTable />
    );
});

export const Inversores = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Información del Sistema Inversor
                    </Typography>
                    <Typography component="h1" align='center' variant="subtitle1">
                       Caracteristicas del Inversor
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}