import { useRef, useState, useEffect, MutableRefObject } from 'react';
import { usePosition } from 'use-position';
import { Map, Marker, NavigationControl, Popup } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { dateShort } from '../../../helpers/fecha';

export const useMapServicios = () => {
	const watch = false;
	const { latitude, longitude } = usePosition(watch);
	const { servicios } = useSelector((state: AppState) => state.servicios);
	const mapContainer = useRef(null);
	const map = useRef(null) as MutableRefObject<any>;
	const [center, setCenter] = useState<[number, number]>();
  
	const getLocation = () =>{    
	  if(longitude === undefined || latitude === undefined) return;
	  setCenter([longitude, latitude])
	}
  
	useEffect(() =>{
	  if(latitude !== undefined){
		getLocation();
	  }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[latitude])
	
	useEffect(()=>{
	  if(map.current) return;
	  if(center){
		map.current = new Map({
		  container: mapContainer.current!,
		  style: 'mapbox://styles/mapbox/streets-v12',
		  center,
		  zoom: 12,
		}); 
		
		servicios?.forEach(servicio =>{
			const lng = servicio.destino?.center![0];
			const lat = servicio.destino?.center![1];
			const fecha = dateShort(servicio.fecha!);
			const tecnico = `${servicio.tecnico}`;
			const cliente = servicio.cliente.nombre;
			const direccion = servicio.destino?.description.split(',',1)
			const popup = new Popup()
			.setHTML(`
			  <div class="popup-container">
				<div class="popup-header">
				  ${cliente}
				</div>
				<div class="popup-body">
				  <div>Fecha: ${ fecha }</div>
				  <div>Tecnico: ${ tecnico}</div>
				  <div>Dirección: ${ direccion}</div>				  
				</div>
			  </div>
			`)
			new Marker({
			  color: servicio.estado.color,
			})
			.setLngLat([lng!, lat!])
			.setPopup(popup)
			.addTo(map.current)
		});
		
		map.current.addControl(new NavigationControl());
  
	  }
	  
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [center, servicios])

	/* const verServicio = (servicio: any) =>{
		console.log(servicio)
	} */

	return {
		mapContainer
	}
}
