import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid';

import {v4 as UUID } from 'uuid'

import { AppState } from '../../../../../reducers/rootReducer';
import { startSetActividadNoPlaneada } from '../action/actividad';
import { EditToolbarProps } from '../../../../../interfaces/shared';


export const ActividadToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
    useSelector((state: AppState) => state.reportes.modules.actividadNoContemplada);
    const { setRowModesModel } = props;

    const handleClick = () => {
        const id = UUID();
        dispatch(startSetActividadNoPlaneada({ id, actividad: '', cantidad: 0, observaciones: '', isNew: true }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'actividad' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar
            </Button>
        </GridToolbarContainer>
    );
}
