import { Avatar, Box, Typography } from '@mui/material'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { ItemsTable } from './ItemsTable';

export const Items = () => {
	const {activeLista} = useSelector((state: AppState) => state.listas);
	return (
        <>
            <Box
                sx={{
                    my: 1,
                    mx: 4,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main",width: 24, height: 24 }}>
                    <PlaylistAddCheckIcon fontSize="small" />
                </Avatar>
                <Typography component="h6" align='center' variant="subtitle1">
                    {activeLista?.lista} 
                </Typography>
            </Box>
            <ItemsTable />
        </>
    )
}