import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';


import { EditToolbarProps } from '../../../interfaces/shared';
import { startSetModalObra } from '../actions/obras';

export const ObraTableToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
    //const { servicios } = useSelector((state: AppState) => state.servicios);
    //const { setRowModesModel } = props;

    const handleClick = () => {
        dispatch(startSetModalObra(true));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Nuevo
            </Button>
        </GridToolbarContainer>
    );
}