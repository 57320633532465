import { Container, Grid } from '@mui/material';
import ReactPDF from '@react-pdf/renderer';
import { Copyright } from '../copyright/Copyright';
import { useLocation } from 'react-router-dom';

export const PdfScreen = () => {
  const location = useLocation();
  console.log(location.pathname) 
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
     
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  )
}
