
import { Font, StyleSheet } from '@react-pdf/renderer';
import tahoma from '../assets/tahoma/TAHOMA_0.ttf';
import tahomaNormal from'../assets/tahoma/Tahoma Regular font.ttf';
import tahomaBold from '../assets/tahoma/TAHOMAB0.ttf';
import tahomaBoldItalic from '../assets/tahoma/tahoma-bold-italic.ttf';
import tahomItalic from '../assets/tahoma/tahoma-italic.ttf';
import verdana from '../assets/verdana/verdana.ttf';
import verdanaBold from '../assets/verdana/verdana-bold.ttf';
import verdanaBoldItalic from '../assets/verdana/verdana-bold-italic.ttf';
import arial from '../assets/arial/arial.ttf';
import arialBold from '../assets/arial/arial_bold.ttf';
import arialBlack from '../assets/arial/arial_black.ttf';
import arialItalic from '../assets/arial/arial_italic.ttf';
import arialItalicBold from '../assets/arial/arial_bold_italic.ttf';
import arialMedium from '../assets/arial/arial_medium.ttf'

Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEliotp6d2Af5fR4k.ttf'
		},
		{
		src: 'https://fonts.gstatic.com/s/robotoserif/v8/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEls0qp6d2Af5fR4k.ttf',
		fontWeight: 'bold'
	  },
	  {
		src: 'https://fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuT-R8BdxaV4nUFw.ttf',
		fontWeight: 'normal',
		fontStyle: 'italic'
	  },
	  {
		src: 'https//fonts.gstatic.com/s/robotoserif/v8/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuqON8BdxaV4nUFw.ttf',
		fontWeight: 'bold',
		fontStyle: 'italic'
	  }
	]
  })

  Font.register({
	family: 'Tahoma',
	fonts:[
		{
			src: tahoma
		},
		{
			src: tahomaNormal,
			fontWeight: 'normal'
		},
		{
			src: tahomaBold,
			fontWeight: 'bold'
		},
		{
			src: tahomItalic,
			fontWeight: 'normal',
			fontStyle: 'italic'
		},
		{
			src: tahomaBoldItalic,
			fontWeight: 'bold',
			fontStyle: 'italic'
		}
	]
  })

  Font.register({
	family: 'Verdana',
	fonts:[
		{
			src: verdana
		},
		{
			src: verdanaBold,
			fontWeight: 'bold'
		},
		{
			src: verdanaBoldItalic,
			fontWeight: 'bold',
			fontStyle: 'italic'
		}
	]
  })

  Font.register({
	family: 'Arial',
	fonts:[
		{
			src: arial
		},
		{
			src: arial,
			fontWeight: 'normal'
		},
		{
			src: arialMedium,
			fontWeight: 'medium'
		},
		{
			src: arialBold,
			fontWeight: 'bold'
		},
		{
			src: arialBlack,
			fontWeight: 'ultrabold'
		},
		{
			src: arialItalicBold,
			fontWeight: 'bold',
			fontStyle: 'italic'
		},
		{
			src: arialItalic,
			fontWeight: 'normal',
			fontStyle: 'italic'
		}
	]
  })


  
export const styles = StyleSheet.create({
	headerContainer:{
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom:10
	},
	page: {
	  fontFamily: 'Tahoma',
	  fontSize: 10,
	  paddingTop: 40,
	  paddingBottom: 60,
	  paddingLeft: 40,
	  paddingRight: 40,
	  lineHeight: 1.5,
	  flexDirection: 'column',
	},
	logo: {
	  width: 84,
	  height: 76,
	},
	pageNumber: {
	  position: 'absolute',
	  fontSize: 12,
	  bottom: 30,
	  left: 0,
	  right: 40,
	  textAlign: 'center',
	  color: 'grey',
	},
  });
  