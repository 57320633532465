import { useDispatch, useSelector } from 'react-redux';
import { GridRowModes} from '@mui/x-data-grid';
import { EditToolbarProps } from '../../../interfaces/shared';


import { startSetModalItemObra, startCreateItemObra, startSetItemObra } from '../actions/item';
import { AppState } from '../../../reducers/rootReducer';
import ObjectID from 'bson-objectid';
import { startSetmodalReporteObra } from '../actions/obras';

export const useItemObraToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
    const { activeGrupo } = useSelector((state: AppState) => state.obras.grupos);
    //const { activeItemObra } = useSelector((state: AppState) => state.obras.itemsObra);
    const { setRowModesModel } = props;

    const handleClick = () => {
        const id = ObjectID().toHexString();
        const newItemObra ={
            item: 'Nuevo item',
            descripcion: 'Agregar descripción',
            unidad: 'Agregar Unidad',
            cantidad: 0,
            valorUnitario: 0,
            grupo: activeGrupo?.id!,
            nuevo: true,
            _id: id,
            id
        }        
        dispatch(startCreateItemObra(newItemObra));
        dispatch(startSetItemObra(newItemObra));
                  
       setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'item' },
        }));
    };

    const handleShowModal = () => {
        dispatch(startSetModalItemObra(true));
    }
    const handleShowReporte = () => {
        dispatch(startSetmodalReporteObra(true));
    }


    return {
        handleClick,
        handleShowModal,
        handleShowReporte
    }

}