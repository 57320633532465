import { GridRowId } from "@mui/x-data-grid";
import { fetchConToken } from "../../../apis/fetch";
import { doMessage, startLoading } from "../../ui/actions/ui";
import { Cliente, ClienteAction } from "../interfaces/clientes";


export const starLoadClientes = () => {
    return async (dispatch: any) => {
        dispatch(startLoading())
        const resp = await fetchConToken('clientes', {});
        const body = await resp.json();
        const { clientes, errors, ok, msg } = body;
        if (ok) {
            dispatch(loadClientes(clientes));            
        } else {
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
        dispatch(startLoading())
    }
}


const loadClientes = (clientes: Cliente[]):ClienteAction => ({
    type: 'load-clientes',
    payload: clientes
})


export const startCreateCliente = (clienteCreate: Cliente) => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('clientes', clienteCreate, 'POST');
        const body = await resp.json();
        const {cliente, errors, msg, ok } = body;
        if (ok) {            
            dispatch(createCliente(cliente));
            dispatch(doMessage({message: msg, type: 'success'}));
        } else {
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
        dispatch(startLoading());
    }
}


const createCliente = (cliente: Cliente):ClienteAction => ({
    type: 'create-cliente',
    payload: cliente
})

export const startUpdateCliente = (clienteUpdate: Cliente) => {  
    console.log(clienteUpdate);   
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading())
        const id = getState().clientes.activeCliente.id;
        if(!id){
            return dispatch(doMessage({message: 'No hay cliente activo', type: 'error'}));            
        }
        const resp = await fetchConToken(`clientes/${id}`, clienteUpdate, 'PUT');
        const body = await resp.json();
        const {cliente, errors, msg, ok } = body;
        if (ok) {
            dispatch(upDateCliente(cliente));
            dispatch(doMessage({message: msg, type: 'success'}));
            dispatch(startSetActiveCliente(cliente.id))
        } else {
            if(msg){
                console.log(msg)
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
        dispatch(startLoading())
    }
}

const upDateCliente = (cliente: Cliente):ClienteAction => ({
    type: 'update-cliente',
    payload: cliente
})

export const startDeleteCliente = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().clientes.activeCliente.id;
        if(!id){
            dispatch(startLoading());
            return dispatch(doMessage({message: 'No hay cliente activo', type: 'error'}));
        }
        const resp = await fetchConToken(`clientes/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const {errors, msg, ok } = body;
        if (ok) {            
            dispatch(deleteCliente(id));
            dispatch(startSetActiveCliente(''));
            dispatch(doMessage({message: msg, type: 'success'}));
            dispatch(startLoading());
        } else {            
            if(msg){
                console.log(msg)
                dispatch(doMessage({message: msg, type: 'error'}));
                dispatch(startLoading());
            }else{
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({message: err, type: 'error'}));
                dispatch(startLoading());
            }
            
        }        
    }
}

const deleteCliente = (id: string):ClienteAction => ({
    type: 'delete-cliente',
    payload: id
})


export const startSetActiveCliente = (id: GridRowId):ClienteAction => ({
    type: 'set-active-cliente',
    payload: id
})

export const startSetModalCliente = (modal: boolean):ClienteAction => ({
    type: 'set-modal-cliente',
    payload: modal
})

export const startSetAlertDeleteCliente = (alert: boolean):ClienteAction => ({
    type: 'set-alert-delete-cliente',
    payload: alert
})

export const startSetIsNewCliente = (isNew: boolean): ClienteAction => ({
    type: 'set-isNew-client',
    payload: isNew
})