import { StyleSheet, View } from "@react-pdf/renderer";
import { Reporte } from "../../../interfaces/reportes";
import { HeaderItems } from "../shared/HeaderItems";
import { ListItems } from "../shared/ListItems";
import { fechaDetalle } from "../../../../../helpers";
import { TitleTables } from "../../../../../components/pdf/TitleTables";

const borderColor = '#bcbcbc';

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		flexWrap: "wrap",
		border: borderColor,
		borderWidth: 1,
	},		
	bodyActividad: {
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'left',
		padding: 0,
		flexWrap: "wrap",
		marginTop: 2
	},
	
});

interface Props {
	active: Reporte;
}

const title: string = 'Geolocalización al finalizar servicio';

export const FinalReporte = ({ active }: Props) => {
	return (
		<View>
			<TitleTables
				title={title}
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
			/>
		<View style={styles.container}>			
			<View style={styles.bodyActividad}>				
				<ListItems sx={{fw:'normal', color:'#4dabf5'}} text={fechaDetalle(active.ubicacionFinal?.timestamp!) ?? ''} />
				<ListItems sx={{fw:'normal', color:'#4dabf5'}} text={`Latitud: ${active.ubicacionFinal?.latitude! ?? ''}, Longitud: ${active.ubicacionFinal?.longitude ?? ''}`} />
			</View>
			<View style={styles.bodyActividad}>
				<HeaderItems text="Fecha y Hora de finalización:" />
				<ListItems sx={{fw:'normal'}} text={fechaDetalle(active.fechaHoraFinal!) ?? ''} />
			</View>
		</View>
		</View>
	)
}