import { AcompanaClienteState, AcompanaAction } from '../interfaces/interface';


const initState: AcompanaClienteState = {
	acompanaCliente:
	{
		nombre: "",
		identificacion: "",
		cargo: "",
		isNew: false
	}
	,
}

export const acompanaClienteReducer = (state = initState, action: AcompanaAction): AcompanaClienteState => {
	switch (action.type) {
		case 'update-acompana-cliente-reporte':
			return {
				...state,
				acompanaCliente: action.payload
			}
		case 'set-acompana-cliente-empty-reporte':
			return {
				...state,
				acompanaCliente:
				{					
					nombre: "",
					identificacion: "",
					cargo: "",
					isNew: false
				}
			}

		default:
			return state;
	}
}