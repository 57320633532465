import { View, StyleSheet } from "@react-pdf/renderer";

import { Reporte } from '../../../interfaces/reportes';
import { getDurationHhMmSs, fechaHora } from '../../../../../helpers/fecha';
import { Cell } from "../../../../../interfaces/shared";
import { HeadItems } from "../../../../../components/pdf/HeadItems";
import { RowItems } from "../../../../../components/pdf/RowItems";

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginBottom: 2
	},
});

const namesUno = ["Cliente:", "Dirección Cliente:", "Dirección Contacto:"];
const namesDos = ["Contacto:", "Referencia:", "Técnico:"];
const namesTres = ["Colaboradores:", "Servicio:", "Duración:"];
const namesCuatro = ["Fecha de Inicio:", "Fecha de Finalización:"];

interface Props {
	active: Reporte;
}
export const HeaderBasico = ({ active }: Props) => {
	const direccionCliente = active.servicio.cliente.direccion?.description.split(',')[0];
	const colaboradores = active.servicio.colaboradores.length > 0 ? active.servicio.colaboradores.join(','): 'Sin Asignar';
	const filaUno: Cell[] = [
		{name: active.servicio?.cliente.nombre},
		{name: direccionCliente? direccionCliente: ''},
		{name: active.servicio?.contacto.direccion},
		];

	const filaDos: Cell[] = [
		{name:
			`${active.servicio.contacto.nombres}`.toUpperCase()},
		{name: active.servicio.referencia},
		{name:
			`${active.servicio.tecnico.name} ${active.servicio.tecnico.lastname}`.toUpperCase()},		
		];
	
	const filaTres: Cell[] = [
		{name: colaboradores},
		{name: active.servicio.nombre},
		{name: getDurationHhMmSs(active.fechaHoraInicio!, active.fechaHoraFinal!)},
	];

	const filaCuatro: Cell[] = [
		{name: fechaHora(active.fechaHoraInicio!)},
		{name: fechaHora(active.fechaHoraFinal!) },		
	];

	return (
		<>
			<View style={styles.headerContainer}>
				<HeadItems names={namesUno} />
				<RowItems columnas={filaUno} />
			</View>
			<View style={styles.headerContainer}>
				<HeadItems names={namesDos} />
				<RowItems columnas={filaDos} />
			</View>
			<View style={styles.headerContainer}>
				<HeadItems names={namesTres} />
				<RowItems columnas={filaTres} />
			</View>
			<View style={styles.headerContainer}>
				<HeadItems names={namesCuatro} />
				<RowItems columnas={filaCuatro} />
			</View>
		</>
	);
};

