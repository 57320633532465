/**
 * Redux: Riesgo Sitio Reducer
 * Recucer para el manejo de la información de riesgo de sitio
 * @param state Estado actual del reducer
 * @param action Acción que se desea ejecutar
 * @returns Estado actualizado
 * @author Heuin Lopez Carvajal
 * @email eduinlopezc@gmail.com
 * @version 1.0
 * @since 1.0
 */

import { RiesgoSitioActions, RiesgoSitioState } from "../interfaces/riesgoSitio";

const initialState: RiesgoSitioState = {
    riesgosSitio: [],
};

export const riesgoSitioReducer = (state = initialState, action: RiesgoSitioActions): RiesgoSitioState => {
    switch (action.type) {
        case "set-riesgos-sitio":
            return {
                ...state,
                riesgosSitio: [ ...state.riesgosSitio, action.payload,]
            };
        case "update-riesgos-sitio":
            return {
                ...state,
                riesgosSitio: state.riesgosSitio.map(rs => {
                    if (rs.id === action.payload.id) {
                        return action.payload;
                    }
                    return rs;
                })
            };
        case "delete-riesgos-sitio":
            return {
                ...state,
                riesgosSitio: state.riesgosSitio.filter((rs) => rs.id !== action.payload),
            };
        case "edit-riesgos-sitio":
            return {
                ...state,
                    riesgosSitio: state.riesgosSitio.map(rs => {
                        if (rs.id === action.payload) {
                            rs.isNew = false;
                        }
                        return rs;
                    })
            };
        case "set-riesgos-sitio-empty":
            return {
                ...state,
                riesgosSitio: [],
            };
        default:
            return state;
    }
}