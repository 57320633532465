import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { AppState } from '../../../../../reducers/rootReducer';
import { StartDeleteSstAnexo, startSetModalAnexoStt } from '../actions/anexos';
import { ModalAnexoSst } from './ModalAnexoSst';
import { Gallery } from '../../../../../shared';
import { deleteImagen } from '../../../../../helpers';


export const Anexos = () => {
	const disptach = useDispatch();
	const { anexos } = useSelector((state: AppState) => state.sst.modules.anexosSst);

	const handeAddImagen = () => {
		disptach(startSetModalAnexoStt(true))
	}

	const handleDelete = async (id: number) => {
		const res = await deleteImagen(id);				
		if (res.status) {			
			disptach(StartDeleteSstAnexo(id));
		}
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<IconButton color="primary" aria-label="Ubicación" size="large" onClick={handeAddImagen}>
					<AddAPhotoIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}>
				{anexos.length > 0 && <Gallery images={anexos} handleDelete={handleDelete} />}
			</Box>
			<ModalAnexoSst />
		</>
	)
}
