import { ImagenFinState, ImagesFinAction } from "../interfaces/images";

const imageState: ImagenFinState = {
    images: [],
    modalImageFin: false
}

export const imageFinReducer = (state = imageState, action: ImagesFinAction): ImagenFinState =>{
    switch(action.type){
        case 'load-fin-images':
            return{
                ...state,
                images: action.payload
            }
            case "create-fin-images":
                return {
                    ...state,
                    images: [...state.images, action.payload]
                }
            case "update-fin-images":
                return {
                    ...state,
                    images: state.images.map(image => image.id === action.payload.id ? action.payload : image)
                }
            case "delete-fin-images":
                return {
                    ...state,
                    images: state.images.filter(image => image.id !== action.payload)
                }
            case "clear-fin-image-state":
                return {
                    ...state,
                    images: []
                }
            case 'set-modal-fin-image':
                return {
                    ...state,
                    modalImageFin: action.payload
                }
        default:
            return state
    }
}