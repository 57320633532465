import { GridRowId } from "@mui/x-data-grid";
import { Detalle } from "../../../interfaces/shared";
import { ActividadAction } from "../interfaces/actividad";

export const startSetActividad = (actividad: Detalle): ActividadAction => ({
    type: 'set-actividad',
    payload: actividad,
});

export const startUpdateActividad = (actividad: Detalle): ActividadAction => ({
    type: 'update-actividad',
    payload: actividad,
});

export const startDeleteActividad = (id: GridRowId) => ({
    type: 'delete-actividad',
    payload: id,
});

export const startSetActividadId = (id: GridRowId) => ({
    type: 'set-actividad-id',
    payload: id,
});

export const startSetActividadEmpty = ():ActividadAction => ({
    type: 'set-actividad-empty',
});
