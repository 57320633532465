import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { Vehiculo } from '../../../interfaces/reportes';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#eeeeee',
        color:'#212121',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight: 14,
        paddingTop: 2,
    },
    vehiculo: {
        width: '50%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    placa: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    kilometraje: {
        width: '25%',
        textAlign: 'center',
        paddingRight: 8,
    },
  });

interface Props {
    items: Vehiculo[];
    }

export const TableVehiculoRow = ({items}: Props) => {
    const rows = items.map( (item) => 
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.vehiculo}>{item.vehiculo}</Text>
            <Text style={styles.placa}>{item.placa}</Text>
            <Text style={styles.kilometraje}>{item.kilometraje}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};