import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Totales } from '../../../../../interfaces/informes';


const styles = StyleSheet.create({
	column: {
		flexDirection: 'column',
		borderBottom: 1,
		height: '100%',
	},
	rowContainer: {
		flexDirection: 'row',		
	},
	borderRow: {
		borderBottom: 1,
		borderRight: 1
	},
	borderRowBottom: {
		borderRight: 1,
		fontWeight: 'bold'
	},
	leftColumn: {
		flexDirection: 'column',
		width: '81%',
	},
	rightColumn: {
		flexDirection: 'column',
		width: '19%',
	},
	firstCell: {
		borderLeft: 1
	},
	cellRight: {
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: -1,
		minHeight:16
	},	
	cellLeft: {
		alignItems: 'center',
		minHeight:16
	},
	headColumn: {
		justifyContent: 'center',
	},
	headText: {
		fontSize: 7,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 3
	},
	text: {
		fontSize: 7,
		fontWeight: 'normal',
		marginLeft: 3,
		marginTop: 3,
		textTransform: 'capitalize'
	},
	bottomText: {
		fontSize: 7,
		fontWeight: 'bold',
		marginLeft: 3,
		marginTop: 3,
		textTransform: 'capitalize'
	},
	rightText: {
		fontSize: 7,
		marginTop: 3,
		fontWeight: 'normal',
		textAlign: 'center'
	},
	rightBottomText: {
		fontSize: 7,
		marginTop: 3,
		fontWeight: 'normal',
		textAlign: 'center'
	},
})

interface Props {
	title: string;
	totalTitle: string;
	first?: boolean;
	totales: Totales[];
	sx?: {
		ml?: number,
		columnWidth?: string | number,
	}
}

export const PersonalView = ({ totales, title, totalTitle, sx, first = false }: Props) => {	
	const { columnWidth } = sx ?? {
		ml: 0,
		columnWidth: 10
	}
	const border = first ? styles.firstCell : {};
	const blankRows = Array(20 - totales.length).fill(0);
	return (
		<View style={[styles.column, { width: columnWidth }]}>
			<View style={[styles.rowContainer, styles.borderRow, border, styles.headColumn, {minHeight: 16}]}>
				<Text style={[styles.headText]}>{title}</Text>
			</View>
			<View style={[styles.rowContainer]}>
				<View style={[styles.leftColumn, border]}>
					<LeftCell text='OCUPACION' />
					{totales.map((item, index)=>{						
						return(
							<LeftCell text={item.cargo} key={index} />
						)
					})}
					{blankRows.map( (x, i) => <LeftCell text='' key={`ROW${i}`}/>)}
					<LeftCell text={totalTitle} bottom/>
				</View>
				<View style={[styles.rightColumn]}>
					<RightCell text='CANT' />
					{totales.map((item, index)=>{
						return(
							<RightCell text={item.total.toString()} key={index} />
						)
					})}
					{blankRows.map( (x, i) => <RightCell text='' key={`ROW${i}`}/>)}
					<RightCell text={sumaCargos(totales).toString()} bottom/>
				</View>
			</View>
		</View>
	)
}

interface CellProps {
	text: string,
	bottom?: boolean
}

const LeftCell = ({ text, bottom = false }: CellProps) => {
	const border = bottom ? styles.borderRowBottom : styles.borderRow;
	const textStyle = bottom? styles.bottomText : styles.text;
	return (
		<View style={[styles.rowContainer, border, styles.cellLeft]}>
			<Text style={[textStyle]}>{text}</Text>
		</View>
	)
}

const RightCell = ({ text, bottom = false }: CellProps) => {
	const border = bottom ? styles.borderRowBottom : styles.borderRow;
	const textStyle = bottom? styles.bottomText : styles.text;
	return (
		<View style={[styles.rowContainer, styles.cellRight, border]}>
			<Text style={[textStyle]}>{text}</Text>
		</View>
	)
} 

function sumaCargos(cargos: Totales[]){
	 const total = cargos.reduce(
	(accumulator, currentValue) => accumulator + currentValue.total,
	0,
  )
  return total;
}