import { Grupo, GrupoAction, NewGrupo } from '../interfaces/grupo.interface'
import { doMessage, startLoading } from '../../ui/actions/ui';
import { fetchConToken } from '../../../apis/fetch';
import { GridRowId } from '@mui/x-data-grid';


export const startLoadGrupos = () => {
    return async (dispatch: any, getState: any) => {
        const obra = getState().obras.obras.activeObra.id;
        if (!obra) return;
        dispatch(startLoading());
        const resp = await fetchConToken(`grupos/${obra}`, {}, "GET");
        const body = await resp.json();
        const { errors, msg, ok, grupos } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(loadGrupos(grupos));
        } else {
            dispatch(startLoading());
            dispatch(loadGrupos([]));
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

export const loadGrupos = (grupos: Grupo[]): GrupoAction => ({
    type: 'load-grupos',
    payload: grupos
})

export const startCreateGrupo = (newGrupo: NewGrupo) => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('grupos', newGrupo, "POST");
        const body = await resp.json();
        const { errors, msg, ok, grupo } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(createGrupo(grupo))
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const createGrupo = (grupo: Grupo): GrupoAction => ({
    type: 'create-grupo',
    payload: grupo
})

export const startUpdateGrupo = (updatedGrupo: NewGrupo) => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().obras.grupos.activeGrupo.id;
        if (!id) {
            return dispatch(doMessage({ message: 'No hay grupo activo', type: 'error' }));
        }
        const resp = await fetchConToken(`grupos/${id}`, updatedGrupo, 'PUT');
        const body = await resp.json();
        const { errors, msg, ok, grupo } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(updateGrupo(grupo));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const updateGrupo = (grupo: Grupo): GrupoAction => ({
    type: 'update-grupo',
    payload: grupo
})

export const startDeleteGrupo = (id: GridRowId) => {
    return async(dispatch: any) =>{
        dispatch(startLoading());
        const resp = await fetchConToken(`grupos/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if(ok){
            dispatch(startLoading());
            dispatch(doMessage({ message: msg, type: 'success' }));
            dispatch(deleteGrupo(id))
        }else{
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const deleteGrupo = (id: GridRowId): GrupoAction => ({
    type: 'delete-grupo',
    payload: id
})

export const startSetActiveGrupo = (id: GridRowId): GrupoAction => ({
    type: 'set-active-grupo',
    payload: id
})

export const startSetInactiveGrupo = (): GrupoAction => ({
    type: 'set-inactive-grupo'
})

export const startSetModalGrupo = (modal: boolean): GrupoAction => ({
    type: 'set-modal-grupo',
    payload: modal
})

