import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
	},
	column: {
		flexDirection: 'column',
		borderTop: 3,
		borderBottom: 3,
		padding: 5,

	},
	columnBottom: {
		flexDirection: 'column',
		borderBottom: 3,
		padding: 5,

	},
	
	lefColumn: {
		height: '100%',
		width: '49.9%',
		borderLeft: 3,
		borderRight: 3,
	},	
	rightColumn: {
		borderRight: 3,
		width: '49.9%',
		height: '100%',
		justifyContent: 'space-around',
		marginLeft: -1
	},
	rowContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '100%',
	},
	colContainer: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	txTitle: {
		fontSize: 11,
		fontWeight: 'bold'
	},
	txContent: {
		fontSize: 11,
		fontWeight: 'normal',
	},
});

interface Props {
	title1: string;
	title2: string;
	content1: string | number;
	content2: string | number;
	top?: boolean;
	sx?:{
		mt?: number;
		wth?: string | number
	}
}

export const BodyLine = ({ title1, title2, content1, content2, top = false, sx }: Props) => {

	const { mt, wth} = sx ?? {
		mt: -1,
		wth: '50%'
	}
	return (
		<>
			<View style={[styles.row, { marginTop: mt }]}>
				<View style={[bodyStyles(top), styles.lefColumn]}>
					<View style={[styles.rowContainer]}>
						<View style={[styles.colContainer, { width: wth }]}>
							<Text style={[styles.txTitle, { textAlign: 'left' }]}>{title1.toUpperCase()}</Text>
						</View>
						<View style={[styles.colContainer]}>
							<Text style={[styles.txContent]}>{content1}</Text>
						</View>
					</View>
				</View>
				<View style={[bodyStyles(top), styles.rightColumn]}>
					<View style={[styles.rowContainer]}>
						<View style={[styles.colContainer, { width: wth, alignContent: 'flex-start' }]}>
							<Text style={[styles.txTitle, { textAlign: 'left' }]}>{title2.toUpperCase()}</Text>
						</View>
						<View style={[styles.colContainer]}>
							<Text style={[styles.txContent, { textAlign: 'center' }]}>{content2}</Text>
						</View>
					</View>
				</View>
			</View>
		</>
	)
}


function bodyStyles(top: boolean) {
	const bodyTop ={
		...styles.column
	}
	const bodyBottom ={
		...styles.columnBottom
	}
	if(top){
		return bodyTop
	}else{
		return bodyBottom
	}
}