import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		},
	},
};

interface Props {
	index: number,
	label: string,
	name: string,
	selecteds: string[],
	values: string[];
	handleChange: (event: SelectChangeEvent<string[]>) => void;
}

export const MultipleSelectCheckBox = (props: Props) => {
	const { index, label, name, selecteds, values, handleChange } = props;

	return (
		<>
			<FormControl fullWidth sx={{ mt: 1 }} >
				<InputLabel id={`${name}-label`}>{label}</InputLabel>
				<Select
					labelId={`${name}-label`}
					id={`${name}-checkbox`}
					multiple
					name={name}
					value={selecteds}
					onChange={handleChange}
					input={<OutlinedInput label={label} />}
					renderValue={(selected) => selected.join(', ')}
					MenuProps={MenuProps}
					inputProps={{ tabIndex: index }}
				>
					{values.map((v) => (
						<MenuItem key={v} value={v}>
							<Checkbox checked={selecteds.indexOf(v) > -1} />
							<ListItemText primary={v} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	)
}
