import { CorrienteAction, CorrienteRectificadorState } from "../interfaces/corriente";

const initState: CorrienteRectificadorState = {
    corrienteRectificador: [
        {
            id: 1,
            l1: "",
            l2: "",
            l3: "",
            isNew: false
        }
    ],
}

export const corrienteRectificadorReducer = (state = initState, action: CorrienteAction): CorrienteRectificadorState => {
    switch (action.type) {
        case 'update-corriente-rectificador':
            return {
                ...state,
                corrienteRectificador: state.corrienteRectificador.map(vr => vr.id === action.payload.id ? action.payload : vr)
            }
        case 'set-corriente-rectificador-empty':
            return {
                ...state,
                corrienteRectificador: [
                    {
                        id: 1,
                        l1: "",
                        l2: "",
                        l3: "",
                        isNew: false
                    }
                ]
            }
        default:
            return state;
    }
}