import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import { startSetModalRutinaRectificador, startSetRutinasRectificador } from '../actions/rutina';
import { AppState } from '../../../../../reducers/rootReducer';
import { useSelect } from '../../../../../hooks/useSelect';
import { startLoadRutinasRectificador } from '../../../../resources/actions/resources';
import { RutinaRectificador } from '../interfaces/rutina';
import { ModalSelect } from '../../../../../components/ui/ModalSelect';

const initSelect = {
  idRutina: '',
}

interface Props {
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}
export const ModalSelectRutinaRectificador = ({ setRowModesModel }: Props) => {
  const dispatch = useDispatch();
  const { rutinasRectificadores: rutinas } = useSelector((state: AppState) => state.resources);
  const { modalSelectRutina } = useSelector((state: AppState) => state.reportes.modules.rutinaRectificador);

  const { onChangeSelect, registerSelectData, changeSelect } = useSelect(initSelect);

  const { idRutina } = registerSelectData;

  const handleClose = () => {
    dispatch(startSetModalRutinaRectificador(false))
    changeSelect(initSelect);
  }

  const handleSelect = () => {
    const rutinaDB = rutinas.filter(rutina => rutina.id === idRutina);

    const rutina: RutinaRectificador = {
      id: rutinaDB[0].id,
      numero: rutinaDB[0].numero,
      nombre: rutinaDB[0].nombre,
      descripcion: rutinaDB[0].descripcion,
      observaciones: '',
      isNew: true
    }

    dispatch(startSetRutinasRectificador(rutina));
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [rutinaDB[0].id]: { mode: GridRowModes.Edit, fieldToFocus: 'nombre' },
    }));
    handleClose();
  }

  useEffect(() => {
    if (rutinas.length === 0) {
      dispatch(startLoadRutinasRectificador())
    }
  }, [dispatch, rutinas])

  return (
    <>
      <ModalSelect
        handleClose={handleClose}
        handleSelect={handleSelect}
        modal={modalSelectRutina}
        onChangeSelect={onChangeSelect}
        value={idRutina}
        values={rutinas}
        name='idRutina'
        label='Rutina'
        title='Rutina Rectificador'
      />      
    </>
  );
}
