import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { MaquinariaTable } from './MaquinariaTable';

const Table = memo(() => {
    return (
        <MaquinariaTable />
    );
});


export const Maquinaria = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Maquinaria y/o Equipos
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}
