import React, { memo } from 'react'
import { HerramientaTable } from '../../modules/herramienta/components/HerramientaTable';

const Table = memo(() => {
	return (
		<HerramientaTable />
	);
});

export const StepFive = () => {
	return (
		<>
			<Table />
		</>
	)
}
