import { useEffect } from 'react'
import { Container, Grid, Paper } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { startLoadTecnicos } from '../actions/tecnico';
import { Tecnicos } from './Tecnicos';
import { AppState } from '../../../reducers/rootReducer';


export const TecnicosScreen = () => {
  const dispatch = useDispatch(); 
  const {tecnicos} = useSelector((state: AppState) => state.tecnicos);

   useEffect(() => {
    if(tecnicos && tecnicos.length > 0) return
    dispatch(startLoadTecnicos());
  }, [dispatch, tecnicos]);
  
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={1} maxWidth="100vw">
        {/* Tecnicos */}
        <Grid item xs={12} maxWidth="100vw">
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Tecnicos />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
