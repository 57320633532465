
import { View, StyleSheet } from '@react-pdf/renderer';

import { HeaderTable, ItemsTable } from '../';
import { Cell } from '../../../../../../interfaces/shared';
import { Informe } from '../../../../interfaces/informes';
import { TitleTables } from '../../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const namesUno = [{name: "Alcance de la Actividad:"}];
const title = 'Describa el Alcance de las Actividades Realizadas:';

interface Props {
	active: Informe;
}

export const Alcance = ({ active }: Props) => {
	const filaUno: Cell[] = [
		{ name: active.alcanceActividad! }
	];
	return (
		<>
			<View>
			<TitleTables title={title} sx={{fw: 'normal', mt: 2}}/>
				<View style={styles.descriptionContainer}>
					<HeaderTable names={namesUno} sx={{mt: 2, fw:'bold', c:'#717171', fz:8, pd:0}}/>
					<ItemsTable columnas={filaUno} sx={{fw: 'normal', fz: 10}} bottom/>
				</View>
			</View>

		</>
	)
}
