import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter,
    Routes,
    Route,
  } from 'react-router-dom';  
  
import { LoginScreen } from '../pages/LoginScreen';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import DashboardRoutes from './DashboardRoutes';
import { AppState } from '../reducers/rootReducer';
import { startCheking } from '../modules/auth/actions/auth';
import { Loading } from '../components/ui/Loading';



export const Navigation = () => {
  const dispatch = useDispatch();
  const { status } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    dispatch(startCheking());
  },[dispatch]);

  if(status === 'checking'){
    return <Loading />
  }

  return (
    <BrowserRouter> 
      <Routes> 
        <Route path="/login" element={
          <PublicRoute>
            <LoginScreen />
          </PublicRoute>
        } />
        <Route path="/*" element={
          <PrivateRoute>
            <DashboardRoutes />
          </PrivateRoute>
        } />
      </Routes>
    </BrowserRouter>
  );
}
