import { InformeAction, InformeState } from "../interfaces/informes";

const initState: InformeState = {
    activeInforme: null,
    actividades: '',
    actividadTipo:[],
    exists: false,
    fechaFinal:{
        fecha: new Date(),
        changed:false
    },
    loading: false,
    modalInformePdf: false,
    observaciones: '',
    informes: [],
    selected: [],
    ubicacionInicial: null,
    ubicacionFinal: null
};


export const informeReducer = (state = initState, action: InformeAction):InformeState => {
switch (action.type) {
    case 'load-informes':
        return {
            ...state,
            informes: action.payload,
        }
        case "create-informes":
            return {
                ...state,
                informes: [...state.informes, action.payload],
            };
        case "update-informes":
            return {
                ...state,
                informes: state.informes.map((r) => (r.id === action.payload.id) ? action.payload : r),
            };
        case "delete-informes":
            return {
                ...state,
                informes: state.informes.filter((r) => r.id !== action.payload),
            };
        case "set-active-informe":
            const informe = state.informes.find((r) => r.id === action.payload);
            return {
                ...state,
                activeInforme: informe ? informe : null,
            };
        case "set-inactive-informe":
            return {
                ...state,
                activeInforme: null,
            };
        case "set-modal-informe-pdf":
            return {
                ...state,
                modalInformePdf: action.payload,
            };
        case "set-selected":
            return {
                ...state,
                selected: action.payload,
            };
        case 'set-ubicacionInicial':
            return {
                ...state,
                ubicacionInicial: action.payload
            }
        case 'set-clear-ubicacionInicial':
            return {
                ...state,
                ubicacionInicial: null
            }
        case 'set-ubicacionFinal':
            return {
                ...state,
                ubicacionFinal: action.payload
            }
        case 'set-clear-ubicacionFinal':
            return {
                ...state,
                ubicacionFinal: null
            }
        case 'set-informe-exist':
            return{
                ...state,
                exists: action.payload
            }
        case 'set-actividad-tipo':
            return{
                ...state,
                actividadTipo: action.payload
            }
        case 'set-actividades-informe':
            return {
                ...state,
                actividades: action.payload
            }
        case 'set-observaciones-informe':
            return {
                ...state,
                observaciones: action.payload
            }
        case 'set-fecha-informe':
            return {
                ...state,
                fechaFinal: action.payload
            }
    default:
        return state
        
}
}