
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from "../../../../interfaces/informes";
import { ColTwo, ColThree, ColFour, ColFive, ColSix } from './fragments';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 24,
    textAlign: 'center',
    marginTop: -1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  column: {
    flexDirection: 'column',
    padding: 2,
    width: '6%',
    height: '100%',
    backgroundColor: '#aaaaaa',
    borderTop: 1

  },
  otherColumn: {
    borderLeft: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  rowContainer: {
    flexDirection: 'row',
  },
})

interface Props {
  active: Informe;
}

export const Periodo = ({ active }: Props) => {
  return (
    <>
      <View style={styles.row} fixed>
        <View style={[styles.column]} />
        <ColTwo active={active} />
        <View style={[styles.otherColumn, styles.column, { marginLeft: -2 }]}>
          <View style={[styles.rowContainer]}>
            <View style={[{ transform: 'rotate(270)' }]}>
              <Text style={[{ fontSize: 8, fontWeight: 'bold' }]}>DIAS</Text>
            </View>
          </View>
        </View>
        <ColThree active={active} />
        <ColFour active={active} />
        <ColFive active={active} />
        <ColSix />
      </View>
    </>
  )
}
