import { GridRowId } from "@mui/x-data-grid";
import { Condicion } from "../../../interfaces/shared";
import { SitioAction } from "../interfaces/sitio";

export const startSetCondicionesSitio = (condiciones: Condicion[]): SitioAction => ({
    type: 'set-condiciones-sitio',
    payload: condiciones,
});

export const startUpdateCondicionSitio = (condicion: Condicion): SitioAction => ({
    type: 'update-condiciones-sitio',
    payload: condicion,
});

export const startDeleteCondicionSitio = (id: GridRowId): SitioAction => ({
    type: 'delete-condicion-sitio',
    payload: id,
});


export const startSetCondicionesSitioEmpty = (): SitioAction => ({
    type: 'set-condiciones-sitio-init-state',
});