import { RectificadorAction, RectificadorState } from "../interfaces/rectificadores";


const initState: RectificadorState = {
    rectificadoresInfo: [],
}

export const rectificadoresReducer = (state = initState, action: RectificadorAction): RectificadorState => {
    switch (action.type) {
        case 'set-rectificadores-info':
            return {
                ...state,
                rectificadoresInfo: [...state.rectificadoresInfo, action.payload],
            };
        case 'update-rectificadores-info':
            return {
                ...state,
                rectificadoresInfo: state.rectificadoresInfo.map((b) => {
                    if (b.id === action.payload.id) {
                        return action.payload;
                    }
                    return b;
                }),
            };
        case 'delete-rectificadores-info':
            return {
                ...state,
                rectificadoresInfo: state.rectificadoresInfo.filter((b) => b.id !== action.payload),
            };
        case 'edit-rectificadores-info':
            return {
                ...state,
                rectificadoresInfo: state.rectificadoresInfo.map((b) => {
                    if (b.id === action.payload) {
                        b.isNew = false;
                    }
                    return b;
                }),
            };
        case 'set-rectificadores-empty':
            return {
                ...state,
                rectificadoresInfo: [],
            };
        default:
            return state;
    }

}