type PasswordType = | 'number' | 'alfha' | 'alfha-number' | 'rand';

export const createPassword = (length: number, type: PasswordType) => {
    let characters: string = '';
    let password = ""
    switch (type) {
        case 'number':
            characters = "0123456789";
            break;
        case 'alfha':
            characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            break;
        case 'rand':
            //FOR
            break;

        case 'alfha-number':
            characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789*_";
            break;

        default:
            characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            break;

    }

    for (let i = 0; i < length; i++) {
        if (type === 'rand') {
            password += String.fromCharCode((Math.floor((Math.random() * 100)) % 94) + 33);
        } else {
            password += characters.charAt(Math.floor(Math.random() * characters.length));
        }
    }

    return password;
}