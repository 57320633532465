//import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { dateShort } from '../../helpers/fecha';
import { Title } from '../title/Title';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers/rootReducer';
import { useNavigate } from 'react-router-dom';
import { TableContainer } from '@mui/material';

export const Orders = () => {
  const navigate = useNavigate();
  const { servicios } = useSelector((state: AppState) => state.servicios);

  const newServicios = servicios.filter(servicio => servicio.estado.name === 'Sin ser asignados');

  const hanleGoToServicios = () => {
    navigate('/servicios');
  }
  return (
    <>
      <Title>Servicios Recientes</Title>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Cliente</TableCell>
              {/* <TableCell>Ubicación</TableCell> */}
              <TableCell>Tipo Mantenimiento</TableCell>
              <TableCell>Técnico</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newServicios.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{dateShort(row.fecha)}</TableCell>
                <TableCell>{row.cliente.nombre}</TableCell>
                {/* <TableCell>{row.ubicacion}</TableCell> */}
                <TableCell>{row.tipo.name}</TableCell>
                <TableCell> {row.tecnico}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Link 
          component="button" 
          variant="body1" 
          color="primary" 
          onClick={hanleGoToServicios} 
          sx={{ mt: 3, textDecoration: 'none' }}
      >
        Ver más servicios
      </Link>
    </>
  );
}