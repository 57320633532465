export const dataPie = (servicios: any[], criterio: string, label: string) =>{		
	const serviciosCliente: string[] = [];
	servicios.forEach(s => {		
		const item = s[criterio];		
		serviciosCliente.push(item)
		serviciosCliente.sort()			
	})
	const resultado: any = {};
	serviciosCliente.forEach((el: any) => (resultado[el] = resultado[el] + 1 || 1))
	const labels = Object.keys(resultado)
    const dataSets: number[] = Object.values(resultado)

	const data = {
		labels: labels,
		datasets: [
			{
				label: label,
				data: dataSets,
				backgroundColor: [
					'#eeeeee',
					'#ef5350',
					'#ba68c8',
					'#42a5f5',
					'#4caf50',
					'#ff9800',
				],
				borderColor: [
					'#9e9e9e',
					'#d32f2f',
					'#9c27b0',
					'#0288d1',
					'#2e7d32',
					'#ed6c02',
				],
				borderWidth: 1,
			},
		],
	};

	return data
}