import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Toolbar, Typography } from '@mui/material';
import { LogoutOutlined } from '@mui/icons-material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

import { AppState } from '../../reducers/rootReducer';
import { closeDrawer, openDrawer, toggleDrawer } from '../../modules/ui/actions/ui';
import { startLogout } from '../../modules/auth/actions/auth';

import { useScreenSize } from '../../hooks';
import { NotificationsMenu } from './NotificationsMenu';
import { AccountMenu } from './AcountMenu';


const drawerWidth: number = 240;


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const NavBar = () => {

  const { width } =useScreenSize();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state: AppState) => state.ui)
  
  const onToggleDrawer = () => {
    dispatch(toggleDrawer(!drawerOpen))
  };

  const onLogout = () => {
    dispatch(startLogout())
  }

  useEffect(() => {
    if(width < 1024){
      dispatch(closeDrawer())
    }else{
      dispatch(openDrawer())
    }
  },[width, dispatch])
  

  return (
    <>
      <AppBar position="absolute" open={drawerOpen} >
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={onToggleDrawer}
            sx={{
              marginRight: '36px',
              ...(drawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Beling SAS
          </Typography>
          <NotificationsMenu />
          <AccountMenu />
          <IconButton color="inherit" onClick={onLogout}>
            <LogoutOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  )
}
