import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { IconButton, ImageListItemBar } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Imagen } from '../../interfaces/image';

interface Props {
  images: Imagen[]
  handleDelete?: (id: number) => void
}
export const Gallery = ({images, handleDelete}: Props) => {

  const handleClick = (e: any)=>{
    e.preventDefault();    
  }
  return (
    <ImageList sx={{ width: 500, padding:5 }} cols={2} rowHeight={164} gap={4}>
      {images.map((item) => (
        <ImageListItem key={item.id} onContextMenu={handleClick}>
          <img
            src={`${item.uri}?w=248&h=248&fit=crop&auto=format`}
            srcSet={`${item.uri}?w=248&h=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.description}
            loading="lazy"
            className='imageContainer'
          /> 
          <ImageListItemBar
          sx={{borderRadius: 1}}
            title={item.description}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.4)'}}
                aria-label={`Borrar ${item.description}`}
                onClick={()=>handleDelete!(item.id!)}
              >
                <RemoveCircleOutlineIcon color='error'/>
              </IconButton>
            }
          />       
        </ImageListItem>
      ))}
    </ImageList>
  );
}
