import { Responsable } from "../../../interfaces/shared";
import { ResponsableAction } from "../interfaces/responsable";

export const startSaveFirma = (responsable: Responsable): ResponsableAction => ({
    type: 'set-responsable-firma-reporte',
    payload: responsable
})


export const startUpdateResponsableBeling = (responsable: Responsable): ResponsableAction => ({
    type: 'update-responsable-beling',
    payload: responsable,
});

export const startSetModalFirmaBeling = (modal: boolean): ResponsableAction => ({
    type: 'set-modal-firma-beling',
    payload: modal,
});

export const startSetResponsableBelingEmpty = (): ResponsableAction => ({
    type: 'set-responsable-beling-empty',
});