import { BateriaAction, BateriaState } from "../interfaces/baterias"

const initState: BateriaState = {
    bateriasInfo: [],
}

export const bateriasReducer = (state = initState, action: BateriaAction): BateriaState => {
    switch (action.type) {
        case 'set-baterias-info':
            return {
                ...state,
                bateriasInfo: [...state.bateriasInfo, action.payload],
            };
        case 'update-baterias-info':
            return {
                ...state,
                bateriasInfo: state.bateriasInfo.map((b) => {
                    if (b.id === action.payload.id) {
                        return action.payload;
                    }
                    return b;
                }),
            };
        case 'delete-baterias-info':
            return {
                ...state,
                bateriasInfo: state.bateriasInfo.filter((b) => b.id !== action.payload),
            };
        case 'edit-baterias-info':
            return {
                ...state,
                bateriasInfo: state.bateriasInfo.map((b) => {
                    if (b.id === action.payload) {
                        b.isNew = false;
                    }
                    return b;
                }),
            };
        case 'set-baterias-empty':
            return {
                ...state,
                bateriasInfo: [],
            };
        default:
            return state;
    }

}