import { GridRowId } from "@mui/x-data-grid";
import { fetchConToken } from "../../../apis/fetch";
import { doMessage, startLoading } from "../../ui/actions/ui";
import { Contacto, ContactoAction } from "../interfaces/contactos";


export const starLoadContactos = () => {
    return async (dispatch: any) => {
        dispatch(startLoading())
        const resp = await fetchConToken('contactos', {});
        const body = await resp.json();
        const { contactos, errors, ok, msg } = body;
        if (ok) {
            dispatch(loadContactos(contactos));            
        } else {
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
        dispatch(startLoading())
    }
}


const loadContactos = (contactos: Contacto[]):ContactoAction => ({
    type: 'load-contactos',
    payload: contactos
})


export const startCreateContacto = (contactoCreate: Contacto) => {
    console.log(contactoCreate)
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('contactos', contactoCreate, 'POST');
        const body = await resp.json();
        const {contacto, errors, msg, ok } = body;
        if (ok) {            
            dispatch(createContacto(contacto));
            dispatch(doMessage({message: msg, type: 'success'}));
        } else {
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
        dispatch(startLoading());
    }
}


const createContacto = (contacto: Contacto):ContactoAction => ({
    type: 'create-contacto',
    payload: contacto
})

export const startUpdateContacto = (contactoUpdate: Contacto) => {     
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading())
        const id = getState().contactos.activeContacto.id;
        if(!id){
            return dispatch(doMessage({message: 'No hay contacto activo', type: 'error'}));            
        }
        const resp = await fetchConToken(`contactos/${id}`, contactoUpdate, 'PUT');
        const body = await resp.json();
        const {contacto, errors, msg, ok } = body;
        if (ok) {
            dispatch(upDateContacto(contacto));
            dispatch(doMessage({message: msg, type: 'success'}));
            dispatch(startSetActiveContacto(''))
        } else {
            if(msg){
                console.log(msg)
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
        dispatch(startLoading())
    }
}

const upDateContacto = (contacto: Contacto):ContactoAction => ({
    type: 'update-contacto',
    payload: contacto
})

export const startDeleteContacto = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().contactos.activeContacto.id;
        if(!id){
            dispatch(startLoading());
            return dispatch(doMessage({message: 'No hay contacto activo', type: 'error'}));
        }
        const resp = await fetchConToken(`contactos/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const {errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(deleteContacto(id));
            dispatch(startSetActiveContacto(''));
            dispatch(doMessage({message: msg, type: 'success'}));
            
        } else {            
            if(msg){
                console.log(msg)
                dispatch(doMessage({message: msg, type: 'error'}));
                dispatch(startLoading());
            }else{
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({message: err, type: 'error'}));
                dispatch(startLoading());
            }
            
        }        
    }
}

const deleteContacto = (id: string):ContactoAction => ({
    type: 'delete-contacto',
    payload: id
})


export const startSetActiveContacto = (id: GridRowId):ContactoAction => ({
    type: 'set-active-contacto',
    payload: id
})

export const startSetModalContacto = (modal: boolean):ContactoAction => ({
    type: 'set-modal-contacto',
    payload: modal
})

export const startSetAlertDeleteContacto = (alert: boolean):ContactoAction => ({
    type: 'set-alert-delete-contacto',
    payload: alert
})