import { EquipoAction, EquipoState } from "../interfaces/equipo";

const initState: EquipoState = {
    equipos: []
};

export const equipoSeguridadReducer = (state = initState, action: EquipoAction): EquipoState => {
    switch (action.type) {
        case "set-equipo-seguridad":
            return {
                ...state,
                equipos: [...state.equipos, action.payload]
            };
        case "update-equipo-seguridad":
            return {
                ...state,
                equipos: state.equipos.map(h => {
                    if (h.id === action.payload.id) {
                        return action.payload;
                    }
                    return h;
                })
            };
            case "edit-equipo-seguridad":
                return {
                    ...state,
                    equipos: state.equipos.map(h => {
                        if (h.id === action.payload) {
                            h.isNew = false;
                        }
                        return h;
                    })
                };
        case "delete-equipo-seguridad":
            return {
                ...state,
                equipos: state.equipos.filter(h => h.id !== action.payload)
            };
        case "set-equipo-seguridad-empty":
            return {
                ...state,
                equipos: []
            };
        default:
            return state;
    }
}
