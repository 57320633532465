import { SubcontratistasAction, SubcontratistasState } from '../interfaces/subcontratista';

const initState: SubcontratistasState = {
    subcontratistas: [],
};

export const subcontratistaReducer = (state = initState, action: SubcontratistasAction): SubcontratistasState => {
    switch (action.type) {
        case 'set-personal-subcontratista':
            return {
                ...state,
                subcontratistas: [...state.subcontratistas, action.payload],
            };
        case 'update-personal-subcontratista':
            return {
                ...state,
                subcontratistas: state.subcontratistas.map((personal) => {
                    if (personal.id === action.payload.id) {
                        return action.payload;
                    }
                    return personal;
                }),
            };
        case 'delete-personal-subcontratista':
            return {
                ...state,
                subcontratistas: state.subcontratistas.filter((personal) => personal.id !== action.payload),
            };
        case 'edit-personal-subcontratista':
            return {
                ...state,
                subcontratistas: state.subcontratistas.map((personal) => {
                    if (personal.id === action.payload) {
                        personal.isNew = false;
                    }
                    return personal;
                }),
            };
        case 'set-personal-subcontratista-empty':
            return {
                ...state,
                subcontratistas: [],
            };
        default:
            return state;
    }
}