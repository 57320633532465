import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, selecteds: readonly string[], theme: Theme) {
  return {
    fontWeight:
	selecteds.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
  };
}

interface Props {
	values: any[];
	selecteds: string[],
	label: string,
 	name: string,
	handleChange: (event: SelectChangeEvent<string[]>) => void;
}

export const MultipleSelectChip = ({ label, name, selecteds, values, handleChange }: Props) => {
  const theme = useTheme();
  return (
    <div>
      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel id={`${name}-multiple-chip-label`}>{label}</InputLabel>
        <Select
          labelId={`${name}-multiple-chip-label`}
          id={`${name}-multiple-chip`}
          multiple
          value={selecteds}
          onChange={handleChange}
          input={<OutlinedInput id={`${name}-select-multiple-chip`} label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: any) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {values.map((v) => (
            <MenuItem
              key={v.id}
              value={v.name}
              style={getStyles(v.name, selecteds, theme)}
            >
              {v.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
