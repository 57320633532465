import { GridRowId } from "@mui/x-data-grid";
import {PersonalExternoAction } from "../interfaces/personal";
import { Personal } from "../../../interfaces/shared";

export const startSetpersonalExterno = (personal: Personal):PersonalExternoAction => ({
    type: 'set-personal-externo',
    payload: personal,
});

export const startUpdatePersonalExterno = (personal: Personal):PersonalExternoAction => ({
    type: 'update-personal-externo',
    payload: personal,
});

export const startDeletepersonalExterno = (id: GridRowId) => ({
    type: 'delete-personal-externo',
    payload: id,
});

export const startSetPersonalExternoEmpty = ():PersonalExternoAction => ({
    type: 'set-personal-externo-empty',
});