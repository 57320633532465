import { Page, Document, Text } from '@react-pdf/renderer';

import { Reporte } from '../../interfaces/reportes';
import { HerramientasTable } from './table/HerramientasTable';
import { TitleTables } from '../../../../components/pdf/TitleTables';
import { Header } from './header/Header';

import { Detalle } from './detalle/Detalle';
import { VehiculoTable } from './vehiculo/VehiculoTable';
import { MaterialTable } from './material/MaterialTable';
import { PersonalBelingTable } from './personal_beling/PersonalBelingTable';
import { Footer } from './footer/Footer';
import { styles } from '../../../../styles/pdfStyles';
import { AcompanaClientes } from './acompana_cliente/AcompanaCliente';
import { TableEquipoSeguridad } from './equipo-seguridad/TableEquipoSeguridad';
import { SelloFisico } from './sello/SelloFisico';
import { Actividades } from './actividades/Actividades';
import { Incio } from './inicio/Inicio';
import { FinalReporte } from './final/FinalReporte';
import { LogoHeader } from './shared/LogoHeader';
import { ActividadesNoPlaneadasTable } from './actividades_no_planeadas/ActividadesNoPlaneadasTable';
import { hasImages } from '../../../../helpers';
import { ImagesActividaesNoPlaneadas } from './actividades_no_planeadas/ImagesActividaesNoPlaneadas';
import { Descripcion } from './actividades/Descripcion';

interface Props {
	active: Reporte;
}

export const ReportePdfBdB = ({ active }: Props) => {
	return (
		<Document
			language='es-CO'
			pdfVersion='1.5'
			author='Beling SAS'
			title={`Reporte Servicio No: ${active.servicio?.numero}`}
		>
			<Page size="LETTER" style={styles.page} wrap >
				<LogoHeader active={active} />
				<Header active={active} />
				<Detalle marginTop={5} field={active.servicio.descripcion!} title='Descripción:' />
				<TitleTables
					title='RESUMEN'
					sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
				/>
				<AcompanaClientes actividad={active.tipoActividad!.join(',')} field={active.acompanaCliente!} />
				<PersonalBelingTable active={active} />
				<VehiculoTable active={active} />
				<HerramientasTable active={active} />
				<TableEquipoSeguridad active={active} />
				<MaterialTable active={active} />
				<Incio active={active} />
				<Descripcion active={active}/>
				<Actividades active={active} />
				{active?.actividadesNoPlaneadas?.length! > 0
					&& (<>
						<ActividadesNoPlaneadasTable active={active} />
						{hasImages(active.actividadesNoPlaneadas!) > 0 &&
							<ImagesActividaesNoPlaneadas active={active} />}
					</>
					)}
				<Detalle field={active.observaciones!} title="Observaciones y/o Recomendaciones:" marginTop={3} />
				<Footer active={active} />
				<SelloFisico active={active} />
				<FinalReporte active={active} />
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
					`Página ${pageNumber} de  ${totalPages} páginas`
				)} fixed />
			</Page>
		</Document>
	)
}
