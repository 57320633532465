import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { es } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DateTimeField, esES } from "@mui/x-date-pickers";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";


import { useServicioModal } from "../hooks/useServicioModal";
import { SelectComponent } from "../../../components/select/SelectComponent";
import { MultipleSelectChip } from "../../../components/multipleselect/MultipleSelectChip";
import { AutoComplete } from "../../../components/autocomplete/Autocomplete";
import { Transition } from "../../../components";


export const ModalServicio = () => {

  const {
    activeServicio,
    checked,
    clientes,
    colaboradores,
    handleChangeMultiple,
    contactos,
    estados,
    fullscreen,
    isMobile,
    modalServicio,
    registerData,
    registerDataDate,
    registerSelectData,
    tipos,
    tecnicos,
    origen,
    destino,
    setOrigen,
    setDestino,
    handleChange,
    handleClose,
    handleSave,
    onChange,
    onChangeSelect,
    onDateChange
  } = useServicioModal();

  const { descripcion, nombre, referencia } = registerData;
  const { fecha } = registerDataDate;
  const { cliente, contacto, estado, tecnico, tipo } = registerSelectData;

  return (
    <>
      <Dialog
        open={modalServicio}
        onClose={handleClose}
        fullScreen={fullscreen}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
        scroll="paper"
      >
        <DialogTitle>
          <Box
            sx={{
              my: 1,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            { !isMobile && <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <ElectricalServicesOutlinedIcon />
            </Avatar>}
            <Typography component="h1" variant={isMobile ? "h6" : "h5"}>
              {activeServicio
                ? `Editar Servicio No ${activeServicio.numero}`
                : "Agregar Servicio"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box width="100%" component="div" sx={{ mt: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth sx={{ mt: 1, mx: 1 }} size="small">
                  <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={es} dateAdapter={AdapterDateFns}>
                    {isMobile ?
                      <MobileDateTimePicker
                        label="Fecha y Hora"
                        value={fecha}
                        onChange={(newValue) => {
                          onDateChange(newValue, "fecha");
                        }}
                      /> :
                      <DateTimeField
                        label="Fecha y Hora"
                        required
                        value={fecha}
                        onChange={(newValue) => {
                          onDateChange(newValue, "fecha");
                        }}
                      />}
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <SelectComponent
                  value={tipo}
                  values={tipos}
                  label="Tipo de Servicio"
                  name="tipo"
                  index={2}
                  onChange={onChangeSelect}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <SelectComponent
                  value={cliente}
                  values={clientes}
                  label="Cliente"
                  name="cliente"
                  index={3}
                  onChange={onChangeSelect}
                  isDisabled={activeServicio ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <SelectComponent
                  value={contacto}
                  values={contactos}
                  label="Contacto"
                  name="contacto"
                  index={4}
                  onChange={onChangeSelect}
                  isDisabled={contactos?.length === 0}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth sx={{ mt: 1, mx: 1 }}>
                  <TextField
                    margin="none"
                    value={referencia}
                    onChange={onChange}
                    fullWidth
                    id="referencia"
                    label="Referencia (Opcional)"
                    name="referencia"
                    autoComplete="referencia"
                    inputProps={{ tabIndex: 6 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth sx={{ mt: 1, mx: 1 }}>
                  <TextField
                    margin="none"
                    value={nombre}
                    onChange={onChange}
                    fullWidth
                    id="nombre"
                    label="Nombre Servicio (Opcional)"
                    name="nombre"
                    autoComplete="nombre"
                    inputProps={{ tabIndex: 7 }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <AutoComplete sx={{ marginTop: 1, mx: 1 }} value={origen} setValue={setOrigen} tabIndex={9} title="Origen" required />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <AutoComplete sx={{ marginTop: 1, mx: 1 }} value={destino} setValue={setDestino} tabIndex={9} title="Destino" required />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <SelectComponent
                  value={tecnico}
                  values={tecnicos}
                  label="Técnico"
                  name="tecnico"
                  index={10}
                  onChange={onChangeSelect}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <MultipleSelectChip
                  selecteds={colaboradores}
                  handleChange={handleChangeMultiple}
                  values={tecnicos}
                  label="Colaboradores"
                  name="calaboradores"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth sx={{ mt: 1, mx: 1 }}>
                  <FormLabel component="legend">Prioritario</FormLabel>
                  <FormGroup>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <Switch checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled', tabIndex: 11 }} />
                      <Typography>Si</Typography>
                    </Stack>
                  </FormGroup>

                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {activeServicio && <SelectComponent
                  value={estado}
                  values={estados}
                  label="Estado"
                  name="estado"
                  index={12}
                  onChange={onChangeSelect}
                />}
              </Grid>

            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth sx={{ mt: 1, mx: 1 }}>
                  <TextField
                    margin="none"
                    value={descripcion}
                    onChange={onChange}
                    multiline
                    rows={2}
                    fullWidth
                    id="descripcion"
                    label="Descripción"
                    name="descripcion"
                    autoComplete="descripcion"
                    inputProps={{ tabIndex: 13 }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} tabIndex={14}>Cancelar</Button>
          <Button onClick={handleSave} tabIndex={15}>
            {activeServicio ? "Actualizar" : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};