import { PersonalClaroAction, PersonalClaroState } from '../interfaces/personal';

const initState: PersonalClaroState = {
    personalClaro: [],
};

export const personalClaroReducer = (state = initState, action: PersonalClaroAction): PersonalClaroState => {
    switch (action.type) {
        case 'set-personal-claro':
            return {
                ...state,
                personalClaro: [...state.personalClaro, action.payload],
            };
        case 'update-personal-claro':
            return {
                ...state,
                personalClaro: state.personalClaro.map((personal) => {
                    if (personal.id === action.payload.id) {
                        return action.payload;
                    }
                    return personal;
                }),
            };
        case 'delete-personal-claro':
            return {
                ...state,
                personalClaro: state.personalClaro.filter((personal) => personal.id !== action.payload),
            };
        case 'edit-personal-claro':
            return {
                ...state,
                personalClaro: state.personalClaro.map((personal) => {
                    if (personal.id === action.payload) {
                        personal.isNew = false;
                    }
                    return personal;
                }),
            };
        case 'set-personal-claro-empty':
            return {
                ...state,
                personalClaro: [],
            };
        default:
            return state;
    }
}