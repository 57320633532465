import { AtsResource, ResourceAction, ResourceState } from "../interfaces/resources"


const initialState: ResourceState = {
    atsResource: [],
    alarmas: [],
    baterias: [],
    clientes: [],
    condicionesSeguridad: [],
    contactos: [],
    equipos: [],
    estados: [],
    herramientas: [],
    inversores: [],
    rectificadores: [],
    riesgos: [],
    riesgosAltura: [],
    roles: [],
    rutinasBancoBaterias: [],
    rutinasRectificadores: [],
    sitio: [],
    tecnicos: [],
    tipos: [],
    itemsObra: [],    
}

export const resourceReducer = (state: ResourceState = initialState, action: ResourceAction): ResourceState => {
    switch (action.type) {
        case "load-ats-resources":
            return {
                ...state,
                atsResource: action.payload
            }
        case "load-clientes-select":
            return {
                ...state,
                clientes: action.payload
            }
            case "load-contactos-select":
                return {
                    ...state,
                    contactos: action.payload
                }
        case "load-estados-select":
            return {
                ...state,
                estados: action.payload
            }
        case "load-tipos-select":
            return {
                ...state,
                tipos: action.payload
            }
        case "load-tecnicos-select":
            return {
                ...state,
                tecnicos: action.payload
            }
        case "load-condiciones-seguridad":
            return {
                ...state,
                condicionesSeguridad: action.payload
            }
        case "load-riesgo-altura":
            return {
                ...state,
                riesgosAltura: action.payload
            }
        case "load-riesgos":
            return {
                ...state,
                riesgos: action.payload
            }
        case "load-equipos":
            return {
                ...state,
                equipos: action.payload
            }
        case "load-herramientas":
            return {
                ...state,
                herramientas: action.payload
            }
        case "load-rutinas-rectificador":
            return {
                ...state,
                rutinasRectificadores: action.payload
            }
        case "load-rutinas-banco-baterias":
            return {
                ...state,
                rutinasBancoBaterias: action.payload
            }
        case "load-roles":
            return {
                ...state,
                roles: action.payload
            }
        case "load-alarmas":
            return {
                ...state,
                alarmas: action.payload
            }
        case "load-inversores":
            return {
                ...state,
                inversores: action.payload
            }
        case "load-rectificadores":
            return {
                ...state,
                rectificadores: action.payload
            }
        case "load-baterias":
            return {
                ...state,
                baterias: action.payload
            }
        case "load-sitio":
            return {
                ...state,
                sitio: action.payload
               }
        case "load-items-obra":
            return {
                ...state,
                itemsObra: action.payload
            }
        default:
            return state;
    }
}