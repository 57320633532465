import { Box, Card, CardMedia, IconButton } from "@mui/material";
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';

import { ModalSelloFisico } from "./ModalSelloFisico";
import { useSelloFisico } from "../hooks/useSelloFisico";
import { Titles } from "../../../modules/reportes/components/shared/Titles";


export const SelloFisico = () => {
	const { img, title, modalSelloFisico, handleSelloFisico } = useSelloFisico();
	return (
		<Box
			sx={{
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "flex-start",
				alignItems: "center",
			}}
			mt={1}
		>
			<Titles
				align='left'
				mt={-1}
				text={title ? title : "Sello físico y/o Carnet Empresarial"}
			/>
			<Card sx={{ maxWidth: 460, border: 1, borderColor: 'GrayText' }} elevation={0}>
				<CardMedia
					sx={{ objectFit: 'contain', padding:1, minWidth: 220, maxWidth: 220}}
					component="img"
					height="100"
					image={img}
					alt="sello físco"
				/>
			</Card>
			<IconButton
				color="secondary"
				aria-label="tomar foto"
				size="large"
				onClick={handleSelloFisico()}
			>
				<AddAPhotoOutlinedIcon sx={{ fontSize: 40 }} />
			</IconButton>
			{modalSelloFisico && <ModalSelloFisico /> }
		</Box>
	)
}
