import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridRenderCellParams,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';

import { AppState } from '../../../../../reducers/rootReducer';
import { startLoadCondicionesSeguridad } from '../../../../resources/actions/resources';
import { startDeleteRiesgoElectrico, startEditRiesgoElectrico, startSetRiesgosElectrico, startUpdateRiesgoElectrico } from '../actions/riesgo_electrico';
import { CellExpand } from '../../../../../components/ui/CellExpand';
import { Equipo, Riesgo } from '../../../interfaces/ats';


export const RiesgoElectricoTable = () => {
    const locale = esES.components.MuiDataGrid.defaultProps.localeText
    const dispatch = useDispatch();
    const { condicionesSeguridad: riesgos } = useSelector((state: AppState) => state.resources);
    const { riesgosElectrico } = useSelector((state: AppState) => state.ats.riesgosElectrico);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    useEffect(() => {
        if (riesgos.length === 0) {
            dispatch(startLoadCondicionesSeguridad())
        }

        if(riesgosElectrico.length > 0){
            return
        }
        
        riesgos.map(ra => {
            return dispatch(startSetRiesgosElectrico({
                id: ra.id,
                name: ra.name,
                riesgo: Riesgo.Empty,
                isNew: false
            }))
        })
    }, [dispatch, riesgosElectrico, riesgos]);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        dispatch(startEditRiesgoElectrico(id));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const hanleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteRiesgoElectrico(id))
    }

    const processRowUpdate = (newRow: GridRowModel<Equipo>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateRiesgoElectrico(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        { 
            field: 'name', 
            headerName: 'Identifique y enumere los Controles de riesgos', 
            headerAlign: 'center', 
            flex: 2,
            editable: false,
            sortable: false,
            hideable: false,
            renderCell: CellExpand
        },
        {
            field: 'riesgo',
            headerName: '¿Hay riesgo?',
            flex: 1,
            editable: true,
            sortable: false,
            hideable: false,
            filterable: false,
            type: 'singleSelect',
            valueOptions: [Riesgo.Si, Riesgo.No, Riesgo.NoAplica],
            headerAlign: 'center',
            renderCell:(value: GridRenderCellParams<any, any, any>)=>{                
                return( <Box sx={{mx: 1}}>
                    {value.row.riesgo === Riesgo.Si ? <Chip icon={<CheckCircleOutlineIcon />} label="Si" color="success" variant='outlined' size='small'/> : value.row.riesgo === Riesgo.No ? <Chip icon={<WarningOutlinedIcon />} label="No" color="warning" variant='outlined' size='small' /> : value.row.riesgo === Riesgo.NoAplica ? <Chip icon={<DoNotDisturbOnOutlinedIcon />} label="No Aplica" color="default" variant='outlined' size='small' /> : ''}
                    </Box>)
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        className="textPrimary"
                        onClick={hanleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];
    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={riesgosElectrico}
                columns={columns}
                getRowHeight={() => 45 }                
                editMode="cell"
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}                           
            />
        </Box>
    );
}