import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { Ats } from '../../../interfaces/ats';
import { Title } from "../../../../../components/pdf/Title";
import { EquiposHead } from "../shared/EquiposHead";
import { EquiposRow } from "../shared/EquiposRow";



const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        border: 1,
    },
});

const title = "Equipos de seguridad requeridos."
interface Props {
	active: Ats;
}
export const EquipoSeguridad = ({ active }: Props) => {	
	return (
		<>				
			<Title title={title} sx={{fz: 12, fs: 'italic', fw: 'bold'}}/>
			<View style={styles.tableContainer}>
			<EquiposHead title="Equipos de Seguridad a Utilizar"/>
			<EquiposRow items={active.equipos!} />
			</View>	
		</>
	);
};
