import { MaterialAction, MaterialState } from "../interfaces/material";

const initState: MaterialState = {
    materiales: [],
};

export const materialReducer = (state = initState, action: MaterialAction): MaterialState => {
    switch (action.type) {
        case "set-materiales":
            return {
                ...state,
                materiales: [...state.materiales, action.payload],
            };
        case "update-material":
            return {
                ...state,
                materiales: state.materiales.map((m) => (m.id === action.payload.id) ? action.payload : m),
            };
        case "edit-material":
            return {
                ...state,
                materiales: state.materiales.map((m) => (m.id === action.payload) ? { ...m, isNew: false } : m),
                /*materiales: state.materiales.map((m) => {
                    if (m.id === action.payload) {
                        m.isNew = false;
                    }
                    return m;
                }),*/
            };
            
        case "delete-material":
            return {
                ...state,
                materiales: state.materiales.filter((m) => m.id !== action.payload),
            };
        case "set-materiales-empty":
            return {
                ...state,
                materiales: [],
            };
        default:
            return state;
    }
}
