import {
	Card,
	CardMedia,
	IconButton,
	Stack
} from '@mui/material'
import { styled } from '@mui/material/styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const Input = styled('input')({
	display: 'none',
});

interface Props {
	descripcion?: string;
	foto: string;
	elevation?: number;
	imgWidth: number;
	maxWidth?: number;
	onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;

}
export const CustomCardImage = ({
	descripcion = 'Imagen servicio',
	foto,
	imgWidth,
	elevation = 0,
	maxWidth = 400,
	onChange
}: Props) => {
	return (
		<>
			<Card sx={{ maxWidth: maxWidth }} elevation={elevation}>
				<CardMedia
					component="img"
					height={imgWidth}
					image={foto}
					alt={descripcion}
					sx={{ objectFit: 'cover' }}
				/>
			</Card>
			<Stack direction="row" alignItems="center" spacing={2} sx={{ alignSelf: 'flex-start' }}>
				<label htmlFor="icon-button-file">
					<Input accept="image/*" id="icon-button-file" type="file" onChange={onChange} />
					<IconButton color="primary" aria-label="upload picture" component="span">
						<AddAPhotoIcon />
					</IconButton>
				</label>
			</Stack>
		</>
	)
}
