import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
    GridRowModes,    
    GridActionsCellItem,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';


import { AlertDelete } from '../../servicios/components/AlertDelete';

import { CellExpand } from '../../../components/ui/CellExpand';
import { useItemObraTable } from '../hooks/useItemObraTable';
import { ItemObraTableToolbar } from './ItemObraTableToolbar';
import { ModalItemObra } from './ModalItemObra';

interface Props{
    items: any[]
}
export const ModalItemObraTable = ({items}: Props) => {
 const {
    locale,    
    rowModesModel,    
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    setRowModesModel
 } = useItemObraTable();



    const columns: GridColDef[] = [
        {
            field: 'item',
            headerName: 'Item',
            flex: 1,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'unidad',
            headerName: 'Unidad',
            flex: 1,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'descripcion',
            headerName: 'Descripción',
            flex: 3,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'valorUnitario',
            headerName: 'Valor Unitario',
            align: 'right',
            type: 'number',
            flex: 1,
            editable: true
        },
        {
            field: 'cantidad',
            align: 'center',
            headerName: 'Cantidad',
            flex: 1,
            editable: true
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Guardar"><SaveIcon /></Tooltip>}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Cancelar"><CancelIcon /></Tooltip>}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar"><DeleteIcon color='error' /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <DataGrid
                autoHeight
                rows={items!.slice(0, items!.length)}
                columns={columns}
                editMode='row'
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                slots={{
                    toolbar: ItemObraTableToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }}  
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}                
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}             
            />
            <ModalItemObra />
            {/* <ModalObraPdf /> */}
            <AlertDelete />
        </Box>
    );
}
