import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Paper } from '@mui/material';
import { Contactos } from './Contactos';
import { starLoadContactos } from '../actions/contactos';
import { AppState } from '../../../reducers/rootReducer';



export const ContactosScreen = () => {
    const { contactos } = useSelector((state: AppState) => state.contactos);
  const dispatch = useDispatch(); 
  useEffect(() => {    
    if(contactos && contactos.length > 0) return;
    dispatch(starLoadContactos());
  }, [contactos, dispatch]); 
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Contactos contactos={contactos} />
          </Paper>
        </Grid>
      </Grid>      
    </Container>
  )
}
