import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { ResponsableClienteTable } from './ResponsableClienteTable';

const Table = memo(() => {
    return (
        <ResponsableClienteTable />
    )
});


export const ResponsableCliente = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Quien Recibe los Trabajos Realizados en Sitio por parte de CLARO
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}