import { useState, BaseSyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../../../reducers/rootReducer'

import img from '../../../../../assets/img/bg.png';
import { startAddAnexos, startSetModalAnexoStt } from '../actions/anexos';
import { saveImagen } from '../../../../../helpers';
import { useForm } from '../../../../../hooks';

export const useModalImageSst = ()=>{
    const dispatch = useDispatch();
    const [foto, setFoto] = useState(img)
    const { descripcion, onChange } = useForm({ descripcion: '' });
    const { activeObra } = useSelector((state: AppState) => state.obras.obras)
    const { modalAnexoSst } = useSelector((state: AppState) => state.sst.modules.anexosSst);

    const handleClose = () => {
        dispatch(startSetModalAnexoStt(false));
        setFoto(img)
    };
    
    const handleLoadImage = (event: BaseSyntheticEvent) => {        
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setFoto(reader.result as string);
        }
    }

    const handleSave = async () => {
        const data = {
            imagen: foto,
            servicio: activeObra!.id,
            name: activeObra!.descripcion,
            descripcion: descripcion,
        };
        const { imagen, status } = await saveImagen(data)

        if (status) {
            dispatch(startAddAnexos(imagen))
        }
        dispatch(startSetModalAnexoStt(false));
        setFoto(img)
    }

    return{
        descripcion,
        foto,
        modalAnexoSst,
        handleClose,
        handleLoadImage,
        handleSave,
        onChange,

    }
}