
import { FormReporteScreenBasico } from '../FormReporteScreenBasico';
import { useModalReporteBasico } from '../../hooks';
import { CustomDialog } from '../../../../components';



export const ModalReporteBasico = () => {

    const {
        err,
        fullscreen,
        modalReporteBasico,
        handleClose,
        handleSave
    } = useModalReporteBasico();

    return (
        <CustomDialog
            title='Formulario Mantenimiento'
            onClose={handleClose}
            onSave={handleSave}
            fullscreen={fullscreen}
            err={err}
            openModal={modalReporteBasico}
        >
            <FormReporteScreenBasico />
        </CustomDialog>

    )
}
