import { GridRowId } from "@mui/x-data-grid";
import { MaterialAction } from "../interfaces/material";
import { Material } from "../../../interfaces/shared";


export const startSetMaterial = (material: Material): MaterialAction => ({
    type: 'set-material',
    payload: material,
});

export const startUpdateMaterial = (material: Material): MaterialAction => ({
    type: 'update-material',
    payload: material,
});

export const startDeleteMaterial = (id: GridRowId) => ({
    type: 'delete-material',
    payload: id,
});


export const startSetMaterialEmpty = ():MaterialAction => ({
    type: 'set-material-empty',
});
