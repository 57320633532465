/**
 * Redux: Riesgo Sitio Reducer
 * Recucer para el manejo de la información de riesgo electrico
 * @param state Estado actual del reducer
 * @param action Acción que se desea ejecutar
 * @returns Estado actualizado
 * @author Heuin Lopez Carvajal
 * @email eduinlopezc@gmail.com
 * @version 1.0
 * @since 1.0
 */

import { RiesgoElectricoActions, RiesgoElectricoState } from "../interfaces/riesgo_electrico";

const initialState: RiesgoElectricoState = {
    riesgosElectrico: [],
};

export const riesgoElectricoReducer = (state = initialState, action: RiesgoElectricoActions): RiesgoElectricoState => {
    switch (action.type) {
        case "set-riesgos-electrico":
            return {
                ...state,
                riesgosElectrico: [ ...state.riesgosElectrico, action.payload,]
            };
        case "update-riesgos-electrico":
            return {
                ...state,
                riesgosElectrico: state.riesgosElectrico.map(re => {
                    if (re.id === action.payload.id) {
                        return action.payload;
                    }
                    return re;
                })
            };
        case "delete-riesgos-electrico":
            return {
                ...state,
                riesgosElectrico: state.riesgosElectrico.filter((rs) => rs.id !== action.payload),
            };
        case "edit-riesgos-electrico":
            return {
                ...state,
                    riesgosElectrico: state.riesgosElectrico.map(re => {
                        if (re.id === action.payload) {
                            re.isNew = false;
                        }
                        return re;
                    })
            };
        case "set-riesgos-electrico-empty":
            return {
                ...state,
                riesgosElectrico: [],
            };
        default:
            return state;
    }
}