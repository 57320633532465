import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDate, useMobileDetect } from '../../../hooks';

import { AppState } from '../../../reducers/rootReducer';
import { starSetFechaReporte } from '../actions/reportes';

const initDate = {
    fecha: new Date()
}

export const useFechaFinal = () => {  
	const dispatch = useDispatch();
	const { isMobile } = useMobileDetect();
	const { fechaFinal } = useSelector((state: AppState) => state.reportes.reportes);

	const { onDateChange, changeDate, registerDataDate } = useDate(initDate);

	const { fecha } = registerDataDate;

	const onChangeDate = (newValue: Date, name: string) =>{
		onDateChange(newValue, name);
		const data ={
			fecha: newValue,
			changed: true
		}		
		dispatch(starSetFechaReporte(data));
	}
	
	useEffect(() => {
		if(fechaFinal.changed){
			changeDate({
				fecha: fechaFinal.fecha,
			  });
		}else{
			changeDate(initDate)
		}
		
	},[fechaFinal.changed, fechaFinal.fecha, changeDate])

	return {
		fecha,
		isMobile,
		onChangeDate
	}
}
