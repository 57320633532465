import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { ImagenesTable } from './ImagenesTable';

interface StyleProps{
	mt?: number,
	mb?: number,
	align?: 'center' |'inherit'|'justify'|'left' |'right'
}


const Table = memo(() => {
    return (
        <ImagenesTable />
    );
});

export const ImagenActividad = ({mt= 4, mb=1 }:StyleProps) => {
    return (
        <>
            <Grid container sx={{ mt, mb }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                    Registro Fotográfico
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}