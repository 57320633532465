import { TecnicoState, TecnicoAction } from '../interfaces/tecnico';

const initState: TecnicoState = {
    tecnicos: [],
    activeTecnico: null,
    modalTecnico: false,
    alertDeleteTecnico: false

}

export const tecnicoReducer = (state = initState, action: TecnicoAction): TecnicoState => {
    switch (action.type) {
        case 'load-tecnico':
            const filterTecnicos = action.payload.filter((tecnico: any) => tecnico.roles.some((role: any) => role.name === 'tecnico') && tecnico.roles.length === 1);
            return {
                ...state,
                tecnicos: filterTecnicos
            }
        case 'create-tecnico':
            return {
                ...state,
                tecnicos: [...state.tecnicos, action.payload]
            }
        case 'update-tecnico':
            return {
                ...state,
                tecnicos: state.tecnicos.map(
                    t => (t.id === action.payload.id) ? action.payload : t
                )
            }
        case 'delete-tecnico':
            return {
                ...state,
                tecnicos: state.tecnicos.filter(t => t.id !== action.payload)
            }
        case 'set-active-tecnico':
            const tecnico = state.tecnicos.find(t => t.id === action.payload);
            return {
                ...state,
                activeTecnico: tecnico ? tecnico : null
            }
        case 'set-modal-tecnico':
            return {
                ...state,
                modalTecnico: action.payload
            }
        case 'set-alert-delete-tecnico':
            return {
                ...state,
                alertDeleteTecnico: action.payload
            }
        default:
            return state;
    }
}