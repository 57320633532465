import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box, CssBaseline, Toolbar } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { esES } from '@mui/material/locale';
import { esES as dataGridEsES } from '@mui/x-data-grid';
  import { esES as coreEsES } from '@mui/material/locale';

import { DashboardScreen } from "../components/dashboard/DashboardScreen";
import { NavBar } from "../components/ui/NavBar";
import { SideDrawer } from "../components/ui/SideDrawer";

import { 
  ClientesRoutes,
  TecnicosRoutes,
  ServiciosRoutes,
  AtsRoutes,
  ReporteRoutes,
  UserRoutes,
  ContactosRoutes,
  ListasRoutes, 
} from "../modules";
import { UiAlert } from "../components/ui/UiAlert";
import { LoadingBackdrop } from "../components/ui/LoadingBackdrop";
import { AppState } from "../reducers/rootReducer";
import { ObrasRoutes } from "../modules/obras/components/ObrasRoutes";
import { InformeRoutes } from "../modules/informes/components/InformeRoutes";
import { SstRoutes } from "../modules/sst/components/SstRoutes";
import { ModalChangePassword } from "../modules/user/components/ModalChangePassword";
import { useEffect } from "react";
import { startSetModalChangePass } from "../modules/user/actions/user";
import { PdfScreen } from "../components/dashboard/PdfScreen";


let mdTheme = createTheme({
  palette: {
    primary: {
      main: '#eb445ae6',
    },
    secondary: {
      main: '#9c27b0',
    },
  },
  
})

mdTheme = createTheme(mdTheme, esES, dataGridEsES, coreEsES);

export default function DashboardRoutes() {
  const dispatch = useDispatch();
 const { isLoading: loading} = useSelector((state: AppState) => state.ui);
 const {temporalPassword} = useSelector((state: AppState)=> state.auth.user!)

 useEffect(() => {
   if(temporalPassword){
    dispatch(startSetModalChangePass(true))    
   }
 }, [temporalPassword, dispatch])
 
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}  maxWidth={'100%'}>      
        <CssBaseline />
        <NavBar />
        <SideDrawer />
        <Box
          component="main"
          width={'100%'}
          minHeight={'100vh'}
          maxWidth={'100%'}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
         
          <Routes>
            <Route path="/ats/*" element={<AtsRoutes />} />
            <Route path="/clientes/*" element={<ClientesRoutes />} />
            <Route path="/contactos/*" element={<ContactosRoutes />} />
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/informes/*" element={<InformeRoutes />} />
            <Route path="/listas/*" element={<ListasRoutes />} />
            <Route path="/obras/*" element={<ObrasRoutes />} />
            <Route path="/reportes/*" element={<ReporteRoutes />} />
            <Route path="/servicios/*" element={<ServiciosRoutes />} />
            <Route path="/sst/*" element={<SstRoutes />} />
            <Route path="/tecnicos/*" element={<TecnicosRoutes />} />
            <Route path="/usuarios/*" element={<UserRoutes />} />
            <Route path="/" element={<DashboardScreen />} />
            <Route path="/:id" element={<PdfScreen />} />
          </Routes>     
        </Box>
      </Box>
      { loading && <LoadingBackdrop /> }
      <ModalChangePassword />
      <UiAlert />
    </ThemeProvider>
  )
}
