import { styled } from '@mui/material/styles';
import { Button, Card, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material'
import { PhotoCamera } from '@mui/icons-material';

import { useModalImageSst } from '../hooks/useModalImageSst';

const Input = styled('input')({
    display: 'none',
});

export const ModalImageSst = () => {
    const {descripcion, foto, modalImageSst, onChange, handelLoadImage, handleClose, handleSave} = useModalImageSst();

    return (
        <>
            <Dialog open={modalImageSst} onClose={handleClose}>
                <DialogTitle>Agregar Imagén</DialogTitle>
                <DialogContent>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={foto}
                            alt="Imagen previa"
                        />
                    </Card>
                    <Stack direction="row" alignItems="center" spacing={2} mt={1}>
                        <label htmlFor="icon-button-file">
                            <Input accept="image/*" id="icon-button-file" type="file" onChange={handelLoadImage} />
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                <PhotoCamera />
                            </IconButton>
                        </label>
                        <TextField
                            autoComplete="descripcion"
                            multiline
                            fullWidth
                            size='small'
                            id="descripcion"
                            label="Descripción"
                            margin="dense"
                            name="descripcion"
                            onChange={onChange}
                            required
                            value={descripcion}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
