import React from 'react'
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({	
	container: {
		flexDirection: 'row',
		color: '#9e9e9e',
		flexGrow: 1,
		padding: 0,
		flexWrap: "wrap",
	},
	cellContainer: {
		flexDirection: 'row',
		width: '100%'

	},
	textContainer: {
		paddingLeft: 5,
		textAlign: 'left',
	},
})

interface Props {
	text: string
}
export const HeaderItems = ({text}: Props) => {
	return (
		<View style={styles.container}>
			<View style={styles.cellContainer}>
				<Text style={styles.textContainer}>{text}</Text>
			</View>
		</View>
	)
}