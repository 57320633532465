/**
 * Redux: Riesgo Sitio Reducer
 * Recucer para el manejo de la información de riesgo de altura
 * @param state Estado actual del reducer
 * @param action Acción que se desea ejecutar
 * @returns Estado actualizado
 * @author Heuin Lopez Carvajal
 * @email eduinlopezc@gmail.com
 * @version 1.0
 * @since 1.0
 */

import { RiesgoAlturaActions, RiesgoAlturaState } from "../interfaces/riesgo_altura";

const initialState: RiesgoAlturaState = {
    riesgosAltura: [],
};

export const riesgoAlturaReducer = (state = initialState, action: RiesgoAlturaActions): RiesgoAlturaState => {
    switch (action.type) {
        case "set-riesgos-altura":
            return {
                ...state,
                riesgosAltura: [ ...state.riesgosAltura, action.payload,]
            };
        case "update-riesgos-altura":
            return {
                ...state,
                riesgosAltura: state.riesgosAltura.map(ra => {
                    if (ra.id === action.payload.id) {
                        return action.payload;
                    }
                    return ra;
                })
            };
        case "delete-riesgos-altura":
            return {
                ...state,
                riesgosAltura: state.riesgosAltura.filter((rs) => rs.id !== action.payload),
            };
        case "edit-riesgos-altura":
            return {
                ...state,
                    riesgosAltura: state.riesgosAltura.map(rs => {
                        if (rs.id === action.payload) {
                            rs.isNew = false;
                        }
                        return rs;
                    })
            };
        case "set-riesgos-altura-empty":
            return {
                ...state,
                riesgosAltura: [],
            };
        default:
            return state;
    }
}