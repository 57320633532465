import { GridRowId } from "@mui/x-data-grid";
import { Equipo } from "../../../interfaces/ats";
import { HerramientaAction } from "../interfaces/herramienta";


export const startSetHerramientas = (herramienta: Equipo):HerramientaAction => ({
    type: 'set-herramientas',
    payload: herramienta,
});

export const startUpdateHerramienta = (herramienta: Equipo):HerramientaAction => ({
    type: 'update-herramientas',
    payload: herramienta,
});

export const startDeleteHerramienta = (id: GridRowId):HerramientaAction => ({
    type: 'delete-herramientas',
    payload: id,
});

export const startEditHerramienta = (id: GridRowId):HerramientaAction => ({
    type: 'edit-herramientas',
    payload: id,
});

export const startSetHerramientasEmpty = ():HerramientaAction => ({
    type: 'set-herramientas-empty',
});