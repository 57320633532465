import { View, StyleSheet } from "@react-pdf/renderer";
import { RightRow } from "./RightRow";


const styles = StyleSheet.create({
    column: {
    flexDirection: "column",
    border: 1,
    alignItems: "center",
    padding: 5,
  }, 
  rightColumn: {
    height: "100%",
    width: "25%",
    marginLeft: -1,
    justifyContent: "space-around",
  }
});

export const RightColumn = () => {
  return (
    <>
      <View style={{ ...styles.column, ...styles.rightColumn }}>
		<RightRow titleA="Código No." titleB="BELING_IO_RF001" />
		<RightRow titleA="Versión" titleB="1" />
		<RightRow titleA="Fecha" titleB="2023/11/22" />
		<RightRow titleA="Página" titleB="1 de 1" rowBottom={false} />        
      </View>
    </>
  );
};
