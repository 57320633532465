import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppState } from '../../../reducers/rootReducer';
import { startDeleteReportes, startSetAlertDeleteReporte } from '../actions/reportes';


export const AlertDeleteReporte = () => {
    const dispatch = useDispatch();
    const { alertDeleteReporte } = useSelector((state: AppState) => state.reportes.reportes);
    
    const handleClick = () => {
        dispatch(startDeleteReportes());
        dispatch(startSetAlertDeleteReporte(true));
        handleClose();
    };

    const handleClose = () => {
        dispatch(startSetAlertDeleteReporte(false))
    };

    return (
        <div>
            <Dialog
                open={alertDeleteReporte}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Eliminar Reporte"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea eliminar el Reporte seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleClick} autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}