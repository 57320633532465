import React from 'react'
import { StyleSheet, Image, Text, View } from '@react-pdf/renderer';
import { getImagen } from '../../../../../helpers/imagen';
import { Imagen } from '../../../../../interfaces/image';
import { capitalizeFirtsLetter } from '../../../../../helpers';
import { TitleTables } from '../../../../../components/pdf/TitleTables';


const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 10,
		alignItems: 'center',
		justifyContent: 'space-around'
	},	
	image: {
		padding: 5,
		borderRadius: 10,
		objectFit: 'cover',
	}
});

interface Props {
	images: Imagen[];
	descripcion?: string;
	imgMaxH?: number;
	imgMaxW?: number;
	imgMinH?: number;
	contMinH?: number
	title?: string;
}

export const ImagesPdf = ({
	images,
	descripcion = '',
	imgMaxH,
	imgMaxW,
	imgMinH,
	contMinH,
	title
}: Props) => {

	const sinImages = <View><Text>No evidencias registradas</Text></View>;
	const imagesRows = images.map(i =>
		<View key={i.id}>
			<Image
				style={[
					styles.image, {
						maxHeight: imgMaxH ?? 150,
						maxWidth: imgMaxW ?? 200,
						minHeight: imgMinH ?? 120
					}
				]}
				src={() => getImagen(i.name!)}
			/>
			<Text style={[{ fontSize: 8, fontWeight: 'bold', marginTop: 3 }]}>{capitalizeFirtsLetter(descripcion)}</Text>
		</View>
	)
	const rows = images.length === 0 ? sinImages : imagesRows;
	return (<View >
		{title && <TitleTables title={title} sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />}
		<View style={[styles.container, { minHeight: contMinH ?? 122 }]}>
			{rows}
		</View>
	</View>)
}
