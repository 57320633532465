import { useModalReporteClaro } from '../hooks';

import { FormReporteScreen } from './FormReporteScreen';
import { IniciarForm } from './IniciarForm';
import { CustomDialog } from '../../../components/ui';


export const ModalReporteClaro = () => {

    const {
        atsResource,
        err,
        iniciar,
        pantalla,
        modalReporte,
        registerData,
        registerDataDate,
        registerSelectData,
        reqAts,
        handleChangeRequiereAts,
        handleAddAts,
        handleClose,
        handleIniciar,
        handleSave,
        onChange,
        onDateChange,
        onChangeSelect,
    } = useModalReporteClaro();

    return (
        <CustomDialog
            onClose={handleClose}
            onSave={handleSave}
            openModal={modalReporte}
            err={err}
            fullscreen={pantalla}
            title="Formulario Mantenimiento RIBU CLARO"
        >
            {!iniciar ?
                <IniciarForm handleIniciar={handleIniciar} />
                :
                <FormReporteScreen
                    onChange={onChange}
                    onDateChange={onDateChange}
                    registerData={registerData}
                    registerDataDate={registerDataDate}
                    registerSelectData={registerSelectData}
                    onChangeSelect={onChangeSelect}
                    atsResource={atsResource}
                    handleAddAts={handleAddAts}
                    onClick={handleChangeRequiereAts}
                    reqAts={reqAts}
                />}
        </CustomDialog>
    )
}
