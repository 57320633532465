import { Page, Document, Text } from '@react-pdf/renderer';

import { TitleTables } from '../../../../components/pdf/TitleTables';
import { Reporte } from "../../interfaces/reportes";

import { Detalle } from './detalle/Detalle';
import { VehiculoTable } from './vehiculo/VehiculoTable';
import { HeaderBasico } from './header/HeaderBasico';
import { PersonalBelingTable } from './personal_beling/PersonalBelingTable';
import { TableEquipoSeguridad } from './equipo-seguridad/TableEquipoSeguridad';

import { Footer } from './footer/Footer';
import { SelloFisico } from './sello/SelloFisico';
import { MaterialTable } from './material/MaterialTable';
import { ImagesPdf } from './images/ImagesPdf';
import { LogoHeader } from './shared/LogoHeader';
import { styles } from '../../../../styles/pdfStyles';
import { HerramientasTable } from './table/HerramientasTable';
import { ActividadesBasico } from './actividades/ActividadesBasico';
import { Incio } from './inicio/Inicio';
import { FinalReporte } from './final/FinalReporte';

interface Props {
	active: Reporte;
}

export const ReportePdfBasico = ({ active }: Props) => {
	if(!active){
		return <Document title='No data'>
		  <Page size={'LETTER'}></Page>
		</Document>
	  }

	  return (
		<Document title={`Reporte Servicio No: ${active.servicio?.numero}`}>
			<Page size="LETTER" style={styles.page} wrap >
				<LogoHeader active={active}/>
				<HeaderBasico active={active} />
				<Detalle field={active.servicio.descripcion!} title="Descripción:" />
				{
					active.personal!.length > 0 && (
						<PersonalBelingTable active={active} />
					)
				}
				{
					active.equiposSeguridad?.length! > 0 && (
						<TableEquipoSeguridad active={active} />
					)
				}
				{
					active.vehiculos!.length > 0 && (
						<VehiculoTable active={active} />
					)
				}
				{
					active.herramientas!.length > 0 && (
							<HerramientasTable active={active} />
					)
				}
				<TitleTables title='Datos Generales de la Actividad:' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />
				<Incio active={active}/>
				<TitleTables title='Describa el Tipo de Actividad a realizar:' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />
				<Detalle field={active.tipoActividad!.join(',')} title="Tipo de Actividad" marginTop={0} />
				<TitleTables title='Describa el Alcance de las Actividades Realizadas:' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />
				<Detalle field={active.alcanceActividad!} title="Alcance de la Actividad"  marginTop={0}/>
				{/* <Actividades active={active} /> */}
				<ActividadesBasico active={active} />				
				<ImagesPdf images={active.imagenesAntes} title='Imágenes de Antes de iniciar la Actividad'/>
				<ImagesPdf images={active.imagenesFin} title='Imágenes después de la Actividad:'/>
				<MaterialTable active={active}/>
				<Detalle field={active.observaciones!} title="Conclusiones y/o Recomendaciones:" marginTop={3} />
				<Footer active={active} />
				<SelloFisico active={active} />	
				<FinalReporte active={active} />				
				<Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
					`Página ${pageNumber} de  ${totalPages} páginas`
				)} fixed />
			</Page>
		</Document>
	)
}

