import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
    GridRowModes,    
    GridActionsCellItem,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';


import { AlertDelete } from '../../servicios/components/AlertDelete';

import { CellExpand } from '../../../components/ui/CellExpand';
import { useItemObraTable } from '../hooks/useItemObraTable';
import { ItemObraTableToolbar } from './ItemObraTableToolbar';
import { ModalItemObra } from './ModalItemObra';
import { ModalReporteObra } from '../../informes/components/obra/ModalReporteObra';


export const ItemObraTable = () => {
 const {
    itemsObra,
    locale,    
    rowModesModel,
    width,
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    setRowModesModel
 } = useItemObraTable();



    const columns: GridColDef[] = [
        {
            field: 'item',
            headerAlign: 'center',
            headerName: 'Item',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'unidad',
            headerAlign: 'center',
            headerName: 'Unidad',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'descripcion',
            headerAlign: 'center',
            headerName: 'Descripción',
            flex: width > 900 ? 3 : 0,
            width: 300,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'valorUnitario',
            headerAlign: 'center',
            headerName: 'Valor Unitario',
            align: 'right',
            type: 'number',
            flex: width > 900 ? 1 : 0,
            width: 120,
            editable: true
        },
        {
            field: 'cantidad',
            headerAlign: 'center',
            align: 'center',
            headerName: 'Cantidad',
            flex: width > 900 ? 1 : 0,
            width: 100,
            editable: true
        },
        {
            field: 'actions',
            headerAlign: 'center',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Guardar"><SaveIcon /></Tooltip>}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Cancelar"><CancelIcon /></Tooltip>}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar"><DeleteIcon color='error' /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <DataGrid
                autoHeight
                rows={itemsObra!.slice(0, itemsObra!.length)}
                columns={columns}
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                slots={{
                    toolbar: ItemObraTableToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }}
                editMode='row'         
                
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}                
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true} 
                pageSizeOptions={[5, 10, 20]}      
                
            />
            <ModalItemObra />
            <ModalReporteObra />
            {/* <ModalObraPdf /> */}
            <AlertDelete />
        </Box>
    );
}
