import { combineReducers } from 'redux';
import { reporteReducer } from './reporteReducer';
import { reporteModulesReducer } from './reporteModulesReducer';


export const reporteRootReducer = combineReducers({
    modules: reporteModulesReducer,    
    reportes: reporteReducer,
});

export type ReporteState = ReturnType<typeof reporteRootReducer>;