import { SstAction, SstState } from "../interfaces/sst";

const initState: SstState = {
    activeSst: null,
    informesSst: [],
    informeSst: null,
    modalSst: false,
    modalObraSst: false,
    modalInformePdfSst: false,
    ubicacionInicial: undefined,
    ubicacionFinal: undefined
};


export const sstReducer = (state = initState, action: SstAction): SstState => {

    switch (action.type) {
        case 'load-informes-sst':
            return {
                ...state,
                informesSst: action.payload
            }
        case 'create-informes-sst':
            return {
                ...state,
                informesSst: [...state.informesSst, action.payload]
            }
        case 'update-informes-sst':
            return {
                ...state,
                informesSst: state.informesSst.map(
                    is => (is.id === action.payload.id) ? action.payload : is
                )
            }
        case 'delete-informes-sst':
            return {
                ...state,
                informesSst: state.informesSst.filter(is => is.id !== action.payload)
            }
        case 'set-modal-informe-sst':
            return {
                ...state,
                modalSst: action.payload
            }
        case 'set-modal-obra-sst':
            return {
                ...state,
                modalObraSst: action.payload
            }
        case 'set-modal-informe-pdf-sst':
            return {
                ...state,
                modalInformePdfSst: action.payload
            }
        case 'set-active-informe-sst':
            const sst = state.informesSst.find(is => is.id === action.payload);
            return {
                ...state,
                activeSst: sst ? sst : null
            }
        case 'set-inactive-informe-sst':
            return {
                ...state,
                activeSst: null
            }
        case 'load-informe-sst':
            return {
                ...state,
                informeSst: action.payload
            }
        case 'set-ubicacion-inicial':
            return {
                ...state,
                ubicacionInicial: action.payload
            }
        case 'set-clear-ubicacion-inicial':
            return {
                ...state,
                ubicacionInicial: undefined
            }
        case 'set-ubicacion-final':
            return {
                ...state,
                ubicacionFinal: action.payload
            }
        case 'set-clear-ubicacion-final':
            return {
                ...state,
                ubicacionFinal: undefined
            }
        default:
            return state
    }

}