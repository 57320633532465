import { combineReducers } from 'redux';
import { acompanaClienteReducer } from '../acompana_cliente/state/acompanaClienteReducer';
import { actividadReducer } from '../actividad/state/actividad';
import { materialReducer } from '../material/state/material';
import { selloFisicoReducer } from '../sello_fisico/state/selloFisicoReducer';
import { useReducerTipoActividad } from '../tipo_actividad/state/useReducerTipoActividad';
import { sharedReducer } from '../shared_state/state/shareReducer';
import { accidenteReducer } from '../accidentes/state/accidenteReducer';
import { meteorologicaReducer } from '../meteorologicas/state/meteorologicaReducer';
import { condicionesSitoReducer } from '../sitio/state/condicionesSitioReducer';
import { personalExternoReducer } from '../personal_externo/state/personalExternoReducer';
import { subcontratistaReducer } from '../subcontratistas/state/subcontratistaReducer';
import { maquinariaReducer } from '../maquinaria/state/maquinariaReducer';
import { imagesReducer } from '../images/state/imagesReducer';
import { responsableBelingReducer } from '../responsable/state/responsableBelingReducer';
import { responsableClienteReducer } from '../recibe/state/responsableClienteReducer';

export const sharedRootReducer = combineReducers({
	accidentes: accidenteReducer,
	actividades: actividadReducer,
	acompana: acompanaClienteReducer,
	condicionesSitio: condicionesSitoReducer,
	images: imagesReducer,
	maquinaria: maquinariaReducer,
	materiales: materialReducer,
	meteorologicas: meteorologicaReducer,
	personalExterno: personalExternoReducer,
	responsableBeling: responsableBelingReducer,
    responsableCliente: responsableClienteReducer,
	shared: sharedReducer,
	sello: selloFisicoReducer,
	subcontratistas: subcontratistaReducer,
	tipoActividad: useReducerTipoActividad,
})