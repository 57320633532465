import { Box, Grid, TextField } from "@mui/material";
import { useAcompanaCliente } from "../hooks/useAcompanaCliente";

export const AcompanaCliente = () => {
  const { formError, registerData, onChange, onChangeState } = useAcompanaCliente();
  const { cargoForm, nombreForm, identificacionForm } = registerData;

  return (
    <>
      <Box	 
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
		
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              autoComplete="nombreForm"
			        error={nombreForm.length< 5 && formError}
			        helperText={ nombreForm.length< 5 && formError ? 'El campo es obligatorio':''}
              fullWidth
              id="nombreForm"
              label="Nombre de quien acompaña la actividad"
              margin="normal"
              name="nombreForm"
              onChange={onChange}
              onBlur={onChangeState}
              required
              value={nombreForm}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              autoComplete="identificacionForm"
              fullWidth
              id="identificacionForm"
              label="Identificación"
              margin="normal"
              name="identificacionForm"
              onChange={onChange}
              onBlur={onChangeState}
              required
              value={identificacionForm}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              autoComplete="cargoForm"
              fullWidth
              id="cargoForm"
              label="Cargo"
              margin="normal"
              name="cargoForm"
              onChange={onChange}
              onBlur={onChangeState}
              required
              value={cargoForm}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
