import { PersonalAction, PersonalState } from '../interfaces/personal';

const initState: PersonalState = {
    personal: [],
};

export const personalReducer = (state = initState, action: PersonalAction): PersonalState => {
    switch (action.type) {
        case 'set-personal':
            return {
                ...state,
                personal: [...state.personal, action.payload],
            };
        case 'update-personal':
            return {
                ...state,
                personal: state.personal.map((personal) => {
                    if (personal.id === action.payload.id) {
                        return action.payload;
                    }
                    return personal;
                }),
            };
        case 'delete-personal':
            return {
                ...state,
                personal: state.personal.filter((personal) => personal.id !== action.payload),
            };
        case 'edit-personal':
            return {
                ...state,
                personal: state.personal.map((personal) => {
                    if (personal.id === action.payload) {
                        personal.isNew = false;
                    }
                    return personal;
                }),
            };
        case 'set-personal-empty':
            return {
                ...state,
                personal: [],
            };
        default:
            return state;
    }
}