import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePosition } from 'use-position';
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';

import { AppState } from '../../../../reducers/rootReducer';
import { startSetActividadesReporte } from '../../actions/reportes';
import { useMobileDetect } from '../../../../hooks';
import { startSetUbicacionInicial } from '../../actions/reportes';

export const StepSeven = () => {
	const dispatch = useDispatch();
	const watch = false;
  const { latitude, longitude, timestamp } = usePosition(watch);
	const { isMobile } = useMobileDetect();
	const { actividades } = useSelector((state: AppState) => state.reportes.reportes)
	const [actividad, setForm] = useState(actividades)

	const onChange = (event: ChangeEvent<HTMLInputElement>) =>{
		setForm(event.target.value);
		dispatch(startSetActividadesReporte(actividad))
	}

	const hanleOnBlur = ()=>{
		dispatch(startSetActividadesReporte(actividad))
	}


	const handleIniciar = () => {
		const ubicacion = {
		  latitude,
		  longitude,
		  timestamp
		}
		dispatch(startSetUbicacionInicial(ubicacion));
	  }

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<IconButton 
				color="primary" 
				aria-label="Ubicación" 
				size="large"
				onClick={handleIniciar}
				>
					<AddLocationAltOutlinedIcon  sx={{fontSize: !isMobile ? 70 : undefined}}/>
				</IconButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Typography>
					Pulse para iniciar la actividad
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<TextField
							autoComplete="actividades"
							autoFocus
							fullWidth
							id="actividades"
							label="Descripción de Actividad a Realizar"
							margin="normal"
							multiline
							name="actividades"
							onChange={onChange}
							onBlur={hanleOnBlur}
							required
							rows={3}
							value={actividad}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}
