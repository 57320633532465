import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Stack } from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AppState } from "../../reducers/rootReducer";
import { doRemoveMessage } from "../../modules/ui/actions/ui";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const UiAlert = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const { alert: { type, message, duration } } = useSelector((state: AppState) => state.ui)


  const handleClose = (event: React.SyntheticEvent | Event) => {
    dispatch(doRemoveMessage())
    setOpen(false);
  };

  const delay = (s: number) =>
    new Promise((p) => setTimeout(p, (s * 1000) | 0))

  const timeoutHandler = async (s: number) => {
    await delay(s)
    dispatch(doRemoveMessage())
    setOpen(false);
  }

  useEffect(() => {
    if (message) {
      setOpen(true);
    }

    timeoutHandler(3)
    
    return () => {
      setOpen(false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])
  return (
    <Stack zIndex={9999} spacing={2} sx={{ width: '100%' }}>
      {open && <Snackbar open={open} autoHideDuration={duration ?? 3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }} >
          {message}
        </Alert>
      </Snackbar>}
    </Stack>
  )
}
