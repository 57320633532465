import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppState } from '../../../reducers/rootReducer';
import { startDeleteAts, startSetAlertDeleteAts } from '../actions/ats';

export const AlertDeleteAts = () => {
    const dispatch = useDispatch();
    const { modalDeleteAts } = useSelector((state: AppState) => state.ats.ats);
    
    const handleClick = () => {
        dispatch(startDeleteAts());
        dispatch(startSetAlertDeleteAts(true));
        handleClose();
    };

    const handleClose = () => {
        dispatch(startSetAlertDeleteAts(false))
    };

    return (
        <div>
            <Dialog
                open={modalDeleteAts}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Eliminar ATS"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea eliminar el ATS seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleClick} autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}