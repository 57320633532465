import { StyleSheet, View } from "@react-pdf/renderer";
import { Reporte } from "../../../interfaces/reportes";
import { HeaderItems } from "../shared/HeaderItems";
import { ListItems } from "../shared/ListItems";
import { fechaDetalle } from "../../../../../helpers";
import { TitleTables } from "../../../../../components/pdf/TitleTables";

const borderColor = '#9e9e9e'

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		flexWrap: "wrap",
		border: borderColor,
		borderWidth: 1,
	},		
	bodyActividad: {
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'left',
		padding: 0,
		flexWrap: "wrap",
		marginTop: 2
	},
});

interface Props {
	active: Reporte;
	hasDate?: boolean;
}

const title: string = 'Geolocalizacion al inicio del servicio';

export const Incio = ({ active, hasDate = false }: Props) => {
	return (
		<View>
			<TitleTables
				title={title}
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
			/>
			<View style={styles.container}>
				<View style={styles.bodyActividad}>
					<ListItems sx={{ fw: 'normal', color: '#4dabf5' }} text={fechaDetalle(active.ubicacionInicial?.timestamp!) ?? ''} />
					<ListItems sx={{ fw: 'normal', color: '#4dabf5' }} text={`Latitud: ${active.ubicacionInicial?.latitude! ?? ''}, Longitud: ${active.ubicacionInicial?.longitude ?? ''}`} />
				</View>
				{hasDate && <View style={styles.bodyActividad}>
					<HeaderItems text="Fecha y Hora inicio:" />
					<ListItems sx={{ fw: 'normal' }} text={fechaDetalle(active.fechaHoraInicio!) ?? ''} />
				</View>}
			</View>
		</View>
	)
}