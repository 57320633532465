import { combineReducers } from 'redux';
import { obraReducer } from './obraReducer';
import { grupoReducer } from './grupoReducer';
import { itemObraReducer } from './itemObraReducer';


export const obraRootReducer = combineReducers({  
    obras: obraReducer,
    grupos: grupoReducer,
    itemsObra: itemObraReducer
});

export type ObrasState = ReturnType<typeof obraRootReducer>;