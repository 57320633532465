import { Box } from '@mui/material'
import { Titles } from '../shared/Titles';
import { AcompanaCliente } from '../../../../shared/acompana_cliente/components/AcompanaCliente';
import { TipoActividad } from '../../../../shared/tipo_actividad/components/TipoActividad';
import { SitioInfo } from '../../modules/sitio_info/components/SitioInfo';

interface FormReporteScreenProps {
  registerData: {
    actividades: string,
    observaciones: string,
    ats: boolean,
  };
  registerDataDate: {
    fecha: any,
  };
  onChange: any;
  onDateChange: any;

}

export const FormReporteScreenEmergencia = ({ onChange, registerData, onDateChange, registerDataDate }: FormReporteScreenProps) => {
  // const { actividades, ats } = registerData;
  // const { fecha } = registerDataDate;
  return (
    <Box
      width="100%"
      component="div"
      sx={{ my: 2, mx: 'auto', maxWidth: '90vw' }}
    >
      <Titles
        align='left'
        mt={0}
        text="Describa el tipo de servicio y quien acompaña la actividad por parte de CLARO"
      />
      <AcompanaCliente />
      <Titles
        align='left'
        text="Tipo de Actividad"
      />
      <TipoActividad />
      <SitioInfo />
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            autoComplete="actividades"
            autoFocus
            fullWidth
            id="actividades"
            label="Descripción de las actividades a realizar"
            margin="normal"
            multiline
            name="actividades"
            onChange={onChange}
            required
            rows={3}
            value={actividades}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="nombre"
            autoFocus
            fullWidth
            id="nombre"
            label="Nombre de quien acompaña la actividad"
            margin="normal"
            multiline
            name="nombre"
            onChange={onChange}
            required
            value={actividades}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="nombre"
            autoFocus
            fullWidth
            id="nombre"
            label="Identificación"
            margin="normal"
            multiline
            name="nombre"
            onChange={onChange}
            required
            value={actividades}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="nombre"
            autoFocus
            fullWidth
            id="nombre"
            label="Cargo"
            margin="normal"
            multiline
            name="nombre"
            onChange={onChange}
            required
            value={actividades}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} >
        <Grid item xs={2} alignContent="center" mt={1}>
          <Typography component="h1" align='left' variant="h6">
            Tipo de Actividad
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControl >
            <RadioGroup
              row
              aria-labelledby="ats-buttons-group-label"
              name="ats"
              value={ats}
              onChange={onChange}
            >
              <FormControlLabel value="true" control={<Radio />} label="Visita" />
              <FormControlLabel value="false" control={<Radio />} label="Servicio o Atención de Emergencia" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Personal />
      <Vehiculos />
      <Herramienta />
      <Equipo />
      <Material />
      <ResponsableBeling />
      <ResponsableCliente />
      <Grid container spacing={2} sx={{ mt: 0.5 }}>
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth >
            <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns} utils={DateFnsUtils}>
              <MobileDateTimePicker
                renderInput={(props) => <TextField  {...props} />}
                label="Fecha y Hora de Finalización"
                value={fecha}
                onChange={(newValue, name = "fecha") => {
                  onDateChange(newValue, name);
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
              </Grid>*/}
    </Box>
  )
}
