import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';

import { startSetModalCliente, startSetActiveCliente, startSetIsNewCliente } from '../actions/cliente';

export const ClienteToolBar = () => {
	const dispatch = useDispatch();
    const handleClick = () => {
		dispatch(startSetActiveCliente(''));
        dispatch(startSetIsNewCliente(true));
        dispatch(startSetModalCliente(true));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Nuevo
            </Button>
        </GridToolbarContainer>
    );
}