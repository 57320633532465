import { useEffect } from 'react'
import { Container, Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Obra } from './Obra';
import { AppState } from '../../../reducers/rootReducer';
import { loadGrupos, startLoadGrupos } from '../actions/grupos';
import { useNavigate } from 'react-router-dom';



export const ObraDetailScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeObra } = useSelector((state: AppState) => state.obras.obras);
   
    useEffect(() => { 
        if (!activeObra){
            navigate("/obras", { replace: false });
        }else{
            dispatch(startLoadGrupos())
        }
        
        return ()=>{
            //dispatch(startSetInactiveObra());
            dispatch(loadGrupos([]));
        }
    },[dispatch, activeObra, navigate]);
    
    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                {/* Clientes */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Obra />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}