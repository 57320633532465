import { Box, FormControl, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DateTimeField, esES } from '@mui/x-date-pickers';
import { es } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFechaFinal } from '../../../hooks';


export const FechaFinal = () => {

	const { fecha, isMobile, onChangeDate } = useFechaFinal();

	return (
		<Box
			sx={{
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<FormControl fullWidth >
						<LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={es} dateAdapter={AdapterDateFns}>
							{
								isMobile
									? <MobileDateTimePicker
										label="Fecha y Hora de Finalización"
										value={fecha}
										onChange={(newValue) => {
											onChangeDate(newValue!, "fecha");
										}}
									/>
									: <DateTimeField
										label="Fecha y Hora de Finalización"
										value={fecha}
										onChange={(newValue) => {
											onChangeDate(newValue!, "fecha");
										}}
									/>
							}
						</LocalizationProvider>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	)
}
