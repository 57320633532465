import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { EquipoTable } from './EquipoTable';
import { StyleProps } from '../../../modules/reportes/interfaces/reportes';


const Table = memo(() => {
    return(
        <EquipoTable />
    )
});

export const Equipo = ({mt = 4, mb = 1 }: StyleProps) => {
    return (
        <>
            <Grid container sx={{ mt, mb }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Equipos de seguridad requeridos
                    </Typography>
                </Grid>
            </Grid>
            <Table />
        </>
    )
}