import { 
    atsrootReducer, 
    authReducer, 
    clienteReducer, 
    combineReducers, 
    contactoReducer, 
    imagesReducer,
    informeRootReducer,
    itemReducer,
    listaReducer,
    obraRootReducer,
    reporteRootReducer, 
    resourceReducer, 
    servicioReducer, 
    sharedRootReducer,
    sstRootReducer,
    tecnicoReducer, 
    uiReducer, 
    userReducer,
 } from "../modules";


export const rootReducer = combineReducers({
    ats: atsrootReducer,
    auth: authReducer,
    clientes: clienteReducer,
    contactos: contactoReducer,
    informes: informeRootReducer,
    items: itemReducer,
    images: imagesReducer,
    listas: listaReducer,
    obras: obraRootReducer,
    reportes: reporteRootReducer,
    resources: resourceReducer,
    servicios: servicioReducer,
    shared: sharedRootReducer,
    sst: sstRootReducer,
    tecnicos: tecnicoReducer,
    ui: uiReducer,
    usuarios: userReducer,
});

export type AppState = ReturnType<typeof rootReducer>;