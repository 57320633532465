import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';
import { v4 as UUID } from 'uuid';

import { AppState } from '../../../../../reducers/rootReducer';
import { startSetpersonalClaro, startUpdatePersonalClaro, startDeletepersonalClaro } from '../actions/personal';
import { EditToolbarProps, Personal } from '../../../../../interfaces/shared';
import { useScreenSize } from '../../../../../hooks';

function EditToolbar(props: EditToolbarProps) {
    const dispatch = useDispatch();    
    const { setRowModesModel } = props;

    const handleClick = () => {
        let id = UUID();
        dispatch(startSetpersonalClaro({ id, nombres: '', identificacion: '', cargo: '', isNew: true }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nombres' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar Personal
            </Button>
        </GridToolbarContainer>
    );
}

export const PersonalTable = () => {
    const { width } = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText
    const dispatch = useDispatch();
    const { personalClaro } = useSelector((state: AppState) => state.reportes.modules.personalClaro);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeletepersonalClaro(id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel<Personal>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdatePersonalClaro(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'nombres',
            headerAlign: 'center',
            headerName: 'Nombres y Apellidos',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: true
        },
        {
            field: 'identificacion',
            headerAlign: 'center',
            headerName: 'Identificación',
            flex: width > 900 ? 1 : 0,
            width: 150,
            editable: true
        },
        {
            field: 'cargo',
            headerAlign: 'center',
            headerName: 'Cargo',
            flex: width > 900 ? 1 : 0,
            width: 150,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerAlign: 'center',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={personalClaro}
                columns={columns}
                editMode="row"
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }} 
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                hideFooter                
                localeText={{ ...locale, noRowsLabel: 'No hay personal registrado'}}
                rowHeight={35}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
        </Box>
    );
}