import { GridRowId } from "@mui/x-data-grid";
import { Inversor, InversorAction } from "../interfaces/inversores";

export const startSetInversoresInfo = (inversor: Inversor): InversorAction => ({
    type: "set-inversores-info",
    payload: inversor
});

export const startUpdateInversoresInfo = (inversor: Inversor): InversorAction => ({
    type: "update-inversores-info",
    payload: inversor
});

export const startDeleteInversoresInfo = (id: GridRowId): InversorAction => ({
    type: "delete-inversores-info",
    payload: id
});

export const startEditInversoresInfo = (id: GridRowId): InversorAction => ({
    type: "edit-inversores-info",
    payload: id
});

export const startSetInversoresEmpty = (): InversorAction => ({
    type: "set-inversores-empty"
});
// Compare this snippet from src/modules/reportes/modules/baterias_info/interfaces/baterias.ts: