import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import {
    GridRowModes,
    GridActionsCellItem,
    GridColDef,
    DataGrid,    
} from '@mui/x-data-grid';
import moment from "moment";

import { AlertDelete } from '../../servicios/components/AlertDelete';
import { CellExpand } from '../../../components/ui/CellExpand';
import { useSstTable } from '../hooks/useSstTable';
import { SstTableToolbar } from './SstTableToolbar';
import { ModalSst } from './ModalSst';
import { ModalSelectObra } from './ModalSelectObra';
import { ModalInformeSstPdf } from './pdf/ModalInformeSstPdf';


export const SstTable = () => {
 const {
    locale,
    informes,    
    rowModesModel,
    width,   
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    handleShowDetalle,
    setRowModesModel
 } = useSstTable();

    const columns: GridColDef[] = [
        {
            field: 'numero',
            headerName: 'Numero',
            flex: width > 900 ? 0.5 : 0,
            width: 100,
            editable: false            
        },
        {
            field: 'fecha',
            headerName: 'Fecha Informe',
            flex: width > 900 ? 0.7 : 0,
            width: 100,
            editable: false
            , renderCell: (value) => {
                return (<Typography>{moment(value.row.fecha).format('DD/MM/YYYY')}</Typography>)
            }
        },
        { field: 'cliente', headerName: 'Cliente', flex: 1, editable: false, renderCell: CellExpand },
        {
            field: 'descripcionObra',
            headerName: 'Descripción Obra',
            flex: width > 900 ? 1 : 0,
            width: 200,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Guardar"><SaveIcon /></Tooltip>}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Cancelar"><CancelIcon /></Tooltip>}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Ver informe"><PictureAsPdfOutlinedIcon color='info' /></Tooltip>}
                        label="Ver informe"
                        onClick={handleShowDetalle(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar"><DeleteIcon color='error' /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <DataGrid
                autoHeight
                rows={informes.slice(0, informes.length)}
                columns={columns}
                editMode="row"
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                slots={{
                    toolbar: SstTableToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            <ModalSelectObra />
            <ModalSst />
            <ModalInformeSstPdf />
            <AlertDelete />
        </Box>
    );
}
