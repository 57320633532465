import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardMedia, Grid, IconButton, TextField } from '@mui/material';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';

import imagen from '../../assets/img/bg.png'
import { ModalFirmaCliente } from '../recibe/components/ModalFirmaCliente';
import { startSetModalFirmaCliente, startUpdateResponsableCliente } from '../recibe/actions/recibe';
import { useForm } from '../../hooks/useForm';
import { AppState } from '../../reducers/rootReducer';
import { Titles } from '../../modules/reportes/components/shared/Titles';
import { SelloFisico } from '../sello_fisico/components/SelloFisico';
import { GridRowId } from '@mui/x-data-grid';

const formInit = {
	nombreResCli: '',
	identificacionResCli: '',
	cargoResCli: ''
}

interface Props {
	name: string;
	reporte: GridRowId;
}

export const ResponsableCliente = ({ name, reporte }: Props) => {
	const dispatch = useDispatch();
	const firmaImg = useRef('');
	const { modalFirmaCliente, responsableCliente } = useSelector((state: AppState) => state.shared.responsableCliente);
	const { nombre, identificacion, cargo, firma, isNew } = responsableCliente[0];
	const { uri, name: nameImg } = firma;

	const { registerData, onChange, changeData } = useForm(formInit);
	const { nombreResCli, identificacionResCli, cargoResCli } = registerData;

	const handleFirmaClick = () => {

		handleUpdateResponsable();
		dispatch(startSetModalFirmaCliente(true));
	};

	const handleUpdateResponsable = () => {
		const responsable = {
			id: 1,
			nombre: nombreResCli,
			cargo: cargoResCli,
			identificacion: identificacionResCli,
			isNew: false,
			firma: { uri, name: nameImg }
		}
		dispatch(startUpdateResponsableCliente(responsable))
	}

	useEffect(() => {
		if (uri === '') {
			firmaImg.current = imagen;
		} else {
			firmaImg.current = uri
		}
	}, [uri, modalFirmaCliente])

	useEffect(() => {
		if (!isNew) {
			const data = {
				nombreResCli: nombre,
				identificacionResCli: identificacion,
				cargoResCli: cargo
			};
			changeData(data);
		}
	}, [isNew, cargo, identificacion, nombre, changeData])

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6} md={6}>
						<TextField
							autoComplete="nombreResCli"
							fullWidth
							id="nombreResCli"
							label="Nombre y Apellidos"
							margin="normal"
							multiline
							name="nombreResCli"
							onChange={onChange}
							onBlur={handleUpdateResponsable}
							required
							rows={1}
							value={nombreResCli}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<TextField
							autoComplete="identificacionResCli"
							fullWidth
							id="identificacionResCli"
							label="Identificación"
							margin="normal"
							multiline
							name="identificacionResCli"
							onBlur={handleUpdateResponsable}
							onChange={onChange}
							required
							rows={1}
							value={identificacionResCli}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<TextField
							autoComplete="cargoResCli"
							fullWidth
							id="cargoResCli"
							label="Cargo"
							margin="normal"
							multiline
							name="cargoResCli"
							onBlur={handleUpdateResponsable}
							onChange={onChange}
							required
							rows={1}
							value={cargoResCli}
						/>
					</Grid>
				</Grid>
				<ModalFirmaCliente name={name} reporte={reporte} />
			</Box>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={6} md={6}>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "flex-start",
							alignItems: "center"
						}}
					>
						<Titles
							align='left'
							mt={0}
							text="Firma"
						/>
						<Card sx={{ maxWidth: 460, border: 1, borderColor: 'GrayText' }} elevation={0}>
							<CardMedia
								sx={{ objectFit: 'contain', padding: 1, minWidth: 225, maxWidth: 225 }}
								component="img"
								height="100"
								image={firmaImg.current}
								alt="firma"
							/>
						</Card>
						<IconButton
							color="primary"
							aria-label="Firmar"
							size="large"
							onClick={handleFirmaClick}>
							<DrawOutlinedIcon sx={{ fontSize: 40 }} color='secondary' />
						</IconButton>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<SelloFisico />
				</Grid>
			</Grid>
		</>
	)
}
