import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { usePosition } from 'use-position';


import { AppState } from '../../../reducers/rootReducer';
import { useForm, useScreenSize, useDate, useSelect } from '../../../hooks';
import { 
    closeReportes, 
    startCreateReportes, 
    startSetHasAtsReporte, 
    startSetUbicacionInicial 
} from '../actions/reportes';
import { deleteFirma, fechaHora, toString } from '../../../helpers';
import { starLoadAtsResources } from '../../resources/actions/resources';
import { 
    startCreateServicio, 
    startSetModalAts, 
    startSetModalReporte, 
    startSetOlsActiveServicio 
} from '../../servicios/actions/servicio';
import { startSetNoServicio } from '../../ats/actions/ats';

const initForm = {
    alcanceActividad: '',
    observaciones: '',
    requiereAts: false,
}

const initDate = {
    fecha: new Date(),
    fechaHoraInicio: new Date(),
    fechaHoraFinal: new Date()
}

const initSelect = {
    ats: ''
}

export const useModalReporteClaro = () => {
    const dispatch = useDispatch();
    const { width } = useScreenSize();
    const watch = false;
    const [pantalla, setPantalla] = useState<boolean>(true);
    const { latitude, longitude, timestamp } = usePosition(watch);
    const [guardar, setGuardar] = useState(false);
    const [reqAts, setRequiereAts] = useState(false)
    const [err, setErr] = useState<boolean>(true);

    const [iniciar, setIniciar] = useState(false);
    const { modalReporte, activeServicio } = useSelector((state: AppState) => state.servicios);
    const { modules, reportes } = useSelector((state: AppState) => state.reportes);

    const { responsableBeling, responsableCliente } = useSelector((state: AppState) => state.shared);
    const { atsResource, tipos } = useSelector(
        (state: AppState) => state.resources
    );
    const { onChangeSelect, changeSelect, registerSelectData } = useSelect(initSelect);

    const {
        actividadNoContemplada,
        alarma,
        baterias,
        corriente,
        herramientas,
        inversores,
        materiales,
        medicionesBaterias,
        personal,
        personalClaro,
        rectificadores,
        rutinaBancoBateria,
        rutinaRectificador,
        vehiculos,
        voltaje,
    } = modules;
    const { activeReporte } = reportes;
    const { firma: firmaResponsable } = responsableBeling.responsableBeling[0];
    const { firma: firmaCliente } = responsableCliente.responsableCliente[0];

    const { onChange, registerData, changeData } = useForm(initForm);
    const { onDateChange, changeDate, registerDataDate } = useDate(initDate);

    const atsResources = atsResource.map(r => {
        return {
            ...r,
            name: toString(r.numero)
        }
    })

    useEffect(() => {
        if (!activeReporte) {
            changeData(initForm);
            changeDate(initDate);
            changeSelect(initSelect);
        } else {
            const data = {
                alcanceActividad: activeReporte.alcanceActividad!,
                observaciones: activeReporte.observaciones!,
                requiereAts: activeReporte.requiereAts!,
            }
            changeData(data);
            changeDate({
                fecha: new Date(activeReporte.fecha!),
                fechaHoraInicio: new Date(activeReporte.fecha!),
                fechaHoraFinal: new Date(activeReporte.fechaHoraFinal!)
            });
            changeSelect({
                ats: activeReporte.ats!
            })
        }
    }, [activeReporte, changeData, changeDate, changeSelect]);

    const handleClose = () => {

        if (!guardar) {
            if (firmaResponsable.id) {
                deleteFirma(firmaResponsable.id);
            }
            if (firmaCliente.id) {
                deleteFirma(firmaCliente.id);
            }
        }
        changeData(initForm);
        changeDate(initDate);
        setIniciar(false);
        dispatch(closeReportes());
        setGuardar(false);
    };


    const handleSave = () => {
        setGuardar(true);
        const data = {
            servicio: activeServicio!.id,
            ...registerData,
            ...registerDataDate,
            ...registerSelectData,
            actividadesNoPlaneadas: actividadNoContemplada.actividadesNo,
            corrienteRectificador: corriente.corrienteRectificador,
            infoBaterias: baterias.bateriasInfo,
            infoInversores: inversores.inversoresInfo,
            infoRectificadores: rectificadores.rectificadoresInfo,
            herramientas: herramientas.herramientas,
            materiales: materiales.materiales,
            medicionesBaterias: medicionesBaterias.medicionesBateria,
            personal: personal.personal,
            personalClaro: personalClaro.personalClaro,
            responsableBeling: responsableBeling.responsableBeling,
            responsableCliente: responsableCliente.responsableCliente,
            rutinaAlarmas: alarma.rutinasAlarmas,
            rutinaBancoBateria: rutinaBancoBateria.rutinasBancoBateria,
            rutinaRectificador: rutinaRectificador.rutinasRectificador,
            ubicacionInicial: reportes.ubicacionInicial,
            ubicacionFinal: reportes.ubicacionFinal,
            vehiculos: vehiculos.vehiculos,
            voltajeRectificador: voltaje.voltajeRectificador
        }
        console.log(data)
        console.log(actividadNoContemplada.activeActividadNo)

        dispatch(startCreateReportes(data));
        setIniciar(false);
        setGuardar(false);
    }

    const handleIniciar = () => {
        setIniciar(true);
        const ubicacion = {
            latitude,
            longitude,
            timestamp
        }
        /* changeDate({
            fecha: registerDataDate.fecha,
            fechaHoraInicio: new Date(ubicacion.timestamp!),
            fechaHoraFinal: registerDataDate.fechaHoraFinal
        }) */
        dispatch(startSetUbicacionInicial(ubicacion));
    }

    const handleAddAts = () => {
        dispatch(startSetNoServicio(false));
        dispatch(startSetHasAtsReporte(false));
        dispatch(startSetOlsActiveServicio(activeServicio?.id!))
        createNewServicio();
        dispatch(startSetModalAts(true));
        dispatch(startSetModalReporte(false));
    }

    const createNewServicio = () => {

        const newServicio = {
            cliente: activeServicio?.cliente.id,
            colaboradores: [],
            contacto: activeServicio?.contacto.id,
            descripcion: 'Analisis de Trabajo Seguro RIBU Claro',
            destino: activeServicio?.destino!,
            estado: "En proceso",
            fecha: fechaHora(new Date()),
            nombre: 'Analisis de Trabajo Seguro',
            numero: 0,
            origin: activeServicio?.origen!,
            referencia: 'ATS Mantenimiento RIBU Claro',
            tecnico: activeServicio?.tecnicoId,
            tecnicoId: activeServicio?.tecnicoId,
            tipo: tipos.filter(t => t.alias === 'ats')[0].id,
            tipoId: tipos.filter(t => t.alias === 'ats')[0].id,
            id: undefined,
        }

        dispatch(startCreateServicio(newServicio));
    }

    const handleChangeRequiereAts = () => {
        setRequiereAts(!reqAts);
        //dispatch(setActiveServicio(activeServicio?.id as GridRowId));
    }

    useEffect(() => {
        if (width < 1024) {
            setPantalla(true)
        } else {
            setPantalla(false)
        }
    }, [width])

    useEffect(() => {
        if (reqAts) {
            dispatch(starLoadAtsResources())
        }
    }, [dispatch, reqAts])

    useEffect(() => {
        let counter: number = 0;
        if (herramientas.herramientas.length === 0) {
            counter++;
        }
        if (personal.personal.length === 0) {
            counter++
        }
        if (rutinaBancoBateria.rutinasBancoBateria.length === 0) {
            counter++
        }
        if (rutinaRectificador.rutinasRectificador.length === 0) {
            counter++
        }

        if (responsableBeling.responsableBeling[0].nombre === '') {
            counter++
        }
        if (responsableCliente.responsableCliente[0].nombre === '') {
            counter++
        }

        if(counter === 0){
            setErr(false);
        }else{
            setErr(true)
        }
    }, [herramientas.herramientas.length, personal.personal.length, responsableBeling.responsableBeling, responsableCliente.responsableCliente, rutinaBancoBateria.rutinasBancoBateria.length, rutinaRectificador.rutinasRectificador.length])
    
    return {
        atsResource: atsResources,
        err,
        iniciar,
        pantalla,
        modalReporte,
        registerData,
        registerDataDate,
        registerSelectData,
        reqAts,
        handleChangeRequiereAts,
        handleAddAts,
        handleClose,
        handleIniciar,
        handleSave,
        onChange,
        onDateChange,
        onChangeSelect
    }
}
