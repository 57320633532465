import { View, StyleSheet, Text } from '@react-pdf/renderer';


import { dateShort } from '../../../../../helpers/fecha';
import { Sst } from '../../../interfaces/sst';
import { toString } from '../../../../../helpers';


const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 50,
		textAlign: 'center',
		marginTop: 2
	},
	column: {
		flexDirection: 'column',
		borderTop: 3,
		borderBottom: 3,
		padding: 5,

	},
	lefColumn: {
		height: '100%',
		width: '30%',
		borderLeft: 3,
	},
	centerColumn: {
		borderRight: 3,
		borderLeft: 3,
		height: '100%',
		width: '45%',
		marginLeft: -1
	},
	rightColumn: {
		borderRight: 3,
		width: '25%',
		height: '100%',
		justifyContent: 'space-around',
		marginLeft: -1
	},
	rowContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '100%',
		height: 36
	},
	colContainer: {
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	txTitle: {
		fontSize: 11,
		fontWeight: 'bold'
	},
	txContent: {
		fontSize: 11,
		fontWeight: 'normal'
	},
});

interface Props {
	active: Sst;
}
export const Header = ({ active }: Props) => {


	return (
		<>
			<View style={styles.row}>
				<View style={[styles.column, styles.lefColumn]}>
					<View style={[styles.rowContainer]}>
						<View style={[styles.colContainer, { width: '50%' }]}>
							<Text style={[styles.txTitle, { textAlign: 'left' }]}>FECHA DE ELABORACION:</Text>
						</View>
						<View style={[styles.colContainer]}>
							<Text style={[styles.txContent]}>{dateShort(active.fecha)}</Text>
						</View>
					</View>
				</View>
				<View style={[styles.column, styles.centerColumn]}>
					<View style={[styles.rowContainer]}>
						<View style={[styles.colContainer, { width: '50%', alignContent: 'flex-start'}]}>
							<Text style={[styles.txTitle, { textAlign: 'left' }]}>ELABORADO POR:</Text>
						</View>
						<View style={[styles.colContainer]}>
							<Text style={[styles.txContent, {textAlign: 'left'}]}>{`${active.user.name} ${active.user.lastname}`}</Text>
							<Text style={[styles.txContent, {textAlign: 'left'}]}>LIC. 8408 DE 2019</Text>
						</View>
					</View>
				</View>
				<View style={[styles.column, styles.rightColumn]}>
				<View style={[styles.rowContainer]}>
						<View style={[styles.colContainer, { width: '50%', alignContent: 'flex-start'}]}>
							<Text style={[styles.txTitle, { textAlign: 'left' }]}>INFORME No.</Text>
						</View>
						<View style={[styles.colContainer]}>
							<Text style={[styles.txContent, {textAlign: 'left'}]}>{`${toString(active.numero)}`}</Text>
						</View>
					</View>
				</View>
			</View>
		</>
	);
};

