import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';

import imagen from '../../../../../assets/img/logo_beling.png';
import { AppState } from '../../../../../reducers/rootReducer';
import { 
    startDelImgActividadBdB, 
    startDeleteActividad, 
    startSetActiveActividadBdB, 
    startSetActividadBdBId, 
    startSetModImgActividadBdB, 
    startSetModadlActividad, 
    startUpdateActividad 
} from '../action/actividad';
import { ModalActividad } from './ModalActividad';
import { CellExpand } from '../../../../../components';
import { ActividadToolbar } from './ActividadToolbar';
import { startLoadItems } from '../../../../items/actions/items';
import { useScreenSize } from '../../../../../hooks';
import { ModalImagesActividadBdB } from './ModalImagesActividadBdB';
import { deleteImagen } from '../../../../../helpers';
import { doMessage } from '../../../../ui/actions/ui';
import { Actividad } from '../../../interfaces/reportes';

export const ActividadTable = () => {
    const dispatch = useDispatch();
    const { width } = useScreenSize();
    const { items } = useSelector((state: AppState) => state.items);
    const { actividades, tipoActividad, modalActividad, modalImagesActividadBdb } = useSelector((state: AppState) => state.reportes.modules.actividad);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [values, setValues] = useState<any[]>([])

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        const actividad = actividades.find(a => a.id === id);

        if (actividad?.images && actividad?.images?.length > 0) {
            actividad.images.forEach(async (i) => {
                const dele = await deleteImagen(i.id);                
                if (dele.status) {
                    dispatch(startDelImgActividadBdB(i.id!, id))
                } else {
                    dispatch(doMessage({ message: 'Error guardando la imagen', type: 'error' }))
                }
            })
        }
        dispatch(startDeleteActividad(id));
        dispatch(startSetActiveActividadBdB(''));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const hanleAddImageClick = (id: GridRowId) => () => {
        dispatch(startSetActividadBdBId(id))
        dispatch(startSetModadlActividad(true));
    };

    const handleViewImages = (id: GridRowId) => () => {
        dispatch(startSetActiveActividadBdB(id));
        dispatch(startSetModImgActividadBdB(true));
    }

    const processRowUpdate = (newRow: GridRowModel<Actividad>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateActividad(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'actividad',
            headerName: 'Actividad',
            flex: width > 900 ? 2 : 0,
            width: 300,
            editable: true,
            type: 'singleSelect',
            valueOptions: values,
            headerAlign: 'center',
            renderCell: CellExpand
        },
        {
            editable: true,
            field: 'cantidad',
            flex: width > 900 ? 1 : 0,
            width: 100,
            headerAlign: 'center',
            headerName: 'Cantidad',
            type: 'number',
        },
        {
            field: 'observaciones',
            headerName: 'Observaciones',
            headerAlign: 'center',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: true,
            type: 'string',
            renderCell: CellExpand
        },
        {
            field: 'imagen',
            headerName: 'Imagén',
            editable: false,
            renderCell: (row) => {
                const images = actividades.find(a => a.id === row.id)?.images;
                const lastIndex = images?.length! - 1;
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'contain',
                            backgroundImage: images?.length! > 0 ? `url(${row.row.images[lastIndex].uri})` : `url(${imagen})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                );
            },
            flex: width > 900 ? 1 : 0,
            width: 150,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<AddAPhotoRoundedIcon />}
                            label="Agregar imagen"
                            className="textPrimary"
                            onClick={hanleAddImageClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<ImageSearchRoundedIcon />}
                        label="Ver imagenes"
                        className="textPrimary"
                        onClick={handleViewImages(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    useEffect(() => {
        if (items.length > 0) return;
        dispatch(startLoadItems());

    }, [dispatch, items])

    useEffect(() => {
        if (items.length > 0) {
            const itemsDB = items.filter(i => i.lista.tipo === tipoActividad).map(i => i.nombre);
            setValues(itemsDB);
        }
    }, [dispatch, tipoActividad, items])

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={actividades.slice(0, actividades.length)}
                columns={columns}
                editMode="row"
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                slots={{
                    toolbar: ActividadToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }} 
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}                                
                localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay registros' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            {modalActividad && <ModalActividad />}
            {modalImagesActividadBdb && <ModalImagesActividadBdB />}

        </Box>
    );
}

