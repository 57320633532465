import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { usePosition } from 'use-position';


import { AppState } from '../../../reducers/rootReducer';
import { useScreenSize, useDate, useSelect, useForm } from '../../../hooks';

import { startCreateInformesSst, startSetInactiveInformeSst, startSetModalInformeSst, startSetUbicacionFinal, startSetUbicacionInicial, startUpdateInformeSst } from '../actions/sst';
import { Asistentes } from '../interfaces/sst';
import { SelectChangeEvent } from '@mui/material';
import { startAddImages, startClearImagesSst } from '../modules/images/actions/images';
import { startAddAnexos, startClearAnexosSst } from '../modules/anexos/actions/anexos';
import { startLoadObras, startSetActiveObra, startSetInactiveObra } from '../../obras/actions/obras';


const initForm = {
    numero: 0,
    accidentes: 0,
    incidentes: 0,
    operativos: 0,
    administrativos: 0

};

const initSelect = {
    cliente: ''
};

const initDate = {
    fecha: new Date(),
};

const planes =[
    "Charla Preoperacional - Actividades técnicas a realizar.",
    "Charla Preoperacional - Uso adecuado de los EPP.",
    "Entrega de EPP",
    "Identificación de riesgos - Participación de los trabajadores."
]

const ats = [
    "Riesgo Eléctrico"
]

export const useSstaModal = () => {
    const { width } = useScreenSize();
    const dispatch = useDispatch();
    const watch = false;
    const { latitude, longitude, timestamp } = usePosition(watch);
    const [pantalla, setPantalla] = useState<boolean>(true);
    const [planesTrabajo, setplanTrabajo] = useState<string[]>([]);    
    const [atsM, setAts] = useState<string[]>([]);
    const [iniciar, setIniciar] = useState(false);
    //const [guardar, setGuardar] = useState(false);   
  
    const { onChange, changeData, registerData, numero, accidentes, incidentes, administrativos, operativos } = useForm(initForm);
    const { onChangeSelect, changeSelect, registerSelectData } = useSelect(initSelect);
    const { onDateChange, changeDate, registerDataDate } = useDate(initDate);    
    const { fecha } = registerDataDate;

    const { formError } = useSelector((state: AppState) => state.ui);    
    const { activeObra, obras } = useSelector((state: AppState) => state.obras.obras);
    const { sst:{activeSst, modalSst, ubicacionInicial, ubicacionFinal }, modules:{anexosSst, imagesSst} } = useSelector((state: AppState) => state.sst);
    



    const handleClose = () => {
        dispatch(startSetModalInformeSst(false));
        dispatch(startSetInactiveInformeSst());
        changeData({ ...initForm });
        changeSelect({ ...initSelect });
        changeDate({ ...initDate });
        setplanTrabajo([]);
        setAts([]);
        dispatch(startClearImagesSst());
        dispatch(startClearAnexosSst());
        dispatch(startSetInactiveObra())
    }

    const asistentes: Asistentes = {
        administrativos,
        operativos
    }

    const handleSave = () => {
        if(iniciar) return;
        const sst = {
            asistentes,
            accidentes,
            atsRealizados: atsM,
            fecha,
            imagenes: imagesSst.images,
            incidentes,
            numero,
            obra: activeObra?.id!,
            planesTrabajo,
            soportes: anexosSst.anexos,
            ubicacionFinal,
            ubicacionInicial
        }
        if (!activeSst) {            
            dispatch(startCreateInformesSst(sst));
        } else {
            dispatch(startUpdateInformeSst(sst))
        }
        handleClose();
    }

    const handleIniciar = () => {
        setIniciar(true);
        const ubicacion = {
            latitude,
            longitude,
            timestamp
        }
        dispatch(startSetUbicacionInicial(ubicacion));
    }

    const handleFinalizar = () => {
        setIniciar(false);
        const ubicacion = {
            latitude,
            longitude,
            timestamp
        }
        dispatch(startSetUbicacionFinal(ubicacion));
    }

    const handleChangePlanes = (event: SelectChangeEvent<typeof planesTrabajo>) => {
        const {
            target: { value },
        } = event;
        setplanTrabajo(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeAts = (event: SelectChangeEvent<typeof atsM>) => {
        const {
            target: { value },
        } = event;
        setAts(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const loadImages = ()=>{
        activeSst?.imagenes.forEach(imagen =>dispatch(startAddImages(imagen)));
        activeSst?.soportes.forEach(anexo =>dispatch(startAddAnexos(anexo)))
    }
     
    useEffect(()=>{
        if(obras.length === 0 ){
            dispatch(startLoadObras())
        }
    },[obras, dispatch])

    useEffect(() => {
        if (!activeSst) {
            changeData({ ...initForm });
            changeSelect({ ...initSelect });
            changeDate({ ...initDate })
        } else {
            changeData({
                numero: activeSst.numero,
                accidentes: activeSst.accidentes,
                incidentes: activeSst.incidentes,
                operativos: activeSst.asistentes.operativos,
                administrativos: activeSst.asistentes.administrativos,
            });
            dispatch(startSetActiveObra(activeSst.obra._id))
            setplanTrabajo(activeSst.planesTrabajo);
            setAts(activeSst.atsRealizados);
            loadImages();
            changeSelect({
                cliente: activeSst.obra.cliente._id
            });
            changeDate({
                fecha: activeSst.fecha,
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSst, changeData, changeSelect, changeDate, dispatch]);

    useEffect(() => {
        if (width < 1024) {
            setPantalla(true)
        } else {
            setPantalla(false)
        }
    }, [width])


    return {
        ats,
        atsM,
        activeSst,
        formError,
        modalSst,
        pantalla,
        planes,
        planesTrabajo,
        registerData,
        registerSelectData,
        registerDataDate,
        handleChangeAts,
        handleChangePlanes,
        handleClose,
        handleSave,
        handleIniciar,
        handleFinalizar,
        onChange,
        changeData,
        onChangeSelect,
        changeSelect,
        onDateChange,
        changeDate,
    }
}

