import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AppState } from '../../reducers/rootReducer'

export const LoadingBackdrop = () => {
    const { isLoading: loading } = useSelector((state: AppState) => state.ui);
  return (
    <>
        <Backdrop 
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={loading}>
            <CircularProgress disableShrink color='primary' />
        </Backdrop>
    </>
  )
}
