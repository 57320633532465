import { useEffect } from 'react'
import { Container, Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { startSetInactiveGrupo } from '../actions/grupos';
import { useNavigate } from 'react-router-dom';
import { loadItemsObra, startLoadItemsObra } from '../actions/item';
import { ItemObra } from './ItemObra';



export const ItemObraScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeGrupo } = useSelector((state: AppState) => state.obras.grupos);   
    
    useEffect(() => { 
        if (!activeGrupo){
            navigate("/detalle-obra", { replace: false });
        }else{
            dispatch(startLoadItemsObra())
        }
        
        return ()=>{
            dispatch(startSetInactiveGrupo());
            dispatch(loadItemsObra([]));
        }
    },[dispatch, activeGrupo, navigate]);
    
    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                {/* ItemsObra */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <ItemObra />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}