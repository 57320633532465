import { View, StyleSheet } from '@react-pdf/renderer';

import { HeaderTable, ItemsTable } from '../';
import { Cell } from '../../../../../../interfaces/shared';
import { Informe } from '../../../../interfaces/informes';
import { TitleTables } from '../../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
	container: {
		marginTop: 2
	},
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const title = 'Describa las actividades realizadas:';
const titleTable: Cell[] = [{name: "Actividades realizadas"}];
const tableTitles: Cell[]  = [
	{name: "Actividad", width: "40%"},
	{name: "Cantidad", width: "10%"},
	{name: "Observaciones", width: "20%"},
	{name: "Imagen", width: "30%"}
];

interface Props {
	active: Informe;
}

export const Actividades = ({ active }: Props) => {
	const { actividades } = active;
	return (
		<>
		<TitleTables title={title} sx={{fw: 'normal', mt: 10}}/>
			<View style={styles.container} wrap={ false }>
				<View style={styles.descriptionContainer}>
					<HeaderTable names={titleTable} sx={{ txa: 'center', fw: 'bold' }} />
					<HeaderTable names={tableTitles} bottom defaultWidth={false}/>
					{actividades?.map((item) => {
						const fila: Cell[] = [
							{ name: item.actividad, width: '40%' },
							{ name: item.cantidad!, width: '10%' },
							{ name: item.observaciones!, width: '20%' },
							{ name: item.imagen?.name!, width: '30%' },
						]
						return <ItemsTable key={item.id} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom defaultWidth={false} />
					})}					
				</View>
			</View>
		</>
	)
}