import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from '../../../../../interfaces/informes';
import { getDay } from '../../../../../../../helpers/fecha';



const styles = StyleSheet.create({
	column: {
		flexDirection: 'column',
		borderLeft: 1,
		borderRight: 1

	},
	rowContainer: {
		flexDirection: 'row',
		width: '100%'
	},
	borderRow: {
		borderBottom: 1,
		borderRight: 1
	},
	endBorderRow:{
		borderBottom: 1,
	},
	cellBottom: {
		borderRight: 1,
		height: 14,
		justifyContent: 'center'
	},
	endCellBottom:{
		height: 14,
		justifyContent: 'center'
	},
	mainColumn: {
		width: '36%',
	},		
	containerColumn: {
		flexDirection: 'column',
		width: '14.28571%'
	},
	headColumn: {
		backgroundColor: '#aaaaaa',
		borderBottom: 1,
		alignItems: 'center'
	},
	headText: {
		fontSize: 8,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 2
	},
	diaText: {
		fontSize: 7,
		fontWeight: 'normal',
		marginTop: 2
	},
	rightText: {
		fontSize: 8,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 2
	},
})

interface Props {
	active: Informe;
}
export const ColFive = ({ active }: Props) => {	
	return (
		<View style={[styles.column, styles.mainColumn]}>
			<View style={[styles.rowContainer, styles.headColumn, { justifyContent: 'center', }]}>				
					<Text style={[styles.headText]}>DIA DE LA SEMANA</Text>				
			</View>
			<View style={[styles.rowContainer]}>
				<DiaHeadItem dia='Lunes' />
				<DiaHeadItem dia='Martes' />
				<DiaHeadItem dia='Miercoles' />
				<DiaHeadItem dia='Jueves' />
				<DiaHeadItem dia='Viernes' />
				<DiaHeadItem dia='Sábado' />
				<DiaHeadItem dia='Domingo' end/>
			</View>  
			<View style={[styles.rowContainer]}>
				<DiaItem  x={getDia(active.fecha!, 'lunes')}/>
				<DiaItem x={getDia(active.fecha!, 'martes')}/>
				<DiaItem x={getDia(active.fecha!, 'miercoles')}/>
				<DiaItem x={getDia(active.fecha!, 'jueves')}/>
				<DiaItem x={getDia(active.fecha!, 'viernes')}/>
				<DiaItem x={getDia(active.fecha!, 'sábado')}/>
				<DiaItem x={getDia(active.fecha!, 'domingo')} end/>
			</View>
		</View>
	)
}

interface ItemProps {
	dia: string;
	end?: boolean;
}

interface DiaProps {
	x: string;
	end?: boolean;
}
const DiaHeadItem = ({ dia, end }: ItemProps) => {
	const border = end ? styles.endBorderRow: styles.borderRow;
	return (
		<View style={[styles.containerColumn]}>
			<View style={[styles.rowContainer, border, { height: 14, justifyContent: 'center' }]}>
				<Text style={[styles.diaText]}>{dia}</Text>
			</View>
		</View>
	)
}

const DiaItem = ({x, end}: DiaProps) => {
	const border = end ? styles.endCellBottom: styles.cellBottom;
	return (
		<View style={[styles.containerColumn]}>
			<View style={[styles.rowContainer, border]}>
			<Text style={[styles.rightText]}>{x}</Text>
			</View>
		</View>
	)
}

function getDia(date: Date | string, day: string){
	const x = getDay(date) === day? 'X' : '';
	return x;
}