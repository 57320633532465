import { GridRowId, GridRowModesModel, GridRowsProp } from "@mui/x-data-grid";
import { Imagen } from "./image";

export interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

export interface Detalle{
    id: number;
    actividad: string;
    unidad?: string;
    cantidad: number;
    observaciones?: string;
    isNew?: boolean;
}

export interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

export interface Cell {
	name: string | number | boolean;
    width?: string | number;
    align?: 'center' | 'justify' | 'left' | 'right',
}

export interface Material {
    id: number;
    descripcion: string;
    unidadMedida: Unidad;
    cantidad: number;
    observacion: string;
    isNew?: boolean;
}

export interface Accidente{
    id: number;
    name: string;
    cantidad: number,
    isNew: boolean
}

export interface Condicion{
    id: number,
    name: string,
    status: string | number | boolean,
    isNew: boolean
}

export interface Personal {
    id: GridRowId;
    nombres: string;
    identificacion: string;
    cargo: string;
    isNew?: boolean;
}

export interface Maquinaria {
    id: GridRowId;
    equipo: string;
    cantidad: number;
    marca: string;
    horaInicio: Date;
    horaFinal: Date;
    propiedad: 'beling' |'otro' | '';
    isNew?: boolean;
}

export enum Unidad {
    bolsa = 'bolsa',
    bulto = 'bulto',
    caja = 'caja',
    emty= "",
    gramo = 'gramo',
    kilo = 'kilo',
    libra = 'libra',
    litro = 'litro',
    metro= 'metro',
    pte = 'pte',
    unidad = 'unidad',
}

export interface Responsable {
    id: GridRowId;
    nombre: string;
    identificacion: string;
    cargo: string;
    firma: Imagen;
    isNew: boolean;
}