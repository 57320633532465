import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
} from "@mui/material";
//import Link from '@mui/material/Link';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {
  userListItems,
  //secondaryListItems,
  adminListItems,
  gestorListItems,
} from "../lilstitems/listitems";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import { toggleDrawer } from "../../modules/ui/actions/ui";
import { LogoutOutlined } from "@mui/icons-material";
import { startLogout } from "../../modules/auth/actions/auth";

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const SideDrawer = () => {
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state: AppState) => state.ui);
  const { user } = useSelector((state: AppState) => state.auth);

  const onToggleDrawer = () => {
    dispatch(toggleDrawer(!drawerOpen));
  };

  const onLogout = () => {
    dispatch(startLogout());
  };

  const roles = user!.roles!.map((r) => r.name!);
  
  return (
    <>
      <Drawer variant="permanent" open={drawerOpen}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={onToggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {getRole(roles)}
          <Divider sx={{ my: 1 }} />
          {/* {secondaryListItems} */}
          <ListItemButton onClick={onLogout}>
            <ListItemIcon>
              <Tooltip title="Salir">
                <LogoutOutlined />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Salir" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
};

function getRole(roles: String[]){
  if(roles.includes("admin")){
    return adminListItems
  }else if(roles.includes("gestor")){
    return gestorListItems
  }else if(roles.includes("tecnico")){
    return userListItems
  }else{
    return 
  }
}