import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { CorrienteRectificadorTable } from './CorrienteRectificadorTable';



const Table = memo(() => {
    return (
        <CorrienteRectificadorTable />
    )
})

export const CorrienteRectificador = () => {

    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Corriente AC del Rectificador
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}