import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { TableMedicionesBateriasHeader } from './TableMedicionesBateriasHeader';
import { TableMedicionBateriaRow } from './TableMedicionBateriaRow';
import { TitleTables } from '../../../../../components/pdf/TitleTables';
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        border: 1,
    },
});

interface Props {
    active: Reporte;
}

export const MedicionesBateriasTable = ({ active }: Props) => (
    <View>
        <TitleTables title='Indique las Mediciones en cada Banco de Baterías' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />
        <View style={styles.tableContainer}>
            <TableMedicionesBateriasHeader />
            <TableMedicionBateriaRow items={active.medicionesBaterias!} />
        </View>
    </View>

);
