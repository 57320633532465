
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from '../../../../../interfaces/informes';
import { dateShortFour } from '../../../../../../../helpers';


const styles = StyleSheet.create({	
	column: {
		flexDirection: 'column',
		width: '6%',
		height: '100%',

	},
	rowContainer: {
		flexDirection: 'row',
		width: '100%',
		alignContent: 'center'
	},		
	leftColumn: {
		flexDirection: 'column',
		width: '40%',
	},
	headColumn: {
		backgroundColor: '#aaaaaa',
		borderTop: 1,
		borderBottom: 1
	},
	rightColumn: {
		flexDirection: 'column',
		width: '100%',
	},
	headText: {
		fontSize: 8,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 2
	},
	container: {
		height: 29,
		justifyContent: 'center',
		alignItems: 'center',
	},
	leftText: {
		fontSize: 8,
		fontWeight: 'bold',
		marginTop: 2,
		color: 'red'
	},	
})

interface Props {
	active: Informe;
}
export const ColFour = ({ active }: Props) => {
	return (
		<View style={[styles.column]}>
			<View style={[styles.rowContainer]}>
				<View style={[styles.rightColumn]}>
					<View style={[styles.rowContainer, styles.headColumn, { justifyContent: 'center' }]}>
						<Text style={[styles.headText]}>HOY</Text>
					</View>
					<View style={[styles.rowContainer, styles.container]}>
						<Text style={[styles.leftText]}>{dateShortFour(active.fecha!)}</Text>
					</View>
				</View>
			</View>
		</View>
	)
}
