import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import img from '../../../assets/img/bg.png';
import { getImagen, saveImagen } from "../../../helpers/imagen";
import { doMessage, startLoading } from "../../../modules/ui/actions/ui";
import { startSetModalImagen, startSetUpdateImage } from "../actions/images";
import { AppState } from "../../../reducers/rootReducer";

export const useImagen = () => {

	const dispatch = useDispatch();
	const [foto, setFoto] = useState(img)

	const { modalImagen, image } = useSelector((state: AppState) => state.shared.images);

	const { id, imagen, description } = image!;

	const handleClose = () => {
		dispatch(startSetModalImagen(false));
		setFoto(img)
	}

	const handelLoadImage = (event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			setFoto(reader.result as string);
		}
	}

	const handleSave = async () => {
		dispatch(startLoading());
		if (id) {
			const numero = Date.now();
			const data = {
				imagen: foto,
				servicio: `Actividad_obra_${numero}`,
				name: `Actividad_obra_${numero}`,
				descripcion: `${description}`,
			};			
			const { imagen, status } = await saveImagen(data)
			if (status) {
				const imageSave = await getImagen(imagen.name);
				dispatch(startSetUpdateImage(imagen, id));
				dispatch(startSetModalImagen(false));
				setFoto(imageSave)
				dispatch(doMessage({ type: 'success', message: 'Imagen guardada' }));
			}
		} else {
			dispatch(doMessage({type: 'error', message: 'Error al guardar la imagen'}));
			dispatch(startSetModalImagen(false));
			setFoto(img)
		}
		dispatch(startLoading());
	}

	useEffect(() => {
		if (imagen?.uri !== '') {
			setFoto(imagen?.uri!)
		}
		return () => {
			setFoto(img)
		}
	}, [imagen?.uri])
	
	return {
		foto,
		modalImagen,
		handleClose,
		handelLoadImage,
		handleSave,
	}
}