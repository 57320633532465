//import { GridRowId } from '@mui/x-data-grid';
import { Imagen } from '../../../../../interfaces/image';
import { ImagesSstAction } from '../interfaces/images';

/* export const startAddImages = (image: Imagen) => {
	return async(dispapach: any)=>{
		dispapach(addImagen)
	}
} */

export const StartDeleteSstImage =(id: number):ImagesSstAction =>({
	type: 'delete-sst-images',
	payload: id
})

export const startAddImages =(imagen: Imagen): ImagesSstAction =>({
	type: 'create-sst-images',
	payload: imagen
})

export const startSetModalImageStt = (modal: boolean):ImagesSstAction =>({
	type: 'set-modal-sst-image',
	payload: modal
})

export const startClearImagesSst = ():ImagesSstAction =>({
	type:'clear-sst-image-state'
})