import { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../reducers/rootReducer';
import { startLoadRiesgos } from '../../../../resources/actions/resources';
import { startDeleteRiesgoSitio, startEditRiesgoSitio, startSetRiesgosSitio, startUpdateRiesgoSitio } from '../actions/riesgo_sitio';
import { CellExpand } from '../../../../../components/ui/CellExpand';
import { Equipo, Riesgo } from '../../../interfaces/ats';

export const RiesgoSitioTable = () => {
    const locale = esES.components.MuiDataGrid.defaultProps.localeText
    const dispatch = useDispatch();
    const { riesgos } = useSelector((state: AppState) => state.resources);
    const { riesgosSitio } = useSelector((state: AppState) => state.ats.riesgosSitio);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    useEffect(() => {
        if (riesgos.length === 0) {
            dispatch(startLoadRiesgos())
        }

        if(riesgosSitio.length > 0){
            return
        }
        
        riesgos.map(rs => {
            return dispatch(startSetRiesgosSitio({
                id: rs.id,
                name: rs.name,
                riesgo: Riesgo.Empty,
                isNew: false
            }))
        })
    }, [dispatch, riesgosSitio, riesgos]);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        dispatch(startEditRiesgoSitio(id));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const hanleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteRiesgoSitio(id))
    }

    const processRowUpdate = (newRow: GridRowModel<Equipo>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateRiesgoSitio(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        { 
            field: 'name', 
            headerName: 'Identifique y enumere riesgos', 
            headerAlign: 'center', 
            flex: 2, 
            editable: false,
            sortable: false,
            hideable: false,
            renderCell: CellExpand
        },
        {
            field: 'riesgo',
            headerName: '¿Hay riesgo?',
            flex: 1,
            editable: true,
            filterable:false,
            sortable: false,
            hideable: false,
            type: 'singleSelect',
            valueOptions: [Riesgo.Si, Riesgo.No, Riesgo.NoAplica],
            headerAlign: 'center',
            renderCell:(value)=>{
                return( <Box sx={{mx: 1}}>
                    {value.row.riesgo === Riesgo.Si ? <Chip icon={<WarningOutlinedIcon />} label="Si" color="warning" variant='outlined' size='small'/> : value.row.riesgo === Riesgo.No ? <Chip icon={<ChangeHistoryOutlinedIcon />} label="No" color="secondary" variant='outlined' size='small' /> : value.row.riesgo === Riesgo.NoAplica ? <Chip icon={<DoNotDisturbOnOutlinedIcon />} label="No Aplica" color="default" variant='outlined' size='small' /> : ""}
                    </Box>)
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        className="textPrimary"
                        onClick={hanleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={riesgosSitio}
                columns={columns}
                editMode="cell"
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                rowHeight={35}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}            
            />
        </Box>
    );
}