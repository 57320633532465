import { GridRowId } from "@mui/x-data-grid";
import {SubcontratistasAction } from "../interfaces/subcontratista";
import { Personal } from "../../../interfaces/shared";

export const startSetpersonalSubcontratista = (personal: Personal):SubcontratistasAction => ({
    type: 'set-personal-subcontratista',
    payload: personal,
});

export const startUpdatePersonalSubcontratista = (personal: Personal):SubcontratistasAction => ({
    type: 'update-personal-subcontratista',
    payload: personal,
});

export const startDeletepersonalSubcontratista = (id: GridRowId) => ({
    type: 'delete-personal-subcontratista',
    payload: id,
});

export const startSetPersonalSubcontratistaEmpty = ():SubcontratistasAction => ({
    type: 'set-personal-subcontratista-empty',
});