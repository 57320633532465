import { GridRowId } from "@mui/x-data-grid";
import { MedicionBateria, MedicionBateriaAction } from "../interfaces/medicion";

export const startSetMedicionBateria = (medicion: MedicionBateria):MedicionBateriaAction => ({
    type: 'set-medicion-bateria',
    payload: medicion,
});

export const startUpdateMedicionBateria = (medicion: MedicionBateria):MedicionBateriaAction => ({
    type: 'update-medicion-bateria',
    payload: medicion,
});

export const startDeleteMedicionBateria = (id: GridRowId) => ({
    type: 'delete-medicion-bateria',
    payload: id,
});

export const startSetMedicionBateriaEmpty = ():MedicionBateriaAction => ({
    type: 'set-medicion-bateria-empty',
});