import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../../reducers/rootReducer';
import { useForm } from '../../../hooks/useForm';

import { startCreateGrupo, startUpdateGrupo } from '../actions/grupos';
import { startSetModalItemObra } from '../actions/item';

const initForm = {
	descripcion: "",
	item: ""
};


export const useItemObraModal = () => {
const dispatch = useDispatch();

const {activeObra } = useSelector((state: AppState) => state.obras.obras);

const { activeGrupo } = useSelector((state: AppState) => state.obras.grupos);
const { modalItemObra, itemsModal } = useSelector((state: AppState) => state.obras.itemsObra);

const { onChange, changeData, registerData, item, descripcion } = useForm(initForm);

const handleClose = () => {
    dispatch(startSetModalItemObra(false));
}

const handleSave = () => {
    const grupo = {
        item,
        descripcion,
        obra: activeObra?.id!
    }
    if(!activeGrupo){
        dispatch(startCreateGrupo(grupo));
    }else{
        dispatch(startUpdateGrupo(grupo))
    }
    changeData({...initForm})
    handleClose();

}

/* const handelLoadFile = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    
    reader.onload = (e) => {
        const bufferArray = e.target?.result;        
        const wb = read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data: ItemObra[] = utils.sheet_to_json<ItemObra>(ws);
        //console.log(data)
        //setFile([...data]);
        dispatch(startSetItemsModal([...data]))
    }
}
 */

useEffect(()=>{
    if(!activeGrupo){
        changeData({ ...initForm});
    }else{
        changeData({
            descripcion: activeGrupo.descripcion,
            item: activeGrupo.item,
        });
    }
},[activeGrupo, changeData]);

return {
    activeGrupo,
    modalItemObra,
    registerData,
    items: itemsModal,
    handleClose,
    handleSave,
    //handelLoadFile,
    onChange,
    changeData
}
}