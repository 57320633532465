import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
} from '@mui/x-data-grid';
import { AppState } from '../../../../../reducers/rootReducer';
import { startSetRutinaBancoId, startUpdateRutinasBancoBateria, startSetModalBancos, startDeleteRutinasBancoBateria, startSetActiveRutinaBancoBaterias, startSetModImgBancoBaterias } from '../actions/rutina';
import { useScreenSize } from '../../../../../hooks';
import imagen from '../../../../../assets/img/logo_beling.png';
import { RutinaBancoBaterias } from '../interfaces/rutina';

export const useBancoBateriaTable = () => {
	const { width } = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText
    const dispatch = useDispatch();
    
    const { rutinasBancoBateria, activeBancoBateria, modalBancos } = useSelector((state: AppState) => state.reportes.modules.rutinaBancoBateria);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});  
   
    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };
    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteRutinasBancoBateria(id));
    };

    const hanleAddImageClick = (id: GridRowId) => () => {
        dispatch(startSetRutinaBancoId(id))
        dispatch(startSetModalBancos(true));
    }

    const handleViewImages = (id: GridRowId) => () => {
		dispatch(startSetActiveRutinaBancoBaterias(id));
		dispatch(startSetModImgBancoBaterias(true));
	}

    const processRowUpdate = (newRow: GridRowModel<RutinaBancoBaterias>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateRutinasBancoBateria(updatedRow));
        return updatedRow;
    };

	return{
		activeBancoBateria,
		imagen,
		locale,
		modalBancos,
		rowModesModel,
		rutinasBancoBateria,
		width,
		hanleAddImageClick,
		handleCancelClick,
		handleDeleteClick,
		handleEditClick,
		handleRowEditStart,
		handleRowEditStop,
		handleSaveClick,
		handleViewImages,
		processRowUpdate,
		setRowModesModel,
	}
}
