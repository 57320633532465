import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { ResponsableBelingTable } from './ResponsbleBelingTable';


const Table = memo(() => {
    return (
        <ResponsableBelingTable />
    )
})

export const ResponsableBeling = () => {

    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Quien ejecuta la Actividad por Beling SAS.
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}