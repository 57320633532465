import { View, StyleSheet } from '@react-pdf/renderer';

import { HeaderTable, ItemsTable } from '../';
import { Cell } from '../../../../../../interfaces/shared';
import { Informe } from '../../../../interfaces/informes';
import { TitleTables } from '../../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
	container: {
		marginTop: 2
	},
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const title = 'Describa las Herramientas y/o Equipos utilizados:';
const titleTable: Cell[] = [{name: "Herramientas y Equipos"}];
const tableTitles: Cell[]  = [
	{name: "Descripción", width: "40%"},
	{name: "Cantidad", width: "20%"},
	{name: "Marca", width: "20%"},
	{name: "Propiedad", width: "20%"}
];

interface Props {
	active: Informe;
}

export const Herramientas = ({ active }: Props) => {
	const { herramientas } = active;
	return (
		<>
		<TitleTables title={title} sx={{fw: 'normal', mt: 10}}/>
			<View style={styles.container} >
				<View style={styles.descriptionContainer}>
					<HeaderTable names={titleTable} sx={{ txa: 'center', fw: 'bold' }} />
					<HeaderTable names={tableTitles} bottom defaultWidth={false}/>
					{herramientas?.filter(item => item.cantidad !== 0).map((item) => {
						const fila: Cell[] = [
							{ name: item.tipo, width: '40%' },
							{ name: item.cantidad!, width: '20%' },
							{ name: item.marca!, width: '20%' },
							{ name: item.propiedad!, width: '20%' },
						]
						return <ItemsTable key={item.id} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom defaultWidth={false} />
					})}					
				</View>
			</View>
		</>
	)
}