
import { View, StyleSheet } from '@react-pdf/renderer';

import { HeaderTable, ItemsTable } from '../index';
import { Cell } from '../../../../../../interfaces/shared';
import { Informe } from '../../../../interfaces/informes';

const styles = StyleSheet.create({
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const namesUno = [{name: "Descripción:"}];

interface Props {
	active: Informe;
}

export const Description = ({ active }: Props) => {
	const filaUno: Cell[] = [
		{ name: active.obra?.descripcion }
	];
	return (
		<>
			<View>
				<View style={styles.descriptionContainer}>
					<HeaderTable names={namesUno} bottom sx={{mt: 2, fw:'bold', c:'#717171', fz:8, pd:0}}/>
					<ItemsTable columnas={filaUno} sx={{fw: 'normal', fz: 10}} bottom/>
				</View>
			</View>

		</>
	)
}
