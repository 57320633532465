import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import { AppState } from '../../../../reducers/rootReducer';
import { useScreenSize } from '../../../../hooks';
import { ButtonDownload, Loading, Transition } from '../../../../components';
import { ReportePdf } from './ReportePdf';
import { startClosePdfReporte } from '../../actions/reportes';
import { ReportePdfBasico } from './ReportePdfBasico';
import { servicioTipo } from '../../../../helpers';
import { ReportePdfBdB } from './ReportePdfBdB';
import { ModalBar, ModalActions} from '../../../../shared';


export const ModalReportePdf = () => {
    const dispatch = useDispatch();
    const { reportes } = useSelector((state: AppState) => state.reportes);
    const { activeReporte, modalReportePdf } = reportes;    
    const { width } = useScreenSize();
    const fileName = `Reporte Mantenimiento No ${activeReporte?.servicio?.numero}.pdf`
    const handleClose = () => {
        dispatch(startClosePdfReporte());
    };

    const alias = activeReporte?.servicio?.tipo.alias;

    const { formulario, title } = servicioTipo(alias!);    

    return (
        <>
            <Dialog open={modalReportePdf} onClose={handleClose} fullScreen TransitionComponent={Transition}>
                <ModalBar handleClose={handleClose} title={title} pdf={true} />
                <DialogContent>
                    {width > 1024 ? <PDFViewer style={{ width: '100%', height: '82vh' }}>
                        {
                            formulario === 2 ? <ReportePdf active={activeReporte!} /> : formulario === 1 ? <ReportePdfBasico active={activeReporte!} /> : formulario === 4 ? <ReportePdfBdB active={activeReporte!} /> : <ReportePdfBasico active={activeReporte!} />
                        }
                    </PDFViewer> :
                        <PDFDownloadLink document={formulario === 2 ? <ReportePdf active={activeReporte!} /> : formulario === 1 ? <ReportePdfBasico active={activeReporte!} /> : formulario === 4 ? <ReportePdfBdB active={activeReporte!} /> : <ReportePdfBasico active={activeReporte!} />} fileName={fileName}>
                            {({ loading,error }) => {
                                if(loading){
                                    return <Loading />
                                }                                
                                if(error) return;
                                return <ButtonDownload />
                                }}
                        </PDFDownloadLink>}
                </DialogContent>
                <ModalActions handleClose={handleClose} pdf={true} />
            </Dialog>
        </>
    )
}
