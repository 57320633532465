import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { AppState } from '../../../../reducers/rootReducer';
import { toString } from '../../../../helpers';
import { useScreenSize } from '../../../../hooks';
import { Transition, ButtonDownload, Loading } from '../../../../components';
import { startClosePdfAts } from '../../actions/ats';
import { AtsPdf } from './AtsPdf';

export const ModalAtsPdf = () => {
    const dispatch = useDispatch();
    const { width } = useScreenSize();
    const { ats } = useSelector((state: AppState) => state.ats);
    const { activeAts, modalAtsPdf } = ats;

    const fileName = `ATS No ${activeAts?.numero}.pdf`;

    const handleClose = () => {
        dispatch(startClosePdfAts());               
    };

    return (
        <>
            <Dialog open={modalAtsPdf} onClose={handleClose} fullScreen TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Tooltip title="Cancelar"><CloseIcon /></Tooltip>
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            ATS No {toString(activeAts?.numero!)}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {width > 1024 ? <PDFViewer style={{ width: '100%', height: '82vh' }}>
                        <AtsPdf active={activeAts!} />
                    </PDFViewer> :
                        <PDFDownloadLink document={<AtsPdf active={activeAts!} />} fileName={fileName}>
                            {({ loading }) => (loading ? <Loading /> : <ButtonDownload />)}
                        </PDFDownloadLink>}
                </DialogContent>
                <DialogActions sx={{ mr: 5 }}>
                    <Button onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
