import { GridRowId } from "@mui/x-data-grid";
import { PlaceType } from "../../../interfaces/google";
import { Imagen } from "../../../interfaces/image";
import { Responsable } from "../../../interfaces/shared";

export interface AtsResponse {
    ok:  boolean;
    msg: string;
    ats: Ats[];
}

export interface Ats {
    servicio?:             Servicio;
    numero?:               number;
    actividades?:          string;
    numeroTarjeta?:        string;
    lugarTrabajo?:         string;
    representanteCliente?: string;
    tipoTarjeta?:          string;
    tieneTarjeta?:         string;
    trabajoTension?:       string;
    trabajoAltura?:        string;
    seguirActividad?:      string;
    personalCapacitado?:   string;
    tecnico?:              string;
    fecha?:                string;
    horaInicio?:           string;
    horaFinal?:            string;
    equipos?:              Equipo[];
    herramientas?:         Equipo[];
    responsableBeling?:    Responsable[];
    responsableCliente?:   Responsable[];
    riesgosAltura?:        Equipo[];
    riesgosElectrico?:     Equipo[];
    riesgosSitio?:         Equipo[];
    createdAt?:            string;
    updatedAt?:            string;
    id?:                   GridRowId;
}

export interface Equipo {
    id:        string;
    name:      string;
    cantidad?: number;
    isNew:     boolean;
    riesgo?:   Riesgo;
}

export enum Riesgo {
    Empty = "",
    No = "No",
    NoAplica = "No Aplica",
    Si = "Si",
}


export interface Firma {
    name?:        string;
    description?: string;
    uri?:         string;
    reporte?:     string;
    id?:          number;
}

export interface Servicio {
    _id?:         GridRowId;
    tipo?:        Estado;
    numero?:      string;
    nombre?:      string;
    fecha?:       string;
    descripcion?: string;
    cliente?:     Cliente;
    estado?:      Estado;
    tecnico?:     Tecnico;
    createdAt?:   string;
    updatedAt?:   string;
    lugar?:       string;
}

export interface Cliente {
    identificacion: string;
    nombre: string;
    direccion: PlaceType | null;
    email: string;
    telefono: string;
    contactoPrincipal: string;
    id: string;
    logo: Imagen
}

export interface Contacto {
    nombre:   string;
    apellido: string;
    cargo:    string;
    telefono: string;
    email:    string;
}

export interface Direccion {
    calle:        string;
    ciudad:       string;
    departamento: string;
    codigoPostal: string;
    pais:         string;
}

export interface Estado {
    _id:  string;
    name: string;
}

export interface Tecnico {
    status:    boolean;
    _id:       string;
    username:  string;
    email:     string;
    password:  string;
    name:      string;
    lastname:  string;
    movil:     string;
    roles:     string[];
    createdAt: string;
    updatedAt: string;
    __v:       number;
}
export interface AtsResponse {
    ok: boolean;
    message: string;
    data: Ats[];
}

export interface AtsState{
    ats: Ats[];
    loading:  boolean;
    activeAts: Ats | null;
    selected: string[];
    atsOne?: Ats;
    atsForm?: Object;
    modalAtsPdf: boolean;
    modalDeleteAts: boolean;
    servicioExist: boolean;
}

export type AtsAction =
    | { type: 'load-ats', payload: Ats[] }
    | { type: 'create-ats', payload: Ats }
    | { type: 'update-ats', payload: Ats }
    | { type: 'delete-ats', payload: GridRowId }
    | { type: 'set-active-ats', payload: GridRowId }
    | { type: 'set-inactive-ats' }
    | { type: 'set-modal-ats-pdf', payload: boolean }
    | { type: 'set-selected', payload: string[] }
    | { type: 'set-load-ats', payload: string }
    | { type: 'set-ats-form', payload: Ats }
    | { type: 'set-modal-delete-ats', payload: boolean}
    | { type: 'set-servicio-exist', payload: boolean}
    