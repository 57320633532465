import { useEffect } from 'react'
import { Container, Grid, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Reportes } from './Reportes';
import { startLoadReportes } from '../actions/reportes';


export const ReporteScreen = () => {
  const dispatch = useDispatch(); 
  useEffect(() => {
    dispatch(startLoadReportes());
  }, [dispatch]);
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Clientes */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Reportes />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}