import { GridRowId } from "@mui/x-data-grid";
import { MaquinariaAction } from "../interfaces/maquinaria";
import { Maquinaria } from "../../../interfaces/shared";

export const startSetMaquinaria = (equipo: Maquinaria): MaquinariaAction => ({
    type: 'set-maquinaria-equipo',
    payload: equipo,
});

export const startUpdateMaquinaria = (equipo: Maquinaria): MaquinariaAction => ({
    type: 'update-maquinaria-equipo',
    payload: equipo,
});

export const startDeleteMaquinaria = (id: GridRowId) => ({
    type: 'delete-maquinaria-equipo',
    payload: id,
});

export const startSetHarramientaEmpty = ():MaquinariaAction => ({
    type: 'set-maquinaria-equipo-empty',
});