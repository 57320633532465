import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Paper } from '@mui/material';

import { starLoadClientes } from '../actions/cliente';
import { Clientes } from './Clientes';
import { AppState } from '../../../reducers/rootReducer';



export const ClientesScreen = () => {
  const dispatch = useDispatch();
  const { clientes } = useSelector((state: AppState) => state.clientes);
  useEffect(() => {    
    if(clientes && clientes.length > 0) return;
    dispatch(starLoadClientes());
  }, [dispatch, clientes]); 
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Clientes */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Clientes />
          </Paper>
        </Grid>
      </Grid>      
    </Container>
  )
}
