import { Avatar, Box, Typography } from '@mui/material';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { ServiciosTable } from './ServiciosTable';

export const Servicios = () => {    

    return (
        <>
            <Box
                sx={{
                    my: 1,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <ElectricalServicesIcon />
                </Avatar>
                <Typography component="h1" align='center' variant="h6">
                    Servicios
                </Typography>
            </Box>
            <ServiciosTable />
        </>
    )
}