import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import {v4 as uuid} from 'uuid'

import { EditToolbarProps } from '../../../interfaces/shared';
import { startSetImagenActividad } from '../actions/images';

export const ImagenToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();

    const { setRowModesModel } = props;
   

    const handleClick = () => {
        const id = uuid();
        dispatch(startSetImagenActividad({ id, actividad: '', description: '', imagen: {uri:''}, isNew: true }));

        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'actividad' },
        }));
    };       

    return (
        <GridToolbarContainer>           
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar imagen
            </Button>
        </GridToolbarContainer>
    );
}