import { AuthAction, AuthState } from "../interfaces/auth";

const initState: AuthState = {
    status: 'checking',
    errorMessage: '',
    token: null,
    user: null, 
    isAdmin: false
}

export const authReducer = (state = initState, action: AuthAction): AuthState => {
    switch (action.type) {
        case 'signUp':
            const roles = action.payload.user.roles!.map((r) => r.name);
            const isAdmin = roles!.includes('superadmin') || roles!.includes('admin');
            return {                
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                isAdmin: isAdmin,
                status: 'authenticated',
                errorMessage: '',
            };
        case 'addError':
            return {
                ...state,
                status: 'not-authenticated',
                errorMessage: action.payload,
                token: null,
                user: null,
            };
        case 'removeError':
            return {
                ...state,
                errorMessage: '',
            };
        case 'logout':
        case 'noAuthenticated':
            return {
                ...state,
                token: null,
                user: null,
                isAdmin: false,
                status: "not-authenticated"
            };
        default:
            return state;
    }
}