import { View, StyleSheet } from "@react-pdf/renderer";
import { Actividad, Reporte } from '../../../interfaces/reportes';
import { TitleTables } from "../../../../../components/pdf/TitleTables";
import { getGrupoActividades } from "../../../../../helpers";
import { HeadItems } from "../../../../../components/pdf/HeadItems";
import { RowItems } from "../../../../../components/pdf/RowItems";
import { HeaderTable, ItemsTable } from "../../../../informes/components/pdf/normal";
import { Cell } from "../../../../../interfaces/shared";
import { Imagen } from "../../../../../interfaces/image";
import { ImagenesActividades } from "./ImagenesActividades";

const borderColor = '#bcbcbc';

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		flexWrap: "wrap",
		borderLeftColor: borderColor,
		borderLeftWidth: 1,
		marginTop: 10
	},
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	titleServicio: {
		flexDirection: 'column',
		textAlign: 'left',
		padding: 0,
		marginLeft: 20,
		flexWrap: "wrap",
	},
	titleActividad: {
		flexDirection: 'row',
		width: '100%',

	},
	bodyActividad: {
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'left',
		padding: 0,
		marginLeft: 40,
		flexWrap: "wrap",
		marginTop: 5
	},

	textContainer: {
		fontWeight: 'bold',
		fontSize: 10,
		paddingLeft: 5,
		textAlign: 'left',
	},
});

interface Props {
	active: Reporte;
}

const title: string = 'Actividades a realizar';
const titleTable: string = 'Enumere las actividades a realizar dentro del listado de Actividades del BdB:'
const listaTitle: string = "Lista de actividades:"
const servicio: string = "Tipo de servicio:"

const tableTitles: Cell[] = [
	{ name: "Item", width: "7%" },
	{ name: "Actividad", width: "40%" },
	{ name: "Cantidad", width: "13%" },
	{ name: "Observaciones", width: "40%" }
];


export const Actividades = ({ active }: Props) => {
	const { grupos } = getGrupoActividades(active.actividades ?? []);

	return (<>

		<View>
			<TitleTables
				title={title}
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
			/>
			<TitleTables title={titleTable} sx={{ fw: 'normal', fz: 10 }} />

			{
				Object.keys(grupos).map((key, index) => {
					const actividades: Actividad[] = (grupos[key])
					const filas: Cell[] = [];
					const images: Imagen[] = [];
					let descripcion: string = '';
					let tipoServicio: string = '';
					actividades.forEach((item, index) => {
						filas.push({ name: index + 1, width: '7%', align: 'center' })
						filas.push({ name: item.actividad!, width: '40%' })
						filas.push({ name: item.cantidad!, width: '13%', align: 'center' })
						filas.push({ name: item.observaciones!, width: '40%' })

						images.push(...item.images ?? []);
						descripcion = item.actividad;
						tipoServicio = item.tipoServicio ?? ''
					})
					return (
						<View key={index} style={{ marginTop: 5 }}>
							<View style={[styles.headerContainer]}>
								<HeadItems names={[listaTitle]} />
								<RowItems columnas={[{ name: key }]} />
							</View>
							<View style={[styles.headerContainer]}>
								<HeadItems names={[servicio]} top={false} />
								<RowItems columnas={[{ name: tipoServicio }]} />
							</View>
							<View>
								<HeaderTable names={tableTitles} defaultWidth={false} sx={{ txa: 'center', bc: "#dddddd" }} bottom={true} />
								<ItemsTable key={key} columnas={filas} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom defaultWidth={false} />
							</View>
							<ImagenesActividades imagenes={images} descripcion={descripcion} listado={key} />
						</View>
					)
				})
			}
		</View>

		{/* <View style={styles.container}>
			{
				active.actividades?.map(item => {
					const itemActividad = item.tipoServicio!.includes('Cabina')
						? 'LISTADO BdB CAJEROS CABINA'
						: item.actividad!.includes('Fachada')
							? 'LISTADO BdB CAJEROS FACHADA'
							: item.actividad!.includes('SITE')
								? 'LISTADO BdB CAJEROS SITE' : 'LISTADO BdB MANTENIMIENTOS LOCATIVOS';
					return (
						<View key={item.id} >
							<View style={styles.titleActividad}>
								<HeaderItems text="Enumere las actividades a realizar dentro del listado de Actividades del BdB:" />
							</View>
							<View style={styles.titleActividad}>
								<Text style={styles.textContainer}>{`Lista de actividades ${itemActividad}`}</Text>
							</View>
							<View style={styles.titleServicio}>
								<HeaderItems text="Tipo servicio:" />
								<ListItems text={active.tipoActividad?.join(', ')!} />
							</View>
							<View style={styles.bodyActividad}>
								<HeaderItems text={`Actividades Cajero ${item.tipoServicio}:`} />
								<ListItems sx={{ fw: 'normal' }} text={item.actividad!} />
							</View>
							<View style={[styles.titleServicio, { marginTop: 5 }]} wrap={false}>
								<HeaderItems text="Evidencias:" />
								<ImagesPdf images={item.images!} descripcion={item.actividad} />
							</View>
							<View style={[styles.titleServicio, { marginTop: 5 }]}>
								<HeaderItems text="Observaciones:" />
								<ListItems sx={{ fw: 'normal' }} text={item.observaciones!} />
							</View>
						</View>
					)
				})
			}
		</View> */}
	</>
	);
};
