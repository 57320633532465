import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../../reducers/rootReducer";
import { starLoadContactos } from "../../contactos/actions/contactos";
import { GridRowId } from '@mui/x-data-grid';
import { getColor } from '../../../helpers/getColor';
import { startSetIsNewCliente, startSetModalCliente } from "../actions/cliente";
import { startAddSelloFisico, startClearSelloFisico } from "../../../shared/sello_fisico/actions/images";

interface InitState {
	id: GridRowId;
	nombre: string
}

export const useClienteDetail = () => {
	const navigate = useNavigate();
	const [detailValues, setDetailValues] = useState<InitState>({
		id:'',
		nombre:''
	})

	const [bgcolor, setColor] = useState('');

	const dispatch = useDispatch();
	const { activeCliente } = useSelector((state: AppState) => state.clientes);
	const {id, nombre} = detailValues;
	const { contactos } = useSelector((state: AppState) => state.contactos);

	const clienteContactos = contactos.filter(c => c.cliente === id);
	const avatarLetter = nombre.substring(0,1)

	const handleEditCliente = () =>{
		dispatch(startSetIsNewCliente(false));
		dispatch(startSetModalCliente(true));
	}
	
	useEffect(() => {
	  if(!activeCliente){
		navigate('/clientes',{replace: false})
	  }else{
		setDetailValues({
			id: activeCliente?.id!,
			nombre: activeCliente.nombre
		})
		setColor(getColor())
	  }
	},[activeCliente, navigate])
	
	useEffect(()=>{
		if(contactos && contactos.length > 0) return;
		dispatch(starLoadContactos())
	},[contactos, dispatch])

	useEffect(()=>{
		if(activeCliente?.logo){
			dispatch(startAddSelloFisico(activeCliente.logo))
		}

		return () => {
			dispatch(startClearSelloFisico())
		  };
	},[activeCliente?.logo, dispatch])

  return {
	...activeCliente,
	avatarLetter,
	bgcolor,
	contactos: clienteContactos,
	nombre,
	handleEditCliente
  }
}
