import { GridRowId } from "@mui/x-data-grid";
import { MeteorologicaAction } from "../interfaces/meteorologicas";
import { Condicion } from "../../../interfaces/shared";

export const startSetMeteorologicas = (meteorologicas: Condicion[]): MeteorologicaAction => ({
    type: 'set-meteorologicas',
    payload: meteorologicas,
});

export const startUpdateMeteorologica = (meteorologica: Condicion): MeteorologicaAction => ({
    type: 'update-meteorologica',
    payload: meteorologica,
});

export const startDeleteMeteorologica = (id: GridRowId): MeteorologicaAction => ({
    type: 'delete-meteorologica',
    payload: id,
});


export const startSetMeteorologicaEmpty = (): MeteorologicaAction => ({
    type: 'set-meteorologica-init-state',
});