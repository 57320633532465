import Box from '@mui/material/Box';
import {
    GridActionsCellItem,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useItemsTable } from '../hooks/useItemsTable';
import { ItemsToolBar } from './ItemsToolbar';
import { AlertItem } from './AlertItem';
import { ModalItem } from './ModalItem';
export const ItemsTable = () => {
	const {
        items,
        columns,
        rowModesModel,
        handleEditClick,
        handleDeleteClick,
        processRowUpdate,
        setRowModesModel,
    } = useItemsTable(); 

	const columnsGrid: GridColDef[] = [
        ...columns,       
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex:1,
            cellClassName: 'actions',
            getActions: ({ id }) => {     
                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon color='secondary' /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Borrar lista"><DeleteIcon color='error' /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

  return (
	<Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={items.slice(0, items.length)}
                columns={columnsGrid}
                initialState={{                    
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                rowModesModel={rowModesModel}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: ItemsToolBar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }}               
                localeText={{...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay datos para mostrar'}}
                rowHeight={35}
				pagination={true}                
                pageSizeOptions={[5, 10, 20, 50]}
            />
             <ModalItem />
            <AlertItem />         
        </Box>
  )
}
