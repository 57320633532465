import { SelloFisicoState, SelloFisicoAction } from "../interfaces/images";

const selloState: SelloFisicoState = {
    selloFisico: null,
    modalSelloFisico: false
}

export const selloFisicoReducer = (state = selloState, action: SelloFisicoAction): SelloFisicoState =>{
    switch(action.type){
        case "create-sello-images":
                return {
                    ...state,
                    selloFisico: action.payload
                }
            case "update-sello-images":
                return {
                    ...state,
                    
                }
            case "delete-sello-images":
                return {
                    ...state,
                    selloFisico: null
                    
                }
            case "clear-sello-image-state":
                return {
                    ...state,
                    selloFisico: null
                }
            case 'set-modal-sello-image':
                return {
                    ...state,
                    modalSelloFisico: action.payload
                }
        default:
            return state
    }
}