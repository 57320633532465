import React from "react";
import { View, StyleSheet, Text, Image } from "@react-pdf/renderer";

import { getFirma } from '../../../../../../helpers';
import { Informe } from "../../../../interfaces/informes";

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "column",
		marginTop: 10,
	},
	container: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		maxWidth: '50%',
		marginTop: 10,
	},
	title: {
		flexDirection: 'row',
		width: '100%'
	},
	headerName: {
		flexDirection: 'row',
		width: '100%',
		color: '#aaaaaa',
	},
	name: {
		flexDirection: 'row',
		width: '100%'
	},
	identificacion: {
		flexDirection: 'row',
		width: '100%'
	},
	numero: {
		marginLeft: 4
	},
	firma: {
		flexDirection: 'row',
		width: '100%',
		border: 1,
		borderColor: '#aaaaaa',
		padding: 5,
		borderRadius: 5
	},
	image: {
		maxHeight: 100,
		height: 'auto',
		objectFit: 'fill',
		borderRadius: 10
	}
});

interface Props {
	active: Informe;
}

export const Footer = ({ active }: Props) => {

	return (
		<View >
			<View style={styles.headerContainer}>
				<View style={styles.container} wrap={false}>
					<View style={styles.title}><Text>Quien ejecuto las Actividades por parte de BELING SAS:</Text></View>
					<View style={styles.headerName}>
						<Text>Nombre y Apellido</Text>
					</View>
					<View style={styles.name}>
						<Text>{active.responsableBeling![0].nombre}</Text>
					</View>
					<View style={styles.headerName}>
						<Text>Identificacion:</Text>
					</View>
					<View style={styles.identificacion}>
						<Text>{active.responsableBeling![0].identificacion}</Text>
					</View>
					<View style={styles.headerName}>
						<Text>cargo:</Text>
					</View>
					<View style={styles.identificacion}>
						<Text>{active.responsableBeling![0].cargo}</Text>
					</View>
					<View style={styles.headerName}>
						<Text>Firma:</Text>
					</View>
					<View style={styles.firma}>
						<Image style={styles.image} src={() => getFirma(active.responsableBeling![0].firma.name)} />
					</View>
				</View>
				<View style={styles.container} wrap={false}>
					<View style={styles.title}><Text>Quien recibe los trabajos realizados por parte del CLIENTE:</Text></View>
					<View style={styles.headerName}>
						<Text>Nombre y Apellido</Text>
					</View>
					<View style={styles.name}>
						<Text>{active.responsableCliente![0].nombre}</Text>
					</View>
					<View style={styles.headerName}>
						<Text>Identificacion:</Text>
					</View>
					<View style={styles.identificacion}>
						<Text>{active.responsableCliente![0].identificacion}</Text>
					</View>
					<View style={styles.headerName}>
						<Text>Cargo:</Text>
					</View>
					<View style={styles.identificacion}>
						<Text>{active.responsableCliente![0].cargo}</Text>
					</View>
					<View style={styles.headerName}>
						<Text>Firma:</Text>
					</View>
					<View style={styles.firma}>
						<Image style={styles.image} src={() => getFirma(active.responsableCliente![0].firma.name)} />
					</View>
				</View>
			</View>
		</View>
	)
}