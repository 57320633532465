import { Avatar, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { ClientesTable } from './ClientesTable';

export const Clientes = () => {
  return (
    <>
      <Box
        sx={{
          my: 1,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <PersonIcon />
        </Avatar>
        <Typography component="h1" align="center" variant="h6">
          Clientes
        </Typography>
      </Box>
      <ClientesTable />
    </>
  );
};
