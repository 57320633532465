import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
} from '@mui/x-data-grid';


import { AppState } from '../../../reducers/rootReducer';
import { CellExpand } from '../../../components/ui/CellExpand'; import { startUpdateCondicionSitio } from '../actions/condicionesSitio';
import { Condicion } from '../../../interfaces/shared';
import { StyledDataGrid } from '../../../styles/styledDataGrid';

export const CondicionesSitioTable = () => {
    const dispatch = useDispatch();
    const { condicionesSitio } = useSelector((state: AppState) => state.shared.condicionesSitio);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel<Condicion>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateCondicionSitio(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Descripción',
            flex: 2,
            editable: false,
            renderCell: CellExpand
        },
        {
            field: 'status',
            headerName: 'Codición',
            headerAlign: 'center',
            flex: 1,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <StyledDataGrid
                autoHeight
                rows={condicionesSitio.slice(0, condicionesSitio.length)}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                hideFooter
                componentsProps={{
                    toolbar: { setRowModesModel },
                }}
                experimentalFeatures={{ newEditingApi: true }}
                localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay registros' }}
                rowHeight={35}
            />
        </Box>
    );
}
