import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { AppState } from '../../../reducers/rootReducer';
import {
	setInactiveServicio,
	startSetModalServicio,
	startCreateServicio,
	startUpdateServicio,
	startSetServicioIsNew,
} from '../actions/servicio';
import { useSelect } from '../../../hooks/useSelect';
import { useForm } from '../../../hooks/useForm';
import { useDate } from '../../../hooks/useDate';
import {
	startLoadClientes,
	starLoadContactos,
	startLoadEstados,
	startLoadTecnicos,
	startLoadTipos,
} from '../../resources/actions/resources';
import { Resource } from '../../resources/interfaces/resources';
import { SelectChangeEvent } from '@mui/material';
import { PlaceType } from '../../../interfaces/google';
import { useMobileDetect, useScreenSize } from '../../../hooks';

const initForm = {
	descripcion: "",
	nombre: '',
	numero: 0,
	referencia: ''
};

const initSelect = {
	cliente: '',
	contacto: "",
	estado: "Sin ser asignados",
	tecnico: "",
	tipo: "",
};

const initDate = {
	fecha: new Date(),
};

export const useServicioModal = () => {
	const dispatch = useDispatch();
	const { width } = useScreenSize();
    const [fullscreen, setFullscreen] = useState<boolean>(true);
	const { isMobile } = useMobileDetect();
	const { clientes, contactos: contactosDb, estados, tipos, tecnicos } = useSelector(
		(state: AppState) => state.resources
	);
	const { modalServicio, activeServicio, isNew } = useSelector((state: AppState) => state.servicios);
	const [checked, setChecked] = useState(true);
	const [colaboradores, setColaboradores] = useState<string[]>([]);
	const [origen, setOrigen] = useState<PlaceType | null>(null);
	const [destino, setDestino] = useState<PlaceType | null>(null)
	const [contactos, setContactos] = useState<Resource[]>([]);

	const { onChange, changeData, registerData, numero, descripcion, nombre, referencia } = useForm(initForm);
	const { onChangeSelect, changeSelect, registerSelectData } = useSelect(initSelect);
	const { onDateChange, changeDate, registerDataDate } = useDate(initDate);
	const { cliente, contacto, estado, tecnico, tipo } = registerSelectData;
	const { fecha } = registerDataDate;

	useEffect(() => {
        if (width < 1024) {
            setFullscreen(true)
        } else {
          setFullscreen(false)
        }
    }, [width])

	useEffect(() => {
		if (clientes.length < 1) {
			dispatch(startLoadClientes());
		}
		
	}, [dispatch, clientes.length]);

	useEffect(() => {
		if(contactosDb.length > 0) return;
		dispatch(starLoadContactos());
		setContactos(contactosDb)
		
	},[dispatch, contactosDb]);

	useEffect(() => {
		if (estados.length < 1) {
			dispatch(startLoadEstados());
		}
		
	}, [dispatch, estados.length]);

	useEffect(() => {
		if (tipos.length < 1) {
			dispatch(startLoadTipos());
		}
		
	}, [dispatch, tipos.length]);

	useEffect(() => {
		if (tecnicos.length < 1) {
			dispatch(startLoadTecnicos());
		}
		
	}, [dispatch, tecnicos.length]);	

	useEffect(() => {
		if (!activeServicio) {
			changeData({ ...initForm, ...initSelect, ...initDate });
			setColaboradores([]);
		} else {
			changeData({
				numero: activeServicio.numero,
				descripcion: activeServicio.descripcion,
				nombre: activeServicio.descripcion,
				referencia: activeServicio.referencia
			});
			changeSelect({
				contacto: activeServicio.contacto.id,
				cliente: activeServicio.cliente.id,
				estado: activeServicio.estado.id,
				tecnico: activeServicio.tecnicoId!,
				tipo: activeServicio.tipo.id,
			});
			changeDate({
				fecha: moment(activeServicio.fecha).toDate(),
			});
			setColaboradores(activeServicio.colaboradores);
			setOrigen(activeServicio.origen!);
			setDestino(activeServicio.destino);
		}
		
	}, [activeServicio, changeData, changeSelect, changeDate]);

	useEffect(() => {
		if (cliente) {			
			const contactosDB = contactosDb.filter(c => c.cliente === cliente)
			const contactos = contactosDB.length > 0 ? contactosDB : [];
			setContactos(contactos)			
			if(!activeServicio){
				changeSelect({
					...registerSelectData,
					contacto: '',
				});
			}else{
				changeSelect({
					...registerSelectData,
					contacto: activeServicio.contacto.id,
				});
			}
			
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cliente, contactosDb]);

	useEffect(()=>{
		if(!isNew) return;
		changeData(initForm);
		changeSelect(initSelect);
		changeDate(initDate);
		setDestino(null);
		setOrigen(null);
	},[changeData, changeDate, changeSelect, isNew])

	const handleChangeMultiple = (event: SelectChangeEvent<typeof colaboradores>) => {
		const {
			target: { value },
		} = event;
		setColaboradores(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};


	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		console.log(event.target.checked)
	};

	const handleClose = () => {
		changeData(initForm);
		changeSelect(initSelect);
		changeDate(initDate);
		dispatch(setInactiveServicio());
		dispatch(startSetModalServicio(false));
		setDestino(null);
		setOrigen(null);
		dispatch(startSetServicioIsNew(true))
	};

	const handleSave = () => {
		const servicio = {
			cliente,
			colaboradores,
			contacto,
			descripcion,
			destino,
			estado,
			fecha: moment(fecha).format("YYYY-MM-DD HH:mm:ss"),
			nombre,
			numero,
			origen,
			referencia,
			tecnico,
			tipo,
		};

		if (!activeServicio) {
			dispatch(startCreateServicio(servicio));
		} else {
			dispatch(startUpdateServicio(servicio));
		}
		dispatch(setInactiveServicio());
		handleClose();
	};


	return {
		activeServicio,
		checked,
		clientes,
		colaboradores,
		contactos,
		estados,
		fullscreen,
		isMobile,
		modalServicio,
		registerData,
		registerSelectData,
		registerDataDate,
		tecnicos,
		tipos,
		origen,
		destino,
		setOrigen,
		setDestino,
		handleChange,
		handleChangeMultiple,
		handleClose,
		handleSave,
		onChange,
		onChangeSelect,
		onDateChange,
	}
}
