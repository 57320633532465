import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight: 18,
        textAlign: 'center',        
        flexGrow: 1,
    },
    containerTitle:{
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        textAlign: 'center',
        
        flexGrow: 1,
    },
    title:{
        width: '100%',
        textAlign: 'center'
    },
    descripcion: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    unidad: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        flexWrap: 'wrap',
    },
    cantidad: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    observaciones: {
        width: '30%',    
    }
  });

export const TableMaterialHeader = () => (
    <>
    <View style={styles.containerTitle} fixed>
        <Text style={styles.title}>Listado de materiales autilizados</Text>
    </View>
    <View style={styles.container} fixed>
        <Text style={styles.descripcion}>Descripción</Text>
        <Text style={styles.unidad}>Unidad</Text>
        <Text style={styles.cantidad}>Cantidad</Text>
        <Text style={styles.observaciones}>Observaciones</Text>
    </View>
    </>
  );