import { Actividad } from "../modules/reportes/interfaces/reportes";


export const getGrupoActividades = (actividadesDB: Actividad[]) => {
	const actividades: Actividad[] = actividadesDB ?? [];
	let grupos: any = {};
	let activities: Map<string, Actividad[]> = new Map();

	actividades.forEach(actividad => {
		const nombreGrupo: string = getGrupo(actividad.tipoServicio!);
		if (!grupos[nombreGrupo]) grupos[nombreGrupo] = new Array<Actividad>();
		if (!activities.get(nombreGrupo)) activities.set(nombreGrupo,[]);
		grupos[nombreGrupo].push(actividad)		
		activities.get(nombreGrupo)?.push(actividad)
	})
	return {
		grupos,
		activities
	}
}

function getGrupo(servicio: string): string {

	return servicio.includes('Cabina')
		? 'LISTADO BdB CAJEROS CABINA'
		: servicio.includes('Fachada')
			? 'LISTADO BdB CAJEROS FACHADA'
			: servicio.includes('SITE')
				? 'LISTADO BdB CAJEROS SITE'
				: 'LISTADO BdB MANTENIMIENTOS LOCATIVOS';
}


