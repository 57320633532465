import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { AppState } from '../../../../../reducers/rootReducer'

import { Gallery } from '../../../../../shared/gallery/Gallery';
import { GridRowId } from '@mui/x-data-grid';
import { deleteImagen } from '../../../../../helpers/imagen';
import { doMessage } from '../../../../ui/actions/ui';
import { startDelImgRutinaAlarmas, startSetModImgAlarmas } from '../actions/alarma';


export const ModalImagesRutinaAlarmas = () => {
	const dispatch = useDispatch();
	const {modalImagesRutinaAlarma, activeRutinaAlarma} = useSelector((state: AppState)=> state.reportes.modules.alarma);

    const id = activeRutinaAlarma!.id ?? 0;
    const images = activeRutinaAlarma!.images ?? [];

	const handleClose = ()=>{
		dispatch(startSetModImgAlarmas(false));
	}

    const handleDeleteImagen = async (imgId: GridRowId)=>{        
        const dele = await deleteImagen(imgId)
        if(dele.status){
            dispatch(startDelImgRutinaAlarmas( imgId, id))
        }else{
            dispatch(doMessage({message: 'Error guardando la imagen', type:'error'}))
        }
    }
	return (
        <>
            <Dialog open={modalImagesRutinaAlarma} onClose={handleClose}>
                <DialogTitle>Imágenes</DialogTitle>
                <DialogContent>
                 <Gallery images={images!} handleDelete={handleDeleteImagen}/>   
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
