import { GridRowId } from "@mui/x-data-grid";
import { ActividadAction } from "../interfaces/actividad";
import { Imagen } from "../../../../../interfaces/image";
import { Actividad } from "../../../interfaces/reportes";

export const startSetActividadBdB = (actividad: Actividad): ActividadAction => ({
    type: 'set-actividad-bdb',
    payload: actividad,
});

export const startUpdateActividad = (actividad: Actividad): ActividadAction => ({
    type: 'update-actividad-bdb',
    payload: actividad,
});

export const startDeleteActividad = (id: number | GridRowId): ActividadAction => ({
    type: 'delete-actividad-bdb',
    payload: id,
});

export const startSetModadlActividad = (modalActividad: boolean): ActividadAction => ({
    type: 'set-modal-actividad-bdb',
    payload: modalActividad,
});

export const startSetActiveActividadBdB = (id: GridRowId): ActividadAction => ({
    type: 'set-actividad-Active-bdb',
    payload: id,
});

export const startSetActividadUpdateImage = (image: Imagen, id: GridRowId): ActividadAction => ({
    type: 'set-actividad-update-image-bdb',
    payload: { image, id },
});

export const startSetActividadEmpty = ():ActividadAction => ({
    type: 'set-actividad-empty-bdb',
});

export const startSetTipoActividad = (tipo: string):ActividadAction => ({
    type: 'set-tipo-actividad-bdb',
    payload: tipo
})

export const startDelImgActividadBdB = (imgId: GridRowId, id: GridRowId): ActividadAction => ({
    type: 'set-delete-image-actividad-bdb',
    payload:{
        id,
        imgId
    }
})

export const startClearImagesActividadBdB = (payload: GridRowId): ActividadAction =>({
    type: 'set-clear-images-actividad-bdb',
    payload
})

export const startSetModImgActividadBdB = (payload: boolean): ActividadAction =>({
    type: 'set-modal-imagenes-actividad-bdb',
    payload
})

export const startSetActividadBdBId = (id: GridRowId):ActividadAction => ({
    type: 'set-actividad-bdb-id',
    payload: id,
});

