interface Tipo {
    title: string;
    formulario: number;
}

export const servicioTipo = (alias: string = 'basico') => {
    const tipo: Tipo = {
        title: 'Reporte Mantenimiento',
        formulario: 1
    };

    switch (alias) {
        case 'claro':
            tipo.title = 'Reporte Mantenimiento RIBU CLARO';
            tipo.formulario= 2;           
            break;
        case 'emergencia':
            tipo.title = 'Reporte Servicio de operación y/o atención de emergencia';
            tipo.formulario= 3
            break;
        case 'bdb':
            tipo.title = 'Reporte Servicio O&M - BdB';
            tipo.formulario = 4;
            break;
        case 'ats':
            tipo.title = 'Analisis de Trabajo Seguro ATS';
            tipo.formulario = 5;
            break;
        default:
            tipo.title = 'Reporte Mantenimiento';
            tipo.formulario = 1; 
            break;
    }

    return tipo;

}