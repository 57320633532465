import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
} from '@mui/x-data-grid';


import { AppState } from '../../../reducers/rootReducer';
import { setActiveServicio, startSetModalServicio, startSetModalReporte, startSetModalDelete, startSetModalAts, startSetModalReporteNormal, updateServicioFromReporte, startSetModalReporteEmergencia, startSetModalReporteBdB, startSetServicioIsNew } from '../actions/servicio';
import { startLoadReportes, startSetActiveReporte, startSetInactiveReporte, startSetModalReportePdf } from '../../reportes/actions/reportes';
import { startSetActiveAts, startSetModalAtsPdf, startSetInactiveAts, startLoadAts } from '../../ats/actions/ats';

export const useServicioTable = () => {

    const dispatch = useDispatch();
    const { isAdmin } = useSelector((state: AppState) => state.auth)
    const { servicios: serviciosDB, modalReporte, modalReporteBdB } = useSelector((state: AppState) => state.servicios);
    const { reportes, modalReportePdf } = useSelector((state: AppState) => state.reportes.reportes);
    const { ats } = useSelector((state: AppState) => state.ats.ats)
    const { estados } = useSelector((state: AppState) => state.resources)
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [pageSize, setPageSize] = useState(5);

    const estadosServicio = estados.map(e => {
        const estado = e.name
        return estado
    })

    const hasReporte = (id: any) => {
        const reporte = reportes.filter(r => r.servicio?._id === id)
        let has;
        if (reporte.length > 0) {
            has = true;
        } else {
            has = false;
        }

        return has
    }

    const hasAts = (id: GridRowId) => {
        const at = ats.filter(a => a.servicio?._id === id);
        let has: boolean;
        if (at.length > 0) {
            has = true;
        } else {
            has = false
        }
        return has;
    }
    const servicios = serviciosDB.map(s => {
        return {
            id: s.id,
            numero: s.numero,
            nombre: s.nombre,
            fecha: s.fecha,
            tipo: s.tipo.name,
            aliasTipo: s.tipo.alias,
            cliente: s.cliente.nombre,
            tecnico: s.tecnico,
            estado: s.estado.name,
            color: s.estado.color,
            hasReporte: hasReporte(s.id) || hasAts(s.id!)
        }
    })

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        dispatch(startSetServicioIsNew(false))
        dispatch(setActiveServicio(id));
        dispatch(startSetModalServicio(true));
        //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(setActiveServicio(id));
        dispatch(startSetModalDelete(true));
    };

    const handleAddReporte = (id: GridRowId) => () => {
        const reporte = getReporte(id) || getAts(id);
        const tipo = getServicio(id)        
        if (!reporte) {
            dispatch(setActiveServicio(id));
            dispatch(startSetInactiveReporte())
            dispatch(updateServicioFromReporte(id, 'En proceso'));
            if (tipo === 'claro') {
                dispatch(startSetModalReporte(true));
            } else if (tipo === 'emergencia') {
                dispatch(startSetModalReporteEmergencia(true));
            } else if (tipo === 'bdb') {
                dispatch(startSetModalReporteBdB(true));
            } else if (tipo === 'ats') {
                dispatch(startSetInactiveAts())
                dispatch(startSetModalAts(true));
            } else {
                dispatch(startSetModalReporteNormal(true));
            }
        } else {
            if (tipo === 'claro') {
                dispatch(startSetActiveReporte(reporte));
                dispatch(startSetModalReportePdf(true));
            }else if (tipo === 'bdb') {
                dispatch(startSetActiveReporte(reporte));
                dispatch(startSetModalReportePdf(true));
            }else if (tipo === 'emergencia') {
                dispatch(startSetModalReporteEmergencia(true));
            } else if (tipo === 'ats') {
                dispatch(startSetActiveAts(reporte));
                dispatch(startSetModalAtsPdf(true));
            } else {
                dispatch(startSetActiveReporte(reporte));
                dispatch(startSetModalReportePdf(true));
            }
        }

    }

    const handleAddAts = (id: GridRowId) => () => {
        dispatch(setActiveServicio(id));
        const ats = getAts(id)
        if (!ats) {
            dispatch(startSetInactiveAts())
            dispatch(startSetModalAts(true));
        } else {
            dispatch(startSetActiveAts(ats))
            dispatch(startSetModalAtsPdf(true))
        }
    }

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const getReporte = (servicio: GridRowId) => {
        const reporteId = reportes.find(r => r.servicio?._id === servicio);
        if (!reporteId) {
            return false
        }
        return reporteId.id
    }

    const getAts = (servicio: GridRowId) => {
        const at = ats.find(a => a.servicio?._id === servicio);
        if (!at) {
            return false
        }

        return at.id
    }

    const getServicio = (id: GridRowId) => {
        const servicio = servicios.find(s => s.id === id);
        return servicio?.aliasTipo
    }

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        //dispatch(startUpdateServicio(updatedRow));
        console.log('processRowUpdate', updatedRow);
        return updatedRow;
    };

     useEffect(() => {
        if (ats.length > 0) return;
            dispatch(startLoadAts())
    }, [dispatch, ats.length])

    useEffect(() => {
        if (reportes.length > 0) return;
        dispatch(startLoadReportes())
        
  
    }, [dispatch, reportes.length])

    return {
        estadosServicio,
        isAdmin,
        modalReporte,
        modalReporteBdB,
        modalReportePdf,
        pageSize,
        rowModesModel,
        servicios,
        setPageSize,
        handleRowEditStart,
        handleRowEditStop,
        handleEditClick,
        handleSaveClick,
        handleDeleteClick,
        handleAddReporte,
        handleAddAts,
        handleCancelClick,
        processRowUpdate,
        setRowModesModel,
    }
}
