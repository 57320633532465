import { combineReducers } from 'redux';
import { equipoReducer } from '../modules/equipo/state/equipoReducer';
import { herramientaReducer } from '../modules/herramienta/state/herramientaReducer';
import { riesgoAlturaReducer } from '../modules/riesgo_altura/state/riesgoAlturaReducer';
import { riesgoElectricoReducer } from '../modules/riesgo_electrico/state/riesgoElectricoReducer';
import { riesgoSitioReducer } from '../modules/riesgo_sitio/state/riesgoSitioReducer';
import { atsReducer } from './atsReducer';

export const atsrootReducer = combineReducers({
    ats: atsReducer,
    equipos: equipoReducer,
    herramientas: herramientaReducer,
    riesgosAltura: riesgoAlturaReducer,
    riesgosElectrico: riesgoElectricoReducer,
    riesgosSitio: riesgoSitioReducer,
});

export type AtsState = ReturnType<typeof atsrootReducer>;