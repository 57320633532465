import { GridRowId } from "@mui/x-data-grid";
import { Vehiculo, VehiculoAction } from "../interfaces/vehiculo"

export const startSetVehiculos = (vehiculo: Vehiculo): VehiculoAction => ({
    type: 'set-vehiculos',
    payload: vehiculo
});

export const startUpdateVehiculo = (vehiculo: Vehiculo): VehiculoAction => ({
    type: 'update-vehiculo',
    payload: vehiculo
});

export const startDeleteVehiculo = (id: GridRowId): VehiculoAction => ({
    type: 'delete-vehiculo',
    payload: id
});

export const startSetVehiculosEmpty = (): VehiculoAction => ({
    type: 'set-vehiculos-empty'
});