import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { Ats } from "../../../interfaces/ats";
import { Title } from "../../../../../components/pdf/Title";
import { RiesgoHead } from "../../../../../components/pdf/RiesgoHead";
import { RiesgoRow } from "../../../../../components/pdf/RiesgoRow";
import { ConfirmarOpcion } from "../../../../../components/pdf/ConfirmarOpcion";

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: "#757575",
  },
});

const title = "Riesgos en Alturas.";
const namesUno = ["¿Realiza trabajo en alturas?:"];
interface Props {
  active: Ats;
}
export const TrabajoEnAlturas = ({ active }: Props) => {
  return (
    <>
      <ConfirmarOpcion title={namesUno}  value={active.trabajoAltura!.toUpperCase()}  />      
          <Title title={title} sx={{fz: 12, fs: 'italic', fw: 'bold'}}/>
          <View style={styles.tableContainer}>
            <RiesgoHead />
            <RiesgoRow items={active.riesgosAltura!} />
          </View>
    </>
  );
};
