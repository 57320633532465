import { useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { AppState } from '../../reducers/rootReducer';
import { dataPie } from '../../helpers';
import { Title } from '../title/Title';
import { Box } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieServicios = () => {
	const { servicios: serviciosDB } = useSelector((state: AppState) => state.servicios);

	const servicios = serviciosDB.map(s =>{
		return {
			id: s.id,
			estado: s.estado.name
		}
	})

	const data = dataPie(servicios, 'estado', 'Servicios');
	
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			alignContent: 'center',
			justifyContent: 'center',
			alignItems: 'center',
			height: 300
		}}>
		<Title marginTop={0}>Servicios</Title>
		<Pie data={data} />
		</Box>
	)
}
