import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { MeteorologicasTable } from './MeteorologicasTable';
import { StyleProps } from '../../../modules/reportes/interfaces/reportes';

const Table = memo(() => {
    return (
        <MeteorologicasTable />
    );
});

export const Meteorologicas = ({ mt = 4, mb = 1 }: StyleProps) => {
    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <Typography component="h1" align='center' variant="subtitle1">
                    Condiciones Meteorológicas
                </Typography>
                <Table />
            </Grid>
        </>
    )
}
