import { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Equipo } from '../../modules/ats/interfaces/ats';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        textAlign: 'justify',
        minHeight: 18
    },
    rowTop: {
        borderBottomColor: '#eeeeee',
        borderBottomWidth: 1,
    },
    referencia: {
        flexDirection: 'column',
        width: '70%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 4,
        flexWrap: 'wrap',
        alignContent: 'center',
        minHeight: 18,
        paddingRight: 2,
        height: '100%'
    },
    detalle: {
        width: '30%',
        borderRightColor: borderColor,
        paddingLeft: 4,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
});

interface Props {
    items: Equipo[];
}

export const RiesgoRow = ({ items }: Props) => {
    const itemsFiltered = items.filter(item => item.riesgo);
    const length = itemsFiltered.length;
    const rows = itemsFiltered.map((item, index) => {
        const cellStyles = cellStyle(index, length);
        return (<View wrap={false} style={[cellStyles]} key={item.id.toString()}>
            <View style={styles.referencia}>
                <View><Text >{item.name!}</Text></View>
            </View>
            <View style={styles.detalle}>
                <Text >{item.riesgo}</Text>
            </View>
        </View>)
    }
    )
    return (<Fragment>{rows}</Fragment>)
};

function cellStyle(index: number, length: number) {

    const cell = {
        ...styles.row,
        ...styles.rowTop
    }

    const cellEnd = {
        ...styles.row
    }

    if (length === index + 1) {
        return cellEnd
    } else {
        return cell
    }

}