import { MaterialAction, MaterialState } from '../interfaces/material';

const initState: MaterialState = {
    materiales: []
};

export const materialReducer = (state = initState, action: MaterialAction): MaterialState => {
    switch (action.type) {
        case 'set-material':
            return {
                ...state,
                materiales: [...state.materiales, action.payload]
            };
        case 'update-material':
            return {
                ...state,
                materiales: state.materiales.map(material => {
                    if (material.id === action.payload.id) {
                        return action.payload;
                    }
                    return material;
                })
            };
        case 'delete-material':
            return {
                ...state,
                materiales: state.materiales.filter(material => material.id !== action.payload)
            };
        case 'edit-material':
            return {
                ...state,
                materiales: state.materiales.map(material => {
                    if (material.id === action.payload) {
                        material.isNew = true;
                        return material;
                    }
                    return material;
                })
            };
        case 'set-material-empty':
            return {
                ...state,
                materiales: []
            };
        default:
            return state;
    }
}