import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { RiesgoAlturaTable } from './RiesgoAlturaTable';

const Table = memo(() => {
    return(
        <RiesgoAlturaTable />
    )
});

export const RiesgoAltura = () => {

    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                    Riesgos en Alturas
                    </Typography>
                </Grid>
            </Grid>
            <Table />
        </>
    )
}