import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppState } from '../../../reducers/rootReducer';
import { startDeleteContacto, startSetAlertDeleteContacto } from '../actions/contactos';

export const AlertContacto = () => {
    const dispatch = useDispatch();
    const { alertDeleteContacto } = useSelector((state: AppState) => state.contactos);

    const handleClick = () => {
        dispatch(startDeleteContacto());
        dispatch(startSetAlertDeleteContacto(true));
        handleClose();
    };

    const handleClose = () => {
        dispatch(startSetAlertDeleteContacto(false))
    };

    return (
        <div>
            <Dialog
                open={alertDeleteContacto}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Eliminar Contacto"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Está seguro que desea eliminar el contacto seleccionado?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleClick} autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}