
import { Sst } from '../../../interfaces/sst';
import { BodyLine } from '../shared';


interface Props {
    active: Sst;
}

export const Incidentes = ({ active }: Props) => {
    return (
        <>            
            <BodyLine
                title1='ACCIDENTES PRESENTADOS:'
                title2='INCIDENTES:'
                content1={active.accidentes}
                content2={active.incidentes}
                top
                sx={{wth: '90%', mt: 2}}
            />
        </>
    )
}
