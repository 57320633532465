
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from '../../../../../interfaces/informes';
import { dateShort, getFinalDate } from '../../../../../../../helpers';


const styles = StyleSheet.create({	
	column: {
		flexDirection: 'column',
		borderLeft: 1,
		width: '23.5%',
		height: '100%',	

	},
	rowContainer: {
		flexDirection: 'row',
		width: '100%'		
	},
	rowContainerRight: {
		flexDirection: 'row',
		width: '100%',
		height: '100%',
	},
	borderRow:{
		borderBottom: 1, 
		borderRight:1
	},
	borderBottomRow:{
		borderRight:1
	},	
	headColumn:{
		backgroundColor:'#aaaaaa',
		borderTop: 1,
	},
	headColumnStart:{
		backgroundColor:'#aaaaaa',
		borderTop: 1,
	},
	leftColumn: {
		flexDirection: 'column',
		width: '40%',
	},
	rightColumn: {
		flexDirection: 'column',
		width: '31.3%'
	},
	headText: {
		fontSize: 8,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 2
	},
	leftText: {
		fontSize: 8,
		fontWeight: 'normal',
		marginLeft: 3,
		marginTop: 2
	},
	rightText: {
		fontSize: 8,
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 2
	},	
})

interface Props {
	active: Informe;
}
export const ColThree = ({ active }: Props) => {
	const fechaFinal = getFinalDate(active.obra.fechaInicio, active.obra.plazo);
	return (
		<View style={[styles.column]}>
			<View style={[styles.rowContainer]}>
				<View style={[styles.leftColumn]}>
					<View style={[styles.rowContainer, styles.borderRow, styles.headColumnStart,{ justifyContent: 'center' }]}>
						<Text style={[styles.headText]}>FECHAS</Text>
					</View>
					<View style={[styles.rowContainer, styles.borderRow]}>
						<Text style={[styles.leftText]}>PREVISTO</Text>
					</View>
					<View style={[styles.rowContainer, styles.borderBottomRow]}>
						<Text style={[styles.leftText]}>REALIZADO</Text>
					</View>
				</View>
				<View style={[styles.rightColumn ]}>
					<View style={[styles.rowContainer, styles.borderRow, styles.headColumn, { justifyContent: 'center' }]}>
						<Text style={[styles.headText]}>INICIO</Text>
					</View>
					<View style={[styles.rowContainer, styles.borderRow, { justifyContent: 'center' }]}>
						<Text style={[styles.leftText]}>{dateShort(active.obra.fechaInicio)}</Text>
					</View>
					<View style={[styles.rowContainer, styles.borderBottomRow, { justifyContent: 'center' }]}>
						<Text style={[styles.leftText]}>{dateShort(active.obra.fechaInicio)}</Text>
					</View>
				</View>
				<View style={[styles.rightColumn ]}>
					<View style={[styles.rowContainer, styles.borderRow,styles.headColumn, { justifyContent: 'center' }]}>
						<Text style={[styles.headText]}>FINAL</Text>
					</View>
					<View style={[styles.rowContainer, styles.borderRow, { justifyContent: 'center' }]}>
						<Text style={[styles.leftText]}>{fechaFinal}</Text>
					</View>
					<View style={[styles.rowContainer, styles.borderBottomRow, { justifyContent: 'center' }]}>
						<Text style={[styles.leftText]}>{dateShort(active.fecha!)}</Text>
					</View>
				</View>						
			</View>
		</View>
	)
}
