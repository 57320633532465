import { MedicionBateriaState, MedicionBateriaAction } from '../interfaces/medicion';

const initState: MedicionBateriaState = {
    medicionesBateria: [],
};

export const medicionBateriaReducer = (state = initState, action: MedicionBateriaAction): MedicionBateriaState => {
    switch (action.type) {
        case 'set-medicion-bateria':
            return {
                ...state,
                medicionesBateria: [...state.medicionesBateria, action.payload],
            };
        case 'update-medicion-bateria':
            return {
                ...state,
                medicionesBateria: state.medicionesBateria.map((mb) => {
                    if (mb.id === action.payload.id) {
                        return action.payload;
                    }
                    return mb;
                }),
            };
        case 'delete-medicion-bateria':
            return {
                ...state,
                medicionesBateria: state.medicionesBateria.filter((mb) => mb.id !== action.payload),
            };
        case 'edit-medicion-bateria':
            return {
                ...state,
                medicionesBateria: state.medicionesBateria.map((mb) => {
                    if (mb.id === action.payload) {
                        mb.isNew = false;
                    }
                    return mb;
                }),
            };
        case 'set-medicion-bateria-empty':
            return {
                ...state,
                medicionesBateria: [],
            };
        default:
            return state;
    }
}