import { Route, Routes } from 'react-router-dom'
import { UserScreen } from './UserScreen';
import { UserProfile } from './UserProfile';

export const UserRoutes = () => {
    return (
        <>        
        <Routes>
                <Route path="/usuarios" element={<UserScreen  />} />
                <Route path="/profile" element={<UserProfile  />} />
                <Route path="/" element={<UserScreen />} />
        </Routes>
        </>
      )
}