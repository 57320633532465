import {View, StyleSheet } from '@react-pdf/renderer';
import { Reporte } from '../../../interfaces/reportes';
import { TitleTables } from '../../../../../components/pdf/TitleTables';
import { Cell } from '../../../../../interfaces/shared';
import { HeaderTable, ItemsTable } from '../../../../informes/components/pdf/normal';

const styles = StyleSheet.create({
	container: {
		marginTop: 2
	},
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const tableTitles: Cell[] = [
	{ name: "Item", width: "10%" },
	{ name: "Actividad", width: "35%" },
	{ name: "Observaciones", width: "35%" },
	{ name: "Cantidad", width: "20%" }
];
interface Props {
    active: Reporte;
  }

  export const ActividadesNoPlaneadasTable = ({active}:Props) => {
	const { actividadesNoPlaneadas } = active;
	return (
		<View>
			<TitleTables title='Describa las Actividades No Planeadas' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />
			<View style={styles.container} >
				<View style={styles.descriptionContainer}>
					<HeaderTable names={tableTitles} defaultWidth={false} sx={{ txa: 'center', bc: "#dddddd" }} />
					{actividadesNoPlaneadas?.map((item, index) => {
						const fila: Cell[] = [
							{ name: index + 1, width: '10%', align: 'center' },
							{ name: item.actividad!, width: '35%' },
							{ name: item.observaciones!, width: '35%' },
							{ name: item.cantidad!, width: '20%', align: 'center' },
						]
						return <ItemsTable key={item.id} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom defaultWidth={false} />
					})}
				</View>
			</View>
		</View>
	)
}
