import{ useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { v4 as UUID} from 'uuid'
import {
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid';

import { AppState } from '../../../../../reducers/rootReducer';
import { startSetActividadBdB, startSetTipoActividad } from '../action/actividad';
import { useForm } from '../../../../../hooks/useForm';
import { EditToolbarProps } from '../../../../../interfaces/shared';

export const ActividadToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
    const { tipoActividad } = useSelector((state: AppState) => state.reportes.modules.actividad);

    const { setRowModesModel } = props;
    const { onChange, registerData: { tipo } } = useForm({
        tipo: tipoActividad
    })

    const handleClick = () => {
        const id = UUID();
        
        dispatch(startSetActividadBdB({ id, actividad: '', cantidad: 0, observaciones: '', tipoServicio: tipo!, isNew: true }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'actividad' },
        }));
    };
    
    useEffect(() => {
      dispatch(startSetTipoActividad(tipo!))
    }, [tipo, dispatch])
    

    return (
        <GridToolbarContainer>
            
            <Grid container spacing={3} >
                <Grid item xs={3} alignContent="center" mt={1}>
                    <Typography component="h1" align='left' variant="h6">
                        Tipo de Actividad
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <FormControl >
                        <RadioGroup
                            aria-labelledby="tipo-buttons-group-label"
                            name="tipo"
                            value={tipo}
                            onChange={onChange}
                            row
                            tabIndex={20}
                        >
                            <FormControlLabel value="Mantenimiento Locativo" control={<Radio />} label="Mantenimiento Locativo" />
                            <FormControlLabel value="Tipo Cabina" control={<Radio />} label="Tipo Cabina" />
                            <FormControlLabel value="Tipo Site" control={<Radio />} label="Tipo SITE" />
                            <FormControlLabel value="Tipo Fachada" control={<Radio />} label="Tipo Fachada" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar
            </Button>
        </GridToolbarContainer>
    );
}