import { Box } from '@mui/material';
import { Material } from '../../../../shared/material/components/Material';

export const StepTen = () => {
	
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}>				
				<Material />
			</Box>
		</>
	)
}
