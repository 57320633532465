import { AccidenteAction, AccidenteState } from "../interfaces/accidentes";


const initState: AccidenteState = {
    accidentes: [
        { id: 1, name: 'No hay', cantidad: 0, isNew: false },
        { id: 2, name: 'Accidente sin baja', cantidad: 0, isNew: false },
        { id: 3, name: 'Accidente con baja', cantidad: 0, isNew: false },
        { id: 4, name: 'Casi accidente', cantidad: 0, isNew: false },
    ],
    accidente: null
}

export const accidenteReducer = (state = initState, action: AccidenteAction): AccidenteState => {
    switch (action.type) {
        case 'set-accidentes':
            return {
                ...state,
                accidentes: action.payload
        }
        case 'update-accidente':
            return {
                ...state,
                accidentes: state.accidentes.map(accidente => {
                    if (accidente.id === action.payload.id) {
                        return action.payload;
                    }
                    return accidente;
                })
            }
        case 'set-accidente-init-state':
            return{
                ...state,
                accidentes: initState.accidentes
            }
        default:
            return state
    }
}