import{ useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { PhotoCamera } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';

import { CellExpand } from '../../../components/ui/CellExpand';
import { ImagenToolbar } from './ImagenToolbar';
import { ModalImagen } from './ModalImagen';
import { AppState } from '../../../reducers/rootReducer';
import { startDeleteImagenActividad, startSetActiveImageActividad, startSetModalImagen, startUpdateImageActividad } from '../actions/images';
import { deleteImagen } from '../../../helpers/imagen';
import { Image } from '../interfaces/images';
import { StyledDataGrid } from '../../../styles/styledDataGrid';


export const ImagenesTable = () => {
    const dispatch = useDispatch();
    const { actividadesObra: items } = useSelector((state: AppState) => state.reportes.modules.actividadObra);
    const { images } = useSelector((state: AppState) => state.shared.images);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [values, setValues] = useState<any[]>([])

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        const image = images.filter(img => img.id === id);
        const imgId = image[0].imagen?.id;
        deleteImagen(imgId);
        dispatch(startDeleteImagenActividad(id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const hanleAddImageClick = (id: GridRowId) => () => {
        dispatch(startSetActiveImageActividad(id))
        dispatch(startSetModalImagen(true));
    };

    const processRowUpdate = (newRow: GridRowModel<Image>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateImageActividad(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {
            field: 'actividad',
            headerName: 'Actividad',
            flex: 2,
            editable: true,
            type:'singleSelect',
            valueOptions: values,
            headerAlign:'center',
            renderCell: CellExpand
        },
        {
            field: 'description',
            headerName: 'Descripción',
            headerAlign: 'center',
            flex: 1,
            editable: true,
            type: 'string',
            renderCell: CellExpand
        },
        {
            field: 'imagen',
            headerName: 'Imagén',
            editable: false,
            renderCell: (row) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'contain',
                            backgroundImage: `url(${row.row.imagen.uri})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                );
            },
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [                        
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                            icon={<PhotoCamera />}
                            label="Agregar imagen"
                            className="textPrimary"
                            onClick={hanleAddImageClick(id)}
                            color="inherit"
                        />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    /* useEffect(() => {
      if(items.length > 0) return;
      dispatch(startLoadItemsObra())    ;
      
    }, [dispatch, items]) */
    
     useEffect(() => {
     if(items.length > 0){
        const itemsDB = items.map(i => i.actividad);
        setValues(itemsDB);
     }
    }, [dispatch, items])

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={images.slice(0, images.length)}
                columns={columns}
                editMode="row"
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } }
                }}
                slots={{
                    toolbar: ImagenToolbar,
                }}
                slotProps={{
                    toolbar: { setRowModesModel },
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}                
                localeText={{ ...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay registros' }}
            />
            <ModalImagen />
        </Box>
    );
}
