import { Route, Routes } from 'react-router-dom'
import { ObrasScreen } from './ObrasScreen'
import { ObraDetailScreen } from './ObraDetailScreen'
import { ItemObraScreen } from './ItemObraScreen'

export const ObrasRoutes = () => {
  return (
    <>    
    <Routes>
            <Route path="/obras" element={<ObrasScreen />} />
            <Route path="/detalle-obra" element={<ObraDetailScreen />} />
            <Route path="/items-obra" element={<ItemObraScreen />} />
            <Route path="/" element={<ObrasScreen />} />
    </Routes>    
    </>
  )
}