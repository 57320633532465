import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import {
    GridRowModes,
    GridActionsCellItem,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { ModalRutinaR } from './ModalRutinaR';
import { CellExpand } from '../../../../../components/ui/CellExpand';
import { RutinaRectificadorToolbar } from './RutinaRectificadorToolbar';
import { ModalSelectRutinaRectificador } from './ModalSelectRutinaRectificador';
import { ModalImagesRutinaRectificador } from './ModalImagesRutinaRectificador';
import { useRutinaRTable } from '../hooks/useRutinaRTable';


export const RutinaRectificadorTable = () => {
    const {
        activeRutinaRectificador,
        imagen,
        locale,
        modalRectificador,        
        rowModesModel,
        rutinasRectificador,
        width,
        handleRowEditStart,
        handleRowEditStop,
        handleEditClick,
        handleSaveClick,
        handleCancelClick,
        hanleAddImageClick,
        handleViewImages,
        handleDeleteClick,
        processRowUpdate,
        setRowModesModel,
    } = useRutinaRTable();

    const columns: GridColDef[] = [
        {
            field: 'numero',
            headerAlign: 'center',
            headerName: 'Item',
            flex: width > 900 ? 0.3 : 0,
            width: 80,
            editable: false
        },
        {
            field: 'nombre',
            headerAlign: 'center',
            headerName: 'Rutina',
            flex: width > 900 ? 2 : 0,
            width: 300,
            type: 'string',
            editable: false,
            sortable: false,
            hideable: false,
            renderCell: CellExpand
        },
        {
            field: 'descripcion',
            headerAlign: 'center',
            headerName: 'Descripción',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: false,
            sortable: false,
            hideable: false,
            renderCell: CellExpand
        },
        {
            field: 'observaciones',
            headerAlign: 'center',
            headerName: 'Observaciones',
            flex: width > 900 ? 2 : 0,
            width: 250,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'imagen',
            headerAlign: 'center',
            headerName: 'Imagén',
            editable: false,
            renderCell: (row: any) => {
                const images = rutinasRectificador.find(r => r.id === row.id)?.images;
                const lastIndex = images?.length! - 1;
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'contain',
                            backgroundImage: images?.length! > 0 ? `url(${row.row.images[lastIndex].uri})` : `url(${imagen})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                );
            },
            flex: width > 900 ? 1 : 0,
            width: 150,
        },
        {
            field: 'actions',
            headerAlign: 'center',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }: any) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<AddAPhotoRoundedIcon />}
                            label="Agregar imagen"
                            className="textPrimary"
                            onClick={hanleAddImageClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<ImageSearchRoundedIcon />}
                        label="Ver imagenes"
                        className="textPrimary"
                        onClick={handleViewImages(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={rutinasRectificador}
                columns={columns}
                sx={{
                    '& .MuiDataGrid-cell': {
                        py: '8px',
                    },
                }}
                editMode="row"
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } }
                }}
                slots={{
                    toolbar: RutinaRectificadorToolbar,
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            {modalRectificador && <ModalRutinaR />}
            <ModalSelectRutinaRectificador setRowModesModel={setRowModesModel} />
            {activeRutinaRectificador && <ModalImagesRutinaRectificador />}
        </Box>
    );
}