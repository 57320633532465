import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { PersonalSubcontratistaTable } from './PersonalSubcontratistaTable';

const Table = memo(() => {
    return (
        <PersonalSubcontratistaTable />
    );
});

export const PersonalSubcontratista = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Describa el personal subcontratista que interviene en la actividad
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}
