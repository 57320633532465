import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { HerramientaTable } from './HerramientaTable';
import { StyleProps } from '../../../interfaces/reportes';

const Table = memo(() => {
    return (
        <HerramientaTable />
    );
});


export const Herramienta = ({mt = 4, mb = 1 }: StyleProps) => {
    return (
        <>
            <Grid container sx={{ mt, mb }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Herramientas
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}
