import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Badge,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppState } from '../../reducers/rootReducer';
import { startLoadServicios } from '../../modules/servicios/actions/servicio';
import { Servicio } from '../../modules/servicios/interfaces/servicio';


export const NotificationsMenu = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { servicios } = useSelector((state: AppState) => state.servicios);
    const [nuevos, setNuevos] = useState<Servicio[]>([]);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (servicios.length === 0) {
            dispatch(startLoadServicios())
        }
    }, [dispatch, servicios.length])

    useEffect(() => {
        if (servicios.length > 0) {
            const nuevos = servicios.filter(s => s.estado.name === 'Sin ser asignados');
            setNuevos(nuevos);
        }
    }, [servicios])

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Notificaciones">
                    <IconButton
                        color="inherit"
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {nuevos.length > 0 ? <Badge badgeContent={nuevos.length} color="secondary">
                            <NotificationsIcon />
                        </Badge> : <NotificationsIcon />}
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {nuevos.map(n => (
                    <MenuItem key={n.id} onClick={handleClose}>
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <ElectricalServicesIcon fontSize='small' />
                        </Avatar>
                        <Box
                            sx={{
                                mx: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}
                        >
                            <Typography variant="body2" noWrap>
                                {n.tipo.name}
                            </Typography>
                            <Typography variant="caption" noWrap>
                                {n.cliente.nombre}
                            </Typography>
                        </Box>

                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
