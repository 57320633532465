import { useEffect } from 'react'
import { Container, Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Obras } from './Obras';
import { startLoadObras } from '../actions/obras';
import { AppState } from '../../../reducers/rootReducer';



export const ObrasScreen = () => {
  const dispatch = useDispatch();
  const { obras } = useSelector((state: AppState) => state.obras.obras);

  useEffect(() => {
    if(obras.length !== 0) return;    
    dispatch(startLoadObras());
  }, [dispatch, obras]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Obras />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}