import { GridRowId } from "@mui/x-data-grid";
import { Accidente } from "../../../interfaces/shared";
import { AccidenteAction } from "../interfaces/accidentes";

export const startSetAccidente = (accidentes: Accidente[]): AccidenteAction => ({
    type: 'set-accidentes',
    payload: accidentes,
});

export const startUpdateAccidente = (accidente: Accidente): AccidenteAction => ({
    type: 'update-accidente',
    payload: accidente,
});

export const startDeleteAccidente = (id: GridRowId): AccidenteAction => ({
    type: 'delete-accidente',
    payload: id,
});


export const startSetAccidenteEmpty = ():AccidenteAction => ({
    type: 'set-accidente-init-state',
});