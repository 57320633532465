import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { useModalContacto } from '../hooks/useModalContacto';

export const ModalContacto = () => {
   
    const {
        activeContacto,
        email,
        nombres,
        direccion,
        modalContacto,
        nota,
        referencia,
        telefono,
        handleClose,
        handleSave,
        onChange
      } = useModalContacto();

    return (
        <>
            <Dialog open={modalContacto} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                    <Box
                        sx={{
                            my: 1,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <PeopleAltIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {activeContacto
                                ? "Actualizar"
                                : "Agregar Contacto"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        width="100%"
                        component="div"
                        sx={{ mt: 1 }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={nombres}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="nombres"
                                    label="Nombres"
                                    name="nombres"
                                    autoComplete="nombres"
                                    autoFocus
                                    size="small"
                                    inputProps={{ tabIndex: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={referencia}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="referencia"
                                    label="Referencia"
                                    name="referencia"
                                    autoComplete="referencia"
                                    size="small"
                                    inputProps={{ tabIndex: 2 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>                            
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={telefono}
                                    type={'tel'}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="telefono"
                                    label="Teléfono"
                                    name="telefono"
                                    autoComplete="telefono"
                                    size="small"
                                    inputProps={{ tabIndex: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={direccion}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="direccion"
                                    label="Dirección"
                                    name="direccion"
                                    autoComplete="direccion"
                                    size="small"
                                    inputProps={{ tabIndex: 4 }}
                                />
                            </Grid>
                        </Grid>                        
                        <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6}>
                        
                                <TextField
                                    margin="dense"
                                    type={'email'}
                                    value={email}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo Electrónico"
                                    name="email"
                                    autoComplete="current-email"
                                    size="small"
                                    inputProps={{ tabIndex: 5 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    type={'contactoPrincipal'}
                                    value={nota}
                                    onChange={onChange}
                                    multiline
                    rows={3}
                                    fullWidth
                                    id="nota"
                                    label="Nota"
                                    name="nota"
                                    autoComplete="nota"
                                    size="small"
                                    inputProps={{ tabIndex: 6 }}
                                />
                            </Grid>
                            
                        </Grid>                       
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} tabIndex={8}>Cancelar</Button>
                    <Button onClick={handleSave} tabIndex={7}>{activeContacto ? 'Actualizar' : 'Guardar'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
