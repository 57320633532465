import { Ats, AtsAction, AtsState } from "../interfaces/ats";

const initState: AtsState = {
    ats: [],
    loading: false,
    activeAts: null,
    selected: [],
    atsForm: {} as Ats,
    modalAtsPdf: false,
    modalDeleteAts: false,
    servicioExist: true
};

export const atsReducer = (state = initState, action: AtsAction): AtsState => {
    switch (action.type) {
        case "load-ats":
            return {
                ...state,
                ats: action.payload,
            };
        case "set-load-ats":
            return {
                ...state,
                atsOne: state.ats.find((at: Ats) => at.id === action.payload)
            };
        case "create-ats":
            return {
                ...state,
                ats: [...state.ats, action.payload],
            };
        case "update-ats":
            return {
                ...state,
                ats: state.ats.map((a) => (a.id === action.payload.id) ? action.payload : a),
            };
        case "delete-ats":
            return {
                ...state,
                ats: state.ats.filter((a) => a.id !== action.payload),
            };
        case "set-active-ats":
            const ats = state.ats.find((a) => a.id === action.payload)
            return {
                ...state,
                activeAts: ats ? ats : null,
            };
        case "set-inactive-ats":
            return {
                ...state,
                activeAts: null,
            };
        case "set-modal-ats-pdf":
            return {
                ...state,
                modalAtsPdf: action.payload
            };
        case "set-selected":
            return {
                ...state,
                selected: action.payload,
            };
        case "set-ats-form":
            return {
                ...state,
                atsForm: action.payload,
            };
        case "set-modal-delete-ats":
            return {
                ...state,
                modalDeleteAts: action.payload
            }
        case "set-servicio-exist":
            return {
                ...state,
                servicioExist: action.payload
            }
        default:
            return state;

    }
}