import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { startSetObservacionesReporte } from '../actions/reportes';
import { useForm } from '../../../hooks';

const initForm = {
	observacion:''
}

export const useObservaciones = () => {
	const dispatch = useDispatch();
	const { observaciones } = useSelector(
		(state: AppState) => state.reportes.reportes
	);

	const {registerData, onChange, changeData} = useForm(initForm);
	const {observacion} = registerData;
	

	const hanleOnBlur = () => {
		dispatch(startSetObservacionesReporte(observacion));
	};

	useEffect(()=>{
		if(observaciones !== ''){
			const data = {
				observacion: observaciones
			}
			changeData(data)
		}
	},[observaciones, changeData])

  return {
	onChange,
	hanleOnBlur,
	observacion
  }
}
