import React from "react";
import { View, StyleSheet, Image } from "@react-pdf/renderer";
import { Reporte } from '../../../interfaces/reportes';
import { getImagen } from '../../../../../helpers/imagen';
import { TitleTables } from "../../../../../components/pdf/TitleTables";

const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "column",
		marginTop: 10,
	},
	container: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		maxWidth: '50%',
		marginTop: 10,
	},
	title: {
		flexDirection: 'row',
		width: '100%'
	},
	imagenContainer: {
		flexDirection: 'row',
		width: '100%',
		border: 1,
		borderColor: '#aaaaaa',
		padding: 1,
		borderRadius: 5,
		justifyContent: 'center'
	},
	image: {
		minHeight: 120,
		maxHeight: 150,
		height: 'auto',
		objectFit: 'contain',
		borderRadius: 10
	}
});

interface Props {
	active: Reporte;
}
const title: string = 'Sello físico';
export const SelloFisico = ({ active }: Props) => {

	return (
		<View wrap={false}>
			<View style={styles.headerContainer}>
				<View style={styles.container}>
					<TitleTables
						title={title}
						sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
					/>
					<View style={styles.imagenContainer}>
						<Image style={styles.image} src={() => getImagen(active.selloFisico?.name!)} />
					</View>
				</View>
			</View>
		</View>
	)
}