import { GridRowId } from '@mui/x-data-grid';
import { Imagen } from '../../../../../interfaces/image';
import { ImagesPrevAction } from '../interfaces/images';

/* export const startAddImages = (image: Imagen) => {
	return async(dispapach: any)=>{
		dispapach(addImagen)
	}
} */

export const startAddImages =(imagen: Imagen): ImagesPrevAction =>({
	type: 'create-prev-images',
	payload: imagen
})

export const startSetModalImagePrev = (modal: boolean):ImagesPrevAction =>({
	type: 'set-modal-prev-image',
	payload: modal
})

export const startDeleteImagePrev = (payload: GridRowId): ImagesPrevAction =>({
	type: 'delete-prev-images',
	payload
})

export const startClearImagesPrev = (): ImagesPrevAction =>({
	type: 'clear-prev-image-state'
})