import { Box, CircularProgress } from "@mui/material"
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#eb445ae6',
        },
        secondary: {
            main: '#9c27b0',
        },
    },
})

export const Loading = () => {
    return (
        <ThemeProvider theme={theme}>

            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    typography: 'body1',
                    '& > :not(style) + :not(style)': {
                        ml: 2,
                    },
                }}

            >
                <CircularProgress color="primary" disableShrink />
            </Box>
        </ThemeProvider>
    )
}
