import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { ActividadTableObra } from "./ActividadTableObra";
import { StyleProps } from '../../../interfaces/reportes';


const Table = memo(() => {
    return (
        <ActividadTableObra />
    );
});

export const ActividadObra = ({mt= 4, mb=1 }:StyleProps) => {
    return (
        <>
            <Grid container sx={{ mt, mb }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                    Lista de Actividades
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}