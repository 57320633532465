import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridRowsProp,
} from '@mui/x-data-grid';
import { startDeleteInformeSst, startSetActiveInformeSst, startSetModalInformeSst, startSetModalInformePdfSst } from '../actions/sst';
import { AppState } from '../../../reducers/rootReducer';
import { useScreenSize } from '../../../hooks';

export const useSstTable = () =>{

	const dispatch = useDispatch();
    const { width } = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText;

    const { informesSst: sstResponse } = useSelector((state: AppState) => state.sst.sst);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const informes: GridRowsProp = sstResponse.map((sst) => {
        return {
            id: sst.id,
            fecha: sst.fecha,
            descripcionObra: sst.obra.descripcion,
            cliente: sst.obra.cliente!.nombre,
            numero: sst.numero,
        }
    });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        dispatch(startSetActiveInformeSst(id));
        dispatch(startSetModalInformeSst(true));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteInformeSst(id));
    };

    const handleShowDetalle = (id: GridRowId) => () => {
        dispatch(startSetActiveInformeSst(id));
        dispatch(startSetModalInformePdfSst(true))
        
    }


    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        return updatedRow;
    };

	return{
		informes,
		locale,
		rowModesModel,
        width,
		handleRowEditStart,
		handleRowEditStop,
		handleEditClick,
		handleSaveClick,
		handleDeleteClick,
		handleShowDetalle,
		handleCancelClick,
		processRowUpdate,
		setRowModesModel,
	}
}