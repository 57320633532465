import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    GridRowModes,
    GridToolbarContainer,
} from '@mui/x-data-grid';

import { AppState } from '../../../../../reducers/rootReducer';
import { startSetHerramienta } from '../actions/herramienta';
import { EditToolbarProps } from '../../../../../interfaces/shared';



export const HerramientaTableToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();
    const { herramientas } = useSelector((state: AppState) => state.reportes.modules.herramientas);
    const { setRowModesModel } = props;

    const handleClick = () => {
        let id: number;
        if (herramientas.length === 0) {
            id = 1;
        } else {
            id = herramientas[herramientas.length - 1].id + 1;
        }
        dispatch(startSetHerramienta({ id, tipo: '', unidad: '', cantidad: 0, marca: '', propiedad: '', isNew: true }));
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'tipo' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar Herramienta
            </Button>
        </GridToolbarContainer>
    );
}
