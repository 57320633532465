import { View, StyleSheet } from "@react-pdf/renderer";
import { Reporte } from '../../../interfaces/reportes';
import { TitleTables } from "../../../../../components/pdf/TitleTables";
import { EquiposHead } from "../../../../ats/components/pdf/shared/EquiposHead";
import { EquiposRow } from "../../../../ats/components/pdf/shared/EquiposRow";

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		borderWidth: 1,
	},
});

const title = "Describa los Elementos de Seguridad Industrial a utilizar mientras se realiza la Actividad:"
interface Props {
	active: Reporte;
}
export const TableEquipoSeguridad = ({ active }: Props) => {
	return (
		<View>
			<TitleTables
				title='Equipos de seguridad'
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
			/>
			<TitleTables title={title} sx={{ fw: 'normal', fz: 10 }} />
			<View style={styles.tableContainer}>
				<EquiposHead title="Equipos de Seguridad a Utilizar" />
				<EquiposRow items={active.equiposSeguridad!} />
			</View>
		</View>
	);
};
