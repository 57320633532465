import React from 'react'
import { Sst } from '../../../interfaces/sst'
import { BodyImagenLines, HeadLine } from '../shared'
import { View } from '@react-pdf/renderer'
import { getGrupoImages } from '../../../../../helpers'
import { Imagen } from '../../../../../interfaces/image'


interface Props {
	active: Sst
}

export interface Grupo {
	actividad: Imagen[];
}

export const RegistroFotografico = ({ active }: Props) => {
	const grupo = getGrupoImages(active?.imagenes);
	return (
		<View>
			<HeadLine title='REGISTRO FOTOGRAFICO' />
			{
				Object.keys(grupo).map((key, index) => (
					<View key={index}>
						<HeadLine title={key} sx={{mt: 2, bw:3}} />
						<BodyImagenLines grupo={grupo[key]} imgMaxH={200} contMinH={202} imgMaxW={250} imgMinH={200} />
					</View>
				))
			}
		</View>)

}

/* 
function registroFotografico(imagesDB: Imagen[]) {
	const images: Imagen[] = imagesDB ?? [];
	let grupos: any = {};

	images.forEach(image => {
		const nombreGrupo = image.description!;
		if (!grupos[nombreGrupo]) grupos[nombreGrupo] = [];
		grupos[nombreGrupo].push(image)
	})

	return grupos
} */

/* const grupo = {
	"Actividad": [
		{
			description: "actividad 1",
			id: 132,
			name: "p122_sdc-zfb_subestación_provisional_97e801522871d5bae198bf2515e7ec43.jpeg",
			servicio: "655f66c8985b20be27efe63e",
			uri: "https://beling.com.co/imagenes/public/storage/imagenes/p122_sdc-zfb_subestación_provisional_97e801522871d5bae198bf2515e7ec43.jpeg"
		}
	]
} */