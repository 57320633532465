import { Route, Routes } from 'react-router-dom'
import { TecnicosScreen } from './TecnicosScreen'

export const TecnicosRoutes = () => {
    return (
        <>        
        <Routes>
                <Route path="/tecnicos" element={<TecnicosScreen />} />
                <Route path="/" element={<TecnicosScreen/>} />
        </Routes>
        </>
      )
}
