import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import {
    GridRowModes,
    GridActionsCellItem,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';
import moment from "moment";



import { AlertDelete } from '../../servicios/components/AlertDelete';

import { CellExpand } from '../../../components/ui/CellExpand';
import { useObrasTable } from '../hooks/useObrasTable';
import { ModalObra } from './ModalObra';
import { ObraTableToolbar } from './ObraTableToolbar';


export const ObrasTable = () => {
    const {
        locale,
        obras,
        rowModesModel,
        width,
        handleRowEditStart,
        handleRowEditStop,
        handleEditClick,
        handleSaveClick,
        handleDeleteClick,
        handleCancelClick,
        processRowUpdate,
        handleShowDetalle,
        setRowModesModel
    } = useObrasTable();

    const columns: GridColDef[] = [
        {
            field: 'fechaContrato',
            headerName: 'Fecha Contrato',
            headerAlign: 'center',
            flex: width > 900 ? 1 : 0,
            width: 120,
            editable: false
            , renderCell: (value) => {
                return (<Typography>{moment(value.row.fecha).format('DD/MM/YYYY')}</Typography>)
            }
        },
        {
            field: 'fechaInicio',
            headerAlign: 'center',
            headerName: 'Fecha Inicio',
            flex: width > 900 ? 1 : 0,
            width: 120,
            editable: false
            , renderCell: (value) => {
                return (<Typography>{moment(value.row.fecha).format('DD/MM/YYYY')}</Typography>)
            }
        },
        {
            field: 'cliente',
            headerAlign: 'center',
            headerName: 'Cliente',
            flex: width > 900 ? 1 : 0,
            width: 200,
            editable: false, renderCell: CellExpand
        },
        {
            field: 'descripcion',
            headerAlign: 'center',
            headerName: 'Descripción',
            flex: width > 900 ? 1 : 0,
            width: 200,
            editable: true,
            renderCell: CellExpand
        },
        {
            field: 'actions',
            headerAlign: 'center',
            type: 'actions',
            headerName: 'Acciones',
            flex: width > 900 ? 1 : 0,
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Guardar"><SaveIcon /></Tooltip>}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<Tooltip title="Cancelar"><CancelIcon /></Tooltip>}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Detalle"><PageviewOutlinedIcon color='info' /></Tooltip>}
                        label="Detalle"
                        onClick={handleShowDetalle(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Eliminar"><DeleteIcon color='error' /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >

            <DataGrid
                autoHeight
                rows={obras.slice(0, obras.length)}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 5 } }
                }}
                slots={{
                    toolbar: ObraTableToolbar,
                }}
                slotProps={{
                    toolbar: { setRowModesModel },
                }}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                pagination={true}
                pageSizeOptions={[5, 10, 20]}
            />
            <ModalObra />
            {/* <ModalObraPdf /> */}
            <AlertDelete />
        </Box>
    );
}
