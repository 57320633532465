import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { RutinaBancoBateriaTable } from './RutinaBancoBateriaTable';

const Table = memo(() => {
    return (
        <RutinaBancoBateriaTable />
    );
});

export const RutinaBancoBateria = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Describa las actividades para Bancos de baterias
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Rutina Banco de Baterias
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}
