import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { AccidentesTable } from './AccidenteTable';
import { StyleProps } from '../../../modules/reportes/interfaces/reportes';

const Table = memo(() => {
    return (
        <AccidentesTable />
    );
});

export const Accidentes = ({ mt = 4, mb = 1 }: StyleProps) => {
    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <Typography component="h1" align='center' variant="subtitle1">
                    Accidentes
                </Typography>
                <Table />
            </Grid>
        </>
    )
}
