import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from "date-fns/locale";
import { GridRowId } from '@mui/x-data-grid';
import { DateField, TimeField, esES } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import { AppState } from '../../../reducers/rootReducer';
import { useSelect, useForm, useDate, useMobileDetect } from '../../../hooks';

import { Herramienta } from '../modules/herramienta/components/Herramienta';
import { Equipo } from '../modules/equipo/components/Equipo';
import { RiesgoSitio } from '../modules/riesgo_sitio/components/RiesgoSitio';
import { RiesgoAltura } from '../modules/riesgo_altura/components/RiesgoAltura';
import { RiesgoElectrico } from '../modules/riesgo_electrico/components/RiesgoElectrico';
import { startSetAtsForm } from '../actions/ats';
import { ResponsableBeling, ResponsableCliente } from '../../../shared';
import { setActiveServicio } from '../../servicios/actions/servicio';


const initForm = {
  actividades: '',
  numeroTarjeta: '',
  lugarTrabajo: '',
  representanteCliente: '',
  tipoTarjeta: '',
  tieneTarjeta: '',
  trabajoTension: '',
  trabajoAltura: '',
  seguirActividad: '',
  personalCapacitado: '',
}

const initSelect = {
  tecnico: '',
}

const initDate = {
  fecha: new Date(),
  horaInicio: new Date(),
  horaFinal: new Date()
}

export const FormAtsScreen = () => {
  const dispatch = useDispatch();
  const { isMobile } = useMobileDetect();
  const { tecnicos } = useSelector((state: AppState) => state.resources);
  const { activeServicio } = useSelector((state: AppState) => state.servicios);

  const { onChange, registerData } = useForm(initForm);
  const { onDateChange, registerDataDate } = useDate(initDate);
  const { onChangeSelect, registerSelectData } = useSelect(initSelect);

  const { actividades, numeroTarjeta, lugarTrabajo, representanteCliente, tipoTarjeta, tieneTarjeta, trabajoTension, trabajoAltura, seguirActividad, personalCapacitado } = registerData;
  const { tecnico } = registerSelectData;
  const { fecha, horaFinal, horaInicio } = registerDataDate;

  useEffect(() => {
    const formData = {
      ...registerData,
      ...registerDataDate,
      ...registerSelectData,
    }
    if (seguirActividad === 'si') {
      dispatch(startSetAtsForm(formData));
      dispatch(setActiveServicio(activeServicio?.id as GridRowId))
    }

  }, [seguirActividad, registerData, registerSelectData, registerDataDate, dispatch, activeServicio?.id])

  return (
    <Box
      width="100%"
      component="div"
      sx={{ my: 2, mx: 'auto', maxWidth: '90vw' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth >
            <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={es} dateAdapter={AdapterDateFns}>
              {isMobile ?
                <MobileDatePicker
                  autoFocus
                  label="Fecha"
                  value={fecha}
                  onChange={(newValue) => {
                    onDateChange(newValue, "fecha");
                  }}
                /> :
                <DateField
                  autoFocus
                  label="Fecha"
                  required
                  value={fecha}
                  onChange={(newValue) => {
                    onDateChange(newValue, "fecha");
                  }}
                />}
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="none"
            value={representanteCliente}
            onChange={onChange}
            fullWidth
            id="representanteCliente"
            label="Personal de acompañamiento del Cliente"
            name="representanteCliente"
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="tecnico-select-label">Técnico *</InputLabel>
            <Select
              labelId="tecnico-select-label"
              id="tecnico"
              value={tecnico}
              label="Técnico"
              name="tecnico"
              required
              onChange={onChangeSelect}
            >
              <MenuItem disabled value="">
                <em>Seleccionar Técnico</em>
              </MenuItem>
              {tecnicos.map((tecnico) => (
                <MenuItem
                  key={tecnico.id}
                  value={tecnico.id}
                //style={getStyles(name, personName, theme)}
                >
                  {tecnico.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
              {
                isMobile
                  ? <MobileTimePicker
                    label="Hora Inicio"
                    value={horaInicio}
                    onChange={(newValue) => {
                      onDateChange(newValue, "horaInicio");
                    }}
                  />
                  : <TimeField
                    label="Hora Inicio"
                    value={horaInicio}
                    onChange={(newValue) => {
                      onDateChange(newValue, "horaInicio");
                    }}
                  />
              }
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            value={lugarTrabajo}
            onChange={onChange}
            fullWidth
            label="Lugar del trabajo"
            name="lugarTrabajo"
            autoComplete="lugarTrabajo"
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Describa las actividades a realizar
          </Typography>
          <TextField
            margin="normal"
            value={actividades}
            onChange={onChange}
            multiline
            rows={4}
            fullWidth
            label="Actividades a realizar"
            name="actividades"
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid sx={{ mt: 4, mb: 1 }}>
        <Typography component="h1" variant="h5">
          Antes de iniciar el trabajo verifique.
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl >
            <RadioGroup
              aria-labelledby="tiene-tarjeta-label"
              name="tieneTarjeta"
              value={tieneTarjeta}
              onChange={onChange}
            >
              <FormControlLabel value="si" control={<Radio />} label="Si" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
              <FormControlLabel value="no-aplica" control={<Radio />} label="No Aplica" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={8} alignContent="center">
          <Typography component="h1" align='left' variant="subtitle1">
            ¿El lider técnico o algún miembro del equipo tiene Tarjeta Profesional (CONTE o similar)?
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            value={tipoTarjeta}
            onChange={onChange}
            fullWidth
            label="Tipo Tarjeta Profesional"
            name="tipoTarjeta"
            autoComplete="tipoTarjeta"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            margin="normal"
            value={numeroTarjeta}
            onChange={onChange}
            fullWidth
            label="Número Tarjeta Profesional"
            name="numeroTarjeta"
            autoComplete='off'
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl >
            <RadioGroup
              aria-labelledby="trabajo-tension-label"
              name="trabajoTension"
              value={trabajoTension}
              onChange={onChange}
            >
              <FormControlLabel value="si" control={<Radio />} label="Si" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <Typography component="h1" align='left' variant="subtitle1">
            ¿Se realiza la actividad con Tensión?
          </Typography>
        </Grid>
      </Grid>
      <RiesgoElectrico />
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={4}>
          <FormControl >
            <RadioGroup
              aria-labelledby="trabajo-altura-label"
              name="trabajoAltura"
              value={trabajoAltura}
              onChange={onChange}
            >
              <FormControlLabel value="si" control={<Radio />} label="Si" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <Typography component="h1" align='left' variant="subtitle1">
            ¿Realiza trabajo en alturas?
          </Typography>
        </Grid>
      </Grid>
      <RiesgoAltura />
      <RiesgoSitio />
      <Equipo />
      <Herramienta />
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography component="h1" align='left' variant="subtitle1">
            ¿Es seguro proceder con la tarea con los controles establecidos?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl >
            <RadioGroup
              aria-labelledby="seguir-actividad-group-label"
              name="seguirActividad"
              value={seguirActividad}
              onChange={onChange}
            >
              <FormControlLabel value="si" control={<Radio />} label="Si, proceder con la tarea" />
              <FormControlLabel value="no" control={<Radio />} label="No, consulte al supervisor antes de tomar cualquier decisión" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography component="h1" align='left' variant="subtitle1">
            ¿El personal está capacitado para la realización de la actividad?
          </Typography>
        </Grid>
        <Grid item xs={12} alignItems="start">
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="personal-capacitado-group-label"
              name="personalCapacitado"
              value={personalCapacitado}
              onChange={onChange}
            >
              <FormControlLabel value="si" control={<Radio />} label="Si" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <ResponsableBeling />
      <ResponsableCliente />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
              {
                isMobile
                  ? <MobileTimePicker
                    label="Hora Final"
                    value={horaFinal}
                    onChange={(newValue) => {
                      onDateChange(newValue, "horaFinal");
                    }}
                  />
                  : <TimeField
                    label="Hora Final"
                    value={horaFinal}
                    onChange={(newValue) => {
                      onDateChange(newValue, "horaFinal");
                    }}
                  />
              }
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </Box>
  )
}