import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { AppState } from '../../../../reducers/rootReducer';
import { Gallery } from '../../../../shared/gallery/Gallery'
import { startDeleteImageFin, startSetModalImageFin } from '../../modules/images_final/actions/images';
import { ModalImageFin } from '../../modules/images_final/components/ModalImageFin';
import { doMessage, startLoading } from '../../../ui/actions/ui';
import { deleteImagen } from '../../../../helpers';
import { GridRowId } from '@mui/x-data-grid';


export const StepNine = () => {
	const disptach = useDispatch();
	const { images, modalImageFin } = useSelector((state: AppState) => state.reportes.modules.imagesFin);
	
	const handeAddImagen = () => {
		disptach(startSetModalImageFin(true))
	}

	const handleDelete = async (id: GridRowId) => {
		disptach(startLoading())
		const resp = await deleteImagen(id);
		if(resp.status === 1){
			disptach(startDeleteImageFin(id))
		}else{			
			disptach(doMessage({message: 'Error eliminando la imagen', type: 'error'}))
		}
		disptach(startLoading())
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<IconButton color="primary" aria-label="Ubicación" size="large" onClick={handeAddImagen}>
					<AddAPhotoIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}>
				{images.length > 0 && <Gallery images={images} handleDelete={handleDelete} />}
			</Box>
			{ modalImageFin &&  <ModalImageFin /> }
		</>
	)
}
