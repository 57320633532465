import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
} from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../reducers/rootReducer';
import { startDeleteHerramienta, startEditHerramienta, startSetHerramientas, startUpdateHerramienta } from '../actions/herramienta';
import { startLoadHerramientas } from '../../../../resources/actions/resources';
import { CellExpand } from '../../../../../components/ui/CellExpand';
import { Equipo } from '../../../interfaces/ats';

export const HerramientaTable = () => {
    const locale = esES.components.MuiDataGrid.defaultProps.localeText
    const dispatch = useDispatch();
    const { herramientas: records } = useSelector((state: AppState) => state.resources);
    const { herramientas } = useSelector((state: AppState) => state.ats.herramientas);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});    
    useEffect(() => {
        if (records.length === 0) {
            dispatch(startLoadHerramientas())
        }

        if(herramientas.length > 0){
            return
        }
        
        records.map(r => {
            return dispatch(startSetHerramientas({
                id: r.id,
                name: r.name,
                cantidad: 0,
                isNew: false
            }))
        })
    }, [dispatch, herramientas, records]);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        dispatch(startEditHerramienta(id));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const hanleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteHerramienta(id))
    }

    const processRowUpdate = (newRow: GridRowModel<Equipo>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateHerramienta(updatedRow));
        return updatedRow;
    };

    const columns: GridColDef[] = [
        {   
            field: 'name', 
            headerName: 'Equipos y Herramienta a Utilizar', 
            flex: 2, 
            editable: false,
            sortable: false,
            hideable: false,
            renderCell: CellExpand
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad',
            type: 'number',
            flex: 1,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Eliminar"
                        className="textPrimary"
                        onClick={hanleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={herramientas}
                columns={columns}
                editMode="cell"
                initialState={{
                    pagination:{paginationModel:{pageSize: 5}}
                }}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                localeText={{ ...locale, noRowsLabel: 'No hay datos para mostrar' }}
                rowHeight={35}
                pagination={true}
                pageSizeOptions={[5, 10, 15]}                
            />
        </Box>
    );
}