import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { BateriasTable } from './BateriasTable';

const Table = memo(() => {
    return (
        <BateriasTable />
    );
});

export const Baterias = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                        Información de las Baterías
                    </Typography>
                    <Typography component="h1" align='center' variant="subtitle1">
                       Referencia de las Baterías
                    </Typography>
                    <Table />
                </Grid>
            </Grid>
        </>
    )
}