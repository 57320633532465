import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { startChangePassword, startSetModalChangePass } from '../actions/user';
import { useMobileDetect } from '../../../hooks';

export interface State {
    confirmPassword: string;
    newPassword: string;
    password: string;
    showPassword: boolean;
    showConfirmPass: boolean;
    showNewPassword: boolean
}

const initState: State={
    confirmPassword: "",
    password: "",
    newPassword: "",
    showPassword: false,
    showConfirmPass: false,
    showNewPassword: false

}

export const useChangePassword = () => {
    const dispatch = useDispatch();
    const { isMobile } = useMobileDetect();
    const { modalPass } = useSelector((state: AppState) => state.usuarios);

    const [values, setValues] = useState<State>(initState);

    const onChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowConfirmPass = () => {
        setValues({
            ...values,
            showConfirmPass: !values.showConfirmPass
        });
    };
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword
        });
    };
    const handleClickShowNewPassword = () => {
        setValues({
            ...values,
            showNewPassword: !values.showNewPassword
        });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleMouseDownNewPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleMouseDownConfirmPass = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleClose = () => {
        setValues(initState);
        dispatch(startSetModalChangePass(false));
    }
    const handleSave = () => {
        const data = {
            password: values.password,
            newPassword: values.confirmPassword
        };

        if (values.newPassword !== values.confirmPassword) {
            return
        } else {
            dispatch(startChangePassword(data));
        }
        handleClose();
    }

    return {
        isMobile,
        modalPass,
        values,
        onChange,
        handleClickShowPassword,
        handleClickShowConfirmPass,
        handleMouseDownPassword,
        handleMouseDownConfirmPass,
        handleClickShowNewPassword,
        handleMouseDownNewPassword,
        handleSave,
        handleClose
    };
};
