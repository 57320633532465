import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import AddLocationAltOutlined from '@mui/icons-material/AddLocationAltOutlined'

interface Props {
  text: string,
  onClick: any;
}
export const PositionButton = ({ text, onClick }: Props) => {



  return (
    <Box sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton  onClick={onClick}>
            <ListItemIcon>
              <AddLocationAltOutlined color='primary' fontSize='large' />
            </ListItemIcon>
            <ListItemText primary={`Presione para ${text}`} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )
}
