/**
 * Componente para agregar datos de parte del tenico o encargado
 * de Beling SAS
 */

import { MutableRefObject, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { startSaveFirma, startSetModalFirmaBeling } from '../actions/responsable';
import ReactSignatureCanvas from 'react-signature-canvas';

import '../css/sigCanvas.css'
import { AppState } from '../../../reducers/rootReducer';
import { deleteFirma, saveFirma } from '../../../helpers';
import { doMessage } from '../../../modules/ui/actions/ui';
import { GridRowId } from '@mui/x-data-grid';


interface Props {
    name: string;
    reporte: GridRowId;
}

export const ModalFirma = ({ name, reporte }: Props) => {
    const dispatch = useDispatch();
    const sigCanvas = useRef({}) as MutableRefObject<any>;
    const { modalFirmaBeling, responsableBeling } = useSelector((state: AppState) => state.shared.responsableBeling);

    const handleClose = () => {
        dispatch(startSetModalFirmaBeling(false));
        handleClear();
    };

    //Guarda la firma en la base de datos
    const handleSave = async () => {
        const img = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        const data = {
            firma: img,
            name: name,
            reporte: reporte,
        };
        if (responsableBeling[0].firma.uri !== '') {
            await deleteFirma(responsableBeling[0].firma.id);
        }
        const resp = await saveFirma(data);
        if (resp.status) {
            responsableBeling[0].firma = resp.firma;
            dispatch(startSaveFirma(responsableBeling[0]));
            dispatch(doMessage({ type: 'success', message: 'Firma guardada' }));
            handleClose();
        } else {
            dispatch(doMessage({ type: 'error', message: 'Error al guardar firma' }));
            handleClose();
        }

    }

    const handleClear = () => {
        sigCanvas.current.clear();
    }

    return (
        <>
            <Dialog open={modalFirmaBeling} onClose={handleClose}>
                <DialogTitle>Firmar</DialogTitle>
                <DialogContent>
                    <ReactSignatureCanvas
                        ref={sigCanvas}
                        penColor='black'
                        canvasProps={{ className: 'signatureCanvas', width: 390, height: 130, }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClear} >Limpiar</Button>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
