import { Box, Grid, TextField } from '@mui/material';
import { useObservaciones } from '../../../hooks';



export const Observaciones = () => {
	const {observacion, hanleOnBlur, onChange} = useObservaciones();
	return (
		<Box
			sx={{
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<TextField
						autoComplete="observacion"
						autoFocus
						fullWidth
						id="observacion"
						label="Observaciones y/o recomendaciones"
						margin="normal"
						multiline
						name="observacion"
						onChange={onChange}
						onBlur={hanleOnBlur}
						required
						rows={3}
						value={observacion}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}
