import { UserState, UserAction } from '../interfaces/user';

const initState: UserState = {
    users: [],
    modalUser: false,
    modalPass: false,
    activeUser: null,
    alertDeleteUser: false,
    isSameUser: false
}

export const userReducer = (state = initState, action: UserAction): UserState => {
    switch (action.type) {
        case 'load-users':
            return {
                ...state,
                users: action.payload
            }
        case 'create-user':
            return {
                ...state,
                users: [...state.users, action.payload]
            }
        case 'update-user':
            return {
                ...state,
                users: state.users.map(
                    u => (u.id === action.payload.id) ? action.payload : u
                )
            }
        case 'delete-user':
            return {
                ...state,
                users: state.users.filter(u => u.id !== action.payload)
            }
        case 'set-active-user':
            const user = state.users.find(u => u.id === action.payload);
            return {
                ...state,
                activeUser: user ? user : null
            }
        case 'set-modal-user':
            return {
                ...state,
                modalUser: action.payload
            }
        case 'set-modal-password':
            return {
                ...state,
                modalPass: action.payload
            }
        case 'set-alert-delete-user':
            return {
                ...state,
                alertDeleteUser: action.payload
            }
        case 'set-is-same-user':
            return {
                ...state,
                isSameUser: action.payload
            }
        default:
            return state;
    }
}