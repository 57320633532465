import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight: 18,
        textAlign: 'center',        
        flexGrow: 1,
    },
    containerTitle:{
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        textAlign: 'center',        
        flexGrow: 1,
    },
    title:{
        width: '100%',
        textAlign: 'center'
    },
    nombre: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    identificacion: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    cargo: {
        width: '30%',  
    }
  });

export const TablePersonalBelingHeader = () => (
    <>
    <View style={styles.containerTitle} fixed>
        <Text style={styles.title}>Listado de Personal de BELING SAS</Text>
    </View>
    <View style={styles.container} fixed>
        <Text style={styles.nombre}>Nombres y Apellidos</Text>
        <Text style={styles.identificacion}>Identificación</Text>
        <Text style={styles.cargo}>Cargo</Text>
    </View>
    </>
  );