import { Avatar, Box, Typography } from '@mui/material'
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import { AtsTable } from './AtsTable';

export const Ats = () => {
 return (
    <>
      <Box
        sx={{
          my: 1,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AssignmentLateOutlinedIcon />
        </Avatar>
        <Typography component="h1" align='center' variant="h6">
          ATS
        </Typography>
      </Box>
      <AtsTable />
    </>
  )
}