import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { Material } from '../../../../../interfaces/shared';


const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 14,
        color:'#212121',
        paddingTop: 2,        
    },
    rowTop: {
        borderBottomColor: '#dddddd',
        borderBottomWidth: 1,
    },
    descripcion: {
        width: '40%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    unidad: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
    },
    cantidad: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
    },
    observaciones: {
        width: '30%',
        textAlign: 'left',
        paddingLeft: 8,
    },
  });

interface Props {
    items: Material[];
}

export const TableMaterialRow = ({items}: Props) => {
    const rows = items.map( (item, index) => {
        const cellStyles = cellStyle(index, items.length);
        return (<View style={[cellStyles]} key={item.id.toString()}>
            <Text style={styles.descripcion}>{item.descripcion}</Text>
            <Text style={styles.unidad}>{item.unidadMedida}</Text>
            <Text style={styles.cantidad}>{item.cantidad}</Text>
            <Text style={styles.observaciones}>{item.observacion}</Text>
        </View>)}
    )
    return (<Fragment>{rows}</Fragment> )
};

function cellStyle(index: number, length: number) {

    const cell = {
        ...styles.row,
        ...styles.rowTop
    }

    const cellEnd = {
        ...styles.row
    }

    if (length === index + 1) {
        return cellEnd
    } else {
        return cell
    }

}