import { ReporteAction, ReporteState } from "../interfaces/reportes";

const initState: ReporteState = {
    activeReporte: null,
    actividades: '',
    actividadTipo:[],
    exists: false,
    fechaFinal:{
        fecha: new Date(),
        changed:false
    },
    loading: false,
    modalReportePdf: false,
    observaciones: '',
    reportes: [],
    selected: [],
    ubicacionInicial: null,
    ubicacionFinal: null,
    alertDeleteReporte: false,
    hasAts: true
};


export const reporteReducer = (state = initState, action: ReporteAction): ReporteState => {
    switch (action.type) {
        case "load-reportes":
            return {
                ...state,
                reportes: action.payload,
            };
        case "create-reportes":
            return {
                ...state,
                reportes: [...state.reportes, action.payload],
            };
        case "update-reportes":
            return {
                ...state,
                reportes: state.reportes.map((r) => (r.id === action.payload.id) ? action.payload : r),
            };
        case "delete-reportes":
            return {
                ...state,
                reportes: state.reportes.filter((r) => r.id !== action.payload),
            };
        case "set-active-reporte":
            const reporte = state.reportes.find((r) => r.id === action.payload);
            return {
                ...state,
                activeReporte: reporte ? reporte : null,
            };
        case "set-inactive-reporte":
            return {
                ...state,
                activeReporte: null,
            };
        case "set-modal-reporte-pdf":
            return {
                ...state,
                modalReportePdf: action.payload,
            };
        case "set-selected":
            return {
                ...state,
                selected: action.payload,
            };
        case 'set-ubicacionInicial':
            return {
                ...state,
                ubicacionInicial: action.payload
            }
        case 'set-clear-ubicacionInicial':
            return {
                ...state,
                ubicacionInicial: null
            }
        case 'set-ubicacionFinal':
            return {
                ...state,
                ubicacionFinal: action.payload
            }
        case 'set-clear-ubicacionFinal':
            return {
                ...state,
                ubicacionFinal: null
            }
        case 'set-reporte-exist':
            return{
                ...state,
                exists: action.payload
            }
        case 'set-actividad-tipo':
            return{
                ...state,
                actividadTipo: action.payload
            }
        case 'clear-actividad-tipo':
            return {
                ...state,
                actividadTipo: []
            }
        case 'set-actividades-reporte':
            return {
                ...state,
                actividades: action.payload
            }
        case 'clear-actividades-reporte':
            return {
                ...state,
                actividades: ''
            }
        case 'set-observaciones-reporte':
            return {
                ...state,
                observaciones: action.payload
            }
        case 'set-fecha-reporte':
            return {
                ...state,
                fechaFinal: action.payload
            }
        case "set-alert-delete-reporte":
            return {
                ...state,
                alertDeleteReporte: action.payload
            }
        case "set-has-ats-report":
            return {
                ...state,
                hasAts: action.payload
            }
        default:
            return state;

    }
}