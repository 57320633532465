import { GridRowId } from "@mui/x-data-grid";
import { fetchConToken } from "../../../apis/fetch";
import { doMessage, startLoading } from "../../ui/actions/ui";
import { ItemObra, ItemObraAction } from "../interfaces/item.interface";

export const startLoadItemsObra = () => {
    return async (dispatch: any, getState: any) => {
        const grupo = getState().obras.grupos.activeGrupo.id;
        if (!grupo) return;
        dispatch(startLoading());
        const resp = await fetchConToken(`items-obra/${grupo}`, {}, "GET");
        const body = await resp.json();
        
        const { errors, msg, ok, itemsObra } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(loadItemsObra(itemsObra));
        } else {
            dispatch(startLoading());
            dispatch(loadItemsObra([]));
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

export const loadItemsObra = (itemsObra: ItemObra[]): ItemObraAction =>({
    type: 'load-items-obra',
    payload: itemsObra
})

export const startCreateItemObra = (newItem: ItemObra) =>{
    return async(dispatch: any)=>{        
        const resp = await fetchConToken(`items-obra`, newItem, "POST");        
        const body = await resp.json();        
        const {ok, item} = body;       
        if(ok){
            dispatch(setActiveItemObra(item.id))
        }else{
            dispatch(setInactiveItemObra())
        }
    }
}

export const startSetItemObra = (item: ItemObra): ItemObraAction => ({
    type: 'set-item-obra',
    payload: item
});

export const startUpdateItemObra = (updateItem: ItemObra) => {
    return async(dispatch: any) =>{
        const resp = await fetchConToken(`items-obra/${updateItem.id}`, updateItem, "PUT");        
        const body = await resp.json();        
        const {ok, item} = body;       
        if(ok){
            dispatch(updateItemObra(item))
            dispatch(setActiveItemObra(item.id))
        }else{
            dispatch(setInactiveItemObra())
        }
    }
}

const updateItemObra = (item: ItemObra): ItemObraAction => ({
    type: 'update-item-obra',
    payload: item
});

export const startDeleteItemObra = (id: GridRowId) => {
    return async(dispatch: any) =>{
        dispatch(startLoading());
        const resp = await fetchConToken(`items-obra/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if(ok){
            dispatch(startLoading());
            dispatch(doMessage({ message: msg, type: 'success' }));
            dispatch(deleteItemObra(id))
        }else{
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const deleteItemObra = (id: GridRowId): ItemObraAction => ({
    type: 'delete-item-obra',
    payload: id
})

const setActiveItemObra = (id: GridRowId): ItemObraAction => ({
    type: 'set-active-item-obra',
    payload: id
})

const setInactiveItemObra = (): ItemObraAction => ({
    type: 'set-inactive-item-obra'
})

export const startSetModalItemObra = (modal: boolean): ItemObraAction => ({
    type: 'set-modal-item-obra',
    payload: modal
})

export const startSetItemsModal = (items: any): ItemObraAction =>({
    type: 'set-items-obra-modal',
    payload: items
})