import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GridToolbarContainer} from '@mui/x-data-grid';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

import { EditToolbarProps } from '../../../interfaces/shared';
import { useItemObraToolbar } from '../hooks/useItemObraToolbar';


export const ItemObraTableToolbar = (props: EditToolbarProps) => {
     const { handleClick, handleShowReporte } = useItemObraToolbar(props);

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar Item
            </Button>
            {/* <Button color="primary" startIcon={<UploadFileOutlinedIcon />} onClick={handleShowModal}>
                Cargar Listado
            </Button> */}
            <Button color="primary" startIcon={<NoteAddOutlinedIcon />} onClick={handleShowReporte}>
                Reporte
            </Button>
        </GridToolbarContainer>
    );
} 