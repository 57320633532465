import { View, StyleSheet } from '@react-pdf/renderer';
import { Informe, Totales, Vehiculo } from "../../../../interfaces/informes";
import { BackTitle, Equipos, PersonalView } from './fragments';
import { Maquinaria, Personal } from '../../../../../../interfaces/shared';


const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
		marginTop: -1,
		borderTop: 1,
		borderRight: 1,
		borderLeft: 1
	},
	column: {
		flexDirection: 'column',
		borderBottom: 1,
		width: '100%'
	},
	rowContainer: {
		flexDirection: 'row',
	},
	borderRow: {
		borderLeft: 1,
		borderRight: 1
	},
	borderRowBottom: {
		borderRight: 1
	},
	leftColumn: {
		flexDirection: 'column',
		width: '70%',
	},
	rightColumn: {
		flexDirection: 'column',
		width: '30%'
	},
	firstCell: {
		borderLeft: 1
	},
	cellRight: {
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: -1,
		height: 20,
	},
	cellLeft: {
		height: 20,
		alignItems: 'center'
	},
	headColumn: {
		backgroundColor: '#aaaaaa',
		height: 20,
		alignItems: 'center'
	},
	headText: {
		fontSize: 7,
		fontWeight: 'bold',
		marginTop: 2,
	},
	text: {
		fontSize: 9,
		fontWeight: 'normal',
		marginLeft: 3,
	},
	rightText: {
		fontSize: 10,
		fontWeight: 'bold',
		textAlign: 'center'
	},
})

interface Props {
	active: Informe;
}


export const PersonalEquipo = ({ active }: Props) => {
	const externos: Totales[] = cargosCount(active.personal?.personalExterno!);
	const propios: Totales[] = cargosCount(active.personal?.personal!);
	const subcontratistas: Totales[] = cargosCount(active.personal?.subcontratistas!);
	const equipos: Maquinaria[] = active.equipos!.filter(item => item.cantidad !==0);
	const vehiculos: Vehiculo[] = active.vehiculos?.length! >0 ? active.vehiculos!: [];
	return(
		<>
			<BackTitle />
			<View style={styles.row} fixed>
				<PersonalView
					title='MANO DE OBRA INDIRECTA'
					totalTitle='TOTAL (MOI)'
					sx={{ columnWidth: '26%' }}
					totales={externos}
				/>
				<PersonalView
					title='MANO DE OBRA DIRECTA'
					totalTitle='TOTAL (MOD)'
					sx={{ columnWidth: '24%' }}
					totales={propios}
				/>
				<PersonalView
					title='SUSCONTRATISTAS'
					totalTitle='TOTAL (SE)'
					sx={{ columnWidth: '24%' }}
					totales={subcontratistas}
				/>
				<Equipos
					title='EQUIPOS'
					totalTitle='TOTAL (EQUIP.)'
					equipos={equipos}
					vehiculos={vehiculos}
					sx={{ columnWidth: '26%' }}
				/>
			</View>
			<BackTitle title='ACTIVIDADES REALIZADAS' />
		</>
	)
}


function cargosCount(personalDB: Personal[]) {
	const personal: Personal[] = personalDB ? personalDB : [];
	const totales: Totales[] = [];

	// Agrupamos segun el nombre del cargo
	let cargos: any = {};
	personal.forEach(item => {
		const nombreGrupo = item.cargo!;
		if (!cargos[nombreGrupo]) cargos[nombreGrupo] = [];
		cargos[nombreGrupo].push(item)
	})

	// Totalizamos la cantidad por cada cargo
	Object.entries(cargos).forEach(([key, value]) => {
		const val = value as any[];
		const total: Totales = { cargo: key, total: val.length }
		totales.push(total)
	});

	return totales

}