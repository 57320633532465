import { ClienteAction, ClienteState } from "../interfaces/clientes"

const initState: ClienteState = {
    clientes: [],
    activeCliente: null,
    modalCliente: false,
    alertDeleteCliente: false,
    isNew: false
}

export const clienteReducer = (state = initState, action: ClienteAction): ClienteState => {
    switch (action.type) {
        case 'load-clientes':
            return {
                ...state,
                clientes: action.payload
            }
        case 'create-cliente':
            return {
                ...state,
                clientes: [...state.clientes, action.payload]
            }
        case 'update-cliente':
            return {
                ...state,
                clientes: state.clientes.map(
                    c => (c.id === action.payload.id) ? action.payload : c
                )
            }
        case 'delete-cliente':
            return {
                ...state,
                clientes: state.clientes.filter(c => c.id !== action.payload)
            }
        case 'set-active-cliente':
            const cliente = state.clientes.find(c => c.id === action.payload);
            return {
                ...state,
                activeCliente: cliente ? cliente : null
            }
        case 'set-modal-cliente':
            return {
                ...state,
                modalCliente: action.payload
            }
        case 'set-alert-delete-cliente':
            return {
                ...state,
                alertDeleteCliente: action.payload
            }
        case 'set-isNew-client':
            return{
                ...state,
                isNew: action.payload
            }
        
        default:
            return state
    }
}