import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Container, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useUserProfile } from '../hooks/useUserProfile';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import { ModalUser } from './ModalUser';

export const UserProfile = () => {
  const { 
    avatarLetter, 
    bgcolor,   
    email, 
    movil, 
    lastname,
    nombre, 
    username, 
    handeleChangePassword,
    handleEditUser, 
  } = useUserProfile();

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', minHeight: '70vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Box
              sx={{
                my: 1,
                mx: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              
              <Card sx={{width: '100%'}} elevation={0}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ m: 1, bgcolor }}>
                    {avatarLetter}
                    </Avatar>
                  }                
                  title={nombre}
                />
                <CardContent>
                  <List dense disablePadding>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar sx={{bgcolor}}>
                    {avatarLetter}
                    </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={`${nombre} ${lastname}`} secondary='Nombre '/>
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar children={<LocationOnOutlinedIcon />}/>
                      </ListItemAvatar>
                      <ListItemText primary={username} secondary='Nombre de Usuario'/>
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar children={<ContactMailIcon />}/>
                      </ListItemAvatar>
                      <ListItemText primary={email} secondary='Correo Electronico'/>
                    </ListItem>
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar children={<PhoneIphoneIcon />}/>
                      </ListItemAvatar>
                      <ListItemText primary={movil} secondary='Número de Celular'/> 
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={handleEditUser}>Editar</Button>
                  <Box width={100}/>
                  <Button size="small" onClick={handeleChangePassword}>Cambiar contraseña</Button>
                </CardActions>
              </Card>
              <ModalUser /> 
            </Box>
          </Grid>

        </Grid>
      </Paper>
    </Container>
  )
}
