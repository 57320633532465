import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridRowsProp,
} from '@mui/x-data-grid';


import { AppState } from '../../../reducers/rootReducer';

import { startDeleteObra, startSetActiveObra, startSetModalObra } from '../actions/obras';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '../../../hooks';


export const useObrasTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const{width} = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText;

    const { obras: obrasResp } = useSelector((state: AppState) => state.obras.obras);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const obras: GridRowsProp = obrasResp.map((obra) => {
        return {
            id: obra.id,
            fechaContrato: obra.fechaContrato,
            fechaInicio: obra.fechaInicio,
            cliente: obra.cliente!.nombre,
            descripcion: obra.descripcion,
        }
    });

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        dispatch(startSetActiveObra(id));
        dispatch(startSetModalObra(true));
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteObra(id));
    };

    const handleShowDetalle = (id: GridRowId) => () => {
        dispatch(startSetActiveObra(id));
        navigate("/obras/detalle-obra", { replace: false });
        
    }


    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        //dispatch(startUpdateServicio(updatedRow));
        return updatedRow;
    };

    return {
        locale,
        obras,        
        rowModesModel,
        width,
        handleRowEditStart,
        handleRowEditStop,
        handleEditClick,
        handleSaveClick,
        handleDeleteClick,
        handleCancelClick,
        handleShowDetalle,
        processRowUpdate,
        setRowModesModel,
    }
}