import { Dispatch } from "react";
import { GridRowId } from "@mui/x-data-grid";

import { fetchConToken } from "../../../apis/fetch";
import { doMessage, startLoading } from "../../ui/actions/ui";
import { Reporte, ReporteAction, Ubicacion, FechaFinal } from '../interfaces/reportes';
import { startSetActividadEmptyNoPlaneada } from '../../reportes/modules/actividad_no_contemplada/action/actividad';
import { startSetHarramientaEmpty } from '../../reportes/modules/herramienta/actions/herramienta';
import { startSetMaterialesEmpty } from '../../reportes/modules/material/actions/material';
import { startSetMedicionBateriaEmpty } from '../../reportes/modules/medicion_banco_baterias/actions/medicion';
import { startSetPersonalEmpty } from '../../reportes/modules/personal/actions/personal';
import { startSetPersonalClaroEmpty } from '../../reportes/modules/personal_claro/actions/personal';
import { startSetRutinaBancoBateriaEmpty } from '../../reportes/modules/rutina_banco_baterias/actions/rutina';
import { startSetRutinaRectificadorEmpty } from '../../reportes/modules/rutina_rectificador/actions/rutina';
import { startSetVehiculosEmpty } from '../../reportes/modules/vehiculo/actions/vehiculo';
import { setInactiveServicio, startSetModalReporte, startSetModalReporteBdB, startSetModalReporteEmergencia, startSetModalReporteNormal, startSetModalServicio, updateServicioFromReporte } from '../../servicios/actions/servicio';
import { startSetActividadEmpty } from "../modules/actividad/action/actividad";
import { startSetAcompanaClienteEmpty } from "../../../shared/acompana_cliente/actions/actions";
import { startClearTipoActividad } from "../../../shared/tipo_actividad/actions/actions";
import { startSetmodalReporteObra } from "../../obras/actions/obras";
import { startSetAlarmaEmpty } from "../modules/rutina_alarmas/actions/alarma";
import { startSetResponsableBelingEmpty } from "../../../shared/responsable/actions/responsable";
import { startSetResponsableClienteEmpty } from "../../../shared/recibe/actions/recibe";
import { startSetInactiveAts, startSetNoServicio } from "../../ats/actions/ats";
import { startClearSelloFisico } from "../../../shared/sello_fisico/actions/images";
import { startClearImagesPrev } from "../modules/images_antes/actions/images";
import { startClearImagesFin } from "../modules/images_final/actions/images";


export const startLoadReportes = () => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('reportes', {}, "GET");
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(loadReportes(body.reportes));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const loadReportes = (reportes: Reporte[]): ReporteAction => ({
    type: 'load-reportes',
    payload: reportes
})

export const startSetReporteExist = (id: GridRowId) => {
    
    return async(dispatch: Dispatch<ReporteAction>) =>{
        const resp = await fetchConToken(`reportes/servicio/${id}`, {}, "GET");
        const body = await resp.json();
        const { ok } = body;
        if(ok){
             dispatch(reporteExists(true))
        }else{
            dispatch(reporteExists(false))
        }
    }
}

const reporteExists = (exist: boolean): ReporteAction=>({
    type: 'set-reporte-exist',
    payload: exist
})

export const startCreateReportes = (newReporte: any) => {    
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('reportes', newReporte, 'POST');
        const body = await resp.json();
        console.log(body)        
        const { errors, msg, ok, reporte } = body;
        if (ok) {            
            createReportes(reporte);
            doMessage({ message: msg, type: 'success' });
            dispatch(updateServicioFromReporte(newReporte.servicio, 'Finalizados'));
            dispatch(startLoadReportes());
            dispatch(closeReportes());             
        } else {
            
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error', duration: 6000 }));

            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error', duration: 6000 }));
            }
        }        
        dispatch(startLoading());
    }
}

const createReportes = (reporte: Reporte): ReporteAction => ({
    type: 'create-reportes',
    payload: reporte
})

export const startUpdateReportes = (reporte: Reporte) => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading())
        const { id } = getState().reportes.reportes.activeReporte;
        if(!id){
            dispatch(startLoading());
            return dispatch(doMessage({message: 'Reporte no activado', type: 'error'}));            
        }
        const resp = await fetchConToken(`reportes/${id}`, reporte, "PUT");
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(updateReportes(body.reporte));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const updateReportes = (reporte: Reporte): ReporteAction => ({
    type: 'update-reportes',
    payload: reporte
})

export const startDeleteReportes = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading())
        const { id } = getState().reportes.reportes.activeReporte;
        if(!id){
            dispatch(startLoading());
            return dispatch(doMessage({message: 'Reporte no activado', type: 'error'}));            
        }
        const resp = await fetchConToken(`reportes/${id}`, {}, "DELETE");
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(deleteReportes(id));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

export const startSetActiveReporte = (id: GridRowId): ReporteAction => ({
    type: 'set-active-reporte',
    payload: id
});

export const startSetInactiveReporte = () => ({
    type: 'set-inactive-reporte'
});

const deleteReportes = (id: string): ReporteAction => ({
    type: 'delete-reportes',
    payload: id
})

export const closeReportes = () => {
    return async (dispatch: any) => {
        dispatch(startClearActividadesReporte())
        dispatch(startClearImagesFin());
        dispatch(startClearImagesPrev());
        dispatch(startClearSelloFisico());
        dispatch(setInactiveServicio());
        dispatch(starClearActividadTipo());
        dispatch(startSetActividadEmpty());
        dispatch(startSetActividadEmptyNoPlaneada());
        dispatch(startSetHarramientaEmpty())
        dispatch(startSetMaterialesEmpty());
        dispatch(startSetMedicionBateriaEmpty());
        dispatch(startSetPersonalEmpty());
        dispatch(startSetPersonalClaroEmpty());
        dispatch(startSetRutinaBancoBateriaEmpty());
        dispatch(startSetRutinaRectificadorEmpty());
        dispatch(startSetVehiculosEmpty())
        dispatch(startSetModalReporte(false));
        dispatch(startSetModalReporteNormal(false));
        dispatch(startSetModalReporteEmergencia(false));
        dispatch(startSetModalReporteBdB(false));
        dispatch(startSetmodalReporteObra(false));
        dispatch(startSetResponsableBelingEmpty());
        dispatch(startSetResponsableClienteEmpty()); 
        dispatch(startSetInactiveReporte());
        dispatch(startSetAcompanaClienteEmpty());
        dispatch(startClearTipoActividad());
        dispatch(startClearUbicacionInicial());
        dispatch(startClearUbicacionFinal());
        dispatch(startSetAlarmaEmpty());
        dispatch(startSetHasAtsReporte(true));
        dispatch(startSetNoServicio(true));
    }
};

export const startClosePdfReporte = () => {
    return async (dispatch: any) =>{
        dispatch(startSetModalServicio(false));
        dispatch(startSetInactiveAts());
        dispatch(startSetInactiveReporte());
        dispatch(setInactiveServicio());
        dispatch(startSetModalReportePdf(false));
    }
}

export const startSetModalReportePdf = (modal: boolean): ReporteAction => ({
    type: 'set-modal-reporte-pdf',
    payload: modal
});

export const startSetUbicacionInicial = (ubicacion: Ubicacion): ReporteAction => ({
    type: 'set-ubicacionInicial',
    payload: ubicacion
});

export const startClearUbicacionInicial = (): ReporteAction => ({
    type: 'set-clear-ubicacionInicial',
});

export const startSetUbicacionFinal = (ubicacion: Ubicacion): ReporteAction => ({
    type: 'set-ubicacionFinal',
    payload: ubicacion
});

export const startClearUbicacionFinal = (): ReporteAction => ({
    type: 'set-clear-ubicacionFinal',
});

export const starSetActividadTipo = (actividad: string[]): ReporteAction => ({
    type: 'set-actividad-tipo',
    payload: actividad
});

export const starClearActividadTipo = (): ReporteAction => ({
    type: 'clear-actividad-tipo'
});

export const startSetActividadesReporte = (actividades: string): ReporteAction => ({
    type: 'set-actividades-reporte',
    payload: actividades
});

export const startClearActividadesReporte = (): ReporteAction => ({
    type: 'clear-actividades-reporte'
});

export const startSetObservacionesReporte = (observaciones: string): ReporteAction =>({
    type: 'set-observaciones-reporte',
    payload: observaciones
});

export const starSetFechaReporte = (fecha: FechaFinal): ReporteAction =>({
    type: 'set-fecha-reporte',
    payload: fecha
});

export const startSetAlertDeleteReporte = (payload: boolean): ReporteAction =>({
    type: 'set-alert-delete-reporte',
    payload
});

export const startSetHasAtsReporte = (payload: boolean): ReporteAction =>({
    type: 'set-has-ats-report',
    payload
})