import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridRowModesModel,
    GridRowModes,    
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridColDef,
} from '@mui/x-data-grid';

import { AppState } from '../../../reducers/rootReducer';
import { startSetActiveCliente, startSetAlertDeleteCliente } from '../actions/cliente';
import { CellExpand } from '../../../components/ui/CellExpand';
import { useNavigate } from 'react-router-dom';

export const useClientesTable = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientes } = useSelector((state: AppState) => state.clientes);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const clientesTable = clientes.map(c =>{
    return{
        direccion_detalle: c.direccion?.description,
        ...c
    }
  }) 

  const handleRowEditStart = (
      params: GridRowParams,
      event: MuiEvent<React.SyntheticEvent>,
  ) => {
      event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
      event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {        
      dispatch(startSetActiveCliente(id));
      navigate("/clientes/detalle", { replace: false })
  };

  const handleSaveClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
      dispatch(startSetActiveCliente(id));
      dispatch(startSetAlertDeleteCliente(true));
  };

  const handleCancelClick = (id: GridRowId) => () => {
      setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  };

  const processRowUpdate = (newRow: GridRowModel) => {
      const updatedRow = { ...newRow, isNew: false };
      //dispatch(startUpdateServicio(updatedRow));
      console.log('processRowUpdate', updatedRow);
      return updatedRow;
  };

  const columns: GridColDef[] = [
    { 
        field: 'identificacion', 
        headerName: 'Identificación', 
        flex:1, 
        editable: false, 
        headerAlign: 'center',
        renderCell: CellExpand 
    },
    { 
        field: 'nombre', 
        headerName: 'Nombre', 
        flex:2, 
        editable: false, 
        headerAlign: 'center',
        renderCell: CellExpand 
    },
    { 
        field: 'telefono', 
        headerName: 'Teléfono', 
        flex:1, 
        editable: false, 
        headerAlign: 'center',
        renderCell: CellExpand 
    },
    { 
        field: 'direccion_detalle', 
        headerName: 'Dirección', 
        flex:1, 
        editable: false, 
        headerAlign: 'center', 
        renderCell: CellExpand 
    },        
    
]; 

  return{
    columns,
    clientesTable,
    rowModesModel,
    handleRowEditStart,
    handleRowEditStop,
    handleEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    setRowModesModel,
  }
}
