import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { TableInfoBateriasRow } from './TableInfoBateriasRow';
import { TableInfoBateriaHeader } from './TableInfoBateriaHeader';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
    },
});

interface Props {
    active: Reporte;
}

export const InfoBateriasTable = ({ active }: Props) => (
    <View >
        <TitleTables title='Información de las Baterías - Referencia de las Baterías' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />
        <View style={styles.tableContainer}>
            <TableInfoBateriaHeader />
            <TableInfoBateriasRow items={active.infoBaterias!} />
        </View>
    </View>
);
