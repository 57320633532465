import { combineReducers } from 'redux';
import { informeReducer } from './informeReducer';
//import { informeModuleReducer } from './informeModuleReducer';



export const informeRootReducer = combineReducers({
    //modules: informeModuleReducer,    
    informes: informeReducer,
});

export type InformeState = ReturnType<typeof informeRootReducer>;