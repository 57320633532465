import { GridRowId } from '@mui/x-data-grid';
import { fetchConToken } from '../../../apis/fetch';
import { doMessage, startLoading } from '../../ui/actions/ui';
import { NewObra, Obra, ObraAction } from '../interfaces/obras.interface';

export const startLoadObras = () => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('obras', {}, "GET");
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(loadObras(body.obras));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const loadObras = (obras: Obra[]): ObraAction => ({
    type: 'load-obras',
    payload: obras
});

export const startLoadObra = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().obras.obras.activeObra.id;
        const resp = await fetchConToken(`obras/${id}`, {}, "GET");
        const body = await resp.json();
        const { errors, msg, ok, obra } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(loadObra(obra));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = '';
                const error = Object.values(errors);
                error.map((e: any) => err += `${e.msg} \n`);
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const loadObra = (obra: Obra): ObraAction => ({
    type: 'load-obra',
    payload: obra
})

export const startCreateObras = (newObra: NewObra) => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('obras', newObra, "POST");
        const body = await resp.json();
        const { errors, msg, ok, obra } = body;
        console.log(body)
        if (ok) {
            dispatch(startLoading());
            dispatch(createObra(obra))
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const createObra = (obra: Obra): ObraAction => ({
    type: 'create-obras',
    payload: obra
})

export const startUpdateObra = (updatedObra: NewObra) => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().obras.obras.activeObra.id;
        if (!id) {
            return dispatch(doMessage({ message: 'No hay obra activa', type: 'error' }));
        }
        const resp = await fetchConToken(`obras/${id}`, updatedObra, 'PUT');
        const body = await resp.json();
        const { errors, msg, ok, obra } = body;
        if (ok) {
            dispatch(startLoading());
            dispatch(updateObra(obra));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const updateObra = (obra: Obra): ObraAction => ({
    type: 'update-obras',
    payload: obra
})

export const startDeleteObra = (id: GridRowId) => {
    return async(dispatch: any) =>{
        dispatch(startLoading());
        const resp = await fetchConToken(`obras/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const { errors, msg, ok } = body;
        if(ok){
            dispatch(startLoading());
            dispatch(doMessage({ message: msg, type: 'success' }));
            dispatch(deleteObra(id))
        }else{
            dispatch(startLoading());
            if (msg) {
                dispatch(doMessage({ message: msg, type: 'error' }));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
    }
}

const deleteObra = (id: GridRowId): ObraAction => ({
    type: 'delete-obras',
    payload: id
})

export const startSetActiveObra = (id: GridRowId): ObraAction => ({
    type: 'set-active-obra',
    payload: id
})

export const startSetInactiveObra = (): ObraAction => ({
    type: 'set-inactive-obra'
})

export const startSetModalObra = (modal: boolean): ObraAction => ({
    type: 'set-modal-obra',
    payload: modal
})

export const startSetmodalReporteObra = (modal: boolean): ObraAction =>({
    type: 'set-modal-grupo-reporte',
    payload: modal
})
