import { ApiKeyManager } from "@esri/arcgis-rest-request";
import { geocode } from "@esri/arcgis-rest-geocoding";
import { v4 as uuidv4 } from 'uuid';
const apiKey = process.env.REACT_APP_API_KEY_ARCGIS!;
const authentication = ApiKeyManager.fromKey(apiKey);
interface Props {
	request: Input,
	callback: any
}

interface Input {
	input: string
}

export const getGeoCode = async ({request, callback}: Props) => {	
	const places = geocode({
		address: request.input,
		authentication
	}).then(resp => {
		const data = resp.candidates;
		const candidates: any = [];

		candidates.push(...data)

		let candidatesMap = candidates.map((item: any)=>{
			return [item.address, item]
		});
		
		const candidatesMapArr = new Map(candidatesMap );
		let unicos = [...candidatesMapArr.values()];		

		callback(unicos.map((r: any) => {
			const id = uuidv4();
			const address = r.address;
			const mainText = address.split(',', 1)[0];
			const splitAddress = address.split(',');
			const secondary = splitAddress.slice(1).join(',').trim();
			const matchOne = {
				length: mainText.length - 1,
				offset: 0
			}
			
			const terms = splitAddress.map((s:any, index: any) =>{
				const offset = index === 0? 0 : s.length ;
				return {
					offset,
					value: s
				}
			})

			const matchTow = {
				length: address.split(',', 1)[0].length,
				offset: terms[1]?.offset,
			}
			return {
				center:[
					r.location.x,
					r.location.y
				],
				description: address,
				structured_formatting: {
					main_text: mainText,
					main_text_matched_substrings: [
						matchOne,
						matchTow
					],
					secondary_text: secondary
				},
				matched_substrings: {
					length: mainText.length,
					offset: 0
				},
				place_id:id,
				reference: id,
				terms
			}
		}))
	})

	return places

}
