import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardMedia, Grid, IconButton, TextField } from '@mui/material';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import { ModalFirma } from '../responsable/components/ModalFirma';
import { startSetModalFirmaBeling, startUpdateResponsableBeling } from '../responsable/actions/responsable';

import imagen from '../../assets/img/bg.png'
import { useForm } from '../../hooks/useForm';
import { AppState } from '../../reducers/rootReducer';
import { GridRowId } from '@mui/x-data-grid';


const formInit = {
	nombreResBeling: '',
	identificacionResBeling: '',
	cargoResBeling: ''
}

interface Props {
    name: string;
    reporte: GridRowId;
}

export const ResponsableBeling = ({name, reporte}:Props) => {
	const dispatch = useDispatch();
	const firmaImg = useRef('');
	const {modalFirmaBeling, responsableBeling} = useSelector((state: AppState) => state.shared.responsableBeling);
	const { nombre, identificacion, cargo, firma, isNew} = responsableBeling[0];
	const {uri, name: nameImg } = firma;

	const {registerData, onChange, changeData } = useForm(formInit);
	const {nombreResBeling, identificacionResBeling, cargoResBeling} = registerData;
	

	const handleFirmaClick = () => () => {
		handleUpdateResponsable();
		dispatch(startSetModalFirmaBeling(true));
	};

	const handleUpdateResponsable = ()=>{
		const responsable = {
			id: 1,
			nombre: nombreResBeling,
			cargo: cargoResBeling,
			identificacion: identificacionResBeling,
			isNew: false,
			firma:{uri, name: nameImg}
		}

		dispatch(startUpdateResponsableBeling(responsable))

	}

	useEffect(() => {
	  if(uri === ''){
		firmaImg.current = imagen;
	  }else{
		firmaImg.current = uri
	  }
	
	},[uri, modalFirmaBeling])

	useEffect(() => {
	  if(!isNew){
		const data = {
			nombreResBeling: nombre,
			identificacionResBeling: identificacion,
			cargoResBeling: cargo
		};
		changeData(data);
	  }
	},[isNew, cargo, identificacion, nombre, changeData])
	
	

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6}>
						<TextField
							autoComplete="nombreResBeling"
							fullWidth
							id="nombreResBeling"
							label="Nombre y Apellidos"
							margin="dense"
							multiline
							name="nombreResBeling"
							onChange={onChange}
							onBlur={handleUpdateResponsable}
							required
							rows={1}
							value={nombreResBeling}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<TextField
							autoComplete="identificacionResBeling"
							fullWidth
							id="identificacionResBeling"
							label="Identificación"
							margin="dense"
							multiline
							name="identificacionResBeling"
							onChange={onChange}
							onBlur={handleUpdateResponsable}
							required
							rows={1}
							value={identificacionResBeling}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<TextField
							autoComplete="cargoResBeling"
							fullWidth
							id="cargoResBeling"
							label="Cargo"
							margin="dense"
							multiline
							name="cargoResBeling"
							onBlur={handleUpdateResponsable}
							onChange={onChange}
							required
							rows={1}
							value={cargoResBeling}
						/>
					</Grid>
				</Grid>
				<ModalFirma name={name} reporte={reporte} />
			</Box>
			<Box
				sx={{
					display: "flex",					
					flexWrap: "wrap",
					justifyContent: "flex-start",
					alignItems: "center"
				}}
				mt={2}
			>
				<Card sx={{ maxWidth: 460, border: 1, borderColor: 'GrayText' }} elevation={0}>
					<CardMedia
					sx={{ objectFit: 'contain', padding:1, minWidth: 250}}
						component="img"
						height="100"						
						image={firmaImg.current}
						alt="firma"
					/>
				</Card>
				<IconButton color="secondary" aria-label="Firmar" size="large" onClick={handleFirmaClick()}>
				<DrawOutlinedIcon sx={{ fontSize: 40 }} />
				</IconButton>
			</Box>
		</>
	)
}
