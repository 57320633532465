import { Box } from '@mui/material'
import { Copyright } from '../copyright/Copyright'

export const LoginFooter = () => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Copyright sx={{ mt: 5 }} />
		</Box>
	)
}
