import { Grid, Typography } from '@mui/material'

interface Props {
	text: string,
	mt?: number,
	mb?: number,
	align?: 'center' |'inherit'|'justify'|'left' |'right'
}

export const Titles = ( { align = 'center', mb = 0, mt = 4, text }: Props) => {
	return (
		<Grid container>
			<Grid item alignContent="center" xs={12} sx={{ mb, mt }}>
				<Typography component="h1" align={align} variant="subtitle1">
					{ text }
				</Typography>
			</Grid>
		</Grid>
	)
}
