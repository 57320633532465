import { MaquinariaAction, MaquinariaState } from '../interfaces/maquinaria';

const initState: MaquinariaState = {
    equipos: [],
};

export const maquinariaReducer = (state = initState, action: MaquinariaAction): MaquinariaState => {
    switch (action.type) {
        case 'set-maquinaria-equipo':
            return {
                ...state,
                equipos: [...state.equipos, action.payload],
            };
        case 'update-maquinaria-equipo':
            return {
                ...state,
                equipos: state.equipos.map((equipo) => {
                    if (equipo.id === action.payload.id) {
                        return action.payload;
                    }
                    return equipo;
                }),
            };
        case 'delete-maquinaria-equipo':
            return {
                ...state,
                equipos: state.equipos.filter((equipo) => equipo.id !== action.payload),
            };
        case 'edit-maquinaria-equipo':
            return {
                ...state,
                equipos: state.equipos.map((equipo) => {
                    if (equipo.id === action.payload) {
                        equipo.isNew = false;
                    }
                    return equipo;
                }),
            };
        case 'set-maquinaria-equipo-empty':
            return {
                ...state,
                equipos: [],
            };
        default:
            return state;
    }
}