import { ImagenState, ImagesAction } from '../interfaces/images';

const imageState: ImagenState = {
    images: []
}

export const imagesReducer = (state = imageState, action: ImagesAction): ImagenState => {
    switch(action.type){
        case "load-images":
            return {
                ...state,
                images: action.payload
            }
        case "create-images":
            return {
                ...state,
                images: [...state.images, action.payload]
            }
        case "update-images":
            return {
                ...state,
                images: state.images.map(image => image.id === action.payload.id ? action.payload : image)
            }
        case "delete-images":
            return {
                ...state,
                images: state.images.filter(image => image.id !== action.payload)
            }
        case "clear-image-state":
            return {
                ...state,
                images: []
            }
        default:
        return state
    }
}