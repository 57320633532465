import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { Informe } from "../../../../interfaces/informes";
const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 14,
		textAlign: 'center',
		border: 1,
		marginTop: -0.5,
	},
	columnLeft: {
		flexDirection: 'column',
		width: '50%',
		borderRight: 1
	},
	colunmRight: {
		flexDirection: 'column',
		width: '50%'
	},
	rowContainer: {
		flexDirection: 'row',
		width: '100%',
	},
})


interface Props {
	active: Informe;
}

export const Firmas = ({ active }: Props) => {
	return (
		<View style={styles.row} fixed>
			<View style={[styles.columnLeft]}>
				<View style={[styles.rowContainer, { borderBottom: 1, height: 36 }]}>

				</View>
				<View style={[styles.rowContainer, { alignItems: 'center', justifyContent: 'center' }]}>
					<Text style={[{ fontSize: 8, fontWeight: 'bold', marginTop: 2 }]}>
						CONTRATISTA:
					</Text>
				</View>
			</View>
			<View style={[styles.colunmRight]}>
				<View style={[styles.rowContainer, { borderBottom: 1, height: 36 }]}>

				</View>
				<View style={[styles.rowContainer, { alignItems: 'center', justifyContent: 'center' }]}>
					<Text style={[{ fontSize: 8, fontWeight: 'bold', marginTop: 2 }]}>
						SCALA: RICARDO CORREA
					</Text>
				</View>
			</View>
		</View>
	)
}
