import { useRef, useState, useEffect, MutableRefObject } from 'react';
import { usePosition } from 'use-position';
import { Map, Marker, NavigationControl, Popup } from 'mapbox-gl';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';
import { dateShort } from '../../../helpers/fecha';

export const useMap = () => {
	const watch = false;
	const { latitude, longitude } = usePosition(watch);
	const {reportes} = useSelector((state: AppState) => state.reportes.reportes);
	const mapContainer = useRef(null);
	const map = useRef(null) as MutableRefObject<any>;
	const [center, setCenter] = useState<[number, number]>();
  
	const getLocation = () =>{    
	  if(longitude === undefined || latitude === undefined) return;
	  setCenter([longitude, latitude])
	}
  
	useEffect(() =>{
	  if(latitude !== undefined){
		getLocation();
	  }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[latitude])
	
	useEffect(()=>{		
	  if(map.current) return;
	  if(center){
		map.current = new Map({
		  container: mapContainer.current!,
		  style: 'mapbox://styles/mapbox/streets-v12',
		  center,
		  zoom: 12
		}); 
		
	   reportes?.forEach(reporte =>{
		   const lng = reporte.ubicacionInicial?.longitude ? reporte.ubicacionInicial?.longitude : reporte.ubicacion?.longitude;
		   const lat = reporte.ubicacionInicial?.latitude ? reporte.ubicacionInicial?.latitude : reporte.ubicacion?.latitude;
			const fecha = dateShort(reporte.fecha!);
			const tecnico = `${reporte.servicio?.tecnico.name} ${reporte.servicio?.tecnico.lastname}`;
			const cliente = reporte.servicio?.cliente.nombre;
			const popup = new Popup()
			.setHTML(`
			  <div class="popup-container">
				<div class="popup-header">
				  ${cliente}
				</div>
				<div class="popup-body">
				  <div>Fecha: ${ fecha }</div>
				  <div>Tecnico: ${ tecnico}</div>
				</div>
			  </div>
			`)
			new Marker({
			  color: 'red'
			})
			.setLngLat([lng!, lat!])
			.setPopup(popup)
			.addTo(map.current)
		});

		map.current.addControl(new NavigationControl());
  
	  }
	  
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [center])

	return {
		mapContainer
	}
}
