import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../../reducers/rootReducer";
import { startSetModalSelloFisico } from "../actions/images";

import imagen from '../../../assets/img/bg.png'


export const useSelloFisico = () => {

	const dispatch = useDispatch();

	const { sello: { selloFisico, modalSelloFisico }, shared: { props } } = useSelector((state: AppState) => state.shared);
	const [imagens, setImagens] = useState(imagen)



	const handleSelloFisico = () => () => {
		dispatch(startSetModalSelloFisico(true))
	}

	useEffect(() => {
		if (selloFisico) {
			setImagens(selloFisico.uri)

		} else {
			setImagens(imagen)
		}
	}, [selloFisico])

	return {
		img: imagens,
		title: props?.title,
		modalSelloFisico,
		handleSelloFisico,
	}
}
