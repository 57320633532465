import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight: 18,
        textAlign: 'center',
        flexGrow: 1,
    },
    referencia: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    detalle: {
        width: '50%'
    }
  });

export const TableInfoInversorHeader = () => (
    <View style={styles.container} fixed>
        <Text style={styles.referencia}>Item</Text>
        <Text style={styles.detalle}>Detalle</Text>
    </View>
  );