import { SharedAction, SharedState } from "../interfaces/shared";


const initState: SharedState = {
    props: null
};

export const sharedReducer = (state = initState, action: SharedAction): SharedState => {
    switch (action.type) {        
        case 'set-actividad-props':
            return {
                ...state,
                props: action.payload
            }
        case 'clear-actividad-props':
            return{
                ...state,
                props: null
            }
        default:
            return state;
    }
}