import { GridRowId } from '@mui/x-data-grid';
import { fetchConToken, fetchImages } from '../../../apis/fetch';
import { doMessage, startLoading } from '../../ui/actions/ui';
import { TecnicoAction, Tecnico } from '../interfaces/tecnico';
import { createPassword } from '../../../helpers';

export const startLoadTecnicos = () => {
    return async (dispatch: any) =>{
        dispatch(startLoading());
        const resp = await fetchConToken('tecnicos', {});
        const body = await resp.json();
        if (body.ok) {
            dispatch(startLoading());
            dispatch(loadTecnicos(body.tecnicos));
        } else {
            dispatch(startLoading());
            dispatch(doMessage(body.msg));
        }
    }
}

const loadTecnicos = (tecnicos: Tecnico[]): TecnicoAction => ({
    type: 'load-tecnico',
    payload: tecnicos
})

export const startCreateTecnico = (tecnicoCreate: Tecnico) => {
    tecnicoCreate.password = createPassword(8, 'alfha-number');
    const userImg = {
        email: tecnicoCreate.email,
        name: tecnicoCreate.name,
        password: tecnicoCreate.password,
        password_confirmation: tecnicoCreate.password
    }
    return async (dispatch: any) => {
        dispatch(startLoading());

        const userI = await fetchImages('register', userImg, 'POST');
        const bodyimg = await userI.json();
        const { user: userImages } = bodyimg;
        if (!userI.status) {
            dispatch(doMessage({ message: 'Error almacenando tecnico', type: 'error' }));
            return;
        }
        
        tecnicoCreate.imagesId = userImages.id;
        tecnicoCreate.temporalPassword = userImages.temporal_password;

        const resp = await fetchConToken('tecnicos', tecnicoCreate, 'POST');
        const body = await resp.json();
        const {errors, msg, ok, tecnico } = body;
        console.log(body);
        if (ok) {
            dispatch(startLoading());
            dispatch(createTecnico(tecnico));
            dispatch(doMessage({message: msg, type: 'success'}));
        } else {
            dispatch(startLoading());
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
    }
}

const createTecnico = (tecnico: Tecnico): TecnicoAction => ({
    type: 'create-tecnico',
    payload: tecnico
})

export const startUpdateTecnico = (tecnicoUpdate: Tecnico) => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().tecnicos.activeTecnico.id;
        if(!id){
            return dispatch(doMessage({message: 'No hay tecnico activo', type: 'error'}));
        }
        const resp = await fetchConToken(`tecnicos/${id}`, tecnicoUpdate, 'PUT');
        const body = await resp.json();
        const {errors, msg, ok, tecnico } = body;
        if (ok) {            
            dispatch(startLoading());
            dispatch(updateTecnico(tecnico));
            dispatch(doMessage({message: msg, type: 'success'}));
        } else {
            dispatch(startLoading());
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
    }
}

const updateTecnico = (tecnico: Tecnico): TecnicoAction => ({
    type: 'update-tecnico',
    payload: tecnico
})

export const startDeleteTecnico = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().tecnicos.activeTecnico.id;
        if(!id){
            return dispatch(doMessage({message: 'No hay tecnico activo', type: 'error'}));
        }
        const resp = await fetchConToken(`tecnicos/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const {errors, msg, ok } = body;
        console.log(body);
        if (ok) {
            dispatch(deleteTecnico(id));
            dispatch(startSetActiveTecnico(''));
            dispatch(doMessage({message: msg, type: 'success'}));
        } else {
            if(msg){                
                dispatch(doMessage({message: msg, type: 'error'}));
            }else{
              let err = ''           
              const error = Object.values(errors)
              error.map((e: any) => err += `${e.msg} \n`)
              dispatch(doMessage({message: err, type: 'error'}));
            }
        }
        dispatch(startLoading());
    }
}

const deleteTecnico = (id: string): TecnicoAction => ({
    type: 'delete-tecnico',
    payload: id
})


export const startSetActiveTecnico = (id: GridRowId):TecnicoAction => ({
    type: 'set-active-tecnico',
    payload: id
})

export const startSetModalTecnico = (modal: boolean):TecnicoAction => ({
    type: 'set-modal-tecnico',
    payload: modal
})

export const startSetAlertDeleteTecnico = (alert: boolean):TecnicoAction => ({
    type: 'set-alert-delete-tecnico',
    payload: alert
})
