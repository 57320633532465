import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
	},
	column: {
		flexDirection: 'column',
		padding: 5,

	},
	totalColumn: {
		height: '100%',
		width: '99.7%',
	},

	rowContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '100%',
	},
	colContainer: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	txTitle: {
		fontSize: 11,
		fontWeight: 'bold'
	},
});

interface Props {
	title: string;
	topPosition?: boolean,
	sx?: {
		mt?: number,
		bw?: number,
		color?: string,
		fz?: number
	}
}

export const HeadLine = ({ title, sx, topPosition = true }: Props) => {
	const { mt, bw, color, fz } = sx ?? {
		mt: 2,
		bw: 3,
		color: 'black',
		fz: 10
	}	
	return (
		<>
			<View style={[styles.row, { marginTop: mt }]}>
				<View style={[cellStyle(topPosition, bw), styles.totalColumn, {
					borderLeft: bw,
					borderRight: bw,
				}]}>
					<View style={[styles.rowContainer]}>
						<View style={[styles.colContainer]}>
							<Text style={[styles.txTitle, { textAlign: 'center', color: color, fontSize: fz }]}>{title.toLocaleUpperCase()}</Text>
						</View>
					</View>
				</View>
			</View>
		</>
	)
}

function cellStyle(position: boolean, borderWith: number = 3) {
	const lineTop = {
		...styles.column,
		borderBottom: borderWith,
		borderTop: borderWith
	}

	const lineBottom = {
		...styles.column,
		borderBottom: borderWith,
	}

	if (position) {
		return lineTop
	} else {
		return lineBottom
	}
}
