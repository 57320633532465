import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePosition } from 'use-position';
import { v4 as UUID } from 'uuid'
import { useForm } from '../../../hooks/useForm';
import { useDate } from '../../../hooks/useDate';
import { AppState } from '../../../reducers/rootReducer';
import { setFormError } from '../../ui/actions/ui';
import { closeInformes, startCreateInformes, startSetUbicacionFinal, startSetUbicacionInicial } from '../../informes/actions/informes';
import { deleteFirma } from '../../../helpers';
import { Props } from '../../../shared/shared_state/interfaces/shared';
import { startSetSharedProps } from '../../../shared/shared_state/actions/shared';
import { deleteImagen } from '../../../helpers/imagen';


const initForm = {
  alcanceActividad: '',
  observaciones: '',
}

const initDate = {
  fecha: new Date(),
  fechaHoraInicio: new Date(),
  fechaHoraFinal: new Date()
}

export const useModalReporteObra = () => {
  const dispatch = useDispatch();
  const watch = false;

  const { latitude, longitude, timestamp } = usePosition(watch);

  const [iniciar, setIniciar] = useState(false);
  const [guardar, setGuardar] = useState(false);
  const [props, setProps] = useState({
    name: '',
    reporte: ''
  });

  const { onChange, registerData, changeData } = useForm(initForm);
  const { onDateChange, changeDate, registerDataDate } = useDate(initDate);

  const { formError } = useSelector((state: AppState) => state.ui);
  const { modalReporteObra, activeObra } = useSelector((state: AppState) => state.obras.obras);
  const { modules, reportes } = useSelector((state: AppState) => state.reportes);
  const { acompana, materiales, sello, maquinaria, personalExterno, subcontratistas, condicionesSitio, meteorologicas, accidentes, images: imagesState, responsableBeling,
    responsableCliente, } = useSelector((state: AppState) => state.shared);

  const {
    //actividad,
    actividadObra,
    actividadNoContemplada,
    equiposSeguridad,
    herramientas,
    personal,    
    vehiculos,
  } = modules;

  const {images} = imagesState;

  const { firma: firmaResponsable } = responsableBeling.responsableBeling[0];
  const { firma: firmaCliente } = responsableCliente.responsableCliente[0];

  const handleClose = () => {
    if (!guardar) {
      if (firmaResponsable.id) {
        deleteFirma(firmaResponsable.id);
      }
      if (firmaCliente.id) {
        deleteFirma(firmaCliente.id);
      }
      if (sello.selloFisico && sello.selloFisico.id) {
        deleteImagen(sello.selloFisico.id)
      }
      if(images.length>0){
        images.forEach(async(i)=>{
          await deleteImagen(i.imagen?.id)
        })
      }
    }
    dispatch(closeInformes());
    changeData(initForm);
    changeDate(initDate);
    setIniciar(false);
    setGuardar(false);
  }

  const handleSave = () => {
    setGuardar(true)
    if (registerData.alcanceActividad === '') {
      dispatch(setFormError(true))
      return
    }
    const data = {
      ats: false,
      acompanaCliente: acompana.acompanaCliente,
      actividades: actividadObra.actividadesObra,
      actividadesNoPlaneadas: actividadNoContemplada.actividadesNo,
      condiciones: {
        accidentes: accidentes.accidentes,
        condicionesSitio: condicionesSitio.condicionesSitio,
        meteorologicas: meteorologicas.meteorologicas
      },
      equipos: maquinaria.equipos,
      equiposSeguridad: equiposSeguridad.equipos,
      ...registerData,
      ...registerDataDate,
      images: images,
      herramientas: herramientas.herramientas,
      materiales: materiales.materiales,
      personal: {
        personal: personal.personal,
        personalExterno: personalExterno.personal,
        subcontratistas: subcontratistas.subcontratistas
      },
      responsableBeling: responsableBeling.responsableBeling,
      responsableCliente: responsableCliente.responsableCliente,
      ubicacionInicial: reportes.ubicacionInicial,
      ubicacionFinal: reportes.ubicacionFinal,
      selloFisico: sello.selloFisico,
      obra: activeObra!.id,
      tipoActividad: "Actividad de obra",
      vehiculos: vehiculos.vehiculos,

    }
    //console.log(data)
    dispatch(setFormError(false))
    dispatch(startCreateInformes(data));
    setIniciar(false);
    setGuardar(false);
  }

  const handleIniciar = () => {
    setIniciar(true);
    const ubicacion = {
      latitude,
      longitude,
      timestamp
    }
    dispatch(startSetUbicacionInicial(ubicacion));
  }

  const handleFinalizar = () => {
    const ubicacion = {
      latitude,
      longitude,
      timestamp
    }
    dispatch(startSetUbicacionFinal(ubicacion));
  }

  useEffect(() => {
    if (activeObra) {
      const uid = UUID()
      const props: Props = {
        name: `Obra ${activeObra?.numero!}-${uid}`,
        servicio: activeObra?.id!,
        descripcion: `Actividad_de_obra${uid}`
      }
      setProps({
        name: `Obra ${activeObra?.numero!}-${uid}`,
        reporte: activeObra?.id!
      })
      dispatch(startSetSharedProps(props))
    }
  }, [activeObra, dispatch])


  return {
    formError,
    modalReporteObra,
    registerData,
    registerDataDate,
    iniciar,
    handleClose,
    handleFinalizar,
    handleIniciar,
    handleSave,
    onChange,
    onDateChange,
    props
  }
}