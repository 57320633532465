import { Container, Grid, Paper } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { startLoadAts } from '../actions/ats'
import { Ats } from './Ats'

export const AtsScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(startLoadAts())
  },[dispatch])  
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Clientes */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Ats />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
