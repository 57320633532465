import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as UUID } from 'uuid';

import img from '../../../../../assets/img/bg.png';
import { AppState } from '../../../../../reducers/rootReducer'
import { saveImagen, getImagen } from '../../../../../helpers/imagen';
import { useScreenSize } from '../../../../../hooks';
import { 
  startSetActiveActividadNoPlaneada, 
  startSetActividadUpdateImageNoPlaneada, 
  startSetModadlActividadNoPlaneada 
} from '../action/actividad';
import { doMessage, startLoading } from '../../../../ui/actions/ui';


export const useModalActividad = () => {
  const dispatch = useDispatch();
  const { width } = useScreenSize();
  const [imgWidth, setImgWidth] = useState<number>(194)
  const [foto, setFoto] = useState(img)
  const { activeServicio } = useSelector((state: AppState) => state.servicios);
  const { activeActividadNo, modalActividadNo } = useSelector((state: AppState) => state.reportes.modules.actividadNoContemplada);
  const { images, id } = activeActividadNo!;
  const lastIndex = images? images.length -1 : 0;

  const handleClose = () => {
    dispatch(startSetModadlActividadNoPlaneada(false));
    setFoto(img)
  };

  const handelLoadImage = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFoto(reader.result as string);
    }
  }

  const handleSave = async () => {
    dispatch(startLoading());
    if (id) {
      const data = {
        imagen: foto,
        servicio: `${activeServicio!.tipo.alias}-${UUID()}`,
        name: activeServicio!.tipo.alias,
        descripcion: activeServicio!.descripcion ? activeServicio!.descripcion: 'ribu claro',
      };      
      
      const { imagen, status } = await saveImagen(data)
      if (status) {
        const imageSave = await getImagen(imagen.name);
        dispatch(startSetActividadUpdateImageNoPlaneada(imagen, id));
        dispatch(startSetModadlActividadNoPlaneada(false));
        setFoto(imageSave);
        dispatch(startSetActiveActividadNoPlaneada(''));
        dispatch(doMessage({type: 'success', message: 'Imagen guardada'}));
      }
    } else {
      dispatch(doMessage({type: 'error', message: 'Error al guardar la imagen'}));
      dispatch(startSetModadlActividadNoPlaneada(false));
      setFoto(img)
    }
    dispatch(startLoading());
  }

  useEffect(() => {
    if (width > 843) {
      setImgWidth(220)
    } else {
      setImgWidth(120)
    }
  }, [width])

  useEffect(() => {
    if (images) {
      setFoto(images[lastIndex]?.uri!)
    }
    return () => {
      setFoto(img)
    }
  }, [images, lastIndex])


  return {
    descripcion: activeServicio?.descripcion,
    foto,
    imgWidth,
    modalActividadNo,
    handleClose,
    handelLoadImage,
    handleSave,
  }
}
