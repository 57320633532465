import { RutinaBancoBateriaState, RutinaBancoBateriaAction } from '../interfaces/rutina';

const initState: RutinaBancoBateriaState = {
    rutinasBancoBateria: [],
    activeBancoBateria: null,
    rutinaBancoId: '',
    modalBancos: false,
    modalSelectBanco: false,
    modalImagesBancoBaterias: false
};

export const rutinaBancoBateriasReducer = (state = initState, action: RutinaBancoBateriaAction): RutinaBancoBateriaState => {
    switch (action.type) {
        case 'set-rutina-banco-bateria':
            return {
                ...state,
                rutinasBancoBateria: [...state.rutinasBancoBateria, action.payload],
            };
        case "set-active-rutina-banco-bateria":
            const rutina = state.rutinasBancoBateria.find((rbb) => rbb.id === action.payload);
            return {
                ...state,
                activeBancoBateria: rutina ? rutina : null,
            };
        case 'update-rutina-banco-bateria':
            return {
                ...state,
                rutinasBancoBateria: state.rutinasBancoBateria.map((rbb) => {
                    if (rbb.id === action.payload.id) {
                        return action.payload;
                    }
                    return rbb;
                }),
            };
        case 'delete-rutina-banco-bateria':
            return {
                ...state,
                rutinasBancoBateria: state.rutinasBancoBateria.filter((rbb) => rbb.id !== action.payload),
            };
        case 'edit-rutina-banco-bateria':
            return {
                ...state,
                rutinasBancoBateria: state.rutinasBancoBateria.map((rbb) => {
                    if (rbb.id === action.payload) {
                        rbb.isNew = false;
                    }
                    return rbb;
                }),
            };
        case 'set-rutina-update-image-banco':
            return {
                ...state,
                rutinasBancoBateria: state.rutinasBancoBateria.map((rbb) => {
                    if (rbb.id === action.payload.id) {
                        if (!rbb.images) {
                            rbb.images = []
                        }
                        rbb.images?.push(action.payload.image);
                    }
                    return rbb;
                }),
            };
        case 'set-delete-image-rutina-banco-baterias':
            return {
                ...state,
                rutinasBancoBateria: state.rutinasBancoBateria.map((rbb) => {
                    if (rbb.id === action.payload.id) {
                        rbb.images = rbb.images!.filter(i => i.id !== action.payload.imgId);
                    }
                    return rbb;
                }),
            };
        case 'set-clear-images-rutina-bancos':
            return {
                ...state,
                rutinasBancoBateria: state.rutinasBancoBateria.map(rbb => {
                    if (rbb.id === action.payload) {
                        rbb.images?.shift();
                    }
                    return rbb
                })
            }
        case 'set-rutina-banco-id':
            return {
                ...state,
                rutinaBancoId: action.payload,
            };
        case 'set-rutina-modal-bancos':
            return {
                ...state,
                modalBancos: action.payload,
            };
        case 'set-rutina-modal-select-bancos':
            return {
                ...state,
                modalSelectBanco: action.payload,
            };
        case 'set-modal-imagenes-rutina-banco-baterias':
            return {
                ...state,
                modalImagesBancoBaterias: action.payload
            }
        case 'set-rutina-banco-bateria-empty':
            return {
                ...state,
                rutinasBancoBateria: [],
                rutinaBancoId: '',
            };
        default:
            return state;
    }
}