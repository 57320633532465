import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Equipo } from '../../../interfaces/ats';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',               
        alignItems: 'center',
        textAlign: 'justify',
    },
    rowTop:{
        borderBottomColor: '#eeeeee', 
        borderBottomWidth: 1,
    },
    referencia: {
        width: '70%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 4,
        flexWrap: 'wrap'
    },
    detalle: {
        width: '30%',
        borderRightColor: borderColor,
        paddingLeft: 4,
        textAlign: 'center',
    }
});

interface Props {
    items: Equipo[];
}

export const EquiposRow = ({ items }: Props) => {   
    const itemsfilter = items.filter(item => item.cantidad! > 0);    
    const length = itemsfilter.length;
    const rows = itemsfilter.map((item, index) => {
        const cellStyles = cellStyle(index, length);
        return item.cantidad! > 0 && (<View wrap={false} style={[cellStyles]} key={item.id.toString()}>
            <Text style={styles.referencia}>{item.name!}</Text>
            <Text style={styles.detalle}>{item.cantidad}</Text>
        </View>)
    }
    )
    return (<Fragment>{rows}</Fragment>)
};


function cellStyle(index: number, length: number) {
    
    const cell = {
        ...styles.row,
        ...styles.rowTop
    }

    const cellEnd = {
        ...styles.row
    }

    if (length === index + 1) {
        return cellEnd
    } else {
        return cell
    }

}
