import { useState } from 'react';
import { steps } from '../components/basico/StepList';


export const useFormBasico = () => {
	
	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = steps.length;
  
	const handleNext = () => {
	  setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
  
	const handleBack = () => {
	  setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return {
		activeStep,
		maxSteps,
		steps,
		handleBack,
		handleNext
	}
}
