import { fetchImagesToken } from "../apis/fetch"

export const saveFirma =  async(data: any) => {    
    try {
        const resp = await fetchImagesToken("firmas", data, "POST");
        const body = await resp.json();
        if (body.status) {
            return body;
        }
    }catch(error){
        return error;
    }
}

export const deleteFirma =  async(id: any) => {
    try {
        const resp = await fetchImagesToken(`firmas/${id}`, {}, 'DELETE');
        const body = await resp.json();
        if (body.status) {
            return body;
        }
    }catch(error){
        return error;
    }
}

export const getFirma = async(firma: any) => {
    try {
        const resp = await fetchImagesToken(`firmas/file/${firma}`, {}, 'GET');
        const body = await resp.json();
        if (body.status) {
            return body.firma;
        }
    }catch(error){
        return error;
    }
}