
import { useModalReporteBdB } from '../../hooks'
import { FormReporteScreenMdB } from './FormReporteScreenMdB';
import { CustomDialog } from '../../../../components/ui';

export const ModalReporteBdB = () => {
  const {
    err,
    formError,
    fullscreen,
    modalReporteBdB,
    registerData,
    registerDataDate,
    handleIniciar,
    handleFinalizar,
    onChange,
    onDateChange,
    handleClose,
    handleSave
  } = useModalReporteBdB();

  return (
    <CustomDialog
      onClose={handleClose}
      onSave={handleSave}
      openModal={modalReporteBdB}
      err={err}
      fullscreen={fullscreen}
      title="Formulario Servicios BdB"
    >
      <FormReporteScreenMdB
        formError={formError}
        onChange={onChange}
        onDateChange={onDateChange}
        registerData={registerData}
        registerDataDate={registerDataDate}
        handleFinalizar={handleFinalizar}
        handleIniciar={handleIniciar} />

    </CustomDialog>
  )
}
