import { ActividadAction, ActividadState } from '../interfaces/actividad';

const initState: ActividadState = {
    actividades: [],
    activeActividad: null,
    actividadId: null,
    modalActividad: false,
    tipoActividad: 'Mantenimiento Locativo',
    modalImagesActividadBdb: false
};

export const actividadReducer = (state = initState, action: ActividadAction): ActividadState => {
    switch (action.type) {
        case 'set-actividad-bdb':
            return {
                ...state,
                actividades: [...state.actividades, action.payload]
            };
        case 'update-actividad-bdb':
            return {
                ...state,
                actividades: state.actividades.map(actividad => {
                    if (actividad.id === action.payload.id) {
                        return action.payload;
                    }
                    return actividad;
                })
            };
        case 'delete-actividad-bdb':
            return {
                ...state,
                actividades: state.actividades.filter(actividad => actividad.id !== action.payload)
            };
        case 'edit-actividad-bdb':
            return {
                ...state,
                actividades: state.actividades.map(actividad => {
                    if (actividad.id === action.payload) {
                        actividad.isNew = true;
                        return actividad;
                    }
                    return actividad;
                })
            };
        case 'set-modal-actividad-bdb':
            return {
                ...state,
                modalActividad: action.payload
            };
        case 'set-actividad-Active-bdb':
            const actividad = state.actividades.find(a => a.id === action.payload);
            return {
                ...state,
                activeActividad: actividad ? actividad: null
            };
        case 'set-actividad-update-image-bdb':
            return {
                ...state,
                actividades: state.actividades.map(a => {
                    if (a.id === action.payload.id) {
                        if(!a.images){
                            a.images = [];
                        }
                        a.images.push(action.payload.image);
                        return a;
                    }
                    return a;
                })
            };
            case 'set-delete-image-actividad-bdb':
                return {
                    ...state,
                    actividades: state.actividades.map((a) => {
                        if (a.id === action.payload.id) {
                            a.images = a.images!.filter(i => i.id !== action.payload.imgId);
                        }
                        return a;
                    }),
                };
                case 'set-clear-images-actividad-bdb':
            return {
                ...state,
                actividades: state.actividades.map(a => {
                    if (a.id === action.payload) {
                        a.images?.shift();
                    }
                    return a
                })
            }
        case 'set-actividad-bdb-id':
            return {
                ...state,
                actividadId: action.payload,
            };
        case 'set-actividad-empty-bdb':
            return {
                ...state,
                actividades: [],
                actividadId: null
            };
        case 'set-tipo-actividad-bdb':
            return {
                ...state,
                tipoActividad: action.payload
            }
            case 'set-modal-imagenes-actividad-bdb':
                return {
                    ...state,
                    modalImagesActividadBdb: action.payload
                }
        default:
            return state;
    }
}