import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { StepFive } from './StepFive';
import { StepSix } from './StepSix';
import { StepSeven } from './StepSeven';
import { StepTen } from './StepTen';
//import { ResponsableCliente } from '../../../../shared/responsable_cliente/ResponsableCliente';
import { StepEight } from './StepEight';
import { StepNine } from './StepNine';
import { StepEleven } from './eleven/StepEleven';
import { StepTwuelve } from './StepTwuelve';
import { Step } from '../../interfaces/reportes';
import { FirmaBeling } from './FirmaBeling';
import { FirmaCliente } from './FirmaCliente';
import { StepSevenA } from './StepSevenA';

export const steps: Step[] = [
	{
	  label: "Iniciar el servicio",
	  component: <StepOne />,
	},
	{
	  label: "Describa el personal que interviene en la actividad",
	  component: <StepTwo />,
	},
	{
	  label: "Describa los Elementos de Seguridad Industrial a utilizar mientras se realiza la Actividad",    
	  component: <StepThree />,
	},
	{
	  label: "Describa los Vehículos utilizados para el transporte del Personal y/o Herramientas",    
	  component: <StepFour />,
	},
	{
	  label: "Describa las Herramientas y/o Equipos utilizados",    
	  component: <StepFive />,
	},
	{
	  label: "Datos Generales de la Actividad",    
	  component: <StepSix />,
	},
	{
	  label: "Iniciar la Actividad",    
	  component: <StepSeven />,
	},
	{
		label: "Actividades realizadas",    
		component: <StepSevenA />,
	  },
	{
	  label: "Imágenes de antes de iniciar la Actividad",    
	  component: <StepEight />,
	},
	{
	  label: "Imágenes despúes de la Actividad",    
	  component: <StepNine />,
	},
	{
	  label: "Describa los Materiales Utilizados en la Actividad",    
	  component: <StepTen />,
	},
	{
	  label: "Quien ejecuto las Actividades por parte de BELING SAS",    
	  component: <FirmaBeling />,
	},
	{
	  label: "Quien recibe los trabajos realizados por parte del CLIENTE",    
	  component: <FirmaCliente />,
	},
	{
	  label: "Observaciones",    
	  component: <StepEleven />,
	},
	{
		label: "Finalización de la Actividad",    
		component: <StepTwuelve />,
	  },
  ];
  