import { RutinaAlarmaAction, RutinaAlarmaState } from "../interfaces/alarma";


const initState: RutinaAlarmaState = {
    rutinasAlarmas: [],
    activeRutinaAlarma: null,
    rutinaAlarmaId: '',
    modalAlarma: false,
    modalSelectRutinaAlarma: false,
    modalImagesRutinaAlarma: false
};

export const rutinaAlarmasReducer = (state = initState, action: RutinaAlarmaAction): RutinaAlarmaState => {
    switch (action.type) {
        case 'set-rutina-alarma':
            return {
                ...state,
                rutinasAlarmas: [...state.rutinasAlarmas, action.payload],
            };
        case "set-active-rutina-alarma":
            const rutina = state.rutinasAlarmas.find((rbb) => rbb.id === action.payload);
            return {
                ...state,
                activeRutinaAlarma: rutina ? rutina : null,
            };
        case 'update-rutina-alarma':
            return {
                ...state,
                rutinasAlarmas: state.rutinasAlarmas.map((rbb) => {
                    if (rbb.id === action.payload.id) {
                        return action.payload;
                    }
                    return rbb;
                }),
            };
        case 'delete-rutina-alarma':
            return {
                ...state,
                rutinasAlarmas: state.rutinasAlarmas.filter((rbb) => rbb.id !== action.payload),
            };
        case 'edit-rutina-alarma':
            return {
                ...state,
                rutinasAlarmas: state.rutinasAlarmas.map((rbb) => {
                    if (rbb.id === action.payload) {
                        rbb.isNew = false;
                    }
                    return rbb;
                }),
            };
        case 'set-rutina-update-image-alarma':
            return {
                ...state,
                rutinasAlarmas: state.rutinasAlarmas.map((rbb) => {
                    if (rbb.id === action.payload.id) {
                        if (!rbb.images) {
                            rbb.images = [];
                        }
                        rbb.images.push(action.payload.image)
                    }
                    return rbb;
                }),
            };
        case 'set-delete-image-rutina-alarmas':
            return {
                ...state,
                rutinasAlarmas: state.rutinasAlarmas.map((rbb) => {
                    if (rbb.id === action.payload.id) {
                        rbb.images = rbb.images!.filter(i => i.id !== action.payload.imgId);
                    }
                    return rbb;
                }),
            };
        case 'set-clear-images-rutina-alarmas':
            return {
                ...state,
                rutinasAlarmas: state.rutinasAlarmas.map(rbb => {
                    if (rbb.id === action.payload) {
                        rbb.images?.shift();
                    }
                    return rbb
                })
            }
        case 'set-rutina-alarma-id':
            return {
                ...state,
                rutinaAlarmaId: action.payload,
            };
        case 'set-rutina-modal-alarma':
            return {
                ...state,
                modalAlarma: action.payload,
            };
        case 'set-modal-select-rutina-alarma':
            return {
                ...state,
                modalSelectRutinaAlarma: action.payload
            }
        case 'set-modal-imagenes-rutina-alarmas':
            return {
                ...state,
                modalImagesRutinaAlarma: action.payload
            }
        case 'set-rutina-alarma-empty':
            return {
                ...state,
                rutinasAlarmas: [],
                rutinaAlarmaId: '',
            };
        default:
            return state;
    }
}