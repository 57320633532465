import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
	handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
	handleSelect: React.MouseEventHandler<HTMLButtonElement> | undefined;
	label: string
	modal: boolean;
	name: string
	onChangeSelect: (e: SelectChangeEvent<string>) => void;
	title: string
	value: string;
	values: any[];
}

export const ModalSelect = ({
	label,
	modal,
	name,
	title,
	value,
	values,
	handleClose,
	handleSelect,
	onChangeSelect
}: Props) => {
	return (
		<Dialog open={modal} onClose={handleClose} fullWidth>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Seleccione un item de la lista
				</DialogContentText>
				<FormControl fullWidth sx={{ mt: 2 }}>
					<InputLabel id={`${value}-select-label`}>{label} *</InputLabel>
					<Select
						labelId={`${value}-select-label`}
						id={name}
						value={value}
						label={label}
						name={name}
						required
						onChange={onChangeSelect}
					>
						<MenuItem disabled value="">
						</MenuItem>
						{values.map((v) => (
							<MenuItem
								key={v.id}
								value={v.id}
							//style={getStyles(name, personName, theme)}
							>
								{v.nombre}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancelar</Button>
				<Button onClick={handleSelect}>Seleccionar</Button>
			</DialogActions>
		</Dialog>
	)
}
