
import { Sst } from '../../../interfaces/sst';
import { BodyLine, HeadLine } from '../shared';


interface Props {
    active: Sst;
}

export const Asistentes = ({ active }: Props) => {
    return (
        <>
            <HeadLine title='ASISTENTES' />
            <BodyLine
                title1='OPERATIVO:'
                title2='ADMINISTRATIVO:'
                content1={active.asistentes.operativos}
                content2={active.asistentes.administrativos}
            />
        </>
    )
}
