import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../../reducers/rootReducer";
import { starLoadContactos } from "../../contactos/actions/contactos";
import { GridRowId } from '@mui/x-data-grid';
import { getColor } from '../../../helpers/getColor';
import { startAddSelloFisico, startClearSelloFisico } from "../../../shared/sello_fisico/actions/images";
import { startLoadUsers, startSetActiveUser, startSetIsSameUser, startSetModalChangePass, startSetModalUser } from "../actions/user";

interface InitState {
	id: GridRowId;
	nombre: string
}

export const useUserProfile = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [detailValues, setDetailValues] = useState<InitState>({
		id:'',
		nombre:''
	})
	const [bgcolor, setColor] = useState('');	
    const { user } = useSelector((state: AppState)=> state.auth)
	const { users } = useSelector((state: AppState) => state.usuarios);
	const {id, nombre} = detailValues;
	const { contactos } = useSelector((state: AppState) => state.contactos);

	const clienteContactos = contactos.filter(c => c.cliente === id);
	const avatarLetter = nombre.substring(0,1)

	const handleEditUser = () =>{
		dispatch(startSetActiveUser(id));
		dispatch(startSetIsSameUser(true));
		dispatch(startSetModalUser(true));
	}

	const handeleChangePassword = () =>{
		dispatch(startSetIsSameUser(true));
		dispatch(startSetModalChangePass(true));
	}

	useEffect(() => {
		if (users.length === 0) {
		  dispatch(startLoadUsers());
		}
	  }, [dispatch, users]);
	
	useEffect(() => {
	  if(!user){
		navigate('/dashboard',{replace: false})
	  }else{
		setDetailValues({
			id: user?.uid,
			nombre: user.name
		})
		setColor(getColor())
	  }
	},[user, navigate])
	
	useEffect(()=>{
		if(contactos && contactos.length > 0) return;
		dispatch(starLoadContactos())
	},[contactos, dispatch])

	useEffect(()=>{
		if(user?.picture){
			dispatch(startAddSelloFisico(user.picture))
		}

		return () => {
			dispatch(startClearSelloFisico())
		  };
	},[user?.picture, dispatch])

  return {
	...user,
	avatarLetter,
	bgcolor,
	contactos: clienteContactos,
	nombre,
	handleEditUser,
	handeleChangePassword
  }
}
