import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import img from '../../../assets/img/bg.png';
import { deleteImagen, saveImagen } from '../../../helpers/imagen';
import { AppState } from '../../../reducers/rootReducer';
import { startAddSelloFisico, startDeleteSelloFisico, startSetModalSelloFisico } from '../actions/images';
import { doMessage, startLoading } from '../../../modules/ui/actions/ui';
import { useScreenSize } from '../../../hooks';



export const useModalSelloFisico = () => {

  const dispatch = useDispatch();
  const { width } = useScreenSize();
  const [imgWidth, setImgWidth] = useState<number>(220)
  const [foto, setFoto] = useState(img)
  const { sello: { modalSelloFisico, selloFisico }, shared: { props } } = useSelector((state: AppState) => state.shared)

  const handleClose = () => {
    dispatch(startSetModalSelloFisico(false));    
  };

  const handelLoadImage = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFoto(reader.result as string);
    }
  }

  const handleSave = async () => {
    console.log(props)
    dispatch(startLoading());
    const data = {
      imagen: foto,
      servicio: props?.servicio,
      name: props?.name,
      descripcion: props?.descripcion,
    };
    if(selloFisico){
      console.log(selloFisico);      
      deleteImagen(selloFisico.id);
      dispatch(startDeleteSelloFisico())
    }

    const resp = await saveImagen(data)

    console.log(resp)

    if (resp.status) {
      dispatch(startAddSelloFisico(resp.imagen))
      dispatch(startSetModalSelloFisico(false));
      setFoto(img)
    } else {
      dispatch(doMessage({ message: 'Error guardando la imagen', type: 'error' }))
    }
    dispatch(startLoading());
  }

  useEffect(() => {
    if (width > 843) {
      setImgWidth(220)
    } else {
      setImgWidth(120)
    }
  }, [width])

  useEffect(() => {
    if (selloFisico) {
      setFoto(selloFisico.uri)
    } else {
      setFoto(img)
    }
  }, [selloFisico])

  return {
    descripcion: props?.descripcion ?? 'Sello Físico',
    foto,
    imgWidth,
    modalSelloFisico,
    handleSave,
    handelLoadImage,
    handleClose
  }
}
