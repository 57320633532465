import moment from 'moment';
import 'moment/locale/es';
moment.locale('es-co');

export const dateShort = (fecha: string | Date) => {
    return moment(fecha).format('DD/MM/YYYY');
}

export const dateShortTwo = (fecha: string | Date) => {
    return moment(fecha).format('DD-MM-YYYY');
}

export const dateShortThree = (fecha: string | Date) => {
    return moment(fecha).format('DDMMYYYY');
}

export const dateShortFour = (fecha: string | Date) => {
    return moment(fecha).format('DD/MM/YY');
}

export const getDurationHhMmSs = (fechaInicio: string | number , fechaFin: string | number) => {
    const duration = moment.duration(moment(fechaFin).diff(moment(fechaInicio)))
    const horas = `${duration.hours()}:h `;
	const minutos = `${duration.minutes()}:m `;
    const segundos = `${duration.seconds()}:s `;
    return `${horas} ${minutos} ${segundos}`;
}

export const getDurationHhMm = (fechaInicio: string | number , fechaFin: string | number) => {
    const duration = moment.duration(moment(fechaFin).diff(moment(fechaInicio)))
    const horas = `${duration.hours()}:h `;
	const minutos = `${duration.minutes()}:m `;
    return `${horas} ${minutos}`;
}

export const getDurationDays =(fechaInicio: string | number , fechaFin: string | number) => {
    const duration = moment(fechaInicio).diff(moment(fechaFin), 'days')
    const dias = `${duration}`
    return `${dias}`
}

export const getFinalDate = (fecha: string | Date, duration: number | string)=>{
    const res = moment(fecha).add(duration, 'd');
    return moment(res).format('DD/MM/YYYY')
}

export const fechaHora = (fecha: string | number | Date ) => {
    return moment(fecha).format('DD/MM/YYYY HH:mm:ss');
}

export const fechaDetalle = (fecha: string | number) => {
    return moment(fecha).format('ll, h:mm a')
}

export const getHora = (fecha: string | number)=>{
    return moment(fecha).format('h:mm:ss a')
}

export const getDay = (fecha: string | Date)=>{
    return moment(fecha).format('dddd');
}

export const dayTime = (timeStamp: number)=>{
 return moment.unix(timeStamp).toDate();
}