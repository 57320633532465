import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
		display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'left',
        flexGrow: 1,
    },
	cellContainer:{
		flexDirection: 'row',
		width: '100%',
		flexGrow: 1,
	},
    textContainer: {
		paddingLeft: 5,
		textAlign: 'left',
		flexWrap: 'nowrap'		
    }
  });

interface Props{
	text: string;
	sx?:{
		color?: string;
		fw?: 'bold' | 'extrabold' | 'light' | 'normal',
		fz?: number;
		mb?: number;
		mt?: number;
	}
}

export const ListItems = ({ sx, text}: Props) => {
	const { fz, fw, mb, mt, color } = sx ?? {
		fz: 10,
		fw: 'bold',
		mb:0,
		mt: 0
	};
	return(
    <View style={[styles.container,{marginBottom: mb, marginTop: mt}]}>
		<View style={styles.cellContainer}>
			<Text style={[styles.textContainer,{fontWeight: fw, fontSize: fz, color: color}]}>{text}</Text>
		</View>
    </View>
  );}