import { MeteorologicaAction, MeteorologicaState } from "../interfaces/meteorologicas";



const initState: MeteorologicaState = {
    meteorologicas: [
        { id: 1, name: 'Lluvia Fuerte', status: 'No', isNew: false },
        { id: 2, name: 'Lluvia Moderada', status: 'No', isNew: false },
        { id: 3, name: 'Sin lluvia', status: 'Si', isNew: false },
        { id: 4, name: 'Buen tiempo', status: 'Si', isNew: false },
    ],
    meteorologica: null
}

export const meteorologicaReducer = (state = initState, action: MeteorologicaAction): MeteorologicaState => {
    switch (action.type) {
        case 'set-meteorologicas':
            return {
                ...state,
                meteorologicas: action.payload
        }
        case 'update-meteorologica':
            return {
                ...state,
                meteorologicas: state.meteorologicas.map(meteorologica => {
                    if (meteorologica.id === action.payload.id) {
                        return action.payload;
                    }
                    return meteorologica;
                })
            }
        case 'set-meteorologica-init-state':
            return{
                ...state,
                meteorologicas: initState.meteorologicas
            }
        default:
            return state
    }
}