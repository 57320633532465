import { Grid } from '@mui/material'
import logo from '../../assets/img/beling_lg.png';
export const LoginBackGround = () => {     
  return (
	<Grid
          item
          xs={false}
          sm={6}
          md={8}          
          sx={{
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
  )
}
