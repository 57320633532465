
import { Box, FormControl, Grid, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DateTimeField, esES } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from "date-fns/locale";

import { Material } from '../../../../shared/material/components/Material';
import { Equipo } from '../../../../shared/equipo_seguridad/components/Equipo';
import { Herramienta } from '../../modules/herramienta/components/Herramienta';
import { Personal } from '../../modules/personal/components/Personal';
import { Vehiculos } from '../../modules/vehiculo/components/Vehiculos';
import { Titles } from '../shared/Titles';
import { ActividadNoComtemplada } from '../../modules/actividad_no_contemplada/components/ActividadNoComtemplada';
import { Actividad } from '../../modules/actividad/components/Actividad';
import { AcompanaCliente } from '../../../../shared/acompana_cliente/components/AcompanaCliente';
import { TipoActividad } from '../../../../shared/tipo_actividad/components/TipoActividad';
import { PositionButton } from '../shared/PositionButton';
import { FirmaBeling } from '../basico/FirmaBeling';
import { FirmaCliente } from '../basico/FirmaCliente';
import { useMobileDetect } from '../../../../hooks/useMobileDetect';


interface Props {
  formError: boolean,
  registerData: {
    alcanceActividad: string,
    observaciones: string,
  };
  registerDataDate: {
    fecha: Date,
    fechaHoraInicio: Date,
    fechaHoraFinal: Date
  };
  onChange: any;
  onDateChange: any;
  handleIniciar: any;
  handleFinalizar: any;

}

export const FormReporteScreenMdB = ({
  formError,
  registerData,
  registerDataDate,
  handleIniciar,
  handleFinalizar,
  onChange,
  onDateChange,
}: Props) => {

  const { isMobile } = useMobileDetect();
  const { alcanceActividad, observaciones } = registerData;
  const { fechaHoraFinal, fechaHoraInicio } = registerDataDate;

  return (
    <Box
      width="100%"
      component="form"
      sx={{ my: 2, mx: 'auto', maxWidth: '90vw' }}
      noValidate
    >

      <Titles
        align='left'
        mt={0}
        text="Describa el tipo de servicio y quien acompaña la actividad por parte del CLIENTE"
      />
      <AcompanaCliente />
      <Titles
        align='left'
        text="Tipo de Actividad"
      />
      <TipoActividad />
      <Personal />
      <Titles
        text="Describa los Vehículos utilizados para el transporte del Personal y/o Herramientas"
      />
      <Vehiculos mt={0} />
      <Titles
        text="Describa las Herramientas y/o Equipos a utilizar en la actividad a realizar"
      />
      <Herramienta mt={0} />
      <Titles
        text="Describa los Elementos de Seguridad Industrial a utilizar mientras se realiza la Actividad"
      />
      <Equipo mt={0} />
      <Titles
        text="Describa los Materiales a utilizar en la Actividad"
      />
      <Material mt={0} />
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} sm={6}>
          <PositionButton text='iniciar' onClick={handleIniciar} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={es} dateAdapter={AdapterDateFns}>
              {isMobile ?
                <MobileDateTimePicker
                  label="Fecha y Hora inicio"
                  value={fechaHoraInicio}
                  onChange={(newValue) => {
                    onDateChange(newValue, "fechaHoraInicio");
                  }}
                /> :
                <DateTimeField
                  label="Fecha y Hora inicio"
                  required
                  value={fechaHoraFinal}
                  onChange={(newValue) => {
                    onDateChange(newValue, "fechaHoraFinal");
                  }}
                />}
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Titles
        align='left'
        text="Describa el Tipo de Servicio y Alcance de los trabajos a realizar"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            autoComplete="alcanceActividad"
            fullWidth
            id="alcanceActividad"
            label="Descripción de las actividades a realizar"
            margin="normal"
            multiline
            name="alcanceActividad"
            onChange={onChange}
            required
            rows={3}
            error={alcanceActividad.length < 5 && formError}
            helperText={alcanceActividad.length < 5 && formError ? "La descripción es un campo obligatorio" : ""}
            value={alcanceActividad}
          />
        </Grid>
      </Grid>
      <Titles
        align='left'
        text="Enumere las actividades a realizar dentro del listado de Actividades del BdB"
      />
      <Actividad />
      <Titles
        text="Describa las actividades NO CONTEMPLADAS en el listado de Actividades del BdB"
      />
      <ActividadNoComtemplada mt={0} />
      <Titles
        align='left'
        text="Quien Ejecuta la Actividad por parte de BELING SAS"
      />
      <FirmaBeling />
      <Titles
        align='left'
        text="Quien Recibe los Trabajos Realizados en Sitio por parte del cliente"
      />
      <FirmaCliente />
      <Titles
        align='left'
        text="Conclusiones y Recomendaciones"
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            autoComplete="observaciones"
            multiline
            rows={3}
            fullWidth
            id="observaciones"
            label="Conclusiones y Recomendaciones"
            margin="dense"
            name="observaciones"
            onChange={onChange}
            required
            value={observaciones}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} sm={6}>
          <PositionButton text='finalizar' onClick={handleFinalizar} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={es} dateAdapter={AdapterDateFns}>
              {isMobile ?
                <MobileDateTimePicker
                  label="Fecha y Hora de finalización"
                  value={fechaHoraFinal}
                  onChange={(newValue) => {
                    onDateChange(newValue, "fechaHoraFinal");
                  }}
                /> :
                <DateTimeField
                  label="Fecha y Hora de Finalización"
                  required
                  value={fechaHoraFinal}
                  onChange={(newValue) => {
                    onDateChange(newValue, "fechaHoraFinal");
                  }}
                />}
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
