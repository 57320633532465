import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Typography
} from '@mui/material'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { es } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES } from '@mui/x-date-pickers/locales';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';

import { useObraModal } from '../hooks/useObraModal'
import { SelectComponent } from '../../../components/select/SelectComponent';
import { DateField} from '@mui/x-date-pickers';
import { Transition } from '../../../components';

export const ModalObra = () => {

    const {
        activeObra,
        clientes,
        contactos,
        isMobile,
        modalObra,
        registerData,
        registerSelectData,
        registerDataDate,
        handleClose,
        handleSave,
        onChange,
        onChangeSelect,
        onDateChange,
    } = useObraModal();

    const { descripcion, plazo } = registerData;
    const { fechaContrato, fechaInicio } = registerDataDate;
    const { cliente, contacto } = registerSelectData;

    return (
        <>
            <Dialog
                open={modalObra}
                onClose={handleClose}
                fullWidth
                fullScreen={isMobile}
                maxWidth="md"
                TransitionComponent={Transition}
            >
                <DialogTitle>
                    <Box
                        sx={{
                            my: 1,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {!isMobile && <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <EngineeringOutlinedIcon />
                        </Avatar>}
                        <Typography component="h1" variant={isMobile ? "h6" : "h5"}>
                            {activeObra
                                ? `Editar Obra No ${activeObra.numero}`
                                : "Agregar Obra"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box width="100%" component="div" sx={{ mt: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth sx={{ mt: 1, mx: 1 }} size="small">
                                    <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={es} dateAdapter={AdapterDateFns}>
                                        {isMobile ?
                                            <MobileDatePicker
                                                label="Fecha del Contrato"
                                                value={fechaContrato}
                                                onChange={(newValue) => {
                                                    onDateChange(newValue, "fechaContrato");
                                                }}
                                            /> :
                                            <DateField
                                                label="Fecha del Contrato"
                                                required
                                                value={fechaContrato}
                                                onChange={(newValue) => {
                                                    onDateChange(newValue, "fechaContrato");
                                                }}
                                            />}
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth sx={{ mt: 1, mx: 1 }} size="small">
                                    <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={es} dateAdapter={AdapterDateFns}>
                                        {isMobile ?
                                            <MobileDatePicker
                                                label="Fecha Inicio"
                                                value={fechaInicio}
                                                onChange={(newValue) => {
                                                    onDateChange(newValue, "fechaInicio");
                                                }}
                                            /> :
                                            <DateField
                                                label="Fecha Inicio"
                                                required
                                                value={fechaInicio}
                                                onChange={(newValue) => {
                                                    onDateChange(newValue, "fechaInicio");
                                                }}
                                            />}
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl fullWidth sx={{ mt: 1, mx: 1 }}>
                                    <TextField
                                        margin="none"
                                        value={descripcion}
                                        onChange={onChange}
                                        multiline
                                        rows={2}
                                        fullWidth
                                        id="descripcion"
                                        label="Descripción"
                                        name="descripcion"
                                        autoComplete="descripcion"
                                        inputProps={{ tabIndex: 13 }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectComponent
                                    value={cliente}
                                    values={clientes}
                                    label="Cliente"
                                    name="cliente"
                                    index={3}
                                    onChange={onChangeSelect}
                                    isDisabled={activeObra ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth sx={{ mt: 1, mx: 1 }}>
                                    <TextField
                                        margin="none"
                                        value={plazo}
                                        onChange={onChange}
                                        fullWidth
                                        id="plazo"
                                        label="Plazo"
                                        name="plazo"
                                        autoComplete="plazo"
                                        inputProps={{ tabIndex: 7 }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectComponent
                                    value={contacto}
                                    values={contactos}
                                    label="Contacto"
                                    name="contacto"
                                    index={4}
                                    onChange={onChangeSelect}
                                    isDisabled={contactos?.length === 0}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} tabIndex={14}>Cancelar</Button>
                    <Button onClick={handleSave} tabIndex={15}>
                        {activeObra ? "Actualizar" : "Guardar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
