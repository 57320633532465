import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { AppState } from '../../../../../reducers/rootReducer'

import { Gallery } from '../../../../../shared/gallery/Gallery';
import { GridRowId } from '@mui/x-data-grid';
import { deleteImagen } from '../../../../../helpers/imagen';
import { doMessage } from '../../../../ui/actions/ui';
import { startDelImgActividadBdB, startSetModImgActividadBdB } from '../action/actividad';


export const ModalImagesActividadBdB = () => {
	const dispatch = useDispatch();
	const {modalImagesActividadBdb, activeActividad} = useSelector((state: AppState)=> state.reportes.modules.actividad);

    const id = activeActividad!.id ?? 0;
    const images = activeActividad!.images ?? [];

	const handleClose = ()=>{
		dispatch(startSetModImgActividadBdB(false));
	}

    const handleDeleteImagen = async (imgId: GridRowId)=>{        
        const dele = await deleteImagen(imgId)
        if(dele.status){
            dispatch(startDelImgActividadBdB( imgId, id))
        }else{
            dispatch(doMessage({message: 'Error guardando la imagen', type:'error'}))
        }
    }
	return (
        <>
            <Dialog open={modalImagesActividadBdb} onClose={handleClose}>
                <DialogTitle>Imágenes</DialogTitle>
                <DialogContent>
                 <Gallery images={images!} handleDelete={handleDeleteImagen}/>   
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
