import { v4 as uuidv4 } from 'uuid';
import { Feature, GeocodeResponse } from "../interfaces/mapboxgeocode";

const mapboxUrl = "https://api.mapbox.com/geocoding/v5/mapbox.places/"
const maboxApiKey = process.env.REACT_APP_API_TOKEN_MAPBOX!;

interface Props {
	request: Input,
	callback: any
}

interface Input {
	input: string
}

export const getMapboxGeoCode = async ({ request, callback }: Props) => {

	const resMabox = await fetch(`${mapboxUrl}${request.input}.json?country=co&limit=5&language=es&access_token=${maboxApiKey}`);
	const places = await resMabox.json() as GeocodeResponse;

	const { features } = places;

	callback(features.map((f: Feature) => {
		const id = uuidv4();
		const address = f.place_name;
		const mainText = address.split(',', 1)[0];
		const splitAddress = address.split(',');
		const secondary = splitAddress.slice(1).join(',').trim();
		const matchOne = {
			length: mainText.length - 1,
			offset: 0
		}

		const terms = splitAddress.map((s: any, index: any) => {
			const offset = index === 0 ? 0 : s.length;
			return {
				offset,
				value: s
			}
		})

		const matchTow = {
			length: address.split(',', 1)[0].length,
			offset: terms[1]?.offset,
		}

		return {
			center: [
				f.geometry.coordinates[0],
				f.geometry.coordinates[1]
			],
			description: address,
			structured_formatting: {
				main_text: mainText,
				main_text_matched_substrings: [
					matchOne,
					matchTow
				],
				secondary_text: secondary
			},
			matched_substrings: {
				length: mainText.length,
				offset: 0
			},
			place_id: id,
			reference: id,
			terms
		}

	}))	

}
