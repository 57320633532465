import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';

import logo from '../../../../../assets/img/beling_other.png'
import { Ats } from '../../../interfaces/ats';
import { dateShort, toString } from '../../../../../helpers';

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
		border: 1
	},
	rowContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '105%',
		height: 30
	},
	column: {
		flexDirection: 'column',
		padding: 5,
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	lefColumn: {
		width: '30%',
	},
	centerColumn: {
		justifyContent: 'center',
		alignItems: 'center',
		width: '45%',
		borderLeft: 1,
		borderRight: 1
	},
	rightColumn: {
		width: '25%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	centralTitle: {
		fontSize: 26,
		fontWeight: 'bold'
	},
	centralBottomTitle: {
		fontSize: 10,
		fontWeight: 'bold'
	},
	centralBottomSubtitle: {
		fontSize: 9,
		fontWeight: 'bold'
	},
	rowRight: {
		flexDirection: 'row',
		width: '100%',

	},
	rightTitle: {
		fontSize: 8,
		fontWeight: 'normal',
		textAlign: 'right'
	},
	rightText: {
		fontSize: 8,
		fontWeight: 'bold',
		textAlign: 'right'
	},
	logo: {
		maxWidth: 150,
		objectFit: 'fill',
	}
})

interface Props {
	active: Ats;
	firts?: boolean
}

export const LogoHeader = ({ active, firts = true }: Props) => {
	return (
		<>
			<View style={styles.row}>
				<View style={[styles.lefColumn, styles.column]}>
					<Image src={logo} style={styles.logo} />
				</View>
				<View style={[styles.centerColumn, styles.column]}>
					<View style={[styles.rowContainer, { borderBottom: 1 }]}>
						<Text style={styles.centralBottomTitle}>ANALISIS DE TRABAJO SEGURO - ATS</Text>
					</View>
					<View style={[styles.rowContainer]}>
						<Text style={styles.centralBottomSubtitle}>{active.servicio?.cliente?.nombre}</Text>
					</View>
				</View>
				<View style={[styles.rightColumn]}>
					<View style={[styles.rowRight, { borderBottom: 1 }]}>
						<View style={[styles.column, {width: '45%'}]}>
							<View style={[styles.rowRight]}>
								<Text style={styles.rightTitle}>ATS No:</Text>
							</View>
						</View>
						<View style={[styles.column]}>
							<View style={[styles.rowRight]}>
								<Text style={styles.rightText}>{toString(active.numero!)}</Text>
							</View>
						</View>
					</View>
					<View style={[styles.rowRight, { borderBottom: 1 }]}>
						<View style={[styles.column, {width: '45%'}]}>
							<View style={[styles.rowRight]}>
								<Text style={styles.rightTitle}>Servicio No:</Text>
							</View>
						</View>
						<View style={[styles.column]}>
							<View style={[styles.rowRight]}>
								<Text style={styles.rightText}>{toString(active.servicio?.numero!)}</Text>
							</View>
						</View>
					</View>
					<View style={[styles.rowRight]}>
						<View style={[styles.column, {width: '45%'}]}>
							<View style={[styles.rowRight]}>
								<Text style={styles.rightTitle}>Fecha:</Text>
							</View>
						</View>
						<View style={[styles.column]}>
							<View style={[styles.rowRight]}>
								<Text style={styles.rightText}>{ dateShort(active.fecha!)}</Text>
							</View>
						</View>
					</View>
				</View>
			</View>
		</>
	)
}
