import { Route, Routes } from "react-router-dom";
import { AtsScreen } from "./AtsScreen";

export const AtsRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/ats" element={<AtsScreen />} /> 
        <Route path="/" element={<AtsScreen />} />
      </Routes>
    </>
  ); 
};
