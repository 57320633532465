import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';

import logo from '../../../../../../assets/img/beling_other.png'
import { Informe } from '../../../../interfaces/informes';
import { getImagen } from '../../../../../../helpers/imagen';



const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
		border: 1
	},
	rowContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '105%',
		height: 30
	},
	column: {
		flexDirection: 'column',
		padding: 5,
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	lefColumn: {
		width: '30%',
	},
	centerColumn: {
		justifyContent: 'center',
		alignItems: 'center',
		width: '30%',
		borderLeft: 1,
		borderRight: 1
	},
	rightColumn: {
		width: '40%',
		height: '100%',
	},
	centralTitle: {
		fontSize: 26,
		fontWeight: 'bold'
	},
	centralBottomTitle: {
		fontSize: 12,
		fontWeight: 'bold'
	},
	centralBottomSubtitle:{
		fontSize: 9,
		fontWeight: 'bold'
	},
	logo: {
		width: 150,
		objectFit: 'fill',
	}
})

interface Props {
	active: Informe;
	firts?: boolean
}

export const LogoHeader = ({ active, firts = true }: Props) => {
	const clienteLogo = active.obra.cliente.logo.name;
	return (
		<>
			<View style={styles.row} fixed>
				<View style={[styles.lefColumn, styles.column]}>
					<Image src={logo} style={styles.logo} />
				</View>
				{
					firts
						? <View style={[styles.centerColumn, styles.column]}>
							<Text style={styles.centralTitle}>SBOGZB01</Text>
						</View>
						: <View style={[styles.centerColumn, styles.column]}>
							<View style={[styles.rowContainer, { borderBottom: 1 }]}>
								<Text style={styles.centralBottomTitle}>INFORME FOTOGRÁFICO</Text>
							</View>
							<View style={[styles.rowContainer]}>
								<Text style={styles.centralBottomSubtitle}>Gestión de la construcción</Text>
							</View>
						</View>
				}
				<View style={[styles.column, styles.rightColumn]}>
					<Image src={getImagen(clienteLogo!)} style={styles.logo} />
				</View>
			</View>
		</>
	)
}
