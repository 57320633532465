

import React from 'react'
import { Reporte } from '../../../interfaces/reportes';
import { View } from '@react-pdf/renderer';
import { TitleTables } from '../../../../../components/pdf/TitleTables';
import { getGrupoImages, getImagesRutinas } from '../../../../../helpers';
import { BodyImagenLines, HeadLine } from '../../../../sst/components/pdf/shared';

interface Props {
	active: Reporte;
}
export const ImagesRutinaAlarma = ({active}: Props) => {	
	const images = getImagesRutinas(active.rutinaAlarmas!);
	const grupo = getGrupoImages(images);
  return (
	<View>
		<TitleTables
        title='Imagenes Rutina Alarmas'
        sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
      />
	  {
				Object.keys(grupo).map((key, index) => (
					<View key={index} wrap={false}>
						<HeadLine title={key} sx={{mt: 2, bw: 1, color: 'black', fz: 8}} />
						<BodyImagenLines grupo={grupo[key]} sx={{bw: 1}} />
					</View>
				))
			}
	</View>
  )
}
