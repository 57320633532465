import { GridRowId } from "@mui/x-data-grid";
import { Bateria, BateriaAction } from "../interfaces/baterias";

export const startSetBateriasInfo = (bateria: Bateria): BateriaAction => ({
    type: "set-baterias-info",
    payload: bateria
});

export const startUpdateBateriasInfo = (bateria: Bateria): BateriaAction => ({
    type: "update-baterias-info",
    payload: bateria
});

export const startDeleteBateriasInfo = (id: GridRowId): BateriaAction => ({
    type: "delete-baterias-info",
    payload: id
});

export const startEditBateriasInfo = (id: GridRowId): BateriaAction => ({
    type: "edit-baterias-info",
    payload: id
});

export const startSetBateriasEmpty = (): BateriaAction => ({
    type: "set-baterias-empty"
});
// Compare this snippet from src/modules/reportes/modules/baterias_info/interfaces/baterias.ts: