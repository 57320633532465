import { fetchImagesToken } from "../apis/fetch"
import { Imagen } from "../interfaces/image";
import { ActividadNoPlaneada, Rutina } from "../modules/reportes/interfaces/reportes";

export const saveImagen =  async(data: any) => {    
    try {
        const resp = await fetchImagesToken("imagenes", data, "POST");
        const body = await resp.json();     
        if (body.status) {
            return body;
        }
    }catch(error){
        return error;
    }
}

export const deleteImagen =  async(id: any) => {
    try {
        const resp = await fetchImagesToken(`imagenes/${id}`, {}, 'DELETE');
        const body = await resp.json();        
        return body; 
    }catch(error){
        return error;
    }
}

export const getImagen = async(query: string) => {
    try {
        const resp = await fetchImagesToken(`imagenes/file/${query}`, {}, 'GET');
        const body = await resp.json();        
        if (body.status) {
            return body.firma;
        }
    }catch(error){
        console.log(error)
        return error;

    }
}

export const hasImages = (rutinas: any[]) => {
	let total = 0;
	rutinas.forEach(i =>{		
		if(i.images){
			total += 1
		}else{
			total -=1
		}
	})

	return total;
}

export const getImagesRutinas = (rutinas : Rutina[]) => {
    let images: Imagen[] = [];

	rutinas?.forEach(r =>{
		if(!r.images) return;
		r.images.forEach(i =>{
			const img ={
				description : r.nombre,
				id: i.id,
				name: i.name,
				servicio: i.servicio,
				uri: i.uri
			}
			images.push(img)
		})
	})
	
    return images;
}

export const getImagesActividades = (rutinas : ActividadNoPlaneada[]) => {
    let images: Imagen[] = [];

	rutinas?.forEach(r =>{
		if(!r.images) return;
		r.images!.forEach(i =>{
			const img ={
				description : r.actividad,
				id: i.id,
				name: i.name,
				servicio: i.servicio,
				uri: i.uri
			}
			images.push(img)
		})
	})
	
    return images;
}

export const getGrupoImages = (imagesDB: Imagen[]) => {
	const images: Imagen[] = imagesDB ?? [];
	let grupos: any = {};

	images.forEach(image => {
		const nombreGrupo = image.description!;
		if (!grupos[nombreGrupo]) grupos[nombreGrupo] = [];
		grupos[nombreGrupo].push(image)
	})

	return grupos
}
