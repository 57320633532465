import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';
import { startSetActiveItem, startSetIsNewItem, startSetModalItem } from '../actions/items';

export const ItemsToolBar = () => {
	const dispatch = useDispatch();
    const handleClick = () => {
		dispatch(startSetActiveItem(''));
        dispatch(startSetIsNewItem(true));
        dispatch(startSetModalItem(true));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Agregar Item
            </Button>
        </GridToolbarContainer>
    );
}