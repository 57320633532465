import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { Ats } from '../../../interfaces/ats';
import { Title } from "../../../../../components/pdf/Title";
import { RiesgoHead } from "../../../../../components/pdf/RiesgoHead";
import { RiesgoRow } from "../../../../../components/pdf/RiesgoRow";




const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        border: 1,
    },
});

const title = "Riesgos potenciales en sitio."
interface Props {
	active: Ats;
}
export const RiesgosEnSitio = ({ active }: Props) => {	
	return (
		<>				
			<Title title={title} sx={{fz: 12, fs: 'italic', fw: 'bold'}}/>
			<View style={styles.tableContainer}>
			<RiesgoHead />
			<RiesgoRow items={active.riesgosSitio!} />
			</View>	
		</>
	);
};
