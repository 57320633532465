import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
} from '@mui/x-data-grid';


import { AppState } from '../../../reducers/rootReducer';

import { startDeleteItemObra, startUpdateItemObra } from '../actions/item';
import { ItemObra } from '../interfaces/item.interface';
import { useScreenSize } from '../../../hooks';

export const useItemObraTable = () => {
    const dispatch = useDispatch();
    const {width} = useScreenSize();
    const locale = esES.components.MuiDataGrid.defaultProps.localeText;

    const { itemsObra } = useSelector((state: AppState) => state.obras.itemsObra);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
            
    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {        
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startDeleteItemObra(id));
    };



    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel<ItemObra>) => {
        const updatedRow = { ...newRow, isNew: false };
        dispatch(startUpdateItemObra(updatedRow));
        return updatedRow;
    };

    return {
        itemsObra,
        locale,        
        rowModesModel,
        width,      
        handleRowEditStart,
        handleRowEditStop,
        handleEditClick,
        handleSaveClick,
        handleDeleteClick,
        handleCancelClick,
        processRowUpdate,
        setRowModesModel,
    }
}