import {View, StyleSheet } from '@react-pdf/renderer';

import { Reporte } from '../../../interfaces/reportes';
import { Cell } from '../../../../../interfaces/shared';
import { HeaderTable, ItemsTable } from '../../../../informes/components/pdf/normal';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
	container: {
		marginTop: 2
	},
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const tableTitles: Cell[]  = [
	{name: "L1-L2"},
	{name: "L1-L3"},
	{name: "L2-L3"},
];

interface Props {
    active: Reporte;
  }

  export const VoltajeRectificadorTable = ({active}:Props) => {
    const { voltajeRectificador } = active;
    return (
		<View >
		<TitleTables
        title='Voltaje de Rectificador Entrada AC.'
        sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
      />		
			<View style={styles.container} >
				<View style={styles.descriptionContainer}>					
					<HeaderTable names={tableTitles} sx={{txa: 'center', bc: "#dddddd"}}/>
					{voltajeRectificador?.map((item, index) => {
						const fila: Cell[] = [							
							{ name: item.l1_l2, align: 'center' },
							{ name: item.l1_l3, align: 'center' },
							{ name: item.l2_l3, align: 'center' },
						]
						return <ItemsTable key={item.id} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom />
					})}					
				</View>
			</View>
		</View>
	)
  }
