import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	GridRowModesModel,
	GridRowModes,
	GridRowParams,
	MuiEvent,
	GridEventListener,
	GridRowId,
	GridRowModel,
	esES,
} from '@mui/x-data-grid';
import { AppState } from '../../../../../reducers/rootReducer';
import { startSetRutinaRectificadorId, startUpdateRutinasRectificador, startSetModalRectificador, startDeleteRutinasRectificador, startSetModImgRRectificador, startSetActiveRutinaRectificador } from '../actions/rutina';
import { useScreenSize } from '../../../../../hooks';
import imagen from '../../../../../assets/img/logo_beling.png';
import { RutinaRectificador } from '../interfaces/rutina';

export const useRutinaRTable = () => {
	const { width } = useScreenSize();
	const locale = esES.components.MuiDataGrid.defaultProps.localeText
	const dispatch = useDispatch();
	const { rutinasRectificador, activeRutinaRectificador, modalRectificador } = useSelector((state: AppState) => state.reportes.modules.rutinaRectificador);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

	const handleRowEditStart = (
		params: GridRowParams,
		event: MuiEvent<React.SyntheticEvent>,
	) => {
		event.defaultMuiPrevented = true;
	};

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true;
	};

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	};

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});
	};

	const hanleAddImageClick = (id: GridRowId) => () => {
		dispatch(startSetRutinaRectificadorId(id))
		dispatch(startSetModalRectificador(true));
	}

	const handleViewImages = (id: GridRowId) => () => {
		dispatch(startSetActiveRutinaRectificador(id));
		dispatch(startSetModImgRRectificador(true));
	}
	
	const processRowUpdate = (newRow: GridRowModel<RutinaRectificador>) => {
		const updatedRow = { ...newRow, isNew: false };
		dispatch(startUpdateRutinasRectificador(updatedRow));
		return updatedRow;
	};

	const handleDeleteClick = (id: GridRowId) => () => {
		dispatch(startDeleteRutinasRectificador(id));
	};

	return {
		activeRutinaRectificador,
		imagen,
		locale,
		modalRectificador,		
		rowModesModel,
		rutinasRectificador,
		width,
		hanleAddImageClick,
		handleCancelClick,
		handleDeleteClick,
		handleEditClick,
		handleRowEditStart,
		handleRowEditStop,
		handleSaveClick,
		handleViewImages,
		processRowUpdate,
		setRowModesModel,
	}
}