
import { View, StyleSheet } from '@react-pdf/renderer';
import { Condiciones as PropCondiciones, Informe } from "../../../../interfaces/informes";
import { ColCondicion, Jornada } from './fragments';
import { Condicion } from '../../../../../../interfaces/shared';

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minHeight: 24,
		textAlign: 'center',
		marginTop: -1,
		borderRight: 1,
		borderLeft: 1
	},
})

interface Props {
	active: Informe;
}

/* const clima: string[] = [
	'LLUVIA FUERTE',
	'LLUVIA MODERADA',
	'SIN LLUVIA',
	'BUEN TIEMPO',
]

const accidentes: string[] = [
	'NO HAY',
	'ACCIDENTE SIN BAJA',
	'ACCIDENTE CON BAJA',
	'CASI ACCIDENTE'
]

const sitio: string[] = [
	'OPERABLE',
	'PARCIALMENTE OPERABLE',
	'INOPERABLE',
	'HORAS HOMBRE TRABAJADAS'
] */

export const Condiciones = ({ active }: Props) => {
	const condiciones = setCondiciones(active.condiciones!)
	
	return (
		<>
			<View style={styles.row} fixed>
				<ColCondicion
					condiciones={condiciones.meteorologicas}
					title='CONDICIONES METEOROLÓGICAS (precipitación en mm)'
					sx={{ columnWidth: '31.16%' }}
					first
				/>
				<ColCondicion
					condiciones={condiciones.accidentes}
					title='ACCIDENTES'
					sx={{ columnWidth: '22.9%', ml: -2 }}
				/>
				<ColCondicion
					condiciones={condiciones.condicionesSitio}
					title='CONDICIONES DEL SITIO'
					sx={{ columnWidth: '26%', ml: -2 }}
				/>
				<Jornada active={active} />
			</View>
			)
		</>
	)
}

function setCondiciones(condiciones: PropCondiciones){
		const accidentes: Condicion[] = condiciones.accidentes.map((item)=>{
				return{
				id : item.id,
				name : item.name.toUpperCase(),
				status : item.cantidad,
				isNew : false
				}
		})
		
		const condicionesSitio: Condicion[] = condiciones.condicionesSitio.map((item)=>{
			return{
				id : item.id,
				name : item.name.toUpperCase(),
				status : getStatus(item.status),
				isNew : false
			}
		})

		const meteorologicas: Condicion[] = condiciones.meteorologicas.map((item)=>{
			return{
				id : item.id,
				name : item.name.toUpperCase(),
				status : getStatus(item.status),
				isNew : false
			}
		})
		return{
			accidentes,
			condicionesSitio,
			meteorologicas
		}
}

function getStatus(status: string| number | boolean){
	let cadena;
	if(Number.isInteger(status)){
		cadena = status;		
	}else if(!Number.isInteger(status)&& status.toString().toLowerCase()==='si'){
		cadena = 'X';		
	}else{
		cadena= ''
	}

	return cadena;
}
