import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { AppState } from '../../../reducers/rootReducer';
import { useForm } from '../../../hooks/useForm';
import { startCreateCliente, startSetModalCliente, startUpdateCliente } from '../actions/cliente';
import { Cliente } from '../interfaces/clientes';
import { AutoComplete } from '../../../components/autocomplete/Autocomplete';
import { PlaceType } from '../../../interfaces/google';
import { SelloFisico } from '../../../shared/sello_fisico/components/SelloFisico';
import { Props } from '../../../shared/shared_state/interfaces/shared';
import { startSetSharedProps } from '../../../shared/shared_state/actions/shared';

const initForm = {
    identificacion: '',
    nombre: '',
    email: '',
    telefono: '',
    contactoPrincipal: ''
}



export const ModalCliente = () => {
    const dispatch = useDispatch();
    const [direccion, setDireccion] = useState<PlaceType | null>(null)

    const { activeCliente, isNew, modalCliente } = useSelector((state: AppState) => state.clientes);
    const { selloFisico  } = useSelector((state: AppState) => state.shared.sello);

    const { onChange, changeData, identificacion, nombre, email, telefono, contactoPrincipal } = useForm(initForm);


    const handleClose = () => {
        dispatch(startSetModalCliente(false));
    };

    const handleSave = async () => {
        const cliente: Cliente = {
            identificacion,
            nombre,
            email,
            telefono,
            direccion,
            contactoPrincipal
        };

        if (isNew) {
            dispatch(startCreateCliente(cliente));
            console.log(cliente)
        } else {
            cliente.logo = selloFisico!;
            dispatch(startUpdateCliente(cliente));
            console.log(cliente)
        }
        handleClose();

    }

    useEffect(() => {
        if (isNew) {
            changeData(initForm);
            setDireccion(null)
        } else {
            if (!activeCliente) {
                handleClose()
            } else {                
                const formData = {
                    identificacion: activeCliente.identificacion,
                    nombre: activeCliente.nombre,
                    email: activeCliente.email,
                    telefono: activeCliente.telefono,
                    contactoPrincipal: activeCliente.contactoPrincipal,
                };
                changeData(formData);
                setDireccion(activeCliente.direccion)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNew, activeCliente]);

    useEffect(() => {
        if (activeCliente) {          
          const props: Props = {
            name: `cliente_${activeCliente.id!}`,
            servicio: `cliente_${activeCliente?.id}`,
            descripcion: `cliente_${activeCliente.id}`,
            title: 'Logo de la empresa'
          }         
          dispatch(startSetSharedProps(props))
        }
      }, [activeCliente, dispatch])

    return (
        <>
            <Dialog open={modalCliente} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                    <Box
                        sx={{
                            my: 1,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <PeopleAltIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {isNew
                                ? "Agregar Cliente"
                                : "Actualizar"}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        width="100%"
                        component="div"
                        sx={{ mt: 1 }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={identificacion}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="identificacion"
                                    label="Identificación"
                                    name="identificacion"
                                    autoComplete="identificacion"
                                    autoFocus
                                    size="small"
                                    inputProps={{ tabIndex: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={nombre}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="nombre"
                                    label="Nombre"
                                    name="nombre"
                                    autoComplete="nombre"
                                    size="small"
                                    inputProps={{ tabIndex: 2 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    value={telefono}
                                    type={'tel'}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="telefono"
                                    label="Teléfono"
                                    name="telefono"
                                    autoComplete="telefono"
                                    size="small"
                                    inputProps={{ tabIndex: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <AutoComplete
                                    required 
                                    setValue={setDireccion} 
                                    size='small'
                                    sx={{ marginTop: 1 }}
                                    title="Dirección" 
                                    tabIndex={4} 
                                    value={direccion}
                                /> 
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    type={'contactoPrincipal'}
                                    value={contactoPrincipal}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="contactoPrincipal"
                                    label="Nombre de Contacto Principal"
                                    name="contactoPrincipal"
                                    autoComplete="contactoPrincipal"
                                    size="small"
                                    inputProps={{ tabIndex: 5 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    margin="dense"
                                    type={'email'}
                                    value={email}
                                    onChange={onChange}
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo Electrónico"
                                    name="email"
                                    autoComplete="current-email"
                                    size="small"
                                    inputProps={{ tabIndex: 6 }}
                                />
                            </Grid>
                        </Grid>
                        {activeCliente && <Grid container>
                            <SelloFisico />
                        </Grid>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} tabIndex={8}>Cancelar</Button>
                    <Button onClick={handleSave} tabIndex={9}>{activeCliente ? 'Actualizar' : 'Guardar'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
