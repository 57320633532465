import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { dayTime, deleteFirma, deleteImagen } from '../../../helpers';
import { AppState } from '../../../reducers/rootReducer';
import { updateServicioFromReporte } from '../../servicios/actions/servicio';
import { closeReportes, startCreateReportes } from '../actions/reportes';
import { useScreenSize } from '../../../hooks';
import { Props } from '../../../shared/shared_state/interfaces/shared';
import { startSetSharedProps } from '../../../shared/shared_state/actions/shared';
import { Ubicacion } from '../../informes/interfaces/informes';

export const useModalReporteBasico = () => {
  const dispatch = useDispatch();
  const { width } = useScreenSize();
  const [fullscreen, setFullscreen] = useState<boolean>(true);
  const [err, setErr] = useState<boolean>(true);
  const [guardar, setGuardar] = useState(false);

  const { modalReporteBasico, activeServicio } = useSelector((state: AppState) => state.servicios);
  const { reportes, modules } = useSelector((state: AppState) => state.reportes);
  const { equiposSeguridad, herramientas, personal, vehiculos, imagesPrev, imagesFin } = modules;

  const { actividades: alcanceActividad, fechaFinal, observaciones } = reportes;

  const { actividades, materiales, sello, responsableBeling,
    responsableCliente, } = useSelector((state: AppState) => state.shared);
  //const { selloFisico } = sello;
  const responsableB = responsableBeling.responsableBeling[0];
  const responsableC = responsableCliente.responsableCliente[0];

  const handleClose = () => {

    if (!guardar) {
      if (responsableB.firma.id) {
        deleteFirma(responsableB.firma.id);
      }
      if (responsableC.firma.id) {
        deleteFirma(responsableC.firma.id);
      }
      if (imagesPrev.images.length > 0) {
        imagesPrev.images.forEach(i => {
          deleteImagen(i.id)
        })
      }
      if (imagesFin.images.length > 0) {
        imagesFin.images.forEach(i => {
          deleteImagen(i.id)
        })
      }
      if(sello.selloFisico){
        deleteImagen(sello.selloFisico.id)
      }
      dispatch(updateServicioFromReporte(activeServicio?.id!, 'No completados'))
    }
    setGuardar(false);
    dispatch(closeReportes());
  };

  const handleSave = () => {
    setGuardar(true);
    const data = {
      requiereAts: false,
      servicio: activeServicio!.id,
      alcanceActividad,
      actividades: actividades.actividades,
      tipoActividad: reportes.actividadTipo,
      equiposSeguridad: equiposSeguridad.equipos,
      fecha: fechaFinal.fecha,
      fechaHoraInicio: dayTime(reportes.ubicacionInicial?.timestamp!/1000),
      fechaHoraFinal: fechaFinal.fecha,
      herramientas: herramientas.herramientas,
      imagenesAntes: imagesPrev.images,
      imagenesFin: imagesFin.images,
      materiales: materiales.materiales,
      observaciones: observaciones,
      personal: personal.personal,
      responsableBeling: responsableB,
      responsableCliente: responsableC,
      selloFisico: sello.selloFisico,
      ubicacionInicial: reportes.ubicacionInicial,
      ubicacionFinal: reportes.ubicacionFinal,
      vehiculos: vehiculos.vehiculos
    }
    
    dispatch(startCreateReportes(data));
  }

  useEffect(() => {
    if (width < 1024) {
      setFullscreen(true)
    } else {
      setFullscreen(false)
    }
  }, [width])

  useEffect(() => {
    let counter: number = 0;
    if (actividades.actividades.length === 0) {
      counter++;
    }
    if (alcanceActividad.length === 0) {
      counter++;
    }
    if (personal.personal.length === 0) {
      counter++;
    }
    if (reportes.ubicacionInicial === null) {
      counter++;
    }
    if (reportes.ubicacionFinal === null) {
      counter++;
    } 
    if (responsableB.nombre.length <= 3 || responsableB.identificacion.length <= 5 || responsableB.firma.name.length === 0) {
      counter++;
    }
    if (responsableC.nombre.length <= 3 || responsableC.identificacion.length <= 5 || responsableC.firma.name.length === 0) {
      counter++;
    }

    if(sello.selloFisico === null){
      counter ++
    }

    if (counter === 0) {
      setErr(false)
    } else {
      setErr(true)
    }
  }, [actividades.actividades.length, alcanceActividad.length, personal.personal.length, reportes.ubicacionFinal, reportes.ubicacionInicial, responsableB.firma.name.length, responsableB.identificacion.length, responsableB.nombre.length, responsableBeling.responsableBeling, responsableC.firma.name.length, responsableC.identificacion.length, responsableC.nombre.length, responsableCliente.responsableCliente, sello]);

  useEffect(() => {
    if (activeServicio) {          
      const props: Props = {
        name: `servicio_${activeServicio.nombre}`,
        servicio: `servicio_${activeServicio?.id}`,
        descripcion: `servicio_${activeServicio.descripcion}`,
        title: 'Logo de la empresa'
      }         
      dispatch(startSetSharedProps(props))
    }
  }, [activeServicio, dispatch])

  return {
    err,
    fullscreen,
    modalReporteBasico,
    handleClose,
    handleSave
  }
}
