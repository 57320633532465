import * as React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModesModel,
    GridRowModes,
    GridRowParams,
    MuiEvent,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    esES,
    GridColDef,
    DataGrid,
} from '@mui/x-data-grid';

import { CellExpand } from '../../../components/ui/CellExpand';
import { Tooltip } from '@mui/material';
import { AlertContacto } from './AlertContacto';
import { ModalContacto } from './ModalContacto';
import { startSetActiveContacto, startSetAlertDeleteContacto, startSetModalContacto } from '../actions/contactos';
import { Contacto } from '../../clientes/interfaces/clientes';


function EditToolbar() {
    const dispatch = useDispatch();
    const handleClick = () => {
       dispatch(startSetModalContacto(true));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Nuevo
            </Button>
        </GridToolbarContainer>
    );
}

interface Props {
    contactos: Contacto[];
}
export const ContactosTable = ({contactos}: Props) => {
    const dispatch = useDispatch();
    //const { contactos } = useSelector((state: AppState) => state.contactos);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {        
        dispatch(startSetActiveContacto(id));
        dispatch(startSetModalContacto(true));
        //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        dispatch(startSetActiveContacto(id));
        dispatch(startSetAlertDeleteContacto(true));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        //dispatch(startUpdateServicio(updatedRow));
        //console.log('processRowUpdate', updatedRow);
        return updatedRow;
    };

    const columns: GridColDef[] = [
        { 
            field: 'nombres', 
            headerName: 'Nombres', 
            flex:2, 
            editable: false, 
            headerAlign: 'center',
            renderCell: CellExpand 
        },
        { 
            field: 'telefono', 
            headerName: 'Teléfono', 
            flex:1, 
            editable: false, 
            headerAlign: 'center',
            renderCell: CellExpand 
        },
        { 
            field: 'direccion', 
            headerName: 'Dirección', 
            flex:1, 
            editable: false, 
            headerAlign: 'center', 
            renderCell: CellExpand 
        },        
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex:1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Tooltip title="Guardar"><SaveIcon /></Tooltip>}
                            title="Guardar"
                            label="Guardar"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancelar"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Tooltip title="Editar"><EditIcon color='secondary' /></Tooltip>}
                        label="Editar"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Borrar contacto"><DeleteIcon color='error' /></Tooltip>}
                        label="Eliminar"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 'auto',
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                autoHeight
                rows={contactos.slice(0, contactos.length)}
                columns={columns}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}                
                slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: { setRowModesModel },
                  }}                
                localeText={{...esES.components.MuiDataGrid.defaultProps.localeText, noRowsLabel: 'No hay datos para mostrar'}}
                rowHeight={35}
            />
            <ModalContacto />
            <AlertContacto />         
        </Box>
    );
}
