import React, { memo } from 'react'
import { VehiculoTable } from '../../modules/vehiculo/components/VehiculoTable';

const Table = memo(() => {
	return (
		<VehiculoTable />
	);
});

export const StepFour = () => {
	return (
		<>
			<Table />
		</>
	)
}
