import { Box, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { esES } from '@mui/x-date-pickers/locales';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from "date-fns/locale";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import {
    Herramienta,
    ActividadNoComtemplada,
    Alarma,
    Baterias,
    CorrienteRectificador,
    Inversores,
    Material,
    MedicionesBancoBaterias,
    Personal,
    PersonalClaro,
    Rectificadores,
    RutinaBancoBateria,
    RutinaRectificador,
    Vehiculos,
    VoltajeRectificador,
} from '../modules';
import { ResponsableBeling, ResponsableCliente } from '../../../shared';
import { SelectComponent } from '../../../components/select/SelectComponent';
import { AtsResource } from '../../resources/interfaces/resources';
import { DateTimeField } from '@mui/x-date-pickers';
import { useMobileDetect } from '../../../hooks/useMobileDetect';

interface FormReporteScreenProps {
    atsResource: AtsResource[];
    reqAts: boolean;
    registerData: {
        alcanceActividad: string,
        observaciones: string,
        requiereAts: boolean,
    };
    registerDataDate: {
        fechaHoraFinal: Date,
    };
    onChange: any;
    onDateChange: any;
    onChangeSelect: any;
    onClick: React.MouseEventHandler<HTMLDivElement> | undefined
    handleAddAts: () => void;
    registerSelectData: {
        ats: string
    }

}

export const FormReporteScreen = ({
    atsResource,
    handleAddAts,
    onClick,
    onChange,
    registerData,
    onDateChange,
    reqAts,
    registerDataDate,
    registerSelectData,
    onChangeSelect
}: FormReporteScreenProps) => {
    const { isMobile } = useMobileDetect();
    const { alcanceActividad, observaciones, requiereAts } = registerData;
    const { fechaHoraFinal } = registerDataDate;
    const { ats } = registerSelectData;

    return (
        <Box
            width="100%"
            component="div"
            sx={{ my: 2, mx: 'auto', maxWidth: '90vw' }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        autoComplete=''
                        autoFocus
                        fullWidth
                        id="alcanceActividad"
                        label="Descripción de Actividad a Realizar"
                        margin="normal"
                        multiline
                        name="alcanceActividad"
                        onChange={onChange}
                        required
                        rows={3}
                        value={alcanceActividad}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={6} sm={3} md={3}>
                    <FormControl sx={{ marginTop: 2 }}>
                        <RadioGroup
                            row
                            aria-labelledby="requiereAts-buttons-group-label"
                            name="requiereAts"
                            value={requiereAts}
                            onChange={onChange}
                            onClick={onClick}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Si" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                    <Typography component="h1" align='left' variant="subtitle1" sx={{ marginTop: 3 }}>
                        ¿Requiere ATS?
                    </Typography>
                </Grid>
                {reqAts && (<><Grid item xs={9} sm={5} md={5} >
                    <SelectComponent
                        value={ats}
                        values={atsResource}
                        label="ATS"
                        name="ats"
                        index={3}
                        onChange={onChangeSelect}
                    />
                </Grid>
                    <Grid item xs={3} sm={1}>
                        <IconButton
                            color='primary'
                            size="large"
                            aria-label="agregar ats"
                            onClick={handleAddAts}
                        >
                            <Tooltip title="Agregar ATS">
                                <PostAddOutlinedIcon fontSize="large" />
                            </Tooltip>
                        </IconButton>
                    </Grid>
                </>
                )

                }
            </Grid>
            <Vehiculos />
            <Material />
            <Herramienta />
            <Personal />
            <PersonalClaro />
            <RutinaRectificador />
            <VoltajeRectificador />
            <CorrienteRectificador />
            <Rectificadores />
            <Baterias />
            <RutinaBancoBateria />
            <MedicionesBancoBaterias />
            <Inversores />
            <Alarma />
            <ActividadNoComtemplada />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        margin="normal"
                        value={observaciones}
                        onChange={onChange}
                        multiline
                        rows={4}
                        fullWidth
                        id="observaciones"
                        label="Concluciones y Recomendaciones"
                        name="observaciones"
                    />
                </Grid>
            </Grid>
            <ResponsableBeling />
            <ResponsableCliente />
            <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item xs={6} sm={6}>
                    <FormControl fullWidth >
                        <LocalizationProvider
                            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                            adapterLocale={es} dateAdapter={AdapterDateFns}
                        >
                            {isMobile ?
                                <MobileDateTimePicker
                                    label="Fecha y Hora de Finalización"
                                    value={fechaHoraFinal}
                                    onChange={(newValue) => {
                                        onDateChange(newValue, "fechaHoraFinal");
                                    }}
                                />
                                :
                                <DateTimeField
                                    label="Fecha y Hora de Finalización"
                                    required
                                    value={fechaHoraFinal}
                                    onChange={(newValue) => {
                                        onDateChange(newValue, "fechaHoraFinal");
                                    }}
                                />}
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}