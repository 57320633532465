import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid';


import { setInactiveServicio, startSetModalServicio, startSetServicioIsNew } from '../actions/servicio';
import { EditToolbarProps } from '../../../interfaces/shared';

export const ServicioToolbar = (props: EditToolbarProps) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(startSetServicioIsNew(true))
        dispatch(setInactiveServicio())
        dispatch(startSetModalServicio(true));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Nuevo
            </Button>
        </GridToolbarContainer>
    );
}