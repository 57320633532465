import {View, StyleSheet } from '@react-pdf/renderer';
import { Reporte } from '../../../interfaces/reportes';
import { HeaderTable, ItemsTable } from '../../../../informes/components/pdf/normal';
import { Cell } from '../../../../../interfaces/shared';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
	container: {
		marginTop: 2
	},
	descriptionContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const tableTitles: Cell[]  = [
	{name: "Item", width: "10%"},
	{name: "Rutina", width: "30%"},
	{name: "Descripción", width: "30%"},
	{name: "Observaciones", width: "30%"}
];
interface Props {
    active: Reporte;
  }

  export const RutinaBancoBateriasTable = ({active}:Props) => {
    const { rutinaBancoBateria } = active;
    return (
		<View>	
		<TitleTables title='Rutina de Banco de Baterías' sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }} />	
			<View style={styles.container} >
				<View style={styles.descriptionContainer}>					
					<HeaderTable names={tableTitles} defaultWidth={false} sx={{txa: 'center', bc: "#dddddd"}}/>
					{rutinaBancoBateria?.map((item, index) => {
						const fila: Cell[] = [
							{ name: index + 1, width: '10%', align: 'center' },
							{ name: item.nombre!, width: '30%' },
							{ name: item.descripcion!, width: '30%' },
							{ name: item.observaciones!, width: '30%' },
						]
						return <ItemsTable key={item.id} columnas={fila} sx={{ fw: 'normal', fz: 10, mh: 10 }} bottom defaultWidth={false} />
					})}					
				</View>
			</View>
		</View>
	)
  }