import { ActividadAction, ActividadState } from '../interfaces/actividad';

const initState: ActividadState = {
    actividades: [],
    actividadId: '',
};

export const actividadReducer = (state = initState, action: ActividadAction): ActividadState => {
    switch (action.type) {
        case 'set-actividad':
            return {
                ...state,
                actividades: [...state.actividades, action.payload]
            };
        case 'update-actividad':
            return {
                ...state,
                actividades: state.actividades.map(actividad => {
                    if (actividad.id === action.payload.id) {
                        return action.payload;
                    }
                    return actividad;
                })
            };
        case 'delete-actividad':
            return {
                ...state,
                actividades: state.actividades.filter(actividad => actividad.id !== action.payload)
            };
        case 'edit-actividad':
            return {
                ...state,
                actividades: state.actividades.map(actividad => {
                    if (actividad.id === action.payload) {
                        actividad.isNew = true;
                        return actividad;
                    }
                    return actividad;
                })
            };        
        case 'set-actividad-id':
            return {
                ...state,
                actividadId: action.payload
            };        
        case 'set-actividad-empty':
            return {
                ...state,
                actividades: []
            };
        default:
            return state;
    }
}