import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { RiesgoElectricoTable } from './RiesgoElectricoTable';

const Table = memo(() => {
    return(
        <RiesgoElectricoTable />
    )
})

export const RiesgoElectrico = () => {
    return (
        <>
            <Grid container sx={{ mt: 4, mb: 1 }}>
                <Grid item xs={12}>
                    <Typography component="h1" align='center' variant="subtitle1">
                    Riesgos Eléctricos
                    </Typography>
                </Grid>
            </Grid>
            <Table />
        </>
    )
}