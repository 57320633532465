import { GridRowId } from '@mui/x-data-grid';
import { fetchConToken, fetchImages, fetchImagesToken } from '../../../apis/fetch';
import { createPassword } from '../../../helpers';
import { doMessage, startLoading } from '../../ui/actions/ui';
import { ChangePassword, User, UserAction } from '../interfaces/user';

export const startLoadUsers = () => {
    return async (dispatch: any) => {
        dispatch(startLoading());
        const resp = await fetchConToken('user', null, 'GET');
        const body = await resp.json();
        const { ok, users, msg } = body;
        if (ok) {
            dispatch(startLoading());
            const records = users.map((user: any) => {
                return {
                    id: user.uid,
                    ...user
                }
            })
            dispatch(loadUsers(records));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            dispatch(doMessage({ message: msg, type: 'error' }));
        }

    }
}

const loadUsers = (users: User[]): UserAction => ({
    type: 'load-users',
    payload: users
})

export const startCreateUser = (userCreate: User) => {
    userCreate.password = createPassword(8, 'alfha-number');
    const userImg = {
        email: userCreate.email,
        name: userCreate.name,
        password: userCreate.password,
        password_confirmation: userCreate.password
    }
    return async (dispatch: any) => {
        dispatch(startLoading());
        const userI = await fetchImages('register', userImg, 'POST');
        const bodyimg = await userI.json();
        const { user: userImages } = bodyimg;
        if (!userI.status) {
            dispatch(doMessage({ message: 'Error almacenando usuario', type: 'error' }));
            return;
        }
        
        userCreate.imagesId = userImages.id;
        userCreate.temporalPassword = userImages.temporal_password;

        const resp = await fetchConToken('user', userCreate, 'POST');
        const body = await resp.json();
        const { ok, errors, msg, user } = body;
        if (ok) {
            dispatch(createUser(user));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            let err = ''
            const error = Object.values(errors)
            error.map((e: any) => err += `${e.msg} \n`)
            dispatch(doMessage({ message: err, type: 'error' }));
        }

        dispatch(startLoading());
    }
}

const createUser = (user: User): UserAction => ({
    type: 'create-user',
    payload: user
})

export const startUpdateUser = (userUpdate: User) => {
    return async (dispatch: any, getState: any) => {
        const { id } = getState().usuarios.activeUser;
        dispatch(startLoading());
        const resp = await fetchConToken(`user/${id}`, userUpdate, 'PUT');
        const body = await resp.json();
        const { ok, errors, user, msg } = body;        
        if (ok) {
            dispatch(updateUser(user));
            dispatch(startSetActiveUser(''));
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            let err = ''
            const error = Object.values(errors)
            error.map((e: any) => err += `${e.msg} \n`)
            dispatch(doMessage({ message: err, type: 'error' }));
        }
        dispatch(startLoading());
    }
}

const updateUser = (user: User): UserAction => ({
    type: 'update-user',
    payload: user
})

export const startDeleteUser = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const { id, imagesId } = getState().usuarios.activeUser;
        if (!id) {
            return dispatch(doMessage({ message: 'No hay usuario activo', type: 'error' }));
        }

        const respImg = await fetchImagesToken(`user/${imagesId}`, {}, 'DELETE');
        const bodyImg = await respImg.json();
        
        if (!bodyImg.status) {
            dispatch(doMessage({ message: 'Error eliminando el usuario', type: 'error' }));
            return;
        }
        
        const resp = await fetchConToken(`user/${id}`, {}, 'DELETE');
        const body = await resp.json();
        const { ok, msg } = body;
        if (ok) {
            dispatch(deleteUser(id));
            dispatch(startSetActiveUser(''))
            dispatch(doMessage({ message: msg, type: 'success' }));
        } else {
            dispatch(startLoading());
            dispatch(doMessage({ message: msg, type: 'error' }));
        }
        dispatch(startLoading());
    }
}

const deleteUser = (id: GridRowId): UserAction => ({
    type: 'delete-user',
    payload: id
})


export const startChangePassword = (data: ChangePassword) => {
    const userImg = {
        password: data.newPassword,
        password_confirmation: data.newPassword
    }
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const { imagesId } = getState().auth.user;
        console.log(imagesId)
        if (!imagesId) {
            dispatch(startLoading());
            return dispatch(doMessage({ message: 'Error con el usuario', type: 'error' }));
        }

        const resp = await fetchConToken(`auth/updatePassword`, data, 'POST');
        const body = await resp.json();
        const { ok, msg } = body;        
        if (ok) {
            const respImg = await fetchImagesToken(`updatepass/${imagesId}`, userImg, 'PUT');
            const bodyImg = await respImg.json();
            if (!bodyImg.status) {
                dispatch(doMessage({ message: 'Error actualizando contraseña', type: 'error' }));
                return;
            }            
            dispatch(doMessage({ message: msg, type: 'success' }));            
        } else {            
            console.log(body)
            dispatch(doMessage({ message: msg, type: 'error' }));
        }
        dispatch(startLoading());
    }
}
export const startSetActiveUser = (id: GridRowId): UserAction => ({
    type: 'set-active-user',
    payload: id
})

export const startSetModalUser = (modal: boolean): UserAction => ({
    type: 'set-modal-user',
    payload: modal
})

export const startSetAlertDeleteUser = (alert: boolean): UserAction => ({
    type: 'set-alert-delete-user',
    payload: alert
})

export const startSetIsSameUser = (payload: boolean): UserAction => ({
    type: 'set-is-same-user',
    payload
})

export const startSetModalChangePass = (payload: boolean): UserAction => ({
    type: 'set-modal-password',
    payload

})

