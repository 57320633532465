import { Avatar, Box, Typography } from '@mui/material';
import { EngineeringOutlined } from '@mui/icons-material';
import { TecnicosTable } from './TecnicosTable';

export const Tecnicos = () => {

    return (
        <>
            <Box
                sx={{
                    my: 1,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <EngineeringOutlined />
                </Avatar>
                <Typography component="h1" align='center' variant="h6">
                    Tecnicos
                </Typography>
            </Box>
            <TecnicosTable />
        </>
    )
}