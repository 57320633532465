/**
 * Componente para mostrar la tabla de usuarios
 * @author Heduin Lopez
 * @since 1.0.0
 * @version 1.0.0
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Paper } from '@mui/material';
import { startLoadUsers } from '../actions/user';
import { AppState } from '../../../reducers/rootReducer';
import { Users } from './Users';



export const UserScreen = () => {
  const { users } = useSelector((state: AppState) => state.usuarios);
  const dispatch = useDispatch(); 
  useEffect(() => {
    if (users.length === 0) {
      dispatch(startLoadUsers());
    }
  }, [dispatch, users]);
  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Tecnicos */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Users />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}