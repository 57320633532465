import { GridRowId } from '@mui/x-data-grid';
import { fetchConToken } from '../../../apis/fetch';
import { doMessage, startLoading } from '../../ui/actions/ui'
import { ListaAction, Lista, ListaResponse } from '../interfaces/interfaces';


export const startLoadListas = ()=>{
	return async(dispatch: any)=>{
		dispatch(startLoading());
		const resp = await fetchConToken('listas', {});
        const body: ListaResponse = await resp.json();
		const { errors, listas, msg, ok } = body;
		if(ok){
			dispatch(loadListas(listas));
		}else{			             
            if (msg) {
                dispatch(doMessage({message: msg, type: 'error'}));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }     
		}
		dispatch(startLoading())
	}
}

const loadListas = (listas: Lista[]):ListaAction =>({
	type: 'load-listas',
	payload: listas
})


export const startCreateLista = (newLista: Lista) => {
    return async(dispatch: any) =>{
        dispatch(startLoading());
        const resp = await fetchConToken('listas', newLista, 'POST');
        const body: ListaResponse = await resp.json();
        const {errors, lista, msg, ok } = body;

        if(ok){
         dispatch(createLista(lista));
         dispatch(doMessage({message: msg, type: 'success'}));
        }else{
            if (msg) {
                dispatch(doMessage({message: msg, type: 'error'}));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            }
        }
        dispatch(startLoading());
    }
}

const createLista = (lista: Lista): ListaAction => ({
    type: 'create-lista',
    payload: lista
})

export const startUpdateLista = (updateLista: Lista) => {
    return async(dispatch: any, getState: any) =>{
        dispatch(startLoading())
        const id = getState().listas.activeLista.id;
        if(!id){
            return dispatch(doMessage({message: 'No hay lista activa', type: 'error'}));            
        }

        const resp = await fetchConToken(`listas/${id}`, updateLista, 'PUT');
        const body: ListaResponse = await resp.json();
        const {errors, lista, msg, ok } = body;
        if (ok) {
            dispatch(upDateLista(lista));
            dispatch(doMessage({message: msg, type: 'success'}));
            dispatch(startSetActiveLista(''))
        } else {
            if (msg) {
                dispatch(doMessage({message: msg, type: 'error'}));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            } 
        }
        dispatch(startLoading())
    }
}

const upDateLista = (lista: Lista): ListaAction => ({
    type: 'update-lista',
    payload: lista
})


export const startDeleteLista = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(startLoading());
        const id = getState().listas.activeLista.id;
        if(!id){
            dispatch(startLoading());
            return dispatch(doMessage({message: 'No hay lista activa', type: 'error'}));
        }
        const resp = await fetchConToken(`listas/${id}`, {}, 'DELETE');
        const body: ListaResponse = await resp.json();
        const { errors, msg, ok } = body;
        if (ok) {            
            dispatch(deleteLista(id));
            dispatch(startSetActiveLista(''));
            dispatch(doMessage({message: msg, type: 'success'}));            
        } else {            
            if (msg) {
                dispatch(doMessage({message: msg, type: 'error'}));
            } else {
                let err = ''
                const error = Object.values(errors)
                error.map((e: any) => err += `${e.msg} \n`)
                dispatch(doMessage({ message: err, type: 'error' }));
            } 
            
        }  

        dispatch(startLoading());      
    }
}

const deleteLista = (id: string): ListaAction => ({
    type: 'delete-lista',
    payload: id
})


export const startSetActiveLista =(id: GridRowId):ListaAction => ({
    type: 'set-active-lista',
    payload: id
})

export const startSetModalLista = (modal: boolean):ListaAction => ({
    type: 'set-modal-lista',
    payload: modal
})

export const startSetAlertDeleteLista = (alert: boolean):ListaAction => ({
    type: 'set-alert-delete-lista',
    payload: alert
})

export const startSetIsNewLista = (isNew: boolean): ListaAction => ({
    type: 'set-isnew-lista',
    payload: isNew
})