import { UIAction, UIState } from '../interfaces/ui';

const initState: UIState = {
    drawerOpen: true,
    modalOpen: false,
    isLoading: false,
    alert: {
        message: '',
        type: 'info'
    },
    formError: false
}
export const uiReducer = (state = initState, action: UIAction): UIState => {
    switch (action.type) {
        case 'toggleDrawer':
            return {
                ...state,
                drawerOpen: action.payload
            }
        case 'drawer-close':
            return{
                ...state,
                drawerOpen: false
            }
        case 'drawer-open':
                return{
                    ...state,
                    drawerOpen: true
                }
        case 'openModal':
            return {
                ...state,
                modalOpen: action.payload
            }
        case 'closeModal':
            return {
                ...state,
                modalOpen: false
            }
        case 'set-message':
            return {
                ...state,
                alert: action.payload
            }
        case 'remove-message':
            return {
                ...state,
                alert: {
                    message: '',
                    type: 'info'
                }
            }
        case 'set-loading':
            return {
                ...state,
                isLoading: !state.isLoading
            }
        case 'set-form-error':
            return {
                ...state,
                formError: action.payload
            }     
        default:
            return state;
    }
}
