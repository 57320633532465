import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#9e9e9e'
const styles = StyleSheet.create({
  containerMedicionesBat: {
    flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#dddddd',
        borderBottomWidth: 1,
        alignItems: 'center',
        minHeight: 18,
        textAlign: 'center',        
        flexGrow: 1,
  },
  numeroBanco: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    flexWrap: 'wrap',
    minHeight: 18
  },
  numeroBateria: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    flexWrap: 'wrap',
    minHeight: 18
  },
  medicion: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    flexWrap: 'wrap',
    minHeight: 18
  },
  impedancia: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    flexWrap: 'wrap',
    minHeight: 18
  },
  porcentajeSulfatacion: {
    width: '20%',
    flexWrap: 'wrap',
    minHeight: 18
  }
});

export const TableMedicionesBateriasHeader = () => (
  <View style={styles.containerMedicionesBat} fixed>
    <Text style={styles.numeroBanco}>No. de Banco</Text>
    <Text style={styles.numeroBateria}>No. de Batería</Text>
    <Text style={styles.medicion}>Medición [Vdc]</Text>
    <Text style={styles.impedancia}>Impedancia [Ohmios]</Text>
    <Text style={styles.porcentajeSulfatacion}>% de Sulfatación</Text>
  </View>
);