import { View, StyleSheet } from '@react-pdf/renderer';

import { HeaderTable, ItemsTable } from '../index';
import { getDurationHhMmSs, dateShort, fechaHora } from '../../../../../../helpers/fecha';
import { Cell } from '../../../../../../interfaces/shared';
import { Informe } from '../../../../interfaces/informes';

const styles = StyleSheet.create({
	container:{
		marginTop: 10,
		flexWrap: "wrap",
	},
	headerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const namesUno = [{name:"Cliente:"}, {name:"Dirección Cliente:"}, {name:"Contacto Cliente:"}];
const namesDos = [{name:"Fecha de Contrato:"}, {name:"Fecha de Inicio Obra:"}, {name:"Plazo:"}];
const namesTres = [{name:"Responsable Beling:"}, {name:"Recibe en Sitio:"}, {name:"Duración:"}];
const namesCuatro = [{name:"Fecha de Inicio:"}, {name:"Fecha de Finalización:"}, {name:"Duración:"}];

interface Props {
	active: Informe;
}
export const Header = ({ active }: Props) => {
	const filaUno: Cell[] = [
		{name: active.obra?.cliente.nombre!},
		{name:
			`${active.obra?.cliente.direccion.description!}`},
		{name: `${active.obra.contacto.nombres}`},
		];

	const filaDos: Cell[] = [		
		{name: dateShort(active.obra?.fechaContrato!)},
		{name: dateShort(active.obra?.fechaInicio!)},
		{name: active.obra?.plazo},
		];
	
	const filaTres: Cell[] = [
		{name: active.responsableBeling![0].nombre},
		{name: active.acompanaCliente?.nombre!},
		{name: getDurationHhMmSs(active.fechaHoraFinal!, active.fechaHoraInicio!)},		
	];

	const filaCuatro: Cell[] = [
		{name: fechaHora(active.fechaHoraInicio!)},
		{name: fechaHora(active.fechaHoraFinal!) },
		{name: getDurationHhMmSs(active.fechaHoraFinal!, active.fechaHoraInicio!)},
	]

	return (
		<>
		<View style={styles.container}>
			<View style={styles.headerContainer}>
				<HeaderTable names={namesUno} />
				<ItemsTable columnas={filaUno} />
			</View>
			<View style={styles.headerContainer}>
				<HeaderTable names={namesDos} />
				<ItemsTable columnas={filaDos} />
			</View>
			<View style={styles.headerContainer}>
				<HeaderTable names={namesTres} />
				<ItemsTable columnas={filaTres} />
			</View>
			<View style={styles.headerContainer}>
				<HeaderTable names={namesCuatro} />
				<ItemsTable columnas={filaCuatro} bottom />
			</View>
		</View>
		</>
	);
};

