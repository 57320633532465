import { View } from '@react-pdf/renderer';
import { TitleTables } from '../../../../../components/pdf/TitleTables';
import { Imagen } from '../../../../../interfaces/image';
import { ImagesPdf } from '../images/ImagesPdf';

interface Props {
	imagenes: Imagen[];
	descripcion: string;
	listado: string;
}
export const ImagenesActividades = ({ imagenes, descripcion, listado }: Props) => {

	return (
		<View style={[{ marginBottom: 10 }]}>
			<TitleTables
				title= {`Evidencias de las actividades del listado: ${listado.toLowerCase().slice(7)}`} 
				sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
			/>
			<View>
				<ImagesPdf images={imagenes} descripcion={descripcion} />
			</View>
		</View>
	)
}