export const toString = (value: string | number) => {  
    
     const newValue = `${value}`

    if(newValue.length === 1){
        return `000${value}`
    }else if(newValue.length === 2){
        return `00${value}`
    }else if(newValue.length === 3){
        return `0${value}`
    }else{
        return `${value}`
    }
    
}

export const capitalizeFirtsLetter = (str: string)=>{
    return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`
}
