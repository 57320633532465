import { ResponsableAction, ResponsableClienteState } from "../interfaces/recibe";

const initState: ResponsableClienteState = {
    responsableCliente: [
        {
            id: 1,
            nombre: "",
            identificacion: "",
            cargo: "",
            firma: {
                uri: "",
                name: ""
            },
            isNew: false
        }
    ],
    modalFirmaCliente: false
}

export const responsableClienteReducer = (state = initState, action: ResponsableAction): ResponsableClienteState => {
    switch (action.type) {
        case 'update-responsable-cliente-reporte':
            return {
                ...state,
                responsableCliente: state.responsableCliente.map(responsable => responsable.id === action.payload.id ? action.payload : responsable)
            }
        case 'set-modal-firma-cliente-reporte':
            return {
                ...state,
                modalFirmaCliente: action.payload
            }
        case 'set-responsable-cliente-empty-reporte':
            return {
                ...state,
                responsableCliente: [
                    {
                        id: 1,
                        nombre: "",
                        identificacion: "",
                        cargo: "",
                        firma: {
                            uri: "",
                            name: ""
                        },
                        isNew: false
                    }
                ]
            }
            case 'set-responsable-firma-cliente-reporte':
                return {
                    ...state,
                    responsableCliente:[ action.payload ]
                }
        default:
            return state;
    }
}