import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { Title } from '../../../../components/title/Title';
import { useStepSix } from '../../hooks';

export const StepSix = () => {
  const { actividades, checked, handleToggle } = useStepSix();

  return (
    <>
      <Title variant="h6" color="subtitle2" marginTop={0}>
        Describa el tipo de actividad a realizar
      </Title>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {actividades.map((value, index) => {
          const labelId = value;
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={handleToggle(value)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId.toString() }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId.toString()} primary={`${value}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
