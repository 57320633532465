import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import { Reporte } from '../../../interfaces/reportes';
import { TableMaterialRow } from './TableMaterialRow';
import { TableMaterialHeader } from './TableMaterialHeader';
import { TitleTables } from '../../../../../components/pdf/TitleTables';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderWidth: 1,
    },
});

const title = 'Describa los Materiales Utilizados en la Actividad:';

interface Props {
    active: Reporte;
  }

  export const MaterialTable = ({active}:Props) => (
    <View>
    <TitleTables
        title='Materiales utilizados'
        sx={{ fs: 'italic', fw: 'bold', fz: 11, mt: 8 }}
      />
    <TitleTables title={title} sx={{fw: 'normal', fz:10}} />
    <View style={styles.tableContainer}>
        <TableMaterialHeader />
        <TableMaterialRow items={active.materiales!} />
    </View>
    </View>
  );
