
import { Box, FormControl, Grid, TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { es } from "date-fns/locale";
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { PositionButton } from '../../reportes/components/shared/PositionButton';
import { Titles } from '../../reportes/components/shared/Titles';
import { MultipleSelectCheckBox } from '../../../components/multipleselect/MultipleSelectCheckBox';
import { RegistroFotografico } from '../modules/images/components/RegistroFotografico';
import { Anexos } from '../modules/anexos/components/Anexos';
import { Sst } from '../interfaces/sst';
import {  DateTimeField, esES } from '@mui/x-date-pickers';
import { useMobileDetect } from '../../../hooks';

interface Props {
  activeSst: Sst,
  ats: any,
  atsM: any[],
  formError: boolean,
  registerData: {
    accidentes: number,
    incidentes: number,
    operativos: number,
    administrativos: number,
  };
  registerDataDate: {
    fecha: Date,
  };
  handleChangePlanes: any,
  handleChangeAts: any,
  onChange: any;
  onDateChange: any;
  handleIniciar: any;
  handleFinalizar: any;
  planes: any,
  planesTrabajo: string[],
  
}

export const FormReporteScreenSst = ({
  activeSst,
  ats,
  atsM,
  formError,
  registerData,
  registerDataDate,
  handleIniciar,
  handleFinalizar,
  onChange,
  onDateChange,
  planes,
  planesTrabajo,
  handleChangePlanes,
  handleChangeAts,
}: Props) => {

  const { isMobile } = useMobileDetect()

  const { accidentes, incidentes, operativos, administrativos } = registerData;
  const { fecha } = registerDataDate;
  return (
    <Box
      width="100%"
      component="form"
      sx={{ my: 2, mx: 'auto', maxWidth: '90vw' }}
      noValidate
    >
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} sm={6}>
          {!activeSst?<PositionButton text='iniciar el informe' onClick={handleIniciar} />:<></>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
          <LocalizationProvider localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={es} dateAdapter={AdapterDateFns}>
          {isMobile ?
                <MobileDateTimePicker
                  autoFocus
                  label="Fecha"
                  value={fecha}
                  onChange={(newValue) => {
                    onDateChange(newValue, "fecha");
                  }}
                /> :
                <DateTimeField
                  autoFocus
                  label="Fecha"
                  required
                  value={fecha}
                  onChange={(newValue) => {
                    onDateChange(newValue, "fecha");
                  }}
                />}
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>

      <Titles
        align='left'
        mt={5}
        text="Asistentes"
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            autoComplete="administrativos"
            multiline
            fullWidth
            id="administrativos"
            label="Administrativos"
            margin="dense"
            name="administrativos"
            onChange={onChange}
            required
            value={administrativos}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoComplete="operativos"
            multiline
            fullWidth
            id="operativos"
            label="Operativos"
            margin="dense"
            name="operativos"
            onChange={onChange}
            required
            value={operativos}
          />
        </Grid>
      </Grid>
      <Titles
        text="Novedades"
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            autoComplete="accidentes"
            multiline
            fullWidth
            id="accidentes"
            label="Accidentes presentados"
            margin="dense"
            name="accidentes"
            onChange={onChange}
            required
            value={accidentes}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoComplete="incidentes"
            multiline
            fullWidth
            id="incidentes"
            label="Incidentes presentados"
            margin="dense"
            name="incidentes"
            onChange={onChange}
            required
            value={incidentes}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <Titles
            align='left'
            text="Plan de trabajo"
          />
          <MultipleSelectCheckBox
            index={5}
            selecteds={planesTrabajo}
            handleChange={handleChangePlanes}
            values={planes}
            label="Planes de trabajo"
            name="planes"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Titles
            align='left'
            text="ATS realizados"
          />
          <MultipleSelectCheckBox
            index={6}
            selecteds={atsM}
            handleChange={handleChangeAts}
            values={ats}
            label="ATS Realizadas"
            name="ats"
          />
        </Grid>
      </Grid>
      <Titles
        align='left'
        text="Registro fotográfico"
      />
      <RegistroFotografico />
      <Titles
        align='left'
        text="Anexos"
      />
      <Anexos />
      { !activeSst ?<Grid container spacing={2} mt={4}>
        <Grid item xs={12} sm={6}>
          <PositionButton text='finalizar' onClick={handleFinalizar} />
        </Grid>
        <Grid item xs={12} sm={6}>
        </Grid>
      </Grid>:''}
    </Box>
  )
}
