import React, { memo } from 'react'
import { PersonalTable } from '../../modules/personal/components/PersonalTable';

const Table = memo(() => {
  return (
    <PersonalTable />
  );
});
export const StepTwo = () => {
  return (    
      <Table />    
  )
}
