import { HerramientaAction, HerramientaState } from '../interfaces/herramienta';

const initState: HerramientaState = {
    herramientas: [],
};

export const herramientaReducer = (state = initState, action: HerramientaAction): HerramientaState => {
    switch (action.type) {
        case 'set-herramienta':
            return {
                ...state,
                herramientas: [...state.herramientas, action.payload],
            };
        case 'update-herramienta':
            return {
                ...state,
                herramientas: state.herramientas.map((herramienta) => {
                    if (herramienta.id === action.payload.id) {
                        return action.payload;
                    }
                    return herramienta;
                }),
            };
        case 'delete-herramienta':
            return {
                ...state,
                herramientas: state.herramientas.filter((herramienta) => herramienta.id !== action.payload),
            };
        case 'edit-herramienta':
            return {
                ...state,
                herramientas: state.herramientas.map((herramienta) => {
                    if (herramienta.id === action.payload) {
                        herramienta.isNew = false;
                    }
                    return herramienta;
                }),
            };
        case 'set-herramienta-empty':
            return {
                ...state,
                herramientas: [],
            };
        default:
            return state;
    }
}