import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { startCreateObras, startSetInactiveObra, startSetModalObra, startUpdateObra } from '../actions/obras';
import { AppState } from '../../../reducers/rootReducer';
import { useForm } from '../../../hooks/useForm';
import { useSelect } from '../../../hooks/useSelect';
import { useDate } from '../../../hooks/useDate';

import { starLoadContactos, startLoadClientes } from '../../resources/actions/resources';
import { Resource } from '../../resources/interfaces/resources';
import { useMobileDetect } from '../../../hooks';

const initForm = {
    descripcion: "",
    numero: "numero",
    plazo: ""
};

const initSelect = {
    cliente: "",
    contacto: ""
};

const initDate = {
    fechaContrato: new Date(),
    fechaInicio: new Date(),
};

export const useObraModal = () => {
    const dispatch = useDispatch();
    const { isMobile } = useMobileDetect();
    const { clientes,contactos: contactosDb } = useSelector((state: AppState) => state.resources);
    const { activeObra, modalObra } = useSelector((state: AppState) => state.obras.obras);
    
    const [contactos, setContactos] = useState<Resource[]>([]);

    const { onChange, changeData, registerData, numero, descripcion, plazo } = useForm(initForm);
    const { onChangeSelect, changeSelect, registerSelectData } = useSelect(initSelect);
    const { onDateChange, changeDate, registerDataDate } = useDate(initDate);
    const { cliente, contacto } = registerSelectData;
    const { fechaContrato, fechaInicio } = registerDataDate;

    const handleClose = () => {
        dispatch(startSetModalObra(false));
        dispatch(startSetInactiveObra())
    }

    const handleSave = () => {
        const obra = {
            cliente,
            contacto,
            descripcion,
            fechaContrato,
            fechaInicio,
            plazo,
            numero,
            estado: 'nuevo'
        }
        if (!activeObra) {
            dispatch(startCreateObras(obra));
        } else {
            dispatch(startUpdateObra(obra))
        }
        handleClose();
    }

    useEffect(() => {
        if (clientes.length < 1) {
            dispatch(startLoadClientes());
        }

    }, [dispatch, clientes.length]);

    useEffect(() => {
		if(contactosDb.length > 0) return;
		dispatch(starLoadContactos());
		setContactos(contactosDb)
		
	},[dispatch, contactosDb]);

    useEffect(() => {
        if (!activeObra) {
            changeData({ ...initForm });
            changeSelect({ ...initSelect });
            changeDate({ ...initDate })
        } else {
            changeData({
                descripcion: activeObra.descripcion!,
                numero: activeObra.numero!,
                plazo: activeObra.plazo!
            });
            changeSelect({
                cliente: activeObra.cliente._id,
                contacto: activeObra.contacto!
            });
            changeDate({
                fechaContrato: activeObra.fechaContrato!,
                fechaInicio: activeObra.fechaInicio!
            })
        }        
    }, [activeObra, changeData, changeSelect, changeDate]);

    useEffect(() => {
		if (cliente) {			
			const contactosDB = contactosDb.filter(c => c.cliente === cliente)
			const contactos = contactosDB.length > 0 ? contactosDB : [];
			setContactos(contactos)			
			if(!activeObra){
				changeSelect({
					...registerSelectData,
					contacto: '',
				});
			}else{
				changeSelect({
					...registerSelectData,
					contacto: activeObra.contacto!,
				});
			}
			
		}		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cliente, contactosDb, activeObra, contacto]);

    return {
        activeObra,
        clientes,
        contactos,
        isMobile,
        modalObra,
        registerData,
        registerSelectData,
        registerDataDate,
        handleClose,
        handleSave,
        onChange,
        changeData,
        onChangeSelect,
        changeSelect,
        onDateChange,
        changeDate,
    }
}