import { ImagenState, ImagesAction } from '../interfaces/images';

const imageState: ImagenState = {
    images: [],
    image: {
        actividad: '',
        description: '',
        id: 0,
        imagen: {
            uri: ''
        },
        isNew: false
    },
    modalImagen: false
}

export const imagesReducer = (state = imageState, action: ImagesAction): ImagenState => {
    switch (action.type) {
        case 'set-image-actividad':
            return {
                ...state,
                images: [...state.images, action.payload]
            };
        case 'update-images-actividad':
            return {
                ...state,
                images: state.images.map(image => {
                    if (image.id === action.payload.id) {
                        return action.payload;
                    }
                    return image;
                })
            };
        case 'delete-images-actividad':
            return {
                ...state,
                images: state.images.filter(i => i.id === action.payload)
            };
        case 'set-image-actividad-update-image':
            return {
                ...state,
                images: state.images.map(imagen => {
                    if (imagen.id === action.payload.id) {
                        imagen.imagen = action.payload.image;
                        return imagen;
                    }
                    return imagen;
                })
            };
        case 'set-images-actividad-active':
            const image = state.images.find(i => i.id === action.payload);
            return {
                ...state,
                image: image ? image : imageState.image
            };
        case 'set-modal-image-actividad':
            return {
                ...state,
                modalImagen: action.payload
            };

        case 'set-images-actividad-emty':
            return{
                ...state,
                images: [],
                image: imageState.image,
                modalImagen: false
            }

        default:
            return state
    }
}