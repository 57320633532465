import { GridRowId } from "@mui/x-data-grid";
import { Actividad, ActividadAction } from "../interfaces/actividad";
import { Imagen } from "../../../../../interfaces/image";

export const startSetActividadNoPlaneada = (actividad: Actividad): ActividadAction => ({
    type: 'set-actividad-no-planeada',
    payload: actividad,
});

export const startUpdateActividadNoPlaneada = (actividad: Actividad): ActividadAction => ({
    type: 'update-actividad-no-planeada',
    payload: actividad,
});

export const startDeleteActividadNoPlaneada = (id: GridRowId):ActividadAction => ({
    type: 'delete-actividad-no-planeada',
    payload: id,
});

export const startSetModadlActividadNoPlaneada = (modalActividad: boolean): ActividadAction => ({
    type: 'set-modal-actividad-no-planeada',
    payload: modalActividad,
});

export const startSetActiveActividadNoPlaneada = (id: GridRowId):ActividadAction => ({
    type: 'set-active-actividad-no-planeada',
    payload: id,
});

export const startSetActividadUpdateImageNoPlaneada = (image: Imagen, id: GridRowId): ActividadAction => ({
    type: 'set-actividad-update-image-no-planeada',
    payload: { image, id },
});

export const startSetActividadEmptyNoPlaneada = ():ActividadAction => ({
    type: 'set-actividad-empty-no-planeada',
});

export const startDelImgActividadNoPlaneada = (imgId: GridRowId, id: GridRowId): ActividadAction => ({
    type: 'set-delete-image-actividad-no-planeada',
    payload:{
        id,
        imgId
    }
})

export const startClearImagesActividadNoPlaneada = (payload: GridRowId): ActividadAction =>({
    type: 'set-clear-images-actividad-no-planeada',
    payload
})

export const startSetModImgActividadNoPlaneada = (payload: boolean): ActividadAction =>({
    type: 'set-modal-imagenes-actividad-no-planeada',
    payload
})