import { useSelector } from 'react-redux';
import {
  CssBaseline,
  Grid,
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppState } from '../reducers/rootReducer';


import { LoadingBackdrop } from '../components/ui/LoadingBackdrop';
import { UiAlert } from '../components/ui/UiAlert';
import { LoginBackGround } from '../components/login/LoginBackGround';
import { LoginMain } from '../components/login/LoginMain';

//const theme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#eb445ae6',
    },
    secondary: {
      main: '#9c27b0',
    },
  },
})

export const LoginScreen = () => {
  const { isLoading } = useSelector((state: AppState) => state.ui); 
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <LoginBackGround />
        <LoginMain />
      </Grid>
      {isLoading && <LoadingBackdrop />}
      <UiAlert />
    </ThemeProvider>
  );
};
