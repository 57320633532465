import { Box, Button, Paper, Typography, } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { useFormBasico } from '../hooks';
import { useMobileDetect } from '../../../hooks';
import { useSelector } from 'react-redux';
import { AppState } from '../../../reducers/rootReducer';

export const FormReporteScreenBasico = () => {
  const theme = useTheme();
  const { isMobile } = useMobileDetect();
  const { activeStep, handleBack, handleNext, maxSteps, steps } = useFormBasico();
  const { ubicacionInicial, actividades, actividadTipo } = useSelector((state: AppState) => state.reportes.reportes);
  const { personal, herramientas, imagesPrev, imagesFin } = useSelector((state: AppState) => state.reportes.modules);
  const { actividades: actividadesRealizadas, responsableBeling, responsableCliente, sello } = useSelector((state: AppState) => state.shared);
  
  const responsableB = responsableBeling.responsableBeling[0];
  const responsableC = responsableCliente.responsableCliente[0];

  return (
    <Box
      width={!isMobile ? 600 : undefined}
      height={!isMobile ?  400 : undefined}
      sx={{ flexGrow: 1, minHeight: '70%' }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          bgcolor: "background.default",
        }}
      >
        <Typography>{steps[activeStep].label}</Typography>
      </Paper>
      <Box
        component="div"
        sx={{
          minHeight: isMobile ? 170: 300,          
          width: "100%",
        }}
      >
        {steps[activeStep].component}
      </Box>
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={
              activeStep === maxSteps - 1 
              || ( activeStep === 0 && ubicacionInicial === null) 
              || (activeStep === 1 && personal.personal.length === 0) 
              || (activeStep === 4 && herramientas.herramientas.length === 0)
              || (activeStep === 5 && actividadTipo.length === 0)
              || (activeStep === 6 && actividades.length <= 10)
              || (activeStep === 7 && (actividadesRealizadas.actividades.length === 0 || ubicacionInicial === null))
              || (activeStep === 8 && imagesPrev.images.length === 0)
              || (activeStep === 9 && imagesFin.images.length === 0)
              || (activeStep === 11 && (responsableB.nombre.length <= 3 || responsableB.identificacion.length <= 5 || responsableB.firma.name.length === 0))
              || (activeStep === 12 && (responsableC.nombre.length <= 3 || responsableC.identificacion.length <= 5 || responsableC.firma.name.length === 0 || sello.selloFisico === null))
            }           
          >
            
            Siguiente
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Anterior
          </Button>
        }
      />
    </Box>
  );
};
