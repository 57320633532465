import { Unidad } from "../interfaces/shared";


export const unidades = [
    Unidad.emty,
    Unidad.bolsa,
    Unidad.bulto,
    Unidad.caja,
    Unidad.gramo,
    Unidad.kilo,
    Unidad.libra,
    Unidad.litro,
    Unidad.metro,
    Unidad.pte,
    Unidad.unidad,        
]