import { GridRowId } from "@mui/x-data-grid";
import { RutinaRectificador, RutinaRectificadorAction } from "../interfaces/rutina";
import { Imagen } from "../../../../../interfaces/image";

export const startSetRutinasRectificador = (rutina: RutinaRectificador):RutinaRectificadorAction => ({
    type: 'set-rutina-rectificador',
    payload: rutina,
});

export const startUpdateRutinasRectificador = (rutina: RutinaRectificador):RutinaRectificadorAction => ({
    type: 'update-rutina-rectificador',
    payload: rutina,
});

export const startDeleteRutinasRectificador = (id: GridRowId):RutinaRectificadorAction => ({
    type: 'delete-rutina-rectificador',
    payload: id,
});

export const startSetRutinaRectificadorId = (id: GridRowId):RutinaRectificadorAction => ({
    type: 'set-rutina-rectificador-id',
    payload: id,
});

export const startSetRutinaUpdateImageRectificador = (image: Imagen, id: GridRowId):RutinaRectificadorAction => ({
    type: 'set-rutina-update-image-rectificador',
    payload: {
        image,
        id,
    },
});

export const startSetModalRectificador = (modal: boolean):RutinaRectificadorAction => ({
    type: 'set-rutina-modal',
    payload: modal,
});

export const startSetRutinaRectificadorEmpty = ():RutinaRectificadorAction => ({
    type: 'set-rutina-rectificador-empty',
});

export const startSetModalRutinaRectificador = (payload: boolean): RutinaRectificadorAction =>({
    type: 'set-modal-select-rutina',
    payload
})

export const startSetActiveRutinaRectificador = (payload: GridRowId): RutinaRectificadorAction =>({
    type: 'set-active-rutina-rectificador',
    payload
})

export const startDelImgRutinaRectificador = (imgId: GridRowId, id: GridRowId): RutinaRectificadorAction => ({
    type: 'set-delete-image-rutina-rectificador',
    payload:{
        id,
        imgId
    }
})

export const startClearImagesRutina = (payload: GridRowId): RutinaRectificadorAction =>({
    type: 'set-clear-images-rutina',
    payload
})

export const startSetModImgRRectificador = (payload: boolean): RutinaRectificadorAction =>({
    type: 'set-modal-imagenes-rutina-rectificador',
    payload
})