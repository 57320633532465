import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { Ats } from "../../../../interfaces/ats";
import { Title } from "../../../../../../components/pdf/Title";
import { RiesgoHead } from "../../../../../../components/pdf/RiesgoHead";
import { RiesgoRow } from "../../../../../../components/pdf/RiesgoRow";
import { ConfirmarOpcion } from "../../../../../../components/pdf/ConfirmarOpcion";

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    border: 1,
  },
});

const title = "Riesgos Eléctricos.";
const namesUno = ["¿Se realiza la actividad con Tensión?:"];
interface Props {
  active: Ats;
}
export const RiegosElectricos = ({ active }: Props) => {
  return (
    <>
      <ConfirmarOpcion title={namesUno} value={active.trabajoTension!.toUpperCase()} />
      <Title title={title} sx={{ fz: 12, fs: 'italic', fw: 'bold' }} />
      <View style={styles.tableContainer}>
        <RiesgoHead />
        <RiesgoRow items={active.riesgosElectrico!} />
      </View>
    </>
  );
};
